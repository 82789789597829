import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
'Government', 
'Military', 
'Scheduled Commercial Air Transport Services', 
'Non-Scheduled Commercial Air Transport Services', 
'General Aviation', 
'Cargo/Freight'
];

const optionsSpanish = [
'Gobierno',
'Militar',
'Servicios Regulares de Transporte A\xE9reo Comercial',
'Servicios de Transporte A\xE9reo Comercial No Regular',
'Aviaci\xF3n General',
'Carga/Flete'
];

export default function FlightCategoryDropdown() {

    //const [age, setAge] = React.useState('');
    const setflightcategory = useCentroStore((state) => state.setflightcategory);
    const setsubcategory = useCentroStore((state) => state.setflightsubcategory);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

    const handleChange = (event) => {
      //setAge(event.target.value);
      //var id = options.indexOf(event.target.value);
      setflightcategory(event.target.value);
    };

    const isMounted = React.useRef(false); // For help not running during First Render

    // For clearing Flight sub category if a new category is selected
    React.useEffect(() => {

        if (isMounted.current) {
            if (currentIncidentReport.flightSubcategory !== "") {
                setsubcategory("");
            }
        } else {
            isMounted.current = true;
            return;
        }
    }, [currentIncidentReport.flightCategory]);

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentIncidentReport.flightCategory}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
          options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          )) 
          :
          optionsSpanish.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}


