import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Slide, makeStyles } from "@mui/material";
import { red } from "@mui/material/colors";
import { useCentroStore } from "../../store";

//const useStyles = makeStyles(() => ({
//    dialog: {
//        borderRadius: 0
//    },
//    button: {
//        borderRadius: 0,
//        textTransform: "none",
//        padding: 5
//    },
//    logout: {
//        color: "#fff",
//        backgroundColor: red[500],
//        "&:hover": {
//            backgroundColor: red[700]
//        }
//    },
//    countdown: {
//        color: red[700]
//    }
//}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref}{...props} />;
});

const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
    //const classes = useStyles();
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}>
            <DialogTitle sx={{ textTransform: "uppercase" }}>
                {EnglishLanguage ? "Session Timeout" : "Hora de t\xE9rmino de la sesi\xF3n"}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    {EnglishLanguage ? "The current session is about to expire in " : "La sesi\xF3n actual est\xE1 a punto de caducar en "}
                    <span>{countdown}</span>{EnglishLanguage ? " seconds." : " segundos."}
                </Typography>
                <Typography variant="body2">{EnglishLanguage ? `Would you like to continue the session?` : `Quieres continuar con la sesi\xF3n?`} </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onLogout}
                    variant="contained"
                    className="logoutSession"> {EnglishLanguage ? "Logout" : "Cerrar Sesi\xF3n"} </Button>
                <Button
                    onClick={onContinue}
                    variant="contained"> {EnglishLanguage ? "Continue Session" : "Continuar Sesi\xF3n"} </Button>
            </DialogActions>
        </Dialog>
        );
}
export default SessionTimeoutDialog;