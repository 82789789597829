import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [  'Runway',
'Taxiway',
'Other',];

const optionsSpanish = [  'Pista',
'Calle de Rodaje',
'Otro',];

export default function OccurrenceSurfaceDropdown() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setIsOccurrenceSurface = useCentroStore((state) => state.setoccurrenceSurface);
    const currentincidentreport = useCentroStore ((state) => state.currentincidentreport);

  const handleChange = (event) => {
      setIsOccurrenceSurface(event.target.value);
      console.log(currentincidentreport);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentincidentreport.occuranceSurface}
          onChange={handleChange}
          displayEmpty
          
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
          options.map((options) => (
            <MenuItem
              key={options}
              value={options}
            >
              {options}
            </MenuItem>
          ))
          :
          optionsSpanish.map((options) => (
            <MenuItem
              key={options}
              value={options}
            >
              {options}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}

