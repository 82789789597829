import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from "../../../../store";
import { useFacilities } from '../../../../store/queries/facilityQueries';

export default function IncidentFacilityDropdown() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setReportingFacilityId = useCentroStore((state) => state.setreportingfacilityid);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setFacPositions = useCentroStore((state) => state.setFacilityPosList);
    const { facilities } = useFacilities();

    const handleChange = (event) => {

        setReportingFacilityId(event.target.value);
        var fac = facilities?.data?.data.find(f => f.facilityId === parseInt(event.target.value));
        setFacPositions(fac.facilityPositionModels);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentIncidentReport.reportingFacilityId}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
              >
                  <MenuItem disabled value="0">
                      <span  className='placeholder-text'>{EnglishLanguage ? ("Select") : ("Seleccionar")}</span>
                  </MenuItem>
                  {facilities?.data?.data.map(({ facilityId, name }) =>
                      <MenuItem

                          key={facilityId}
                          value={facilityId}>
                          {name}
                      </MenuItem>

                  )}
          {/*<MenuItem value="">*/}
          {/*BWI {"(Default)"}*/}
          {/*</MenuItem>*/}
          {/*<MenuItem value={10}>DCA</MenuItem>*/}
          {/*<MenuItem value={20}>IAD</MenuItem>*/}
        </Select>
      </FormControl>

    </Box>
  );
}

