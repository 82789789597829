import * as React from "react";
import "./TopNavBar.css";
import Box from "@mui/material/Box";
import SettingsIcon from "@mui/icons-material/Settings";

//import sunnyimage from "../../assets/icons/md-sunny@2x.png";
import ThunderstormOutlinedIcon from "@mui/icons-material/ThunderstormOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import WaterOutlinedIcon from "@mui/icons-material/WaterOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import AirOutlinedIcon from "@mui/icons-material/AirOutlined";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Divider, Grid, Tooltip } from "@mui/material";
import AccountMenu from "../AccountMenu";
import MediaQuery from "react-responsive";
import { useCentroStore } from "../../store";
import { useWeather } from "../../store/queries/facilityQueries";
import PersonIcon from "@mui/icons-material/Person";
import { useState, useEffect } from "react";
import { useUser } from "../../store/queries/userQueries";
import { useQueryClient } from "react-query";
import ButtonBase from "@mui/material/ButtonBase";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';



export default function TopNavBar() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const currentFacility = useCentroStore((state) => state.currentFacility);
  var weatherInputModel = { facId: currentFacility.facilityId, language: EnglishLanguage };
  const { weatherForcast } = useWeather(currentFacility.facilityId);
  const WeatherTempMetric = useCentroStore((state) => state.WeatherTempMetric);
  const WeatherWindSpeedMetric = useCentroStore((state) => state.WeatherWindSpeedMetric);
  //Contains weather icon codes returned from the API, to help determined which MUI icon to use
  const weatherSunny = [
  '01d',
  '01n',
  ];
  const weatherCloudy = [
  '02d',  
  '02n', 
  '03d',
  '03n',
  '04d',
  '04n',
  ];
  const weatherStorm = [
  '09d',  
  '09n', 
  '10d',  
  '10n', 
  '11d',  
  '11n', 
  ];
  const weatherSnow = [
  '13d',  
  '13n', 
  ];
  const weatherMist = [
  '50d',  
  '50n', 
  ];

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const userId = useCentroStore((state) => state.userId);
  const { userData, editUser } = useUser(userId);
  const queryClient = useQueryClient();
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const activeRole = useCentroStore((state) => state.activeRole);
  const setActiveRole = useCentroStore((state) => state.setActiveRole);
  const [selectedRoleId, setSelectedRoleId] = useState(userData?.data?.data.roleId);
  const [openSnackbar, setOpenSnackbar] = useState(false);

 // const userRoles = ["Admin", "Safety Director", "Regional Manager", "Facility Manager", "ATCO", "CNS Tech", "Engineer"]; // Replace this with the actual roles array
  const [userRoles, setUserRoles] = useState(userData?.data?.data.roles);
  const [activeUserRole, setActiveUserRole] = useState(userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)));
  

  const handleRoleModalOpen = () => {
    setOpenRoleModal(true);
  };
  
  const handleRoleModalClose = () => {
    queryClient.refetchQueries({ queryKey: ["user", userId], type: 'active'}); // Refetching current user data, all users list data and to do list data
    queryClient.refetchQueries({ queryKey: ["users"], type: 'active'});
    queryClient.refetchQueries({ queryKey: ['toDoList', userId], type: 'active'});
    setOpenRoleModal(false);
  };

  const handleRoleChange = (event) => {
    setSelectedRoleId(event.target.value);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  
  if (weatherForcast.isLoading) {
    return (
      <>
        <MediaQuery minWidth={1025}>
          <Box sx={{ flexGrow: 1 }} display="flex" className="top-nav-container">
            <Grid container wrap="nowrap" spacing={0} flexDirection="row" justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Grid container justifyContent="flex-start" flexDirection="column" mt={0.5}>
                  <Grid item flexDirection="row" justifyContent="flex-start" alignItems="center" display="flex">
                    {/* <PersonIcon sx={{margin: "0!important", padding: "0!important"}} /> */}
                    <span className="user-welcome-wrap">
                      <span className="white"> Welcome</span>{" "}
                      <Link to="/user-profile">
                        {userData?.data?.data.firstName} {userData?.data?.data.lastName}
                      </Link>{" "}
                      {userData?.data?.data.roleId}
                    </span>
                  </Grid>
                  <Grid item className="weather-text">
                    <Typography
                      variant="h6"
                      className="weather-icon-wrap"
                      sx={{
                        fontWeight: "500",
                        textTransform: "uppercase",
                        color: "#FFF",
                        marginTop: "0px!important",
                      }}>
                      {EnglishLanguage ? "Loading Weather..." : "Cargando Clima..."}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </MediaQuery>
        <MediaQuery maxWidth={1024}>
          <Box sx={{ flexGrow: 1 }} display="flex" className="top-nav-container">
            <Grid container spacing={3} flexDirection="row">
              <Grid item sx={{ paddingLeft: "10px!important", paddingTop: "10px!important", position: "relative", top: "5px" }}>
                <Grid container justifyContent="flex-start">
                  <Grid item className="weather-text"></Grid>
                  <Grid container justifyContent="flex-start"></Grid>
                </Grid>
              </Grid>
              <Grid item className="settings-wrapper">
                <Link to="/user-settings" style={{}}>
                  <SettingsIcon />
                </Link>
              </Grid>
              <Tooltip open={tooltipOpen} onClose={() => setTooltipOpen(false)} title={EnglishLanguage ? "Click for user options." : "Haga clic para opciones de usuario."}>
                <Grid item className="account-wrapper">
                  <AccountMenu />
                </Grid>
              </Tooltip>
            </Grid>
          </Box>
        
        </MediaQuery>
      </>
    );
  }

  return (
    <>
      <MediaQuery minWidth={1025}>
        <Box sx={{ flexGrow: 1 }} display="flex" className="top-nav-container">
          <Grid container spacing={0} flexDirection="row" justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Grid container justifyContent="flex-start" flexDirection="column" mt={0.5}>
                <Grid item flexDirection="row" justifyContent="flex-start" alignItems="center" display="flex">
                  {/* <PersonIcon sx={{margin: "0!important", padding: "0!important"}} /> */}
                  <span className="user-welcome-wrap">
                    {/* <span className="white"> Welcome</span> */}
                    <Grid container alignItems="center" className="username-role-wrap">
                      <Grid item>
                      <Tooltip title={EnglishLanguage ? "Click here to view your user profile" : "Haga clic aqu\xED para ver su perfil de usuario"}>
                        <Link to="/user-profile">
                  {userData?.data?.data.firstName} {userData?.data?.data.lastName}
                        </Link>
                        </Tooltip>
                      </Grid>
                      <Divider
                        light={true}
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                          backgroundColor: "#FFF",
                          marginLeft: 1,
                          marginRight: 1,
                          alignSelf: "center",
                          height: "1.5em",
                        }}
                      />
                  <Grid item onClick={handleRoleModalOpen}>
                    <Tooltip title={EnglishLanguage ? "Click here to change your role" : "Haga clic aqu\xED para cambiar su rol"}>
                      <ButtonBase >
                        <Typography variant="h6">{activeUserRole?.name}</Typography>
                      </ButtonBase>
                    </Tooltip>
                  </Grid>
                    </Grid>
                  </span>
                </Grid>
                <Grid item className="weather-text">
                  <Typography
                    variant="h6"
                    className="weather-icon-wrap"
                    sx={{
                      fontWeight: "500",
                      textTransform: "uppercase",
                      color: "#FFF",
                      marginTop: "0px!important",
                    }}>
                    {currentFacility.facilityId === "" ? "" : currentFacility.city + ", "}
                    {currentFacility.facilityId === "" ? "" : currentFacility.state}
                    {currentFacility.facilityId === "" ? "" : " - " + weatherForcast?.data?.data.weatherMain}
                    {currentFacility.facilityId === "" ? (
                      ""
                    ) : weatherSunny.includes(weatherForcast?.data?.data?.icon?.substr(0, 3)) ? (
                      <WbSunnyOutlinedIcon />
                    ) : weatherCloudy.includes(weatherForcast?.data?.data?.icon?.substr(0, 3)) ? (
                      <CloudOutlinedIcon />
                    ) : weatherStorm.includes(weatherForcast?.data?.data?.icon?.substr(0, 3)) ? (
                      <ThunderstormOutlinedIcon />
                    ) : weatherSnow.includes(weatherForcast?.data?.data?.icon?.substr(0, 3)) ? (
                      <AcUnitOutlinedIcon />
                    ) : weatherForcast?.data?.data.weatherMain === "" ? (
                      ""
                    ) : (
                      <WaterOutlinedIcon />
                    )}
                  </Typography>
                </Grid>
                <Grid container justifyContent="flex-start">
                  <Grid item className="weather-text">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "500",
                        textTransform: "uppercase",
                        color: "#FFF",
                        marginTop: "0px!important",
                        position: "relative",
                        left: "1px",
                      }}>
                      {currentFacility.facilityId === ""
                        ? ""
                        : WeatherTempMetric
                        ? weatherForcast?.data?.data.mainTempCRounded + " °C"
                        : weatherForcast?.data?.data.mainTempF + " °F"}
                    </Typography>
                  </Grid>
                  <Grid item className="weather-text">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "500",
                        textTransform: "uppercase",
                        color: "#FFF",
                        marginTop: "0px!important",
                        position: "relative",
                        left: "10px",
                      }}>
                      {currentFacility.facilityId === ""
                        ? ""
                        : (EnglishLanguage ? "Humidity: " : "Humedad: ") + weatherForcast?.data?.data.humidity + "%"}
                    </Typography>
                  </Grid>
                  <Grid item className="weather-text">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "500",
                        textTransform: "uppercase",
                        color: "#FFF",
                        marginTop: "0px!important",
                        position: "relative",
                        left: "10px",
                      }}>
                      {currentFacility.facilityId === ""
                        ? ""
                        : (EnglishLanguage ? "Wind: " : "Viento: ") +
                          (WeatherWindSpeedMetric
                            ? weatherForcast?.data?.data.windSpeedKm + " km/h"
                            : weatherForcast?.data?.data.windSpeedM + " mph")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="settings-wrapper" ml={2}>
              <Tooltip title={EnglishLanguage ? "Settings" : "Configuraci\xF3n"}>
                <Link to="/user-settings" style={{}}>
                  <SettingsIcon />
                </Link>
              </Tooltip>
            </Grid>
   
              <Grid item className="account-wrapper">
                <AccountMenu />
              </Grid>
        
          </Grid>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <Box sx={{ flexGrow: 1 }} display="flex" className="top-nav-container">
          <Grid container spacing={3} flexDirection="row">
            <Grid item sx={{ paddingLeft: "10px!important", paddingTop: "10px!important", position: "relative", top: "5px" }}>
              <Grid container justifyContent="flex-start">
                <Grid item className="weather-text"></Grid>
                <Grid container justifyContent="flex-start"></Grid>
              </Grid>
            </Grid>
            <Grid item className="settings-wrapper">
              <Link to="/user-settings" style={{}}>
                <SettingsIcon />
              </Link>
            </Grid>
       
              <Grid item className="account-wrapper">
                <AccountMenu />
              </Grid>
       
          </Grid>
        </Box>
        
      </MediaQuery>
      <Dialog
      open={openRoleModal}
      onClose={handleRoleModalClose}
      aria-labelledby="change-role-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="change-role-dialog-title">{EnglishLanguage ? "Change Your Role" : "Cambiar su Rol"}</DialogTitle>
      <DialogContent>
        <Typography>{EnglishLanguage ? "Select one of the options below to change your role. Your roles are assigned by your ANSP." 
                      : "Seleccione una de las siguientes opciones para cambiar su rol. Sus roles son asignados por su ANSP."}</Typography>
        <RadioGroup
  value={selectedRoleId}
  onChange={handleRoleChange}
  row={false}
  sx={{ marginTop: 2 }}
>
  {userRoles.map((role) => (
    <FormControlLabel key={role.id} value={role.id} control={<Radio />} label={role.name} />
  ))}
  </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRoleModalClose}>{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
        <Button
  onClick={async () => {
    //console.log(userData);
    // Setting selected default role in users db
    var tempUserData = userData?.data?.data;
    tempUserData.roleId = selectedRoleId;
    var response = await editUser.mutateAsync(tempUserData);
      if (response.status !== 200) {
          if (EnglishLanguage) {
              alert("User Role Switch Failed.");
          }
          else {
              alert("Cambio de rol de usuario fallido.");
          }
          handleRoleModalClose();
       } else { // Success
          setActiveRole(selectedRoleId);
          setActiveUserRole(userData?.data?.data.roles.find(element => element.id === parseInt(selectedRoleId)));
          handleRoleModalClose();
          setOpenSnackbar(true); // Add this line to display the success toast
       }
    
  }}
  color="primary"
  variant="contained"
>
          {EnglishLanguage ? "Change Role" : "Cambriar su Rol"}
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleCloseSnackbar}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
>
  <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
    {EnglishLanguage ? "You have successfully changed your selected role." : "Ha cambiado correctamente el rol seleccionado"}
  </Alert>
</Snackbar>
    </>
  );
}
