import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useCentroStore } from "../../../../store";

export default function AircraftTwoTypeUnknownCheckboxes() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setAircraftTwoUnknownType = useCentroStore((state) => state.setAircraftTwoTypeUnknown);
    const addUnknowns = useCentroStore((state) => state.addAircraftTwoUnknowns);
    const currentAircraftTwoUnknowns = useCentroStore((state) => state.currentAircraftTwoUnknowns);
    const removeUnknowns = useCentroStore((state) => state.removeAircraftTwoUnknowns);

    const aircraft = useCentroStore((state) => state.aircraftTwo);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);

    const handleChange = (event, values) => {
        if (values.length > 0) {
            addUnknowns(values);
        }
        else {
            removeUnknowns([]);
        }
    };
    const handleBlur = (event) => {
        var unknownTypes = currentAircraftTwoUnknowns.map(({ title }) => title);
        var unknownsForInsert = "";
        unknownTypes.forEach((item, i , arr) => {
            if (unknownsForInsert === "") {
                unknownsForInsert = item + '.';
            }
            else if (arr.length - 1 === i) {
                unknownsForInsert += item;
            }
            else {
                unknownsForInsert += item + '.';
            }
        });
        setAircraftTwoUnknownType(unknownsForInsert);
        var tempAircraftList = [];
        tempAircraftList[1] = aircraft;
        setaircraftsList(tempAircraftList);
    }


  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={EnglishLanguage ? types : typesSpanish}
      disableCloseOnSelect
      size="small"
      limitTags='3'
      
      color="error"
          getOptionLabel={(option) => option.title}
          value={currentAircraftTwoUnknowns === [] ? "" : currentAircraftTwoUnknowns }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            style={{ margin:0, marginRight: 8, padding: 0, color: "#f7b01a", background:"white!important", width: "auto"}}
            checked={selected}
          />
          {option.title}
        </li>
          )}
          onChange={handleChange}
          onBlur={handleBlur }
      style={{ width: "auto" }}
      renderInput={(params) => (
        <TextField {...params} label={EnglishLanguage ? ("Select") : ("Seleccionar")} placeholder={EnglishLanguage ? ("Select") : ("Seleccionar")}  />
      )}
    />
  );
}
const types = [
  { title: "High Wing" },
  { title: "Mid Wing" },
  { title: "Low Wing"},
  { title: "Rotocraft"},
  { title: "1 Engine" },
  { title: "2 Engine" },
  { title: "3 Engine" },
  { title: "4 Engine" },
  { title: "> 4 Engine" },
];

const typesSpanish = [
    { title: "Ala Alta" },
    { title: "Ala Media" },
    { title: "Ala Baja" },
    { title: "Ala Rotatoria" },
    { title: "1 Motor" },
    { title: "2 Motor" },
    { title: "3 Motor" },
    { title: "4 Motor" },
    { title: "> 4 Motor" },
];