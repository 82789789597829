import * as React from 'react';
import { useState, useEffect } from "react";
import { IconButton } from '@mui/material';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { MuiFileInput } from 'mui-file-input'
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { useCentroStore } from "../../../../store";
import { useAttachments } from "../../../../store/queries/incidentReportQueries";

export default function FFUploadButton() {
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const { reportAttachments, createAttachment, deleteAttachment } = useAttachments({ params: { irId: currentIncidentReport.irId, tab: 7 } });
    //const irAttachment = useCentroStore((state) => state.irAttachment);
    const [files, setFiles] = React.useState(null);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const MAX_FILES = 5; // Const variable to determine max number of files to be uploaded
    let maxFilesReached = false; // Variable to help determine if the MAX_FILES has been reached
    const [uploadInProgress, setuploadInProgress] = useState(false); // Variable to help detect if an upload is in progress

    // Function to assist with converting byte[] to array buffer for file download
    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    // Function to perform file download
    function saveByteArray(fileName, byte, contentType) {
        var blob = new Blob([byte], { type: contentType });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var downloadName = fileName;
        link.download = downloadName;
        link.click();
    };

    const handleDownload = async (params) => {
        var arrayBuffer = base64ToArrayBuffer(params.row.attachment);
        saveByteArray(params.row.attachmentName, arrayBuffer, params.row.contentType);
    }

    // Function for retrieving file name extension
    function getFileExtension(fileName) {
        return ('.' + fileName.split('.').pop());
    };

    const handleChange = async (newFiles) => {
        setFiles(newFiles); // console.log(newFiles);

        // Checking for oversized files and wrong file types
        var oversizedFiles = [];
        // Allowed file type extensions for upload, Documents/PDF, Images, Videos, Audio
        var wrongTypeFiles = [];
        var permittedExtensions = [
            ".doc", ".docx", ".pdf", ".odt", ".xls", ".xlsx", ".ods", ".txt",
            ".jpg", ".jpeg", ".png", ".webp", ".tiff", ".tif", ".heif", ".heic", ".bmp",
            ".mp4", ".mpg", ".mov", ".wmv", ".flv", ".avi",
            ".mp3", ".wav", ".aiff", ".flac", ".m4a", ".ogg", ".aac", ".wma"];
        for (let i = 0; i < newFiles.length; i++) {
            if (newFiles[i].size > 104857600) {
                oversizedFiles.push(newFiles[i].name);
                newFiles.splice(i, 1);
                i -= 1;
            }
            else if (!permittedExtensions.includes(getFileExtension(newFiles[i].name))) {
                wrongTypeFiles.push(newFiles[i].name);
                newFiles.splice(i, 1);
                i -= 1;
            }
        }

        if (oversizedFiles.length > 0) {
            var errorString = "The following files are too large and cannot be uploaded. Please reduce the size of the following files to below 100 mb:";
            if (!EnglishLanguage) {
                errorString = "Los siguientes archivos son demasiado grandes y no se pueden cargar. Reduzca el tama�o de los siguientes archivos a menos de 100 mb: ";
            }
            for (let i = 0; i < oversizedFiles.length; i++) {
                errorString += ("\n" + oversizedFiles[i])
            }
            alert(errorString);
        }
        if (wrongTypeFiles.length > 0) {
            var errorString = "The following files have an unpermitted type and cannot be uploaded for security reasons:";
            if (!EnglishLanguage) {
                errorString = "Los siguientes archivos tienen un tipo no permitido y no se pueden cargar por razones de seguridad:";
            }
            for (let i = 0; i < wrongTypeFiles.length; i++) {
                errorString += ("\n" + wrongTypeFiles[i])
            }
            alert(errorString);
        }

        let response;

        // If current report attachments number plus uploaded files number is > MAX_FILES, don't proceed
        if (reportAttachments?.data?.data === undefined) {
            if (newFiles.length > MAX_FILES) {
                if (EnglishLanguage) {
                    alert("File's not uploaded. Upload Limit Reached, Please make sure you are uploading " + MAX_FILES + " files or less.");
                }
                else {
                    alert("El archivo no est\xE1 cargado. Se alcanz\xF3 el l\xEDmite de carga. Aseg\xFArese de cargar " + MAX_FILES + " archivos o menos.");
                }
                maxFilesReached = true;
            }
            else {
                setuploadInProgress(true);// Update uploadInProgress
                maxFilesReached = false;
                const formData = new FormData();
                formData.append("irId", currentIncidentReport.irId);
                formData.append("tab", 7); // Specifies the correct tab
                for (let i = 0; i < newFiles.length; i++) {
                    formData.append('attachmentFiles', newFiles[i]);
                }

                try {
                    response = await createAttachment.mutateAsync(formData);
                    if (response) {
                        setuploadInProgress(false);// Update uploadInProgress
                    }
                } catch (e) {
                    setuploadInProgress(false);// Update uploadInProgress
                    console.log("Exception caught: " + e);
                }
            }
        }
        else if (reportAttachments?.data?.data.length + newFiles.length > MAX_FILES) {
            if (EnglishLanguage) {
                alert("File's not uploaded. Upload Limit Reached, Please make sure you are uploading " + MAX_FILES + " files or less.");
            }
            else {
                alert("El archivo no est\xE1 cargado. Se alcanz\xF3 el l\xEDmite de carga. Aseg\xFArese de cargar " + MAX_FILES + " archivos o menos.");
            }
            maxFilesReached = true;
        }
        else {
            setuploadInProgress(true);// Update uploadInProgress
            maxFilesReached = false;
            const formData = new FormData();
            formData.append("irId", currentIncidentReport.irId);
            formData.append("tab", 7); // Specifies the correct tab
            for (let i = 0; i < newFiles.length; i++) {
                formData.append('attachmentFiles', newFiles[i]);
            }

            /* // Listing the contents
            for (var pair of formData.entries()) {
                console.log(pair[0] + ' - ' + pair[1]);
            }
            */

            try {
                response = await createAttachment.mutateAsync(formData);
                if (response) {
                    setuploadInProgress(false);// Update uploadInProgress
                }
            } catch (e) {
                setuploadInProgress(false);// Update uploadInProgress
                console.log("Exception caught: " + e);
            }
        }
    }

    // columns[] for DataGrid
    const columns = [
        { field: 'attachmentName', headerName: 'File Name', flex: 1 },
        { field: 'dateCreated', headerName: 'Upload Date (UTC)', flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
            },
        },
        { field: 'download', headerName: '', sortable: false, width: 50,
            renderCell: (params) => {
                const handleDownload = async () => {
                    console.log(params);
                    var arrayBuffer = base64ToArrayBuffer(params.row.attachment);
                    saveByteArray(params.row.attachmentName, arrayBuffer, params.row.contentType);
                }
                return <IconButton onClick={handleDownload}><SaveAltOutlinedIcon /></IconButton>;
            },
        },
        { field: 'delete', headerName: '', sortable: false, width: 50,
            renderCell: (params) => {
                const onClick = async () => {
                    await deleteAttachment.mutateAsync(params.id);
                };
                return <IconButton onClick={onClick}><ClearIcon className="checkbox-clear-icon" /></IconButton>;
            },
        },
    ];

    const columnsSpanish = [
        { field: 'attachmentName', headerName: 'Nombre del Archivo', flex: 1 },
        { field: 'dateCreated', headerName: 'Fecha de Carga (UTC)', flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
            },
        },
        {
            field: 'download', headerName: '', sortable: false, width: 50,
            renderCell: (params) => {
                const handleDownload = async () => {
                    console.log(params);
                    var arrayBuffer = base64ToArrayBuffer(params.row.attachment);
                    saveByteArray(params.row.attachmentName, arrayBuffer, params.row.contentType);
                }
                return <IconButton onClick={handleDownload}><SaveAltOutlinedIcon /></IconButton>;
            },
        },
        {
            field: 'delete', headerName: '', sortable: false, width: 50,
            renderCell: (params) => {
                const onClick = async () => {
                    await deleteAttachment.mutateAsync(params.id);
                };
                return <IconButton onClick={onClick}><ClearIcon className="checkbox-clear-icon" /></IconButton>;
            },
        },
    ];

    return (
        <div style={{ height: "calc(100vh - 21rem)", width: '100%' }}>
            <Button variant="contained" component="label">
                <FileUploadIcon /><div className="no-wrap-ua">{EnglishLanguage ? "Upload Attachments" : "Cargar Archivos Adjuntos"}</div>
                <MuiFileInput multiple={true} value={files === null ? "Filename" : files} onChange={handleChange} />{files !== null && (<DoneIcon className="checkbox-validation-icon" />)}
            </Button>
            <Typography
                sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                    cursor: "pointer",
                }}>

                {EnglishLanguage ? maxFilesReached ? `Maximum Number of Files Uploaded: ${MAX_FILES}` : `Upload Limit: ${MAX_FILES} , Size Limit Per File: 100MB`
                    : maxFilesReached ? `Archivos Cargados: ${MAX_FILES}` : `L\xEDmite de Carga: ${MAX_FILES} , L\xEDmite Por Archivo: 100MB`}
            </Typography>
            {uploadInProgress &&
                (<CircularProgress />)
            }
            <br />
            <DataGrid
                className={`ir-table-wrapper file-upload`}
                rows={reportAttachments?.data?.data === undefined ? [] : reportAttachments?.data?.data.filter(r => r.irId === currentIncidentReport.irId)}
                getRowId={(row) => row.irAttachmentId}
                columns={EnglishLanguage ? columns : columnsSpanish}
                pageSize={5}
                rowsPerPageOptions={[5]}
                //onRowClick={handleDownload}
                //checkboxSelection
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                localeText={{
                    noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                    noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                    toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                    toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                    toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                    toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir",
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            EnglishLanguage ?
                                `${from}-${to} of ${count}` :
                                `${from}-${to} de ${count}`,
                    },
                }}
            />
        </div>
    );
}