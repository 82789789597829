import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useCentroStore } from "../store";
import HeaderSection from "./header/HeaderSection";
import { useClearState } from "./Helpers/HelperFunctions";
import UserListTables from "./UserListTables";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import { useCSVReader, useCSVDownloader} from 'react-papaparse'; // For parsing CSV files
//import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useUser, useFacUsers, useAllUsers, useAllRoles, useAllFacilities } from '../store/queries/userQueries';
import { Dialog, DialogTitle, DialogContent, TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Switch, Button, DialogActions, Tooltip } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const UserList = (props) => {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const userId = useCentroStore((state) => state.userId);
    const { userData, addUser, addMultipleUsers } = useUser(userId);
    const userDataPermissions = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0];
    const currentFacility = useCentroStore((state) => state.currentFacility);
    //const { data: facUsersData } = useFacUsers(currentFacility.facilityId)
    const { data: usersData, refetch, isLoading } = useAllUsers();
    const { data: rolesData } = useAllRoles();
    const { data: facilitiesData } = useAllFacilities();
    //const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [open, setOpen] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState(null);
    const [currentUserRoleIds, setCurrentUserRoleIds] = React.useState([]);
    const [currentUserFacIds, setCurrentUserFacIds] = React.useState([]);
    const newUser = useCentroStore((state) => state.newUser);
    //const setNewUser = useCentroStore((state) => state.setNewUser);
    const [defaultRoleField, setDefaultRoleField] = React.useState("");
    const [defaultFacField, setDefaultFacField] = React.useState("");

    const [openMulti, setOpenMulti] = React.useState(false); // For opening add multi user modal
    const [multipleUsers, setMultipleUsers] = React.useState([]); // For containing multiple user data
    const [multipleUsersDataGrid, setMultipleUsersDataGrid] = React.useState([]); // For display multiple user data in datagrid
    const { CSVReader } = useCSVReader();
    const { CSVDownloader, Type } = useCSVDownloader(); // For downloading template
    const [uploadInProgress, setuploadInProgress] = React.useState(false); // Variable to help detect if an upload is in progress

    const [errorCode, setErrorCode] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState("");

    const handleOpen = () => {
        setuploadInProgress(false); // Update uploadInProgress
        if (userDataPermissions.createUser === 1) {
            setCurrentUser(newUser);
            // Setting clientID to match the admin user's
            setCurrentUser({
                ...currentUser,
                ['clientId']: userData?.data?.data.clientId,
                ['facilities']: [],
                ['roles']: [],
                ['facilityId']: 0
            });
            setOpen(true);
        }
        else {
            if (EnglishLanguage) {
                alert("You do not have permission to add users.");
            }
            else {
                alert("No tienes permiso para agregar usuarios.");
            }
        }
    };

    const handleClose = () => {
        setErrorCode(0);
        setErrorMsg("");
        setCurrentUser(null);
        setCurrentUserRoleIds([]);
        setCurrentUserFacIds([]);
        setDefaultRoleField("");
        setDefaultFacField("");
        setOpen(false);
    };

    const handleOpenMulti = () => {
        setuploadInProgress(false); // Update uploadInProgress
        if (userDataPermissions.createUser === 1) {
            setOpenMulti(true);
        }
        else {
            if (EnglishLanguage) {
                alert("You do not have permission to add users.");
            }
            else {
                alert("No tienes permiso para agregar usuarios.");
            }
        }
    };

    const handleCloseMulti = () => {
        setErrorCode(0);
        setErrorMsg("");
        setMultipleUsers([]);
        setMultipleUsersDataGrid([]);
        setCurrentUserRoleIds([]);
        setCurrentUserFacIds([]);
        setDefaultRoleField("");
        setDefaultFacField("");
        setOpenMulti(false);
    };

    const styles = { // For use in CSVReader
        csvReader: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10,
        },
        browseFile: {
            width: '20%',
            textTransform: 'uppercase',
            color: 'white',
            backgroundColor: '#55819b',
            border: 'none',
            cursor: 'pointer',
        },
        acceptedFile: {
            border: '1px solid #ccc',
            height: 45,
            lineHeight: 2.5,
            paddingLeft: 10,
            width: '80%',
        },
        progressBarBackgroundColor: {
            backgroundColor: '#55819b',
        },
    };
    
    const columnsUsersUpload = [
        { field: 'firstName', headerName: 'First Name', flex: 0.5 },
        { field: 'lastName', headerName: 'Last Name', flex: 0.5 },
        { field: 'email', headerName: 'Email', flex: 0.75 },
        { field: 'title', headerName: 'Position Title', flex: 0.75 },
        { field: 'roles', headerName: 'Assigned Roles', flex: 1,
            renderCell: (params) => (<Tooltip title={params.row.roles} arrow>
                <Typography variant='body2'>{params.row.roles}</Typography>
            </Tooltip>)
        },
        { field: 'defaultRole', headerName: 'Default Role', flex: 0.75 },
        { field: 'facilities', headerName: 'Assigned Facilities', flex: 1,
            renderCell: (params) => (<Tooltip title={params.row.facilities} arrow>
                <Typography variant='body2'>{params.row.facilities}</Typography>
            </Tooltip>)
        },
        { field: 'defaultFacility', headerName: 'Default Facility', flex: 1 }
    ];

    const columnsUsersUploadSpanish = [
        { field: 'firstName', headerName: 'Nombre', flex: 0.5 },
        { field: 'lastName', headerName: 'Apellido', flex: 0.5 },
        { field: 'email', headerName: 'Correo Electr\xF3nico', flex: 0.75 },
        { field: 'title', headerName: 'T\xEDtulo del Puesto', flex: 0.75 },
        { field: 'roles', headerName: 'Roles Asignados', flex: 1,
            renderCell: (params) => (<Tooltip title={params.row.roles} arrow>
                <Typography variant='body2'>{params.row.roles}</Typography>
            </Tooltip>)
        },
        { field: 'defaultRole', headerName: 'Rol Predeterminado', flex: 0.75 },
        { field: 'facilities', headerName: 'Instalaciones Asignadas', flex: 1,
            renderCell: (params) => (<Tooltip title={params.row.facilities} arrow>
                <Typography variant='body2'>{params.row.facilities}</Typography>
            </Tooltip>)
        },
        { field: 'defaultFacility', headerName: 'Instalaci\xF3n Predeterminada', flex: 1 }
    ];

    const handleTextFieldChange = (event, field) => {
        setCurrentUser(prevState => ({
            ...prevState,
            [field]: event.target.value
        }));
    };

    const handleSelectRoleChange = (event, field) => {
        setDefaultRoleField(event.target.value);
        setCurrentUser({
            ...currentUser,
            [field]: event.target.value,
        });
    };

    const handleSelectMultiRoleChange = (event, field) => {
        //console.log(event.target.value);
        //console.log(currentUser.roles);
        setCurrentUserRoleIds(event.target.value);
        let tempValues = [];
        (event.target.value).forEach(element => tempValues.push(rolesData.find(item => item.id === element)));
        setDefaultRoleField(tempValues[tempValues.length - 1].id); // Automatically set default role field
        setCurrentUser({
            ...currentUser,
            [field]: tempValues,
            ['roleId']: tempValues[tempValues.length - 1].id,
        });
    };

    const handleSelectFacChange = (event, field) => {
        setDefaultFacField(event.target.value);
        setCurrentUser({
            ...currentUser,
            [field]: event.target.value,
        });
    };

    const handleSelectMultiFacChange = (event, field) => {
        setCurrentUserFacIds(event.target.value);
        let tempValues = [];
        (event.target.value).forEach(element => tempValues.push(facilitiesData.find(item => item.facilityId === element)));
        setDefaultFacField(tempValues[tempValues.length - 1].facilityId); // Automatically set default fac field
        setCurrentUser({
            ...currentUser,
            [field]: tempValues,
            ['facilityId']: tempValues[tempValues.length - 1].facilityId,
        });
    };

    /*const handleSwitchChange = (event, field) => {
        setCurrentUser(prevState => ({
            ...prevState,
            [field]: event.target.checked
        }));
    };*/

    const handleSave = async () => {
        //console.log(currentUser); // to log the updated user data
        setErrorCode(0);
        setuploadInProgress(true); // Update uploadInProgress
        // Check to make sure duplicate e-mail addresses aren't being added
        if (currentUser.email === undefined) {
            setErrorCode(400);
            if (EnglishLanguage)
                setErrorMsg("There was an error creating a New User. E-mail Address is required.");
            else
                setErrorMsg("Hubo un error al crear un nuevo usuario. Se requiere Direcci\xF3n de correo electr\xF3nico.");
            setuploadInProgress(false); // Update uploadInProgress
            return;
        }
        const emailExistsInDb = usersData.find(element => element.email.toLowerCase() === currentUser.email.toLowerCase());
        if (emailExistsInDb !== undefined) {
            setErrorCode(400);
            if (EnglishLanguage) 
                setErrorMsg("There was an error creating a New User. This E-mail Address is already tied to another User.");
            else 
                setErrorMsg("Hubo un error al crear un nuevo usuario. Esta direcci\xF3n de correo electr\xF3nico ya est\xE1 vinculada a otro usuario.");
            setuploadInProgress(false); // Update uploadInProgress
            return;
        }

        // Check for Assigned Roles or Default Facility not being selected
        if (currentUser.facilityId === 0 || currentUser.roles.length === 0) {
            setErrorCode(400);
            if (EnglishLanguage) 
                setErrorMsg("There was an error creating a New User. Required fields are missing.");
            else 
                setErrorMsg("Hubo un error al crear un nuevo usuario. Faltan campos obligatorios.");
            setuploadInProgress(false); // Update uploadInProgress
            return;
        }

        let response;
        response = await addUser.mutateAsync(currentUser).catch(function (error) {
            setErrorCode(error.response.status);
            if (error.response.status === 400) {
                if (EnglishLanguage)
                    setErrorMsg("There was an error creating a New User. Required fields are missing.");
                else
                    setErrorMsg("Hubo un error al crear un nuevo usuario. Faltan campos obligatorios.");
                setuploadInProgress(false); // Update uploadInProgress
                return;
            }
            else {
                setErrorMsg(error.message);
                setuploadInProgress(false); // Update uploadInProgress
                return;
            }
        });
        if (response.status === 200) {
            refetch(); // Refetch query to update datagrid
            queryClient.refetchQueries({ queryKey: ["facUsers", currentFacility.facilityId], type: 'active' }); // Refetch fac user data
            setuploadInProgress(false); // Update uploadInProgress
            if (EnglishLanguage) {
                alert("New User has been successfully created.");// Successful create message
            }
            else {
                alert("El nuevo usuario se ha creado con \xE9xito.");
            }
        }
        else {
            setErrorCode(response.status);
            setErrorMsg(response.statusText);
            if (EnglishLanguage) 
                alert("There was an error creating a New User. User could not be created.");// Error
            else 
                alert("Hubo un error al crear un nuevo usuario. No se pudo crear el usuario.");
            setuploadInProgress(false); // Update uploadInProgress
            return;
        }
        setuploadInProgress(false); // Update uploadInProgress
        handleClose();
    };

    const handleSaveMulti = async () => {
        //console.log(multipleUsers); // to log the updated user data
        setErrorCode(0);
        setuploadInProgress(true); // Update uploadInProgress
        if (multipleUsers.length === 0) { // Check for blank so you cant submit an empty list
            setErrorCode(400);
            if (EnglishLanguage)
                setErrorMsg("There was an error creating New Users. Users List cannot be blank.");
            else
                setErrorMsg("Hubo un error al crear nuevos usuarios. La lista de usuarios no puede estar en blanco.");
            setuploadInProgress(false); // Update uploadInProgress
            return;
        }
        
        let response;
        response = await addMultipleUsers.mutateAsync(multipleUsers).catch(function (error) {
            setErrorCode(error.response.status);
            if (error.response.status === 400) {
                if (EnglishLanguage)
                    setErrorMsg("There was an error creating New Users. Required fields are missing.");
                else
                    setErrorMsg("Hubo un error al crear nuevos usuarios. Faltan campos obligatorios.");
                setuploadInProgress(false); // Update uploadInProgress
                return;
            }
            else {
                setErrorMsg(error.message);
                setuploadInProgress(false); // Update uploadInProgress
                return;
            }
        });
        if (response?.status === 200) {
            setuploadInProgress(false); // Update uploadInProgress
            refetch(); // Refetch query to update datagrid
            queryClient.refetchQueries({ queryKey: ["facUsers", currentFacility.facilityId], type: 'active' }); // Refetch fac user data
            if (EnglishLanguage) 
                alert("New Users have been successfully created.");// Successful create message
            else 
                alert("Los nuevos usuarios se han creado con \xE9xito.");
            handleCloseMulti();
        }
        else {
            setuploadInProgress(false); // Update uploadInProgress
            refetch(); // Refetch query to update datagrid
            queryClient.refetchQueries({ queryKey: ["facUsers", currentFacility.facilityId], type: 'active' }); // Refetch fac user data
            setErrorCode(400);
            if (EnglishLanguage)
                setErrorMsg("There was an error creating New Users. User could not be created."); // Error
            else 
                setErrorMsg("Hubo un error al crear nuevos usuarios. No se pudo crear el usuario.");
            return;
        }
    };

    const modalBody = 
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ textTransform: "capitalize" }}>{EnglishLanguage ? "Add User" : "Agregar Usuario"}</DialogTitle>
            <DialogContent>
                <Typography>{EnglishLanguage ? "First Name" : "Nombre"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "First Name" : "Nombre"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'firstName')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Last Name" : "Apellido"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Last Name" : "Apellido"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'lastName')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Email Address" : "Correo Electr\xF3nico"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Email Address" : "Correo Electr\xF3nico"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'email')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Position Title" : "T\xEDtulo del Puesto"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Position Title" : "T\xEDtulo del Puesto"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'title')} fullWidth margin="normal" />
                <Tooltip title={EnglishLanguage ? "Assign single or multiple Roles to this user. If there are no Roles, they will need to be created in the Roles page."
                    : "Asigne uno o varios roles a este usuario. Si no hay roles, deber\xE1n crearse en la p\xE1gina Roles"} arrow>
                    <Typography><br />{EnglishLanguage ? "Assigned Roles" : "Roles Asignados"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                </Tooltip>
                <FormControl fullWidth margin="normal">
                    <Select variant="filled" multiple value={currentUserRoleIds || [""]} onChange={(event) => handleSelectMultiRoleChange(event, 'roles')} >
                        <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Roles" : "Seleccionar Roles"}</em></MenuItem>
                        {rolesData?.map((role) => (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>))}
                    </Select>
                </FormControl>
                <Tooltip title={EnglishLanguage ? "Assign Default Role to this user that they will view on login. If there are no Roles, they need to be added to the Assigned Roles list."
                    : "Asigne la funci\xF3n predeterminada a este usuario que ver\xE1 al iniciar sesi\xF3n. Si no hay roles, deben agregarse a la lista de roles asignados."} arrow>
                    <Typography><br />{EnglishLanguage ? "Default Role" : "Rol Predeterminado"}:</Typography>
                </Tooltip>
                <FormControl fullWidth margin="normal">
                    <Select variant="filled" value={defaultRoleField} onChange={(event) => handleSelectRoleChange(event, 'roleId')} >
                        <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Default Role" : "Seleccionar rol predeterminado"}</em></MenuItem>
                        {currentUser?.roles?.map((role) => (<MenuItem key={role?.id} value={role?.id}>{role?.name}</MenuItem>))}
                    </Select>
                </FormControl>
                <Tooltip title={EnglishLanguage ? "Assign single or multiple Facilities to this user. If there are no Facilities, they will need to be created in the Facilities page."
                    : "Asigne instalaciones \xFAnicas o m\xFAltiples a este usuario. Si no hay instalaciones, ser\xE1 necesario crearlas en la p\xE1gina Instalaciones."} arrow>
                    <Typography><br />{EnglishLanguage ? "Assigned Facilities" : "Instalaciones Asignadas"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                </Tooltip>
                <FormControl fullWidth margin="normal">
                    <Select variant="filled" multiple value={currentUserFacIds || [""]} onChange={(event) => handleSelectMultiFacChange(event, 'facilities')} >
                        <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Facilities" : "Seleccionar Instalaciones"}</em></MenuItem>
                        {facilitiesData?.map((fac) => (<MenuItem key={fac.facilityId} value={fac.facilityId}>{fac.name}</MenuItem>))}
                    </Select>
                </FormControl>
                <Tooltip title={EnglishLanguage ? "Assign Default Facility to this user that they will view on login. If there are no Facilities, they need to be created in the Facilities page."
                    : "Asigne la instalaci\xF3n predeterminada a este usuario que ver\xE1 al iniciar sesi\xF3n. Si no hay instalaciones, deben crearse en la p\xE1gina Instalaciones."} arrow>
                    <Typography><br />{EnglishLanguage ? "Default Facility" : "Instalaci\xF3n Predeterminada"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                </Tooltip>
                <FormControl fullWidth margin="normal">
                    <Select variant="filled" value={defaultFacField} onChange={(event) => handleSelectFacChange(event, 'facilityId')} >
                        <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Default Facility" : "Seleccionar instalaci\xF3n predeterminada"}</em></MenuItem>
                        {currentUser?.facilities?.map((fac) => (<MenuItem key={fac?.facilityId} value={fac?.facilityId}>{fac?.name}</MenuItem>))}
                    </Select>
                </FormControl>
                {/*
                <Typography><br />User Status:</Typography>
                <FormControlLabel
                    control={<Switch defaultChecked={true} onChange={(event) => handleSwitchChange(event, 'activeStatus')} />}
                    label="Active Status"
                />*/}
                <br />
            </DialogContent>
            <DialogActions>
                {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>{errorMsg}</Grid></Grid> : null}
                {uploadInProgress &&
                    (<CircularProgress />)}
                <Button onClick={handleClose} disabled={uploadInProgress} color="primary">{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
                <Button onClick={handleSave} disabled={uploadInProgress} color="primary">{EnglishLanguage ? "Save" : "Guardar"}</Button>
            </DialogActions>
        </Dialog>
    ;

    const modalBodyMultiUsers = 
        <Dialog open={openMulti} onClose={handleCloseMulti} fullWidth maxWidth="xl">
            <DialogTitle sx={{ textTransform: "capitalize" }}>{EnglishLanguage ? "Add Multiple Users" : "Agregar M\xFAltiples Usuarios"}</DialogTitle>
            <DialogContent>
                <Typography>{EnglishLanguage ? "Upload a CSV (Comma-Separated Values) file to add multiple users at once. The CSV data records must follow this template: "
                    : "Cargue un archivo CSV (valores separados por comas) para agregar varios usuarios a la vez. Los registros de datos CSV deben seguir esta plantilla: "}
                </Typography><br />
                <div style={styles.csvReader}>
                    <CSVDownloader
                        type={Type.Button}
                        style={styles.browseFile}
                        filename={'centro_bulk-user-upload_template'}
                        bom={true}
                        config={{
                            delimiter: ',',
                        }}
                        data={[
                            {
                                'firstName': 'John',
                                'lastName': 'Doe',
                                'email': 'john@centro.com',
                                'title': 'Supervisor',
                                'roles': 'Demo Admin, Manager, Reporter',
                                'defaultRole': 'Reporter',
                                'facilities': 'ABC, EFG',
                                'defaultFacility': 'ABC',
                            },
                            {
                                'firstName': 'Jane',
                                'lastName': 'Doe',
                                'email': 'jane@centro.com',
                                'title': 'ATC',
                                'roles': 'Reviewer, Reporter',
                                'defaultRole': 'Reporter',
                                'facilities': 'ABC, EFG',
                                'defaultFacility': 'ABC',
                            },
                        ]}
                    >
                        {EnglishLanguage ? ("Download CSV Template") : ("Descargar plantilla CSV")}
                    </CSVDownloader>
                </div><br />
                <Typography><Tooltip title="Required"><span className="validation">* </span></Tooltip>
                {EnglishLanguage ? "Roles and Facility must already be created in CENTRO for multiple user upload to save properly."
                 : "Los roles y las instalaciones ya deben estar creados en CENTRO para que la carga de varios usuarios se guarde correctamente."}
                </Typography><br />
                <CSVReader
                    onUploadAccepted={(results) => {
                        //console.log(results.data);
                        setErrorCode(0); // Reset error message
                        // Check that the csv columns are the right format
                        if (results.data[0].toString() !== "firstName,lastName,email,title,roles,defaultRole,facilities,defaultFacility") {
                            setErrorCode(400);
                            if (EnglishLanguage)
                                setErrorMsg("There was an error creating New Users. CSV columns are not properly formatted.");
                            else
                                setErrorMsg("Hubo un error al crear nuevos usuarios. Las columnas CSV no tienen el formato correcto.");
                            errorTracked = true;
                            return;
                        }

                        var outputArray = []; // For setting multipleUsers in state which will go to the backend
                        var dataGridArray = []; // For displaying readable data in the datagrid before saving
                        var errorTracked = false; // For helping track when a bad row is read
                        for (let i = 1; i < results.data.length; i++) { // Skip over first row in CSV, which is a column title row
                            if (results.data[i].length != 8) // Checks to skip over any bad rows, 8 columns
                                continue;

                            // Checking for missing/duplicate emails
                            if (results.data[i][2] === '') { // Check for email being null
                                setErrorCode(400);
                                if (EnglishLanguage)
                                    setErrorMsg("There was an error creating New Users. Email is missing for a user.");
                                else
                                    setErrorMsg("Hubo un error al crear nuevos usuarios. Falta un correo electr\xF3nico para un usuario");
                                errorTracked = true;
                                break;
                            }
                            var emailExistsInDb = usersData.find(e => e.email.toLowerCase() === results.data[i][2].trim().toLowerCase());
                            if (emailExistsInDb !== undefined) {
                                setErrorCode(400);
                                if (EnglishLanguage) 
                                    setErrorMsg("There was an error creating New Users. This E-mail Address is already tied to another User: " + results.data[i][2]);
                                else 
                                    setErrorMsg("Hubo un error al crear nuevos usuarios. Esta direcci\xF3n de correo electr\xF3nico ya est\xE1 vinculada a otro usuario: " + results.data[i][2]);
                                errorTracked = true;
                                break;
                            }
                            // Parsing roles list
                            var parsedRoles = results.data[i][4].split(",").map(item => item.trim().toUpperCase()); // Splits the string by commas, removes trailing whitespace, & makes uppercase
                            var parsedRoleModels = [];
                            if (parsedRoles.length === 0 || parsedRoles[0] === '') { // Check for Assigned Roles being null
                                setErrorCode(400);
                                if (EnglishLanguage)
                                    setErrorMsg("There was an error creating New Users. Assigned roles are missing for user: " + results.data[i][2]);
                                else
                                    setErrorMsg("Hubo un error al crear nuevos usuarios. Faltan roles asignados para el usuario: " + results.data[i][2]);
                                errorTracked = true;
                                break;
                            }
                            for (let x = 0; x < parsedRoles.length; x++) {
                                if (parsedRoles[x] === ""){ // Account for any accidental empty indexes
                                    continue;
                                }
                                var roleExistsInDb = rolesData?.find(r => r.name.toUpperCase() === parsedRoles[x]);
                                if (roleExistsInDb === undefined) {
                                    setErrorCode(400);
                                    if (EnglishLanguage)
                                        setErrorMsg("There was an error creating New Users. The following Role does not exist: '" + parsedRoles[x]
                                            + "', assigned to User: " + results.data[i][2]);
                                    else
                                        setErrorMsg("Hubo un error al crear nuevos usuarios. El siguiente Rol no existe: '" + parsedRoles[x]
                                            + "', asignado al Usuario: " + results.data[i][2]);
                                    errorTracked = true;
                                    break;
                                }
                                parsedRoleModels.push(roleExistsInDb); // Add role to the list to upload to db
                            }
                            // Finding correct role ID
                            var roleIsInList = parsedRoles.find(r => r === results.data[i][5].trim().toUpperCase());
                            if (roleIsInList === undefined) {
                                setErrorCode(400);
                                if (EnglishLanguage)
                                    setErrorMsg("There was an error creating New Users. The Default Role: '" + results.data[i][5]
                                        + "', isn't listed in Assigned Roles for user: " + results.data[i][2]);
                                else
                                    setErrorMsg("Hubo un error al crear nuevos usuarios. El rol predeterminado: '" + results.data[i][5]
                                        + "', no aparece en Roles asignados para el usuario: " + results.data[i][2]);
                                errorTracked = true;
                                break;
                            }
                            var foundRole = rolesData?.find(r => r.name.toLowerCase() === results.data[i][5].trim().toLowerCase());
                            if (foundRole === undefined) {
                                setErrorCode(400);
                                if (EnglishLanguage)
                                    setErrorMsg("There was an error creating New Users. The following Role does not exist: " + results.data[i][5]);
                                else
                                    setErrorMsg("Hubo un error al crear nuevos usuarios. El siguiente Rol no existe: " + results.data[i][5]);
                                errorTracked = true;
                                break;
                            }
                            // Parsing facilities list
                            var parsedFacilities = results.data[i][6].split(",").map(item => item.trim().toUpperCase()); // Splits the string by commas, removes trailing whitespace, & makes uppercase
                            var parsedFacModels = [];
                            if (parsedFacilities.length === 0 || parsedFacilities[0] === '') { // Check for Assigned Roles being null
                                setErrorCode(400);
                                if (EnglishLanguage)
                                    setErrorMsg("There was an error creating New Users. Assigned facilities are missing for user: " + results.data[i][2]);
                                else
                                    setErrorMsg("Hubo un error al crear nuevos usuarios. Faltan instalaciones asignadas para el usuario: " + results.data[i][2]);
                                errorTracked = true;
                                break;
                            }
                            for (let x = 0; x < parsedFacilities.length; x++) {
                                if (parsedFacilities[x] === "") { // Account for any accidental empty indexes
                                    continue;
                                }
                                var facExistsInDb = facilitiesData?.find(f => f.name.toUpperCase() === parsedFacilities[x]);
                                if (facExistsInDb === undefined) {
                                    setErrorCode(400);
                                    if (EnglishLanguage)
                                        setErrorMsg("There was an error creating New Users. The following Facility does not exist: '" + parsedFacilities[x]
                                            + "', assigned to User: " + results.data[i][2]);
                                    else
                                        setErrorMsg("Hubo un error al crear nuevos usuarios. La siguiente instalaci\xF3n no existe: '" + parsedFacilities[x]
                                            + "', asignado al Usuario: " + results.data[i][2]);
                                    errorTracked = true;
                                    break;
                                }
                                parsedFacModels.push(facExistsInDb); // Add role to the list to upload to db
                            }
                            // Finding correct facility ID
                            var facIsInList = parsedFacilities.find(r => r === results.data[i][7].trim().toUpperCase());
                            if (facIsInList === undefined) {
                                setErrorCode(400);
                                if (EnglishLanguage)
                                    setErrorMsg("There was an error creating New Users. The Default Facility: '" + results.data[i][7]
                                        + "', isn't listed in Assigned Facilities for user: " + results.data[i][2]);
                                else
                                    setErrorMsg("Hubo un error al crear nuevos usuarios. La instalaci\xF3n predeterminada: '" + results.data[i][7]
                                        + "', no aparece en Instalaciones asignadas para el usuario: " + results.data[i][2]);
                                errorTracked = true;
                                break;
                            }
                            var foundFac = facilitiesData?.find(f => f.name.toLowerCase() === results.data[i][7].toLowerCase());
                            if (foundFac === undefined) {
                                setErrorCode(400);
                                if (EnglishLanguage)
                                    setErrorMsg("There was an error creating New Users. The following Facility does not exist: " + results.data[i][7]);
                                else
                                    setErrorMsg("Hubo un error al crear nuevos usuarios. La siguiente Instalaci\xF3n no existe: " + results.data[i][7]);
                                errorTracked = true;
                                break;
                            }

                            var currentMultiUser = {
                                clientId: userData?.data?.data.clientId, // Make same client id as the current logged in user
                                firstName: results.data[i][0].trim(),
                                lastName: results.data[i][1].trim(),
                                email: results.data[i][2].trim(),
                                title: results.data[i][3].trim(),
                                roles: parsedRoleModels,
                                roleId: foundRole.id,
                                facilities: parsedFacModels,
                                facilityId: foundFac.facilityId,
                            };
                            //console.log(currentMultiUser);
                            outputArray.push(currentMultiUser);

                            var dataGridMultiUser = {
                                id: i,
                                firstName: results.data[i][0].trim(),
                                lastName: results.data[i][1].trim(),
                                email: results.data[i][2].trim(),
                                title: results.data[i][3].trim(),
                                roles: results.data[i][4].trim().toUpperCase(),
                                defaultRole: results.data[i][5].trim().toUpperCase(),
                                facilities: results.data[i][6].trim().toUpperCase(),
                                defaultFacility: results.data[i][7].trim().toUpperCase(),
                            };
                            //console.log(dataGridMultiUser);
                            dataGridArray.push(dataGridMultiUser);
                        }
                        if (!errorTracked) {
                            setMultipleUsers(outputArray); // Set multipleUsers list in state
                            setMultipleUsersDataGrid(dataGridArray); // Setting multipleUsersDataGrid in state for the datagrid display
                        }
                        //console.log(outputArray);
                        //console.log(dataGridArray);
                    }}
                >
                    {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                    }) => (
                        <>
                            <div style={styles.csvReader}>
                                <button type='button' {...getRootProps()} style={styles.browseFile}>
                                    {EnglishLanguage ? "Browse File" : "Buscar Archivo"}
                                </button>
                                <div style={styles.acceptedFile}>
                                    {acceptedFile && acceptedFile.name}
                                </div>
                            </div>
                            <ProgressBar style={styles.progressBarBackgroundColor} />
                        </>
                    )}
                </CSVReader><br />
                <div style={{ width: '100%' }}>
                    <DataGrid
                        rows={multipleUsersDataGrid}
                        getRowId={(row) => row.id}
                        columns={EnglishLanguage ? columnsUsersUpload : columnsUsersUploadSpanish}
                        autoHeight={true}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        //autoPageSize={true}
                        components={{ Toolbar: GridToolbar }}
                        //onRowClick={} 
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: {
                                    debounceMs: 500,
                                },
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                            },
                        }}
                        localeText={{
                            noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                            noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                            toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                            toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                            toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                            toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir",
                            MuiTablePagination: {
                                labelDisplayedRows: ({ from, to, count }) =>
                                    EnglishLanguage ?
                                        `${from}-${to} of ${count}` :
                                        `${from}-${to} de ${count}`,
                            },
                        }}
                    />
                </div>
                <br />
            </DialogContent>
            <DialogActions>
                {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item>{errorMsg}</Grid></Grid> : null}
                {uploadInProgress &&
                    (<CircularProgress />)}
                <Button onClick={handleCloseMulti} disabled={uploadInProgress} color="primary">{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
                <Button onClick={handleSaveMulti} disabled={uploadInProgress} color="primary">{EnglishLanguage ? "Save" : "Guardar"}</Button>
            </DialogActions>
        </Dialog>
    ;

  return (
    <Container component="main" maxWidth="none" id="incident-reports" sx={{padding: "0!important"}}>
          <HeaderSection className="content-wrap" sectionName="User List" sectionNameES="Lista de usuarios"/>
      <Grid
       className="page-wrap"
        container
        xs={12}
        mr={2}
        sx={{ position: "relative", left: ".5rem" }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                  {EnglishLanguage ? ("User List") : ("Lista de usuarios")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >
              <Item elevation={0} sx={{ background: "transparent" }} >
                <Link sx={{ textDecoration: "none" }} onClick={handleOpen}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "500" }}
                    mb={3}
                    mt={1.25}
                    className="add-text-ir"
                  >
                    {EnglishLanguage ? ("Add New User") : ("AGREGAR USUARIO")}
                  </Typography>{" "}
                </Link>
              </Item>

              <Item
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  top: "9px",
                  right: "10px",
                  position: "relative",
                }} 
              >
                <Link sx={{ textDecoration: "none" }} onClick={handleOpen}>
                  <PersonAddIcon className="add-todo-icon" />
                </Link>
              </Item>
            </Grid>
          </Grid>
                  <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >
              <Item elevation={0} sx={{ background: "transparent" }} >
                <Link sx={{ textDecoration: "none" }} onClick={handleOpenMulti}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "500" }}
                    mb={3}
                    mt={1.25}
                    className="add-text-ir"
                  >
                    {EnglishLanguage ? ("Add Multiple Users") : ("Agregar varios usuarios")}
                  </Typography>{" "}
                </Link>
              </Item>

              <Item
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  top: "9px",
                  right: "10px",
                  position: "relative",
                }} 
              >
                <Link sx={{ textDecoration: "none" }} onClick={handleOpenMulti}>
                  <FileUploadIcon className="add-todo-icon" />
                </Link>
              </Item>
            </Grid>
          <Grid lg={12} px={2} mt={3} sx={{ height: "calc(100% - 66px)" }}>
            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
              {modalBody}
              {modalBodyMultiUsers}
              <UserListTables />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserList;
