import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useCentroStore } from "../store";
import { useUser, useAllFacilities, useAllFacilityTypes } from '../store/queries/userQueries';
import { useFacilities } from '../store/queries/facilityQueries';
import { Dialog, DialogTitle, DialogContent, TextField, Select, MenuItem, FormControl, FormControlLabel, Switch, Button, DialogActions, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from "@mui/icons-material/Add";

export default function FacilityListTables() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const userId = useCentroStore((state) => state.userId);
  const { userData } = useUser(userId);
    const userDataPermissions = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0];
  const { data: FacilitiesData, refetch, isLoading } = useAllFacilities();
  const { data: FacilityTypeData } = useAllFacilityTypes();
  const { editFacility } = useFacilities();
  //const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [currentFacility, setCurrentFacility] = React.useState(null);
  const [errorCode, setErrorCode] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [currentFacilityName, setCurrentFacilityName] = React.useState(""); // For detecing name changes

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'city', headerName: 'City', flex: 1 },
    { field: 'state', headerName: 'State', flex: 1 },
    { field: 'phone', headerName: 'Phone #', flex: 1 },
    { field: 'typeId', headerName: 'Type', flex: 1,
          valueGetter: (params) => {
              return FacilityTypeData?.find(element => element.typeId === params.value).typeName;
          },
    },
    { field: 'activeStatus', headerName: 'Status', flex: 1, valueGetter: (params) => { if (params.value === true) { return "Active"; } return "Inactive"; }, },
    { field: 'dateCreated', headerName: 'Created Date (UTC)', flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
          },
      },
    { field: 'edit', headerName: 'Edit', flex: 1, renderCell: (params) => ( <EditIcon style={{cursor: 'pointer'}} onClick={(event) => { event.stopPropagation(); handleOpen(params.row); }} /> ) }
  ];

  const columnsSpanish = [
    { field: 'name', headerName: 'Nombre', flex: 1 },
    { field: 'city', headerName: 'Ciudad', flex: 1 },
    { field: 'state', headerName: 'Estado/Regi\xF3n', flex: 1 },
    { field: 'phone', headerName: 'Tel\xE9fono #', flex: 1 },
    { field: 'typeId', headerName: 'Tipo', flex: 1,
          valueGetter: (params) => {
              return FacilityTypeData?.find(element => element.typeId === params.value).typeName;
          },
    },
    { field: 'activeStatus', headerName: 'Estado', flex: 1, valueGetter: (params) => { if (params.value === true) { return "Activo"; } return "Inactivo"; }, },
    { field: 'dateCreated', headerName: 'FECHA CREADA (UTC)', flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
          },
      },
    { field: 'edit', headerName: 'Editar', flex: 1, renderCell: (params) => ( <EditIcon style={{cursor: 'pointer'}} onClick={(event) => { event.stopPropagation(); handleOpen(params.row); }} /> ) }
  ];

  // Columns for Facility Positions datagrid in modal
  const columnsPositions = [
    { field: 'posName', headerName: 'Position Name', flex: 1, editable: true },
    { field: 'frequency', headerName: 'Frequency', flex: 1, editable: true, type: 'number' },
    //{ field: 'facilityId', headerName: 'Facility', flex: 1 },
    { field: 'delete', headerName: 'Delete', flex: 1, renderCell: (params) => ( <ClearIcon className="checkbox-clear-icon" style={{cursor: 'pointer'}} onClick={(event) => { event.stopPropagation(); handleDeletePos(params.row); }} /> ) }
  ];

  const columnsPositionsSpanish = [
      { field: 'posName', headerName: 'Nombre de la Posici\xF3n', flex: 1.5, editable: true },
      { field: 'frequency', headerName: 'Frecuencia', flex: 1, editable: true, type: 'number' },
    //{ field: 'facilityId', headerName: 'Facility', flex: 1 },
      { field: 'delete', headerName: 'Borrar', flex: 1, renderCell: (params) => ( <ClearIcon className="checkbox-clear-icon" style={{cursor: 'pointer'}} onClick={(event) => { event.stopPropagation(); handleDeletePos(params.row); }} /> ) }
  ];

  const handleOpen = (Facility) => {
      if (userDataPermissions.updateFac === 1) {
          setCurrentFacility(Facility);
          setCurrentFacilityName(Facility.name);
          setOpen(true);
      }
      else {
          if (EnglishLanguage) {
              alert("You do not have permission to edit facilities.");
          }
          else {
              alert("No tienes permiso para editar las instalaciones.");
          }
      }
  };

  const handleClose = () => {
    setErrorCode(0);
    setCurrentFacility(null);
    setCurrentFacilityName("");
    setOpen(false);
  };

  const handleSave = async () => {
      //console.log(currentFacility); // to log the updated Facility data
      setErrorCode(0);
      // Check to make sure duplicate e-mail addresses aren't being added
      const facilityNameExistsInDb = FacilitiesData.find(element => element.name.toLowerCase() === currentFacility.name.toLowerCase());
      if (facilityNameExistsInDb !== undefined && currentFacilityName !== currentFacility.name) {
          setErrorCode(400);
          if (EnglishLanguage) {
              setErrorMsg("There was an error creating New Facility. Name/Acronym is already tied to another Facility.");
          } else {
              setErrorMsg("Hubo un error al crear la nueva instalaci\xF3n. El nombre/acr\xF3nimo ya est\xE1 vinculado a otra instalaci\xF3n.");
          }
          return;
      }

      let response;
      response = await editFacility.mutateAsync(currentFacility).catch(function (error) {
          setErrorCode(error.response.status);
          if (error.response.status === 400) {
              if (EnglishLanguage) {
                  setErrorMsg("There was an error saving changes to Facility. Required fields are missing.");
              }
              else {
                  setErrorMsg("Se produjo un error al guardar los cambios en la instalaci\xF3n. Faltan campos obligatorios.");
              }
          }
          else {
              setErrorMsg(error.message);
          }
      });
      if (response.status === 200) {
          refetch(); // Refetch query to update datagrid
          if (EnglishLanguage) {
              alert("Facility changes have been successfully saved.");// Successful save message
          }
          else {
              alert("Los cambios en las instalaciones se han guardado correctamente.");
          }
      }
      else {
          setErrorCode(response.status);
          setErrorMsg(response.statusText);
          if (EnglishLanguage) {
              alert("There was an error saving changes to Facility. Facility changes could not be saved.");// Error
          }
          else {
              alert("Se produjo un error al guardar los cambios en la instalaci\xF3n. Los cambios en las instalaciones no se pudieron guardar");
          }
      }
      handleClose();
  };

  // Assist with deleting positions from data grid
  const handleDeletePos = (Position) => {
    if (userDataPermissions.deleteFac === 1) {
        if (EnglishLanguage) {
            if (window.confirm("Confirm deleting this Facility Position? - '" + Position.posName + "'") == true) {
                var index = currentFacility.facilityPositionModels.indexOf(Position);
                if (index > -1) { // only splice array when item is found
                    var tempFacPositions = [...currentFacility.facilityPositionModels];
                    tempFacPositions.splice(index, 1);
                    setCurrentFacility({
                        ...currentFacility,
                        ['facilityPositionModels']: tempFacPositions,
                    });
                }
            }
        }
        else {
            if (window.confirm("Confirmar la eliminaci\xF3n de esta Posici\xF3n de la Instalaci\xF3n? - '" + Position.posName + "'") == true) {
                var index = currentFacility.facilityPositionModels.indexOf(Position);
                if (index > -1) { // only splice array when item is found
                    var tempFacPositions = [...currentFacility.facilityPositionModels];
                    tempFacPositions.splice(index, 1);
                    setCurrentFacility({
                        ...currentFacility,
                        ['facilityPositionModels']: tempFacPositions,
                    });
                }
            }
        }
    }
    else {
        if (EnglishLanguage) {
            alert("You do not have permission to delete facility positions.");
        }
        else {
            alert("No tiene permiso para eliminar posiciones de instalaciones.");
        }
    }
  };

  // Add new empty row to the facility position data grid
  const handleAddPos = () => {
    const randomId = Math.floor(Math.random() * 100000);
    var tempFacPositions = [...currentFacility.facilityPositionModels];
    tempFacPositions.push({id: randomId, posName: "", frequency: "", facilityId: currentFacility.id});
    setCurrentFacility({
            ...currentFacility,
            ['facilityPositionModels']: tempFacPositions,
        });
  }

  // Editing row info for facility positions
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    var tempFacPositions = [...currentFacility.facilityPositionModels];
    const index = tempFacPositions.findIndex(object => {
        return object.id === updatedRow.id;
    });
    if (index !== -1) {
        tempFacPositions[index].posName = updatedRow.posName;
        tempFacPositions[index].frequency = updatedRow.frequency;
        tempFacPositions[index].facilityId = updatedRow.facilityId;
        setCurrentFacility({
            ...currentFacility,
            ['facilityPositionModels']: tempFacPositions,
        });
    }

    return updatedRow;
  };

  const handleTextFieldChange = (event, field) => {
    setCurrentFacility(prevState => ({
      ...prevState,
      [field]: event.target.value
    }));
  };

  const handleSwitchChange = (event, field) => {
      if (userDataPermissions.deleteFac === 1) {
          setCurrentFacility(prevState => ({
              ...prevState,
              [field]: event.target.checked
          }));
      }
      else {
          if (EnglishLanguage) {
              alert("You do not have permission to deactivate facilities.");
          }
          else {
              alert("No tienes permiso para desactivar instalaciones.");
          }
      }
    };

  const handleSelectChange = (event, field) => {
      setCurrentFacility({
            ...currentFacility,
            [field]: event.target.value,
        });
    };

  const modalBody = currentFacility ? (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{textTransform: "capitalize"}}>{EnglishLanguage ? "Edit Facility Details" : "Editar Detalles de la Instalacicn"}</DialogTitle>
      <DialogContent>
          <Typography>{EnglishLanguage ? "Name" : "Nombre"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          <TextField label={EnglishLanguage ? "Name" : "Nombre"} variant="standard" defaultValue={currentFacility.name} onChange={(event) => handleTextFieldChange(event, 'name')} fullWidth margin="normal" />
          <Typography><br />{EnglishLanguage ? "Address 1" : "Direcci\xF3n 1"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          <TextField label={EnglishLanguage ? "Address 1" : "Direcci\xF3n 1"} variant="standard" defaultValue={currentFacility.address1} onChange={(event) => handleTextFieldChange(event, 'address1')} fullWidth margin="normal" />
          <Typography><br />{EnglishLanguage ? "Address 2" : "Direcci\xF3n 2"}:</Typography>
          <TextField label={EnglishLanguage ? "Address 2" : "Direcci\xF3n 2"} variant="standard" defaultValue={currentFacility.address2} onChange={(event) => handleTextFieldChange(event, 'address2')} fullWidth margin="normal" />
          <Typography><br />{EnglishLanguage ? "City" : "Ciudad"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          <TextField label={EnglishLanguage ? "City" : "Ciudad"} variant="standard" defaultValue={currentFacility.city} onChange={(event) => handleTextFieldChange(event, 'city')} fullWidth margin="normal" />
          <Typography><br />{EnglishLanguage ? "State" : "Estado/Regi\xF3n"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          <TextField label={EnglishLanguage ? "State" : "Estado/Regi\xF3n"} variant="standard" defaultValue={currentFacility.state} onChange={(event) => handleTextFieldChange(event, 'state')} fullWidth margin="normal" />
          <Typography><br />{EnglishLanguage ? "Country" : "La Regi\xF3n"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          <TextField label={EnglishLanguage ? "Country" : "La Regi\xF3n"} variant="standard" defaultValue={currentFacility.country} onChange={(event) => handleTextFieldChange(event, 'country')} fullWidth margin="normal" />
          <Typography><br />{EnglishLanguage ? "Zip Code" : "El C\xF3digo Postal"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          <TextField label={EnglishLanguage ? "Zip Code" : "El C\xF3digo Postal"} variant="standard" defaultValue={currentFacility.zipcode} onChange={(event) => handleTextFieldChange(event, 'zipcode')} fullWidth margin="normal" />
          <Typography><br />{EnglishLanguage ? "Phone Number" : "Tel\xE9fono #"}:</Typography>
          <TextField label={EnglishLanguage ? "Phone Number" : "Tel\xE9fono #"} variant="standard" defaultValue={currentFacility.phone} onChange={(event) => handleTextFieldChange(event, 'phone')} fullWidth margin="normal" />
          <Tooltip title={EnglishLanguage ? "Assign which Facility Type this role belongs to. If there are no Types, they will need to be created in the Facility Types page."
                  : "Asigne a qu\xE9 tipo de instalaci\xF3n pertenece este rol. Si no hay tipos, deber\xE1n crearse en la p\xE1gina Tipos de instalaciones."} arrow>
          <Typography><br />{EnglishLanguage ? "Facility Type" : "Tipos de Instalaciones"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          </Tooltip>
            <FormControl fullWidth margin="normal">
                <Select variant="filled" defaultValue={currentFacility.typeId || ""} onChange={(event) => handleSelectChange(event, 'typeId')} >
                    <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Type" : "Seleccione Tipo"}</em></MenuItem>
                    {FacilityTypeData.map((type) => (<MenuItem key={type.typeId} value={type.typeId}>{type.typeName}</MenuItem>))}
                </Select>
            </FormControl>
          <Typography><br />{EnglishLanguage ? "Latitude" : "Latitud"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          <TextField label={EnglishLanguage ? "Latitude" : "Latitud"} variant="standard" type="number" defaultValue={currentFacility.latitude} onChange={(event) => handleTextFieldChange(event, 'latitude')} fullWidth margin="normal" />
          <Typography><br />{EnglishLanguage ? "Longitude" : "Longitud"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          <TextField label={EnglishLanguage ? "Longitude" : "Longitud"} variant="standard" type="number" defaultValue={currentFacility.longitude} onChange={(event) => handleTextFieldChange(event, 'longitude')} fullWidth margin="normal" />

              <Typography><br />{EnglishLanguage ? "Facility Positions: (Double-Click Row To Edit)"
                  : "Posiciones de las Instalaciones: (Haga Doble-Clic en la fila para editar)"}<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          <Button color="primary" startIcon={<AddIcon />}  onClick={handleAddPos}>
          {EnglishLanguage ? "Add Empty Record" : "A\xF1adir registro vac\xEDo"}
          </Button>
          <div style={{ width: '100%' }}>
          <DataGrid 
          editMode="row" 
          rows={currentFacility.facilityPositionModels} 
          //onRowEditStop={(params, event) =>  handleDataGridChange(event, 'facilityPositionModels')}
          processRowUpdate={processRowUpdate} // Detects when a change is made to a row
          experimentalFeatures={{ newEditingApi: true }} // For processRowUpdate to work
          getRowId={(row) => row.id} 
          columns={EnglishLanguage ? columnsPositions : columnsPositionsSpanish}
          autoHeight={true}
          rowsPerPageOptions={[10]}
          //autoPageSize={true}
          components={{ Toolbar: GridToolbar }} 
          //onRowClick={FacilityDataSetup} 
          disableColumnFilter 
          disableColumnSelector 
          disableDensitySelector 
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                debounceMs: 500,
              },
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }} 
          localeText={{
                    noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                    noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                    toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                    toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                    toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                    toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir", 
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            EnglishLanguage ? 
                            `${from}-${to} of ${count}` : 
                            `${from}-${to} de ${count}`, 
                    },
          }}
          
          />          
          </div>

          <Typography><br />{EnglishLanguage ? "Created Date (UTC, Read-Only)" : "Fecha de creaci\xF3n (UTC, solo-lectura)"}</Typography>
          <TextField label={EnglishLanguage ? "Created Date" : "Fecha de creaci\xF3n"} defaultValue={currentFacility.dateCreated} InputProps={{ readOnly: true, }} fullWidth variant="standard" margin="normal" />
          <Typography><br />{EnglishLanguage ? "Facility Status" : "Estado de la Instalaci\xF3n"}:</Typography>
            <FormControlLabel
                control={<Switch defaultChecked={Boolean(currentFacility.activeStatus)} onChange={(event) => handleSwitchChange(event, 'activeStatus')} />}
                label={EnglishLanguage ? "Active Status" : "Estado Activo"}
            />
            <br />
      </DialogContent>
      <DialogActions>
        {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>{errorMsg}</Grid></Grid> : null}
        <Button onClick={handleClose} color="primary">{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
        <Button onClick={handleSave} color="primary">{EnglishLanguage ? "Save" : "Guardar"}</Button>
      </DialogActions>
    </Dialog>
  ) : null;

  return (
    <div style={{ height: "calc(100vh - 17rem)", width: '100%' }}>
      {modalBody}
      {isLoading ? <div>Loading...</div> : (
        <DataGrid 
          rows={FacilitiesData} 
          getRowId={(row) => row.facilityId} 
          columns={EnglishLanguage ? columns : columnsSpanish}
          pageSize={10} 
          rowsPerPageOptions={[10]} 
          components={{ Toolbar: GridToolbar }} 
          //onRowClick={FacilityDataSetup} 
          disableColumnFilter 
          disableColumnSelector 
          disableDensitySelector 
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                debounceMs: 500,
              },
            },
          }} 
          localeText={{
                    noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                    noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                    toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                    toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                    toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                    toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir", 
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            EnglishLanguage ? 
                            `${from}-${to} of ${count}` : 
                            `${from}-${to} de ${count}`, 
                    },
          }}
        />
      )}
    </div>
  );
}
