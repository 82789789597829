import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
export default function ClosestVerticalText() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setclosestverticaldistance = useCentroStore((state) => state.setclosestverticaldistance);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const blurHandler = (event) => {
        setclosestverticaldistance(parseInt(event.target.value));
    }

  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? "Number" : "N\xFAmero"}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          rows={1}
          defaultValue={ currentIncidentReport.closestVerticalDistance }
          variant="standard"
          onBlur={ blurHandler }
        />
  );
};