import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";
import { useCentroStore } from "../../../../store";
import { useAircrafts } from "../../../../store/queries/aircraftQueries";

const options = ["Placeholder"];

export default function AircraftTwoEquipmentSuffixDropdown() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const setAircraftTwoEquipmentTypeId = useCentroStore(
    (state) => state.setAircraftTwoEquipmentTypeId
  );
    const setAircraftTwoSuffix = useCentroStore(
    (state) => state.setAircraftTwoSuffix
  );
  const aircraftTwo = useCentroStore((state) => state.aircraftTwo);
  const currentIncidentReport = useCentroStore(
    (state) => state.currentincidentreport
  );
  const { equipmentTypes } = useAircrafts(currentIncidentReport.irId);

  const handleChange = (event) => {
    setAircraftTwoEquipmentTypeId(event.target.value);
 //Inside the handle change event on AircraftEquipmentSuffixDropdown for both aircraft 1 and 2
    var equipmentType = equipmentTypes?.data?.data.find(
      (e) => e.id === event.target.value
    );

    //need to create this setter in zustand
    setAircraftTwoSuffix(equipmentType.suffix);


  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="standard">
        <Select
          className="select-wrap"
          value={
            aircraftTwo.equipmentTypeId === undefined || aircraftTwo.equipmentTypeId === 0
              ? 0
              : aircraftTwo.equipmentTypeId
          }
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="0">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {equipmentTypes?.data?.data.map(({ id, suffix }) => (
            <MenuItem key={id} value={id}>
              {suffix}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
