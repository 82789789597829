import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
//import Breadcrumbs from "@mui/material/Breadcrumbs";
//import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom"
import Grid from "@mui/material/Grid";
//import TopNavBar from "./TopNavBar";
//import FacilityDropdown from "./forms/FacilityDropdown";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IncidentReportsTables from "./IncidentReportsTables";
import { useCentroStore } from "../store";
import HeaderSection from "./header/HeaderSection";
import { useClearState } from "./Helpers/HelperFunctions";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const IncidentReports = (props) => {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const { ClearReport } = useClearState();
    
    const handleNewIncidentClick = async () => {
        ClearReport();
    };
  return (
    <Container component="main" maxWidth="none" id="incident-reports" sx={{padding: "0!important"}}>
      <HeaderSection className="content-wrap" sectionName="Occurrence Reports" sectionNameES="Informes De Incidencia"/>
      <Grid
       className="page-wrap"
        container
        xs={12}
        mr={2}
        sx={{ position: "relative", left: ".5rem" }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                {EnglishLanguage ? ("Occurrence Reports") : ("Informes De Incidencia")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >
              <Item elevation={0} sx={{ background: "transparent" }} >
                <Link to="/create-new-incident-report" sx={{ textDecoration: "none" }} onClick={handleNewIncidentClick}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "500" }}
                    mb={3}
                    mt={1.25}
                    className="add-text-ir"
                  >
                    {EnglishLanguage ? ("Add") : ("AGREGAR")}
                  </Typography>{" "}
                </Link>
              </Item>

              <Item
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  top: "9px",
                  right: "10px",
                  position: "relative",
                }} 
              >
                <Link to="/create-new-incident-report" sx={{ textDecoration: "none" }} onClick={handleNewIncidentClick}>
                  <PlaylistAddIcon className="add-todo-icon" />
                </Link>
              </Item>
            </Grid>
          </Grid>
          <Grid lg={12} px={2} mt={3} sx={{ height: "calc(100% - 66px)" }}>
            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
              <IncidentReportsTables />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IncidentReports;
