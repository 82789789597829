import React, { useState, useEffect } from "react";

import GoogleMapReact from "google-map-react";
import MyMarker from "../../../MyMarker";
import { useCentroStore } from "../../../../store";

const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    return Math.sqrt(
      (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
    );
  }
};

export default function MapLocationFind() {
  //const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const incidentreportsLocations = useCentroStore((state) => state.incidentreportsLocations);
  const currentFacility = useCentroStore((state) => state.currentFacility);
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);

  const [center, setCenter] = useState({
    lat: currentFacility.latitude,
    lng: currentFacility.longitude
  });

  // When the currentFacility changes, update the center state
  useEffect(() => {
    setCenter({
      lat: currentFacility.latitude,
      lng: currentFacility.longitude
    });
  }, [currentFacility]);

  return (
    <div className="incident-map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDlOA6BKZWCiRuCQIa-resTmtNtixwHRcM",
          language: "en",
          region: "US"
        }}
        defaultCenter={center}
        defaultZoom={14}
        // distanceToMouse={distanceToMouse}
        options={map => ({ scrollwheel: false, mapTypeId: map.MapTypeId.SATELLITE, fullscreenControl: false, zoomControl: true })}
      >
        {currentIncidentReport && <MyMarker lat={currentIncidentReport.latitude} lng={currentIncidentReport.longitude} isClickedLocation={true} />}
        {incidentreportsLocations.map(({ lat, lng, id }) => {
          return (
            <MyMarker id={id} lat={lat} lng={lng} isClicked />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}
