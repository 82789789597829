import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from "../assets/images/logo-infina.png"

const PoweredBy = () => {
    return (
      <Box className="powered-by-container">
        <Typography className="powered-by-text">Powered By</Typography>
        <img className="powered-by-logo" src={logo} alt="Logo" />
      </Box>
    );
  };
  
  export default PoweredBy;