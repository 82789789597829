import * as React from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import { useCentroStore } from '../store';
import { useQueryClient } from 'react-query';
import { Tooltip } from '@mui/material';

export default function MenuPopupState() {
    //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
    const setAuth = useCentroStore((state) => state.setAuth);
    const setUserId = useCentroStore((state) => state.setUserId);
    const setCurrentFacilityId = useCentroStore((state) => state.setCurrentFacilityId);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

    const queryClient = useQueryClient();
    function clearState() {
        //setuserloggedin("No");
        setAuth(false);
        setUserId(0);
        setCurrentFacilityId("");
        queryClient.clear();
        sessionStorage.clear();
    }

  return (
<Tooltip title={EnglishLanguage ? "Click to logout of the Centro App" : "Haga clic para cerrar sesi\xF3n en la aplicaci\xF3n Centro"}>
          <Link to="/" onClick={clearState}>
          <LogoutIcon/>
          </Link>
          </Tooltip>
  );
}
