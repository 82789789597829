import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useCentroStore } from "../../../store";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import AirCraft from "./aircraft-1/Aircraft";
import AircraftTwo from "./aircraft-2/AircraftTwo";
import Vehicle from "./vehicle-1/Vehicle";
import AirCraftPed from "./pedestrian-1/AircraftPed";
import { Box } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AirplaneTabs() {
  const [value, setValue] = React.useState(0);
  const currentIncidnetReport = useCentroStore(
    (state) => state.currentincidentreport
  );
  const aircraftone = useCentroStore((state) => state.aircraftOne);
  const aircrafttwo = useCentroStore((state) => state.aircraftTwo);
  const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  return (
    currentIncidnetReport.aircraftCount !== 0 ? (
    <Box sx={{ width: "100%" }}>
      {/* <Box>
        <Tabs
          value={value}
          className="accordian-tabs-wrap"
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="primary"
          indicatorColor="#ffffff"
        >
          <Tab
            label={
              aircraftone === undefined || aircraftone === "" || aircraftone.callsign === "" ? "Aircraft 1" : aircraftone.callsign
            }
            {...a11yProps(0)}
          />
          {currentIncidnetReport.aircraftCount === 2 ? (
            <Tab
              label={
               aircrafttwo === undefined || aircrafttwo === "" || aircrafttwo.callsign === "" ? "Aircraft 2"
                  : aircrafttwo.callsign
              }
              {...a11yProps(1)}
            />
          ) : currentIncidnetReport.aircraftCount === 3 ? (
            <Tab
              label={
                currentIncidnetReport.vehicleId === ""  ? "Vehicle"
                  : currentIncidnetReport.vehicleId
              }
              {...a11yProps(1)}
            />
          ) : currentIncidnetReport.aircraftCount === 4 ? (
            <Tab
              label={
                currentIncidnetReport.pedestrianName === "" ? "Pedestrian"
                  : currentIncidnetReport.pedestrianName
              }
              {...a11yProps(1)}
            />
          ) : null}
        </Tabs>
      </Box> */}
      <Grid
        container
        justifyContent="space-between"
        spacing={0}
        className="ir-form-wrapper"
        sx={{ marginRight: "-20px" }}
      >
        <Grid xs={12} sx={{marginTop: "0!important", marginBottom: "0!important"}}>
          <Item elevation={0}>
            <AirCraft />
          </Item>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        spacing={0}
        className="ir-form-wrapper"
      >
        <Grid xs={12} sx={{marginTop: "0!important", marginBottom: "0!important"}}>
          <Item elevation={0}>
          {currentIncidnetReport.aircraftCount === 2 ? (
              <AircraftTwo />
            ) : currentIncidnetReport.aircraftCount === 3 ? (
              <Vehicle />
            ) : currentIncidnetReport.aircraftCount === 4 ? (
              <AirCraftPed />
            ): null}
          </Item>
        </Grid>
      </Grid>
    </Box>   ) : null
  );
}

