import * as React from "react";
import TextField from "@mui/material/TextField";
import { useCentroStore } from "../../../../store";

export default function OtherAtcPosition() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  const setvehicleotheratcposition = useCentroStore((state) => state.setvehicleotheratcposition);

  const blurHandler = (event) => {
    //currentIncidentReport.vehicleOtherAtcPosition = event.target.value;
    setvehicleotheratcposition(event.target.value);
  };
  return (
    <TextField
      id="standard-multiline-static"
      label={EnglishLanguage ? "Name" : "Nombre"}
      sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
      fullWidth
      rows={1}
      defaultValue={currentIncidentReport.vehicleOtherAtcPosition}
      variant="standard"
      onBlur={blurHandler}
    />
  );
}
