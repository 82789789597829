import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useCentroStore} from "../store";
import { SetupCurrentIncidentReport } from "./Helpers/HelperFunctions";
import { useCategories, useReports } from "../store/queries/incidentReportQueries";
import { useFacilities } from "../store/queries/facilityQueries";
import { useAircrafts } from "../store/queries/aircraftQueries";


export default function IncidentReportSelector() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentFacility = useCentroStore((state) => state.currentFacility);
    const [reportId, setReportId] = React.useState(0);
    const { reports, aircraftsFromDb } = useReports(currentFacility.facilityId, reportId);
    const setcurrentincidentreport = useCentroStore((state) => state.setcurrentincidentreport);
    const { categories } = useCategories();
    //const { facilityPositions } = useFacilityPositions();
    const { facilities } = useFacilities();
    // const { aircraftsFromDb } = useAircrafts(reportId);

    const setCurrentAircraftOne = useCentroStore((state) => state.setCurrentAircraftOne);
    //const setCurrentAircraftOneOperator = useCentroStore((state) => state.setCurrentAircraftOneOperator);
    const setAircraftOneType = useCentroStore((state) => state.setAircraftOneType);
    const setAircraftOneFac = useCentroStore((state) => state.setAircraftOneFac);
    const addAircraftOneUnknowns = useCentroStore((state) => state.addAircraftOneUnknowns);
    const addAircraftOneLights = useCentroStore((state) => state.addAircraftOneLights);

    const setCurrentAircraftTwo = useCentroStore((state) => state.setCurrentAircraftTwo);
    //const setCurrentAircraftTwoOperator = useCentroStore((state) => state.setCurrentAircraftTwoOperator);
    const setAircraftTwoType = useCentroStore((state) => state.setAircraftTwoType);
    const setAircraftTwoFac = useCentroStore((state) => state.setAircraftTwoFac);
    const addAircraftTwoUnknowns = useCentroStore((state) => state.addAircraftTwoUnknowns);
    const addAircraftTwoLights = useCentroStore((state) => state.addAircraftTwoLights);

    const setCurrentCategory = useCentroStore((state) => state.setCurrentCategory);
    const setCurrentSubcategory = useCentroStore((state) => state.setCurrentSubcategory);
    const setcurrentincidenttype = useCentroStore((state) => state.setcurrentincidenttype);
    //const setIsSignificantEvent = useCentroStore((state) => state.setIsSignificantEvent);
    //const setCurrentTrainingInProgress = useCentroStore((state) => state.setIsTrainingInProgress);
    //const setIsInjury = useCentroStore((state) => state.setIsInjury);
    //const setIsMedicalHelp = useCentroStore((state) => state.setIsMedicalHelp);
    //const setIsLawContacted = useCentroStore((state) => state.setIsLawContacted);
    //const setIsPilotViolation = useCentroStore((state) => state.setIsPilotViolation);
    //const setIsBrasherWarning = useCentroStore((state) => state.setIsBrasherWarning);
    const setCurrentPosition = useCentroStore((state) => state.setCurrentPosition);

    //const addDeviations = useCentroStore((state) => state.addDeviation);
    const addAircraftOneWeather = useCentroStore((state) => state.addAircraftOneWeather);
    const addAircraftTwoWeather = useCentroStore((state) => state.addAircraftTwoWeather);
    const setFetchedAircraftsFromDB = useCentroStore((state) => state.setFetchedAircraftsFromDB);

    const CurrentIncidentReportSetup = async (event, value) => {
        var reportNumber = value.split('(')[0];
        var report = reports?.data?.data.find(r => r.reportNumber === reportNumber);
        setReportId(report.irId);
        var incidentReport = await SetupCurrentIncidentReport(report, aircraftsFromDb?.data?.data, categories?.data?.data, facilities?.data.data); // , facilityPositions?.data?.data);

        setCurrentAircraftOne(incidentReport.currentAircraftOne);
        //setCurrentAircraftOneOperator(incidentReport.currentAircraftOneOperator);
        setAircraftOneType(incidentReport.aircraftOneType);
        setAircraftOneFac(incidentReport.aircraftOneFac);
        addAircraftOneUnknowns(incidentReport.aircraftOneUnknowns);
        addAircraftOneLights(incidentReport.aircraftOneLights);

        setCurrentAircraftTwo(incidentReport.currentAircraftTwo);
        //setCurrentAircraftTwoOperator(incidentReport.currentAircraftTwoOperator);
        setAircraftTwoType(incidentReport.aircraftTwoType);
        setAircraftTwoFac(incidentReport.aircraftTwoFac);
        addAircraftTwoUnknowns(incidentReport.aircraftTwoUnknowns);
        addAircraftTwoLights(incidentReport.aircraftTwoLights);

        setCurrentCategory(incidentReport.currentCategory);
        setCurrentSubcategory(incidentReport.currentSubcategory);
        setcurrentincidenttype(incidentReport.currentIncidentType);
        //setCurrentTrainingInProgress(incidentReport.currentTrainingInProgress);
        //setIsInjury(incidentReport.isInjury);
        ////setIsMedicalHelp(incidentReport.isMedicalHelp);
        //setIsLawContacted(incidentReport.isLawContacted);
        //setIsPilotViolation(incidentReport.isPilotViolation);
        //setIsBrasherWarning(incidentReport.isBrasherWarning);
        setCurrentPosition(incidentReport.currentPosition);

        //addDeviations(incidentReport.deviations);
        addAircraftOneWeather(incidentReport.aircraftOneWeather);
        addAircraftTwoWeather(incidentReport.aircraftTwoWeather);
        setFetchedAircraftsFromDB(incidentReport.fetchedAcFromDB);

        var tempAircraftList = [incidentReport.currentAircraftOne, incidentReport.currentAircraftTwo];
        report.aircraftsList = tempAircraftList;
        setcurrentincidentreport(report);
    }
  return (

<Autocomplete
        id="checkboxes-tags-demo"
        className="ir-tags-demo"
        size="small"
        options={reports?.data?.data === undefined ? [] : reports?.data?.data}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.reportNumber + "(" + option.status + ")" }
        onInputChange={CurrentIncidentReportSetup}
        placeholder={EnglishLanguage ? "Search Occurrence Report Numbers" : "N\xFAmeros de Informe de Ocurrencia de B\xFAsqueda"} 
        renderInput={(params) => (
          <TextField
          className="ir-tags-demo ir-placeholder-field"
            {...params}
            variant="standard"
            placeholder={EnglishLanguage ? "Search Occurrence Report #" : "# de Informe de Ocurrencia de B\xFAsqueda"}  
          />
        )}
      />

  );
}
