import * as React from "react";
import TextField from "@mui/material/TextField";
import { useCentroStore } from "../../store";
import { useUser } from "../../store/queries/userQueries";
import { useState } from "react";


export default function UsernameTextfield() {

  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  const userId = useCentroStore((state) => state.userId);
  const { userData } = useUser(userId);
  const userEmail = userData?.data?.data.email;
  const [username, setUsername] = useState("");
  const blurHandler = (event) => {
    //currentIncidentReport.reporterName = event.target.value;
    setUsername(event.target.value);
  };
  return (
    <TextField
      id="standard-multiline-static"
      label="Name"
      sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
      fullWidth
      defaultValue={userEmail}
      rows={1}
      variant="standard"
      //onBlur={blurHandler}
      InputProps={{
        readOnly: true,
      }}
    />
  );
}
