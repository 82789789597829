import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';
import { useFacilities, useFacilityPositions } from '../../../../store/queries/facilityQueries';

export default function AircraftFacilityDropdown() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setAircraftOneFacility = useCentroStore((state) => state.setAircraftOneFacility);
    //const facilityList = useCentroStore((state) => state.facilityList);
    const { facilities } = useFacilities();
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const aircraftOne = useCentroStore((state) => state.aircraftOne);
    const aircraftTwo = useCentroStore((state) => state.aircraftTwo);
    const setAircraftOneFac = useCentroStore((state) => state.setAircraftOneFac);
    const aircraftOneFac = useCentroStore((state) => state.aircraftOneFac);
    const handleChange = (event) => {
        var fac = facilities?.data?.data.find(f => f.facilityId === event.target.value);
        setAircraftOneFac(fac);
        setAircraftOneFacility(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl fullWidth variant="standard">
              <Select
                  className='select-wrap'
                  displayEmpty
                  value={!aircraftOneFac ? "" : aircraftOneFac.facilityId}

                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                 
              >
                  <MenuItem disabled value=''>
                      {EnglishLanguage ? ("-Select-") : ("-Seleccionar-")}
                  </MenuItem>
                  {facilities?.data?.data.map(({ facilityId, name }) =>
                      <MenuItem
                          key={facilityId}
                          value={facilityId}>
                          {name}
                      </MenuItem>

                  )}
              </Select>
      </FormControl>

    </Box>
  );
}

