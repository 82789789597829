import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
'Verification',
'Periodic Maintenance',
'Certification',
'Decertification',
'Flight Inspection',
'Technical Evaluation',
'PM (Periodic Maintenance) not performed',
'Modification',
'Remote Maintenance',
'Corrective Maintenance',
'Other',
'Improvements',
'Administrative',
'Software',
'Protective Shutdown',
'Equipment',
'Prime Power',
'Standby Power',
'Nature / Weather',
'Unknown',
];

const optionsSpanish = [
'Verificaci\xF3n',
'Mantenimiento Peri\xF3dico',
'Certificaci\xF3n',
'Descertificaci\xF3n',
'Inspecci\xF3n de Vuelo',
'Evaluaci\xF3n T\xE9cnica',
'Mantenimiento Peri\xF3dico no realizado',
'Modificaci\xF3n',
'Mantenimiento Remoto',
'Mantenimiento Correctivo',
'Otros',
'Mejoras',
'Administrativo',
'Software',
'Apagado de Protecci\xF3n',
'Equipos',
'Energ\xEDa Primaria',
'Energ\xEDa de Reserva',
'Naturaleza / Clima',
'Desconocido',
];

export default function OutageReason() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setoutageReason = useCentroStore((state) => state.setoutageReason);

    const handleChange = (event) => {
        setoutageReason(event.target.value);
    };

  return (
    <Box sx={{ minWidth: 120 }}>
    <FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          defaultValue=""
          value={currentIncidentReport.outageReason}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }
            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccione")}</em>
          </MenuItem>
          {EnglishLanguage ? 
           options.map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              {category}
            </MenuItem>
           ))
           :
           optionsSpanish.map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              {category}
            </MenuItem>
           ))}
        </Select>
    </FormControl>
    </Box>
  );
}