import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    maintainAspectRatio: false,
    responsive: true,
    color: "#3d556c",

    layout: {
      

    },
    plugins: {
        legend: {
          position: "left",
          backgroundColor: "red",
          labels: {
            boxWidth: 20,
            padding: 25,
        
              color: "#3d556c",
              font: {
                  size: 18,
                  family:"'Montserrat', Arial",
                  weight:"500",
              },
           
      
          },
        },
    }
}    

export const data = {
  labels: ['CFIT', 'LOC-I', 'MAC', 'RE', 'RI'],

  datasets: [
    {
      label: '# of Votes',
      data: [1, 19, 12, 17, 8],
      backgroundColor: [
        '#F7B01A',
        '#3d556c',
        '#8e8e8e',
        '#a8bfcc',
        '#48494b',

      ],
      borderColor: [
        '#F7B01A',
        '#3d556c',
        '#8e8e8e',
        '#a8bfcc',
        '#48494b',
      ],
      borderWidth: 1,
      cutout: '70%',
      
    },
  ],
};

export function RecentCatChart() {
  return (
  <div className="donut-wrap">
  <Doughnut data={data} options={options} className="recent-cat-container" />
  </div>
  );
}
