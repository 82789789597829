import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';
import { useCategories } from '../../../../store/queries/incidentReportQueries';

  
  export default function IncidentCategoryAirborneDropdown() {

      const setCurrentIncidentCategoryId = useCentroStore((state) => state.setincidentcategoryid);
      const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
      const setCurrentCategory = useCentroStore((state) => state.setCurrentCategory);
      const setCurrentSubcategory = useCentroStore((state) => state.setCurrentSubcategory);
      const currentCategory = useCentroStore((state) => state.currentCategory);
      const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
      const settechopsNotified = useCentroStore((state) => state.settechopsNotified); // For CNS
      const { categories } = useCategories();

      const handleChange = (event) => {
          if (EnglishLanguage) {
              var category = categories?.data?.data.find(c => c.irCategory1 === event.target.value);
          }
          else {
              var category = categories?.data?.data.find(c => c.irCategoryEs === event.target.value);
          }
          setCurrentCategory(category);
          setCurrentSubcategory({});
          setCurrentIncidentCategoryId(category.irCategoryId);
          // For CNS field, if "Tech Ops Reported Interruption" is selected, TechOpsNotified = Yes
          if (event.target.value === "Tech-Ops Reported Interruption" || event.target.value === "Interrupci�n reportada por CNS") {
              settechopsNotified(1);
          }
      };
      if (currentIncidentReport.reportTypeId === 1) {
          return (
              <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth variant="standard">
                      <Select
                          className='select-wrap'
                          defaultValue="0"
                          value={EnglishLanguage ? currentCategory.irCategory1 : currentCategory.irCategoryEs}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                      >


                          <MenuItem disabled value="0">
                              <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
                          </MenuItem>
                          {EnglishLanguage ?
                          categories?.data?.data.filter(c => c.categoryAirborne === 1).map(({ irCategoryId, irCategory1 }) => (
                              <MenuItem
                                  key={irCategoryId}
                                  value={irCategory1}
                              >
                                  {irCategory1}
                              </MenuItem>
                          ))
                          :
                          categories?.data?.data.filter(c => c.categoryAirborne === 1).map(({ irCategoryId, irCategoryEs }) => (
                              <MenuItem
                                  key={irCategoryId}
                                  value={irCategoryEs}
                              >
                                  {irCategoryEs}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>

              </Box>
          );
      }
      else if (currentIncidentReport.reportTypeId === 2) {
          return (
              <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth variant="standard">
                      <Select
                          className='select-wrap'
                          defaultValue="0"
                          value={EnglishLanguage ? currentCategory.irCategory1 : currentCategory.irCategoryEs}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                      >


                          <MenuItem disabled value="0">
                              <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
                          </MenuItem>
                          { EnglishLanguage ? 
                              categories?.data?.data.filter(c => c.categoryCns === 1).map(({ irCategoryId, irCategory1 }) => (
                                  <MenuItem
                                      key={irCategoryId}
                                      value={irCategory1}
                                  >
                                      {irCategory1}
                                  </MenuItem>
                              ))
                           :
                             categories?.data?.data.filter(c => c.categoryCns === 1).map(({ irCategoryId, irCategoryEs }) => (
                              <MenuItem
                                  key={irCategoryId}
                                  value={irCategoryEs}
                              >
                                  {irCategoryEs}
                              </MenuItem>
                            ))
                          }
                          
                      </Select>
                  </FormControl>

              </Box>
          );
      }
      else {
          return (
              <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth variant="standard">
                      <Select
                          className='select-wrap'
                          defaultValue="0"
                          value={EnglishLanguage ? currentCategory.irCategory1 : currentCategory.irCategoryEs}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                      >


                          <MenuItem disabled value="0">
                              <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
                          </MenuItem>
                          {EnglishLanguage ?
                           categories?.data?.data.filter(c => c.categorySurface === 1).map(({ irCategoryId, irCategory1 }) => (
                              <MenuItem
                                  key={irCategoryId}
                                  value={irCategory1}
                              >
                                  {irCategory1}
                              </MenuItem>
                           ))
                           :
                           categories?.data?.data.filter(c => c.categorySurface === 1).map(({ irCategoryId, irCategoryEs }) => (
                              <MenuItem
                                  key={irCategoryId}
                                  value={irCategoryEs}
                              >
                                  {irCategoryEs}
                              </MenuItem>
                           ))}
                      </Select>
                  </FormControl>

              </Box>
          );
      }
}

