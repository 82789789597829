import * as React from "react";
import Typography from "@mui/material/Typography";
import incidentreport from "../../assets/icons/create-incident-button@2x.png";
import { Link } from "react-router-dom";
import { useCentroStore } from "../../store";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useClearState } from "../Helpers/HelperFunctions";
import { Tooltip } from "@mui/material";




export default function IncidentReport(props) {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const { ClearReport } = useClearState();
    
    const handleNewIncidentClick = async () => {
        ClearReport();
    };
  return (
    <Tooltip title={EnglishLanguage ? "Create a new Occurrence Report." : "Crear un nuevo Informe de Ocurrencia."}>
      <span>
    <div className="action-btn">
         
          <Link to="/create-new-incident-report" className="ir-sa-button-link" onClick={handleNewIncidentClick }>
          <WarningRoundedIcon sx={{fill: "white"}} className="plus-helper-or"/><span className='ir-sa-plus-icon'>+</span>
          <Typography variant="h3" sx={{ fontWeight: "500" }}>
          {EnglishLanguage ? (<span>Occurrence Report </span>) : (<span>Informe de Ocurrencia </span>)} 
          </Typography>
          
      </Link>
      
    </div>
    </span>
    </Tooltip>
  );
}
