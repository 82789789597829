import Grid from "@mui/material/Grid";
import { Button, Container, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";
import { LineChart } from "./charts/LineChart";
import ToDoList from "./ToDoList";
import { RecentCatChart } from "./charts/RecentCatChart";
import RecentIncidents from "./RecentIncidents";

import IncidentReport from "./forms/IncidentReport";
import SafetyAssessment from "./forms/SafetyAssessment";
import FeatureDropdown from "./forms/FeatureDropdown";

//import { useEffect } from "react";
import { useCentroStore } from "../store";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useNavigate } from "react-router-dom";
import HeaderSection from "./header/HeaderSection";
import TipsComponent from "./TipsComponent";
import { Suspense } from "react";
import ReferenceGuide from "./reference-guide/ReferenceGuide";

import { useReferenceGuideStore } from "../store";
import CreateNewUser from "./forms/CreateNewUser";
import { useClearState } from "./Helpers/HelperFunctions";
import { useUser } from "../store/queries/userQueries";

const Home = (props) => {
  const theme = props.theme;
  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);

  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const openModal = useReferenceGuideStore((state) => state.openModal);
  const activeRole = useCentroStore((state) => state.activeRole);
  const { ClearReport } = useClearState();
  
  const userId = useCentroStore((state) => state.userId);
  const { userData } = useUser(userId);
  const activeUserRole = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId));

  const handleNewIncidentClick = async () => {
      ClearReport();
  };

  return (
    <Container component="main" maxWidth="none" id="Home" sx={{ padding: "0!important" }}>
      <TipsComponent />
      <HeaderSection className="content-wrap" sectionName="Home" sectionNameES="Inicio" />
      <Grid
        container
        justifyContent="flex-start"
        className="content-wrap"
        sx={{
          overflow: "auto",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "0.5rem",
            borderRadius: "0.5rem",
          },
          "&::-webkit-scrollbar-track": {
            background: "#dadada",
            borderRadius: "0.5rem",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#8e8e8e",
            borderRadius: "0.5rem",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
            borderRadius: "0.5rem",
          },
        }}>
        <Grid container px={1} xl={7} className="home-padding-fix">
          {/*{activeRole === "Safety Director" || activeRole === "Regional Manager" || activeRole === "Facility Manager" ? (
            <Grid item xs={12} xl={12} mb={1}>
              <Card sx={{ borderRadius: "0" }}>
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Tooltip
                      placement="right"
                      title="Select a graph to view. You can change the default dropdown in your user settings page.">
                      <Grid item>
                        <Typography mb={3} mt={-2}>
                          <FeatureDropdown />
                        </Typography>
                      </Grid>
                    </Tooltip>
                    <Grid item>
                      /* <Tooltip title="Go to the Analytics page.">
                        <Link to="/analytics" sx={{ textDecoration: "none" }}>
                          <Grid container spacing={2} className="link-hover-home">
                            <Grid item>
                              <Typography variant="h6" sx={{ fontWeight: "500" }} className="mobile-hide">
                                {EnglishLanguage ? "Analyze" : "Analizar"}
                              </Typography>{" "}
                            </Grid>
                            <Grid item sx={{ position: "relative", top: "-8px" }}>
                              <QueryStatsIcon className="add-todo-icon" />
                            </Grid>
                          </Grid>
                        </Link>
                      </Tooltip> /
                    </Grid>
                  </Grid>
                  <LineChart />
                  /* <Button onClick={openModal} variant="contained">Open Reference Guide</Button> /
                </CardContent>
              </Card>
            </Grid>
          ) : null}*/}

          <Grid item xs={12} xl={12}>
            <Card sx={{ borderRadius: "0", marginBottom: "2rem" }} className="m-b-0">
            {/*<CardContent sx={activeRole === "ATCO" ? { minHeight: "calc(100vh - 10.95rem)" } : {}}>*/}
            <CardContent sx={{}}>
  <Grid container justifyContent="space-between">
    <Grid item>
      <Typography variant="h6" sx={{ fontWeight: "500" }}>
        {EnglishLanguage ? "To-Do" : "LISTA DE TAREAS"}
      </Typography>
    </Grid>
    <Grid item>
      <Tooltip title={EnglishLanguage ? "Add an item to your to-do list." : "Agregue un elemento a su lista de tareas pendientes."}>
        <Link to="/create-new-incident-report" sx={{ textDecoration: "none" }} onClick={handleNewIncidentClick }>
          <Grid container spacing={2} className="link-hover-home">
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "500" }} mb={3} mt={1.25} className="mobile-hide">
                {EnglishLanguage ? "Add" : "Agregar"}
              </Typography>{" "}
            </Grid>
            <Grid item sx={{ position: "relative", top: "-8px" }}>
              <PlaylistAddIcon className="add-todo-icon" />
            </Grid>
          </Grid>
        </Link>
      </Tooltip>
    </Grid>
  </Grid>
  <ToDoList />
</CardContent>

            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          pr={1}
          xl={5}
          // ml={1}
          sx={{ maxHeight: "159px" }}
          className="right-grid-wrap"
          alignContent="flex-start">
          <Grid item xs={6} xl={6} pr={0.5} mb={1} className="action-items-wrap">
            <Card
              sx={{
                borderRadius: "0",
                background: "#46494b",
                minHeight: "95px",
              }}>
                   {/*activeRole === "Admin" ? (
                     <CreateNewUser theme={theme} />
            
                   ): null*/}
                    {/*activeRole !== "Admin" ? (
               <IncidentReport theme={theme} />
                   ): null*/}
                <IncidentReport theme={theme} />
            </Card>
          </Grid>

          <Grid item xs={6} xl={6} pl={0.5} mb={1} className="action-items-wrap">
            <Card
              sx={{
                borderRadius: "0",
                background: "#46494b",
                minHeight: "95px",
              }}>
              <SafetyAssessment />
            </Card>
          </Grid>
          {/*activeRole === "Safety Director" || activeRole === "Regional Manager" || activeRole === "Facility Manager" ? (
          <Grid item xs={12} lg={12} mb={1}>
            <Card sx={{ borderRadius: "0" }}>
              <CardContent className="incidents">
                <Typography variant="h6" sx={{ fontWeight: "500" }} mb={2} mt={0}>
                  {EnglishLanguage ? (
                    <span>Top Incident Categories for September</span>
                  ) : (
                    <span>Principales categorías de ocurrencias de septiembre</span>
                  )}
                </Typography>
                <Card
                  sx={{
                    borderRadius: "0",
                    height: "100%",
                    background: "transparent",
                    boxShadow: "none",
                  }}>
                  <RecentCatChart />
                </Card>
              </CardContent>
            </Card>
          </Grid>
  ) : null*/}


         {(activeUserRole.permissions[0].approveReport === 1 || activeUserRole.permissions[0].approveEscalatedReport === 1) ? 
          <Grid item xs={12} lg={12} mb={1}>
            <Card sx={{ borderRadius: "0" }}>
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: "500" }} mb={2} mt={0}>
                  {EnglishLanguage ? "Drafts" : "borradores"}
                </Typography>
                <Card
                  sx={{
                    borderRadius: "0",
                    backgroundColor: "transparent",
                    maxHeight: "100%",
                    boxShadow: "none",
                  }}>
                 <RecentIncidents />
                </Card>
              </CardContent>
            </Card>
          </Grid>
         : ""}
        </Grid>
      </Grid>
      <ReferenceGuide />
    </Container>
  );
};

export default Home;
