import React from "react";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-router-dom";
import TopNavBar from "../TopNavBar";
import FacilityDropdown from "../../components/forms/FacilityDropdown";
import Typography from "@mui/material/Typography";
import { useCentroStore } from "../../store";
import { Tooltip } from "@mui/material";
import { useState } from "react";

function HeaderSection(props) {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 3000);
  };
  return (
    <Grid container justifyContent="flex-start" className="header-container">
      <Grid
        container
        mt={2}
        mb={1}
        xl={6}
        pl={2}
        flexDirection="column"
        className="home-padding-fix header-wrap-fix"
      >
        <Grid container justifyContent="flex-start" alignItems="baseline">
          <Grid
            container
            justifyContent="flex-start"
            alignItems="baseline"
            xl={9}
          >
            <Grid item xl={10} className="breadcrumb-facility-wrap">
              <Grid container justifyContent="flex-start">
                <Grid item>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Tooltip title="Return to your Home Page.">
                <Link underline="hover" color="#FFF" to="/home"  className="home-icon">
                  <HomeIcon fontSize="30px" sx={{ paddingTop: "5px" }} />
                </Link>
                </Tooltip>
                {EnglishLanguage ? (
                  <Typography color="#FFF">{props.sectionName}</Typography>
                ) : (
                  <Typography color="#FFF">{props.sectionNameES}</Typography>
                )}
              </Breadcrumbs>
              </Grid>
              
                </Grid>
            </Grid>
            <Grid container alignContent="center" alignItems="center" sx={{padding: "0 0.25rem", marginTop: "-0.25rem", marginBottom: "0.25rem", flexFlow: "nowrap", whiteSpace: "nowrap"}} background="primary" >
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "500",
                    textTransform: "uppercase",
                    color: "#FFF",
                    fontSize: "2.75rem!important",
                  }}
                >
                  {EnglishLanguage ? (
                    <span>Select Facility : </span>
                  ) : (
                    <span>Seleccione la Instalación : </span>
                  )}
                </Typography>
              </Grid>
              <Tooltip title="Choose the facility to pull data from. These options are limited by your ANSP." open={tooltipOpen}  onClose={() => setTooltipOpen(false)} placement= "right">
              <Grid item className="title-select-wrap" onMouseEnter={handleTooltipOpen}>
              <FacilityDropdown />
              </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container   mx={0}   xl={6} flexDirection="column" sx={{height: "max-content"}} alignContent="flex-end">
        <TopNavBar />
      </Grid>
    </Grid>
  );
}

export default HeaderSection;
