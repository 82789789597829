import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import DoneIcon from '@mui/icons-material/Done';
import OperatorName from "./OperatorName";
import AircraftFacilityDropdown from "./AircraftFacilityDropdown";
import AircraftCallsign from "./AircraftCallsign";
import AircraftTypeDynamicDropdown from "./AircraftTypeDynamicDropdown";
import AircraftBeacon from "./AircraftBeacon";
import AircraftIFRVFRDropdown from "./AircraftIFRVFRDropdown";
import AircraftAltitude from "./AircraftAltitude";
import AircraftSpeed from "./AircraftSpeed";
import AircraftPositionDropdown from "./AircraftPositionDropdown";
import AircraftFlightStage from "./AircraftFlightStage";
import AircraftBankAngleDropdown from "./AircraftBankAngleDropdown";
import AircraftBankDirectionDropdown from "./AircraftBankDirectionDropdown";
import AircraftFollowingFlightplanDropdown from "./AircraftFollowingFlightplanDropdown";
import AircraftLightingUsedCheckboxes from "./AircraftLightingUsedCheckboxes";
import AircraftTypeUnknownCheckboxes from "./AircraftTypeUnknownCheckboxes";
import AircraftTrafficAvoidanceDropdown from "./AircraftTrafficAvoidanceDropdown";
import AircraftTrafficAvoidanceOther from "./AircraftTrafficAvoidanceOther";
import AircraftACASDropdown from "./AircraftACASDropdown";
import AircraftIdentificationMethodDropdown from "./AircraftIdentificationMethodDropdown";
import AircraftACSightedDropdown from "./AircraftACSightedDropdown";
import AircraftAvoidingActionTakenDropdown from "./AircraftAvoidingActionTakenDropdown";
import AircraftPilotName from "./AircraftPilotName";
import AircraftPilotContactInfo from "./AircraftPilotNameContactInfo";
import AircraftPilotLicense from "./AircraftPilotLicense";
import { Button, Modal, Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { useCentroStore } from "../../../../store";
import AircraftTrafficInfoUsedDropdown from "./AircraftTrafficInfoUsedDropdown";
import AircraftOtherTrafficInformation from "./AircraftOtherTrafficInformation";
import AircraftAltitudeDropdown from "./AircraftAltitudeDropdown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useAircrafts } from "../../../../store/queries/aircraftQueries";
import { useReports } from '../../../../store/queries/incidentReportQueries';
import AircraftTypeUnknownDescription from "./AircraftTypeUnknownDescription";
import AircraftEquipmentSuffixDropdown from "./AircraftEquipmentSuffixDropdown";
import AircraftFlightStageSurface from "./AircraftFlightStageSurface";
import { useState } from "react";
import { Checkbox } from "@mui/material";
import ACASTypeDropdown from "./ACASTypeDropdown";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

export default function AirCraft() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const aircrafts = useCentroStore((state) => state.aircrafts);
  const addAircrafts = useCentroStore((state) => state.addAircrafts);
  const aircraft = useCentroStore((state) => state.aircraftOne);
  const setAircraftOneIrId = useCentroStore((state) => state.setAircraftOneIrId);
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);

  const aircraftOneFac = useCentroStore((state) => state.aircraftOneFac);
  const aircraftOnePos = useCentroStore((state) => state.aircraftOnePos);
  const aircraftOneSuffix = useCentroStore((state) => state.aircraftOneSuffix);
  const [isTypeUnknown, setIsTypeUnknown] = useState(aircraft?.typeUnknown || (aircraft?.typeUnknownDesc && aircraft?.typeUnknownDesc.length > 0));
  
  const setAircraftOneTypeUnknown = useCentroStore((state) => state.setAircraftOneTypeUnknown);
  const setAircraftOneTypeUnknownDesc = useCentroStore((state) => state.setAircraftOneTypeUnknownDesc);
  
  const handleUnknownCheckbox = (event) => {
    const checked = event.target.checked;
    setIsTypeUnknown(checked);
    setAircraftOneTypeUnknown(checked ? "" : null);
    setAircraftOneTypeUnknownDesc(checked ? "" : null);
  };
  const { aircraftsFromDb } = useReports(currentIncidentReport.reportingFacilityId, currentIncidentReport.irId)
  //const { aircraftsFromDb } = useAircrafts(currentIncidentReport.irId);
  //const currentAircraftOneOperator = useCentroStore((state) => state.currentAircraftOneOperator);
  const currentAircraftOneType = useCentroStore((state) => state.currentAircraftOneType);
  const [open, setOpen] = React.useState(false);
  const currentincidenttype = useCentroStore((state) => state.currentincidenttype);
  const handleOpen = () => {
    setOpen(true);
    setAircraftOneIrId(currentIncidentReport.irId);
    // console.log(aircrafts);
  };
  const handleClose = () => {
    setOpen(false);
      /*
    var index;
    var aircraftFound;
    if (aircraftsFromDb?.data?.data.length === 0) {
      aircraftFound = aircrafts.some((a, i) => { index = i; return a.irAircraftId === 1; });
    } else {
      aircraftFound = aircrafts.some((a, i) => { index = i; return a.irAircraftId === aircraft.irAircraftId; });
    }
    if (aircraftFound) {
      aircrafts[0] = aircraft;
    } else {
      aircrafts.push(aircraft);
    }
    // console.log(aircrafts);
    addAircrafts(aircrafts);
    */
  };

  return (
    <>
      <Grid container py={0} spacing={0} mt={0} className="ir-form-wrapper">
        <Grid xs={12}>
          <Item elevation={0} sx={{ padding: "0" }}>
            <Grid xs={12}>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ background: "#00000033", borderRadius: ".5rem", padding: "10px 10px 15px" }}
                className="ir-form-wrapper"
                xs={12}>
                <Grid xs={12} mt={0} pb={0} className="table-header-wrap">
                  <Item elevation={0}>
                    <AirplanemodeActiveIcon />
                  </Item>
                  <Item elevation={0} onClick={handleOpen}>
                    <Tooltip title={EnglishLanguage ? "Click to fill out Aircraft 1 Information" : "Haga clic para completar la informaci\xF3n de la Aeronave 1"}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        paddingBottom: ".25rem",
                        color: "#f7b01a!important",
                        cursor: "pointer",
                      }}
                      variant="h6">
                      {EnglishLanguage ? ("Aircraft 1 (edit)") : ("Aeronave 1 (editar)")}
                    </Typography>
                    </Tooltip>
                  </Item>
                </Grid>
                <Grid
                  container
                  direction="row"
                  flexWrap="nowrap"
                  onClick={handleOpen}
                  alignItems="stretch"
                  className="aircraft-details-container-wrap">
                  <Grid container className="section-wrapper aircraft-details-wrapper" xs={2}>
                    {/* <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>Operator:</Grid>
                      <Grid lg={6}>{!currentAircraftOneOperator ? "" : currentAircraftOneOperator.airline}</Grid>
                    </Item> */}
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Tooltip title={EnglishLanguage ? "Facility in communication with A/C" : "INSTALACI\xF3N EN COMUNICACI\xF3N CON LA AERONAVE"}>
                        <Grid lg={6}>{EnglishLanguage ? ("Facility in comm...:") : ("INSTALACI\xF3N EN COMUNICACI\xF3N...:")}</Grid>
                      </Tooltip>
                      <Grid lg={6}>{!aircraftOneFac ? "" : aircraftOneFac.name}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("Callsign:") : ("INDICATIVO:")}</Grid>
                      <Grid lg={6}>{aircraft === undefined || aircraft.callsign === undefined ? "" : aircraft.callsign}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("Beacon:") : ("FARO:")}</Grid>
                      <Grid lg={6}>{!aircraft || aircraft.beaconCode === 0 ? "" : aircraft.beaconCode}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("Aircraft Type:") : ("TIPO DE AERONAVE:")}</Grid>
                      <Grid lg={6}>
                        {currentAircraftOneType === undefined || currentAircraftOneType === null
                          ? ""
                          : currentAircraftOneType.model}
                      </Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("Type Unknown:") : ("TIPO DESCONOCIDA:")}</Grid>
                      <Grid lg={6}>
                        {aircraft === undefined || aircraft.typeUnknown === undefined ? "" : aircraft.typeUnknown}
                      </Grid>
                    </Item>

                    {/* <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>Speed:</Grid>
                      <Grid lg={6}>{aircraft.speed === 0 ? "" : aircraft.speed}</Grid>
                    </Item> */}
                  </Grid>
                  <Grid container className="section-wrapper aircraft-details-wrapper" xs={2}>
                    {" "}
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("Equipment Suffix:") : ("Sufijo de Instrumentos:")}</Grid>
                      <Grid lg={6}>{!aircraft || aircraft.equipmentTypeId === 0 ? "" : aircraftOneSuffix}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>IFR/VFR:</Grid>
                      <Grid lg={6}>{aircraft === undefined || aircraft.flightRule === undefined ? "" : aircraft.flightRule}</Grid>
                    </Item>
                    {currentincidenttype === "airborne" && (
                      <Item
                        className="aircraft-details-text-wrap"
                        elevation={0}
                        sx={{ display: "flex", width: "100%", padding: "0" }}>
                        <Grid lg={6}>{EnglishLanguage ? ("Altitude:") : ("Altitud:")}</Grid>
                        <Grid lg={6}>
                          {aircraft === undefined || isNaN(aircraft.altitude) || aircraft.altitude === 0 ? "" : aircraft.altitude}
                        </Grid>
                      </Item>
                    )}
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("ATC Position:") : ("POSICI\xF3N DE ATC:")}</Grid>
                      <Grid lg={6}>
                        {aircraftOnePos === undefined || aircraftOnePos === null || aircraftOnePos.posName === undefined
                          ? ""
                          : aircraftOnePos.posName + "-" + aircraftOnePos.frequency}
                      </Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("Flight Stage:") : ("ETAPA DE VUELO:")}</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.flightStage}</Grid>
                    </Item>
                    {/* <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>Bank Angle:</Grid>
                      <Grid lg={6}>{aircraft.bankAngle}</Grid>
                    </Item> */}
                    {/* <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>Bank Direction:</Grid>
                      <Grid lg={6}>{aircraft.bankDirection}</Grid>
                    </Item> */}
                    {/* <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>Following FP:</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" :  aircraft.flightPlan}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>Lighting Used:</Grid>
                      <Grid lg={6}>{aircraft.aircraftLighting}</Grid>
                    </Item> */}
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("Traffic Information:") : ("INFORMACI\xF3N DE TR\xE1FICO:")}</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.trafficInformation}</Grid>
                    </Item>
                    {/* <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>Other Avoidance:</Grid>
                      <Grid lg={6}>{aircraft.trafficAvoidanceDesc}</Grid>
                    </Item> */}
                  </Grid>
                  <Grid container className="section-wrapper aircraft-details-wrapper" xs={2}>
                    {" "}
                    {aircraft !== undefined && aircraft.trafficInformation === "Yes, based on Other Information" && (
                      <Item
                        className="aircraft-details-text-wrap"
                        elevation={0}
                        sx={{ display: "flex", width: "100%", padding: "0" }}>
                        <Grid lg={6}>{EnglishLanguage ? ("Other Traffic Info:") : ("otra informaci\xF3n de tr\xE1fico:")}</Grid>
                        <Grid lg={6}>{aircraft === undefined ? "" : aircraft.trafficInformationDesc}</Grid>
                      </Item>
                    )}
                    {aircraft !== undefined && aircraft.trafficInformation !== "Yes, based on Other Information" && (
                      <Item
                        className="aircraft-details-text-wrap"
                        elevation={0}
                        sx={{ display: "flex", width: "100%", padding: "0" }}>
                        <Grid lg={6}>{EnglishLanguage ? ("Other Traffic Info:") : ("otra informaci\xF3n de tr\xE1fico:")}</Grid>
                        <Grid lg={6}>N/A</Grid>
                      </Item>
                    )}
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("Traffic Avoidance:") : ("Evitaci\xF3n de tr\xE1fico:")}</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.trafficAvoidance}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("ACAS Used?:") : ("\u00bfACAS USADOS?:")}</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.acasUsed}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("ACAS Type:") : ("TIPO DE ACAS:")}</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.acasType}</Grid>
                    </Item>
                    {/* <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>Identification Meth... :</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.identificationMethod}</Grid>
                    </Item> */}
                    {/* <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>Other A/C Sighted:</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.otherAircraftSighted}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>Avoiding Action Tak... :</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.avoidingAction}</Grid>
                    </Item> */}
                    {/* <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>Pilot Name:</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.pilotName}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>Pilot Contact Info.</Grid>
                      <Grid lg={6}>{aircraft === undefined ? "" : aircraft.pilotContactInfo}</Grid>
                    </Item>
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>Pilot License #</Grid>
                      <Grid lg={6}>{aircraft.license}</Grid>
                    </Item> */}
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}></Grid>
                      <Grid lg={6}></Grid>
                    </Item>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="aircraft-modal-scroll-wrap">
        <Box sx={style} className="aircraft-modal-wrap">
          <Grid container justifyContent="space-between" spacing={0} sx={{ marginTop: "1rem" }} className="ir-form-wrapper">
            <Grid xl={12} mt={1} className="table-header-wrap" display="flex">
              <Item elevation={0}>
                <AirplanemodeActiveIcon />
              </Item>
              <Item elevation={0}>
                <Typography
                  sx={{
                    textAlign: "left",
                    paddingBottom: ".25rem",
                    color: "#55819b",
                    fontWeight: "400!important",
                    fontSize: "2.5rem!important",
                    padding: "0 0.5rem!important;",
                  }}
                  variant="h6">
                  {EnglishLanguage ? ("Aircraft 1") : ("Aeronave 1")}
                </Typography>
              </Item>
            </Grid>
            {/* General Information Header */}

            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0} style={{ minWidth: "235px" }}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      Operator
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <OperatorName />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}

            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("Facility in communication with A/C") : ("INSTALACI\xF3N EN COMUNICACI\xF3N CON LA AERONAVE")}
                      {aircraft.facility !== null && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftFacilityDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>

            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("Callsign:") : ("INDICATIVO:")}
                      {aircraft.callsign !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftCallsign />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("Beacon:") : ("FARO:")}
                      {aircraft.beaconCode !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftBeacon />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid container display="flex" flexDirection="column" alignItems="stretch">
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("Aircraft Type:") : ("TIPO DE AERONAVE:")}{" "}
                      {aircraft.type !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                      <Checkbox
                        size="small"
                        color="primary"
                        inputProps={{ 'aria-label': 'checkbox' }}
                        checked={isTypeUnknown}
                        onChange={handleUnknownCheckbox}
                      />
                      
                        {EnglishLanguage ? ("Click here if type is unknown") : ("DESCONOCIDO")}
                      </Typography>
                      <AircraftTypeDynamicDropdown unknown={isTypeUnknown} />
                  </Item>
                </Grid>
              </Item>
            </Grid>

            {(!currentAircraftOneType ? "" : isTypeUnknown) && (
              <>
                <Grid lg={12} xs={12} xl={3} ml={3}>
                  <Item elevation={0}>
                    <Grid>
                      <Item className="conditional-arrow">
                        <KeyboardDoubleArrowRightIcon />
                      </Item>
                      <Item elevation={0}>
                        <Typography
                          sx={{
                            color: "text.secondary",
                            textAlign: "left",
                            fontSize: "1.2rem!important",
                          }}>
                          {EnglishLanguage ? ("Aircraft Type Unknown:") : ("TIPO DE AERONAVE DESCONOCIDA:")}
                          {aircraft.typeUnknown !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                        </Typography>
                      </Item>
                      <Item
                        elevation={0}
                        sx={{
                          textAlign: "left",
                          paddingTop: "0",
                        }}>
                        <AircraftTypeUnknownCheckboxes />
                      </Item>
                    </Grid>
                  </Item>
                </Grid>
                <Grid lg={12} xs={12} xl={3} ml={3}>
                  <Item elevation={0}>
                    <Grid>
                      <Item className="conditional-arrow">
                        <KeyboardDoubleArrowRightIcon />
                      </Item>
                      <Item elevation={0}>
                        <Typography
                          sx={{
                            color: "text.secondary",
                            textAlign: "left",
                            fontSize: "1.2rem!important",
                          }}>
                          {EnglishLanguage ? ("Aircraft Type Unknown Description:") : ("DESCRIPCI\xF3N AERONAVE DESCONOCIDA:")}
                          {aircraft.typeUnknownDesc !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                        </Typography>
                      </Item>
                      <Item
                        elevation={0}
                        sx={{
                          textAlign: "left",
                          paddingTop: "0",
                          marginTop: "-10px",
                        }}>
                        <AircraftTypeUnknownDescription />
                      </Item>
                    </Grid>
                  </Item>
                </Grid>
              </>
            )}
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("Equipment Suffix:") : ("Sufijo de Instrumentos:")}
                      {aircraft.equipmentTypeId !== null && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftEquipmentSuffixDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      IFR/VFR
                      {aircraft.flightRule !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftIFRVFRDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {currentincidenttype === "airborne" && (
              <Grid lg={12} xs={12} xl={3}>
                <Item elevation={0}>
                  <Grid>
                    <Item elevation={0}>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          textAlign: "left",
                          fontSize: "1.2rem!important",
                        }}>
                        {EnglishLanguage ? ("Altitude:") : ("Altitud:")}
                        {aircraft.altitude !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                      </Typography>
                    </Item>
                    <Grid lg={12} display="flex" flexDirection="row" alignItems="flex-start">
                      <Grid xs={12} sm={8}>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}>
                          <AircraftAltitude />
                        </Item>
                      </Grid>
                      <Grid xs={12} sm={3} sx={{ minWidth: "inherit" }}>
                        <Item elevation={0}>
                          <AircraftAltitudeDropdown />
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            )}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Speed
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftSpeed />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("ATC Position:") : ("POSICI\xF3N DE ATC:")}
                      {aircraft.position !== null && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftPositionDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {currentincidenttype === "airborne" && (
              <Grid lg={12} xs={12} xl={3}>
                <Item elevation={0}>
                  <Grid>
                    <Item elevation={0}>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          textAlign: "left",
                          fontSize: "1.2rem!important",
                        }}>
                        {EnglishLanguage ? ("Flight Stage:") : ("ETAPA DE VUELO:")}
                        {aircraft.flightStage !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                      </Typography>
                    </Item>
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                        paddingTop: "0",
                        marginTop: "-10px",
                      }}>
                      <AircraftFlightStage />
                    </Item>
                  </Grid>
                </Item>
              </Grid>
            )}
            {currentincidenttype === "surface" && (
              <Grid lg={12} xs={12} xl={3}>
                <Item elevation={0}>
                  <Grid>
                    <Item elevation={0}>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          textAlign: "left",
                          fontSize: "1.2rem!important",
                        }}>
                        {EnglishLanguage ? ("Flight Stage:") : ("ETAPA DE VUELO:")}
                        {aircraft.flightStage !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                      </Typography>
                    </Item>
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                        paddingTop: "0",
                        marginTop: "-10px",
                      }}>
                      <AircraftFlightStageSurface />
                    </Item>
                  </Grid>
                </Item>
              </Grid>
            )}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Bank Angle
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftBankAngleDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Bank Direction
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftBankDirectionDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Following Flight Plan
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftFollowingFlightplanDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Lighting Used Checkbox
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "10px",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftLightingUsedCheckboxes />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}

            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("Traffic Information:") : ("INFORMACI\xF3N DE TR\xE1FICO:")}
                      {aircraft.trafficInformation !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftTrafficInfoUsedDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {/* {(aircraft !== undefined && aircraft.trafficInformation === "Yes, based on Other Information") && (
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Other Information Description
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftOtherTrafficInformation />
                  </Item>
                </Grid>
              </Item>
            </Grid>)} */}
            {/* {(aircraft !== undefined && aircraft.trafficAvoidance === "Yes, based on Other Information") && (
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                <Item className="conditional-arrow"><KeyboardDoubleArrowRightIcon /></Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                        whiteSpace: "no-wrap",
                      }}
                    >
                      Other Avoidance Info
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTrafficAvoidanceOther />
                  </Item>
                </Grid>
              </Item>
            </Grid>)} */}
            {aircraft !== undefined && (aircraft.trafficInformation === "Yes, based on Other Information" ||
             aircraft.trafficInformation === "S\xED, basado en Otra Informaci\xF3n") && (
              <Grid lg={12} xs={12} xl={3}>
                <Item elevation={0}>
                  <Grid>
                    <Item className="conditional-arrow">
                      <KeyboardDoubleArrowRightIcon />
                    </Item>
                    <Item elevation={0}>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          textAlign: "left",
                          fontSize: "1.2rem!important",
                          whiteSpace: "no-wrap",
                        }}>
                        {EnglishLanguage ? ("Other Information Description:") : ("Otra informaci\xF3n Descripci\xF3n:")}
                        {aircraft.trafficInformationDesc !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                      </Typography>
                    </Item>
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                        paddingTop: "0",
                        marginTop: "-10px",
                      }}>
                      <AircraftOtherTrafficInformation />
                    </Item>
                  </Grid>
                </Item>
              </Grid>
            )}
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("Traffic Avoidance Instruction by ATS:") : ("INSTRUCCIONES ATS:")}
                      {aircraft.trafficAvoidance !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftTrafficAvoidanceDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("ACAS Used?:") : ("\u00bfACAS USADOS?:")}
                      {aircraft.acasUsed !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftACASDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {(aircraft.acasUsed === "Yes" || aircraft.acasUsed === "S\xED") && (
              <Grid lg={12} xs={12} xl={3}>
                <Item elevation={0}>
                  <Grid>
                    <Item elevation={0}>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          textAlign: "left",
                          fontSize: "1.2rem!important",
                        }}>
                        {EnglishLanguage ? ("ACAS Type:") : ("TIPO DE ACAS:")}
                        {aircraft.acasType !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                      </Typography>
                    </Item>
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                        paddingTop: "0",
                        marginTop: "-10px",
                      }}>
                      <ACASTypeDropdown />
                    </Item>
                  </Grid>
                </Item>
              </Grid>
            )}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      Other A/C Sighted?
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftACSightedDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      Avoiding Action Taken?
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <AircraftAvoidingActionTakenDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid className="airplane-modal-close-btn">
                  <Item elevation={0}>
                    <Tooltip title= {EnglishLanguage ? "This will close this window and populate these fields in the form. You will then need to click 'Save/Advance' before these inputs are saved to the occurrence report"
                      : "Esto cerrar\xE1 esta ventana y completar\xE1 estos campos en el formulario. Luego deber\xE1 hacer clic en 'Guardar/Avanzar' antes de que estas entradas se guarden en el Informe de Ocurrencia" }>
                      <Button variant="contained" className="step-btn" onClick={handleClose}>
                        {EnglishLanguage ? ("Close and import to form") : ("Cerrar e importar al formulario")}
                      </Button>
                    </Tooltip>
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Pilot Name
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftPilotName />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Pilot Contact Info
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftPilotContactInfo />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Pilot License #
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftPilotLicense />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
