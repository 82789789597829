import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,

  color: "#67748e",
  elements: {
    line: {
        tension: 0
    }
},
  layout: {
    padding: {
      left: 0,
      botom: 0,
      top: 0,
      right: 0,
    },

  },
  scales: {
    y: {
      ticks: { color: "#3d556c" },
      font: {
        size: 18,
        family:"'Montserrat', Arial",
        weight:"500",
      },
    },
    x: {
      ticks: { color: "#3d556c" },
      font: {
        size: 18,
        family:"'Montserrat', Arial",
        weight:"500",
      },
    },
  },
  plugins: {
    legend: {

      position: "bottom",
      labels: {
        boxWidth: 15,
        boxHeight:15,
        color: "#3d556c",
        font: {
          size: 18,
          family:"'Montserrat', Arial",
          weight:"500",
        },
        padding: 20,
      },
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April" ,
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const data = {
  labels,
  datasets: [
    {
      label: "CFIT",
      pointRadius: 1,
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1 })),
      borderColor: "#F7B01A",
      backgroundColor: "#F7B01A",
      fontColor: "#FFFFFF",
    },
    {
      label: "LOC-I",
      pointRadius: 1,
      data: labels.map(() => faker.datatype.number({ min: 30, max: 50 })),
      borderColor: "#55819b",
      backgroundColor: "#55819b",
    },
    {
      label: "MAC",
      pointRadius: 1,
      data: labels.map(() => faker.datatype.number({ min: 30, max: 40 })),
      borderColor: "#8e8e8e",
      backgroundColor: "#8e8e8e",
    },
    {
      label: "RE",
      pointRadius: 1,
      data: labels.map(() => faker.datatype.number({ min: 60, max: 70 })),
      borderColor: "#a8bfcc",
      backgroundColor: "#a8bfcc",
    },
    {
      label: "RI",
      pointRadius: 1,
      data: labels.map(() => faker.datatype.number({ min: 10, max: 20 })),
      borderColor: "#48494b",
      backgroundColor: "#48494b",
    },
  ],
};

export function LineChart() {
  return <Line options={options} data={data} />;
}
