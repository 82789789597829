import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function AircraftTwoBeacon() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const aircraft = useCentroStore((state) => state.aircraftTwo);
    const setAircraftTwoBeaconCode = useCentroStore((state) => state.setAircraftTwoBeaconCode);
    const handleChange = (event) => {
        var beaconcode = parseInt(event.target.value);
        if (isNaN(beaconcode)) {
            beaconcode = 0;
        }
        setAircraftTwoBeaconCode(beaconcode);
  };

  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? ("Number") : ("N\xFAmero")}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          rows={1}
          onBlur={handleChange}
          defaultValue={aircraft.beaconCode === 0 ? "" : aircraft.beaconCode}
          variant="standard"
          // type="number"
          onKeyDown={(e) => {
              var expression = new RegExp(/^[0-9\b]+$/);
              //Check needed to ensure deletion of beacon
              if (e.key === "Backspace" || e.key === "Delete") {
                  //console.log(e.key);
              }
              else if (!expression.test(e.key) || e.target.value.length >= 4) {
                  e.preventDefault()
              }
          }}
        />
  );
};