import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import DoneIcon from '@mui/icons-material/Done';
import OperatorName from "./OperatorName";
import AircraftTwoFacilityDropdown from "./AircraftTwoFacilityDropdown";
import AircraftTwoCallsign from "./AircraftTwoCallsign";
import AircraftTwoTypeDynamicDropdown from "./AircraftTwoTypeDynamicDropdown";
import AircraftTwoBeacon from "./AircraftTwoBeacon";
import AircraftTwoIFRVFRDropdown from "./AircraftTwoIFRVFRDropdown";
import AircraftTwoAltitude from "./AircraftTwoAltitude";
import AircraftTwoSpeed from "./AircraftTwoSpeed";
import AircraftTwoPositionDropdown from "./AircraftTwoPositionDropdown";
import AircraftTwoFlightStage from "./AircraftTwoFlightStage";
import AircraftTwoBankAngleDropdown from "./AircraftTwoBankAngleDropdown";
import AircraftTwoBankDirectionDropdown from "./AircraftTwoBankDirectionDropdown";
import AircraftTwoFollowingFlightplanDropdown from "./AircraftTwoFollowingFlightplanDropdown";
import AircraftTwoLightingUsedCheckboxes from "./AircraftTwoLightingUsedCheckboxes";
import AircraftTwoTypeUnknownCheckboxes from "./AircraftTwoTypeUnknownCheckboxes";
import AircraftTwoTrafficAvoidanceDropdown from "./AircraftTwoTrafficAvoidanceDropdown";
import AircraftTwoTrafficAvoidanceOther from "./AircraftTwoTrafficAvoidanceOther";
import AircraftTwoACASDropdown from "./AircraftTwoACASDropdown";
import AircraftTwoIdentificationMethodDropdown from "./AircraftTwoIdentificationMethodDropdown";
import AircraftTwoACSightedDropdown from "./AircraftTwoACSightedDropdown";
import AircraftTwoAvoidingActionTakenDropdown from "./AircraftTwoAvoidingActionTakenDropdown";
import AircraftTwoPilotName from "./AircraftTwoPilotName";
import AircraftTwoPilotContactInfo from "./AircraftTwoPilotNameContactInfo";
import AircraftTwoPilotLicense from "./AircraftTwoPilotLicense";
import { Button, Modal, Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { useCentroStore } from "../../../../store";
import AircraftTwoTrafficInfoUsedDropdown from "./AircraftTwoTrafficInfoUsedDropdown";
import AircraftTwoOtherTrafficInformation from "./AircraftTwoOtherTrafficInformation";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AircraftTwoAltitudeDropdown from "./AircraftTwoAltitudeDropdown";
import AircraftTwoEquipmentSuffixDropdown from "./AircraftTwoEqiupmentSuffixDropdown";
import AircraftTwoTypeUnknownDescription from "./AircraftTwoTypeUnknownDescription";
import AircraftTwoFlightStageSurface from "./AircraftTwoFlightStageSurface";
import { useAircrafts } from "../../../../store/queries/aircraftQueries";
import { useReports } from '../../../../store/queries/incidentReportQueries';
import { useState } from "react";
import {Checkbox} from "@mui/material";
import AircraftTwoACASTypeDropdown from "./AircraftTwoACASTypeDropdown";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
 
export default function AircraftTwo() {
    const [open, setOpen] = React.useState(false);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const aircraft2 = useCentroStore((state) => state.aircraftTwo);
    const aircrafts = useCentroStore((state) => state.aircrafts);
    const aircraft = useCentroStore((state) => state.aircraftTwo);
    const addAircrafts = useCentroStore((state) => state.addAircrafts);
    const setAircraftTwoIrId = useCentroStore((state) => state.setAircraftTwoIrId);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const aircraftTwoFac = useCentroStore((state) => state.aircraftTwoFac);
    const aircraftTwoPos = useCentroStore((state) => state.aircraftTwoPos);
    const aircraftTwoSuffix = useCentroStore((state) => state.aircraftTwoSuffix);
    const [isTypeUnknown, setIsTypeUnknown] = useState(aircraft?.typeUnknown || (aircraft?.typeUnknownDesc && aircraft?.typeUnknownDesc.length > 0));
    
    const setAircraftTwoTypeUnknown = useCentroStore((state) => state.setAircraftTwoTypeUnknown);
    const setAircraftTwoTypeUnknownDesc = useCentroStore((state) => state.setAircraftTwoTypeUnknownDesc);
    
    const handleUnknownCheckbox = (event) => {
      const checked = event.target.checked;
      setIsTypeUnknown(checked);
      setAircraftTwoTypeUnknown(checked ? "" : null);
      setAircraftTwoTypeUnknownDesc(checked ? "" : null);
    };

    const { aircraftsFromDb } = useReports(currentIncidentReport.reportingFacilityId, currentIncidentReport.irId)
    //const { aircraftsFromDb } = useAircrafts(currentIncidentReport.irId);
    //const currentAircraftTwoOperator = useCentroStore((state) => state.currentAircraftTwoOperator);
    const currentAircraftTwoType = useCentroStore((state) => state.currentAircraftTwoType);
    const aircraftTwoType = useCentroStore((state) => state.aircraftTwoType);

    const currentincidenttype = useCentroStore(
      (state) => state.currentincidenttype
    );

    const handleOpen = () => {
        setAircraftTwoIrId(currentIncidentReport.irId);
        setOpen(true);
    }
    const handleClose = () => {
        /*
        //console.log(aircraft2);
        var index;
        var aircraftFound;
        if (aircraftsFromDb?.data?.data.length === 0) {
            aircraftFound = aircrafts.some((a, i) => { index = i; return a.irAircraftId === 2 });
        }
        else {
            aircraftFound = aircrafts.some((a, i) => { index = i; return a.irAircraftId === aircraft2.irAircraftId });
        }
        console.log(aircraftFound, aircrafts);
        //console.log(aircraftFound, index, aircrafts);
        if (aircraftFound) {
            aircrafts[1] = aircraft2;
        }
        else {
            aircrafts.push(aircraft2);
        }
        addAircrafts(aircrafts);
        */
        setOpen(false)
    };

  return (
    <>
      <Grid container py={0} spacing={0} mt={0} className="ir-form-wrapper">
        <Grid xs={12}>
          <Item elevation={0} sx={{ padding: "0" }}>
            <Grid xs={12}>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ background: "#00000033", borderRadius: ".5rem", padding: "10px 10px 15px" }}
                className="ir-form-wrapper"
                xs={12}
              >
                <Grid xs={12} mt={0} pb={0} className="table-header-wrap">
                  <Item elevation={0}>
                    <AirplanemodeActiveIcon />
                  </Item>
                  <Item elevation={0} onClick={handleOpen}>
                  <Tooltip title={EnglishLanguage ? "Click to fill out Aircraft 2 Information" : "Haga clic para completar la informaci\xF3n de la Aeronave 2"}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        paddingBottom: ".25rem",
                        color: "#f7b01a!important",
                        cursor: "pointer",
                      }}
                      variant="h6"
                      className="aircraft-two-varient"
                      >
                      
                    
                     {EnglishLanguage ? ("Aircraft 2 (edit)") : ("Aeronave 2 (editar)")}
                    </Typography>
                    </Tooltip>
                  </Item>
                </Grid>
<Grid container direction="row" flexWrap="nowrap" onClick={handleOpen}   alignItems="stretch"
                  className="aircraft-details-container-wrap">
                <Grid
                  container
                  className="section-wrapper aircraft-details-wrapper"
                  xs={2}
                >
                  {/* <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Operator:</Grid>
                    <Grid lg={6}>{!currentAircraftTwoOperator ? "" :currentAircraftTwoOperator.airline}</Grid>
                  </Item> */}
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                     <Tooltip title={EnglishLanguage ? "Facility in communication with A/C" : "INSTALACI\xF3N EN COMUNICACI\xF3N CON LA AERONAVE"}>
                     <Grid lg={6}>{EnglishLanguage ? ("Facility in comm...:") : ("INSTALACI\xF3N EN COMUNICACI\xF3N...:")}</Grid></Tooltip>
                    <Grid lg={6}>{!aircraftTwoFac ? "" : aircraftTwoFac.name}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("Callsign:") : ("INDICATIVO:")}</Grid>
                    <Grid lg={6}>{aircraft2 === undefined || aircraft2.callsign === undefined ? "" : aircraft2.callsign}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("Beacon:") : ("FARO:")}</Grid>
                    <Grid lg={6}>{!aircraft2 || aircraft2.beaconCode === 0 ? "" : aircraft2.beaconCode}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("Aircraft Type:") : ("TIPO DE AERONAVE:")}</Grid>
                    <Grid lg={6}>{!currentAircraftTwoType ? "" : currentAircraftTwoType.model}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("Type Unknown:") : ("TIPO DESCONOCIDA:")}</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" : aircraft2.typeUnknown}</Grid>
                  </Item>
                  
                  {/* <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Speed:</Grid>
                    <Grid lg={6}>{aircraft2.speed === 0 ? "" : aircraft2.speed}</Grid>
                  </Item> */}
                </Grid>
                <Grid
                  container
                  className="section-wrapper aircraft-details-wrapper"
                  xs={2}
                >
                  {" "}
                  <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>{EnglishLanguage ? ("Equipment Suffix:") : ("Sufijo de Instrumentos:")}</Grid>
                      <Grid lg={6}>{!aircraft2 || aircraft2.equipmentTypeId === 0 ? "" : aircraftTwoSuffix}</Grid>
                    </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>IFR/VFR:</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" : aircraft2.flightRule}</Grid>
                  </Item>
                  {currentincidenttype === "airborne" && (
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("Altitude:") : ("Altitud:")}</Grid>
                    <Grid lg={6}>{aircraft2 === undefined || (isNaN(aircraft2.altitude) || aircraft2.altitude === 0) ? "" : aircraft2.altitude}</Grid>
                  </Item>
                  )}
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("ATC Position:") : ("POSICI\xF3N DE ATC:")}</Grid>
                    <Grid lg={6}>{!aircraftTwoPos || !aircraftTwoPos.posName ? "" : aircraftTwoPos.posName+'-'+aircraftTwoPos.frequency}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("Flight Stage:") : ("ETAPA DE VUELO:")}</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" : aircraft2.flightStage}</Grid>
                  </Item>
                  {/* <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Bank Angle:</Grid>
                    <Grid lg={6}>{aircraft2.bankAngle}</Grid>
                  </Item> */}
                  {/* <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Bank Direction:</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" : aircraft2.bankDirection}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Following FP:</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" :aircraft2.flightPlan}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Lighting Used:</Grid>
                    <Grid lg={6}>{aircraft2.aircraftLighting}</Grid>
                  </Item> */}
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("Traffic Information:") : ("INFORMACI\xF3N DE TR\xE1FICO:")}</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" :aircraft2.trafficInformation}</Grid>
                  </Item>

                  {/* <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Other Avoidance:</Grid>
                    <Grid lg={6}>{aircraft2.trafficAvoidanceDesc}</Grid>
                  </Item> */}

                </Grid>
                <Grid
                  container
                  className="section-wrapper aircraft-details-wrapper"
                  xs={2}
                >
                  {" "}
                  {(aircraft2 !== undefined && aircraft2.trafficInformation === "Yes, based on Other Information") && (
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("Other Traffic Info:") : ("otra informaci\xF3n de tr\xE1fico:")}</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" : aircraft2.trafficInformationDesc}</Grid>
                  </Item>
                  )}
                  {(aircraft2 !== undefined && aircraft2.trafficInformation !== "Yes, based on Other Information") && (
                    <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}
                    >
                      <Grid lg={6}>{EnglishLanguage ? ("Other Traffic Info:") : ("otra informaci\xF3n de tr\xE1fico:")}</Grid>
                      <Grid lg={6}>N/A</Grid>
                    </Item>
                     )}
                                    <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("Traffic Avoidance:") : ("Evitaci\xF3n de tr\xE1fico:")}</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" :aircraft2.trafficAvoidance}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>{EnglishLanguage ? ("ACAS Used?:") : ("\u00bfACAS USADOS?:")}</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" : aircraft2.acasUsed}</Grid>
                  </Item>
                  <Item
                      className="aircraft-details-text-wrap"
                      elevation={0}
                      sx={{ display: "flex", width: "100%", padding: "0" }}>
                      <Grid lg={6}>{EnglishLanguage ? ("ACAS Type:") : ("TIPO DE ACAS:")}</Grid>
                      <Grid lg={6}>{aircraft2 === undefined ? "" : aircraft2.acasType}</Grid>
                    </Item>
                  {/* <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Identification Meth... :</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" :aircraft2.identificationMethod}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Other A/C Sighted:</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" :aircraft2.otherAircraftSighted}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Avoiding Action Tak... :</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" :aircraft2.avoidingAction}</Grid>
                  </Item> */}
                  {/* <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Pilot Name:</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" : aircraft2.pilotName}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Pilot Contact Info.</Grid>
                    <Grid lg={6}>{aircraft2 === undefined ? "" : aircraft2.pilotContactInfo}</Grid>
                  </Item>
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}>Pilot License #</Grid>
                    <Grid lg={6}>{aircraft2.license}</Grid>
                  </Item> */}
                  <Item
                    className="aircraft-details-text-wrap"
                    elevation={0}
                    sx={{ display: "flex", width: "100%", padding: "0" }}
                  >
                    <Grid lg={6}></Grid>
                    <Grid lg={6}></Grid>
                  </Item>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>



      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="aircraft-modal-scroll-wrap"
      >
        <Box sx={style} className="aircraft-modal-wrap">
          <Grid
            container

            className="ir-form-wrapper"
          >
            <Grid xl={12} mt={1} className="table-header-wrap"  display="flex">
            <Item elevation={0}>
                <AirplanemodeActiveIcon />
              </Item>
              <Item elevation={0}>
                <Typography
                  sx={{
                    textAlign: "left",
                    paddingBottom: ".25rem",
                    color: "#55819b",
                    fontWeight: "400!important",
                    fontSize: "2.5rem!important",
                    padding: "0 0.5rem!important;"
                  }}
                  variant="h6"
                >
                  {EnglishLanguage ? ("Aircraft 2") : ("Aeronave 2")}
                </Typography>
              </Item>
            </Grid>
            {/* General Information Header */}

            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0} style={{ minWidth: "235px" }}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Operator
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <OperatorName />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}

            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                   
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Facility in communication with A/C 2") : ("INSTALACI\xF3N EN COMUNICACI\xF3N CON LA AERONAVE 2")}
                      {aircraft2.facility !== null && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                    
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoFacilityDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>

            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Callsign:") : ("INDICATIVO:")}
                      {aircraft2.callsign !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoCallsign />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Beacon:") : ("FARO:")}
                      {aircraft2.beaconCode !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoBeacon />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Aircraft Type
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-25px",
                    }}
                  >
                    <AircraftTwoTypeDynamicDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}


            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid container display="flex" flexDirection="column" alignItems="stretch">
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? ("Aircraft Type:") : ("TIPO DE AERONAVE:")}
                      {aircraft2.type !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                      <Checkbox
                      size="small"
                      sx={{marginLeft: "1rem"}}
                      color="primary"
                      inputProps={{ "aria-label": "checkbox" }}
                      checked={isTypeUnknown}
                      onChange={handleUnknownCheckbox}
                    />{EnglishLanguage ? ("Click here if type is unknown") : ("DESCONOCIDO")}
                    </Typography>
                    

                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-25px",
                    }}>
                    <AircraftTwoTypeDynamicDropdown unknown={isTypeUnknown} />
                  </Item>
                 
                </Grid>
              </Item>
            </Grid>


            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Aircraft Type Unknown
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                    }}
                  >
                    <AircraftTwoTypeUnknownCheckboxes />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {(!currentAircraftTwoType ? "" : isTypeUnknown) && (  
              <>
            <Grid lg={12} xs={12} xl={3} ml={3}>
              <Item elevation={0}>
                <Grid>
                <Item className="conditional-arrow"><KeyboardDoubleArrowRightIcon /></Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Aircraft Type Unknown:") : ("TIPO DE AERONAVE DESCONOCIDA:")}
                      {aircraft2.typeUnknown !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                    }}
                  >
                    <AircraftTwoTypeUnknownCheckboxes />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid lg={12} xs={12} xl={3} ml={3}>
              <Item elevation={0}>
                <Grid>
                <Item className="conditional-arrow"><KeyboardDoubleArrowRightIcon /></Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Aircraft Type Unknown Description:") : ("DESCRIPCI\xF3N AERONAVE DESCONOCIDA:")}
                      {aircraft2.typeUnknownDesc !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoTypeUnknownDescription />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            </>
            )}
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Equipment Suffix:") : ("Sufijo de Instrumentos:")}
                      {aircraft2.equipmentTypeId !== null && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoEquipmentSuffixDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      IFR/VFR
                      {aircraft2.flightRule !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoIFRVFRDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {currentincidenttype === "airborne" && (  
            <Grid lg={12} xs={12} xl={3}>


              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Altitude:") : ("Altitud:")}
                      {aircraft2.altitude !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Grid lg={12} display="flex" flexDirection="row" alignItems="flex-start">
                    <Grid xs={12} sm={8}>
                      <Item
                        elevation={0}
                        sx={{
                          textAlign: "left",
                          paddingTop: "0",
                          marginTop: "-10px",
                        }}
                      >
                        <AircraftTwoAltitude />
                      </Item>
                    </Grid>
                    <Grid xs={12} sm={3} sx={{minWidth: "inherit"}}>
                      <Item elevation={0}>
                        <AircraftTwoAltitudeDropdown />
                      </Item>
                    </Grid>
                  </Grid>
                </Grid>
              </Item>
          
            </Grid>
              )}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Speed
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoSpeed />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
              <Grid lg={12} xs={12} xl={3}>    
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("ATC Position:") : ("POSICI\xF3N DE ATC:")}
                      {aircraft2.position !== null && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoPositionDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {currentincidenttype === "airborne" && ( 
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Flight Stage:") : ("ETAPA DE VUELO:")}
                      {aircraft2.flightStage !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoFlightStage />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            )}
            {currentincidenttype === "surface" && ( 
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Flight Stage:") : ("ETAPA DE VUELO:")}
                      {aircraft2.flightStage !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoFlightStageSurface />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            )}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Bank Angle
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoBankAngleDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Bank Direction
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoBankDirectionDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Following Flight Plan
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoFollowingFlightplanDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Lighting Used Checkbox
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "10px",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoLightingUsedCheckboxes />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}

            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Traffic Information:") : ("INFORMACI\xF3N DE TR\xE1FICO:")}
                      {aircraft2.trafficInformation !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoTrafficInfoUsedDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>

            {/* {(aircraft2 !== undefined && aircraft2.trafficAvoidance === "Yes, based on Other Information") && (
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                <Item className="conditional-arrow"><KeyboardDoubleArrowRightIcon /></Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                        whiteSpace: "no-wrap",
                      }}
                    >
                      Other Avoidance Info
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoTrafficAvoidanceOther />
                  </Item>
                </Grid>
              </Item>
            </Grid>)} */}
            {(aircraft2 !== undefined && (aircraft2.trafficInformation === "Yes, based on Other Information" || 
              aircraft2.trafficInformation === "S\xED, basado en Otra Informaci\xF3n")) && (
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                <Item className="conditional-arrow"><KeyboardDoubleArrowRightIcon /></Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                        whiteSpace: "no-wrap",
                      }}
                    >
                      {EnglishLanguage ? ("Other Information Description:") : ("Otra informaci\xF3n Descripci\xF3n:")}
                      {aircraft2.trafficInformationDesc !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoOtherTrafficInformation />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            )}
             
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Traffic Avoidance Instruction by ATS:") : ("INSTRUCCIONES ATS:")}
                      {aircraft2.trafficAvoidance !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoTrafficAvoidanceDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("ACAS Used?:") : ("\u00bfACAS USADOS?:")}
                      {aircraft.acasUsed !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoACASDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {(aircraft2?.acasUsed === "Yes" || aircraft2?.acasUsed === "S\xED") && (
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("ACAS Type:") : ("TIPO DE ACAS:")}
                      {aircraft2.acasUsed !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                   <AircraftTwoACASTypeDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            )}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Other A/C Sighted?
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoACSightedDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Avoiding Action Taken?
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoAvoidingActionTakenDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid className="airplane-modal-close-btn">
                  <Item elevation={0}>
                  <Tooltip title= {EnglishLanguage ? "This will close this window and populate these fields in the form. You will then need to click 'Save/Advance' before these inputs are saved to the occurrence report"
                      : "Esto cerrar\xE1 esta ventana y completar\xE1 estos campos en el formulario. Luego deber\xE1 hacer clic en 'Guardar/Avanzar' antes de que estas entradas se guarden en el Informe de Ocurrencia" }>
                      <Button variant="contained" className="step-btn" onClick={handleClose}>
                        {EnglishLanguage ? ("Close and import to form") : ("Cerrar e importar al formulario")}</Button> 
                      </Tooltip>
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Pilot Name
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoPilotName />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Pilot Contact Info
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoPilotContactInfo />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Pilot License #
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <AircraftTwoPilotLicense />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
