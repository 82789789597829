import * as React from "react";
import { useCentroStore } from "../../store";
import { useUser, useUpdateUserPreferences, useAllFacilities } from "../../store/queries/userQueries";

//import Radio from '@mui/material/Radio';
//import RadioGroup from '@mui/material/RadioGroup';
import { Select, MenuItem, FormControl, FormControlLabel } from '@mui/material';

export default function DefaultFacilitySelect() {
    const userId = useCentroStore((state) => state.userId);
    const userPreferences = useCentroStore((state) => state.userPreferences);
    const setUserPreferencesDefaultFacilityId = useCentroStore((state) => state.setUserPreferencesDefaultFacilityId);
    //const { data: facilitiesData } = useAllFacilities();
    const { userData, useUpdateUserPreferences } = useUser(userId);
    const userFacilities = userData?.data?.data.facilities; // Retrieve the user's list of facilities from Facility Users table

    const handleChangeDefaultFacility = (event) => {
        setUserPreferencesDefaultFacilityId(event.target.value);
    };

    return (
        <FormControl style={{ width: "20%" }} margin="normal">
            <Select variant="filled"  defaultValue={userPreferences.defaultFacilityId || ""} onChange={(event) => handleChangeDefaultFacility(event)} >
                    <MenuItem disabled value=""><em>Select Default Facility</em></MenuItem>
                    {/*facilitiesData?.map((fac) => (<MenuItem key={fac.facilityId} value={fac.facilityId}>{fac.name}</MenuItem>))*/}
                    {userFacilities?.map((fac) => (<MenuItem key={fac.facilityId} value={fac.facilityId}>{fac.name}</MenuItem>))}
                </Select>
        </FormControl>
    );
}
