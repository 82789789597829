import create from "zustand";
import { persist } from "zustand/middleware";
//import TrainingInProgressDropdown from "../components/stepper/step-3-additional-information/general-information/TrainingInProgressDropdown";


const asyncIncidentReportSlice = (set, get) => ({
  isFromHome: true,
  setFromHome: (isFromHome) => set({ isFromHome }),
    incidentreports: [],
    setIncidentReports: (incidentreports) => set({ incidentreports }),
  loadingIncidentReports: true,
  setLoadingIncidentReports: (loadingIncidentReports) =>
    set({ loadingIncidentReports }),
  currentincidenttype: "Incident Type",
    setcurrentincidenttype: (currentincidenttype) => set({ currentincidenttype }),
  latfrommap: 0,
  setlatfrommap: (latfrommap) => set({ latfrommap }),
  lngfrommap: 0,
  setlngfrommap: (lngfrommap) => set({ lngfrommap }),

  //Boolean for Form Validation
  issignificantevent1Touched: false,
  setsignificantevent1Touched: (issignificantevent1Touched) => set({ issignificantevent1Touched }),


  //String placeholders for yes/no options
  isLawContacted: "",
  setIsLawContacted: (isLawContacted) => set({ isLawContacted }),
  isBrasherWarning: "",
  setIsBrasherWarning: (isBrasherWarning) => set({ isBrasherWarning }),
  isOccurrenceSurface: "",
  setIsOccurrenceSurface: (isOccurrenceSurface) => set({ isOccurrenceSurface }),
  isRunway: "",
  setIsRunway: (isRunway) => set({ isRunway }),
    isControlType: "",
    setIsControlType: (isControlType) => set({isControlType}),

  isDraft: true,
  setIsDraft: (isDraft) => set({ isDraft }),
  isReadyForSubmit: true,
  setIsReadyForSubmit: (isReadyForSubmit) => set({ isReadyForSubmit }),
  doneIconsRendered: 0,
  setdoneIconsRendered: (doneIconsRendered) => set((state) => ({ setdoneIconsRendered: state.doneIconsRendered + 1 })),

  currentincidentreport: {
    irId: 0,
    reportNumber: "",
    reportTypeId: 0,
    incidentCategoryId: 0,
    subcategoryId: null,
    incidentDatetime: "MM/DD/YYYY",
    reportingFacilityId: 0,
    pilotViolation: 0,
    significantEvent: 0,
    trainingInProgress: 0,
    emergencyEquipmentDeployed: 0,
    evacuationRequired: 0,
    equipmentOutageContribute: "",
    airspace: "",
    acasAlert: 0,
    militaryDeviation: false,
    violationType: "",
    controlType: "",
    brasherWarning: false,
    issuingFacility: null,
    issuingPosition: null,
    aircraftCount: 0,
    flightCategory: "",
    flightSubcategory: "",
    headingRoute: "",
    latitude: 0,
    longitude: 0,
    ac1VisibilityRestrictions: "",
    ac2VisibilityRestrictions: "",
    pilotBirdWarning: false,
    flightPhase: "",
    birdSize: "",
    birdsSightedCount: 0,
    birdStruckCount: 0,
    wildlifeTypeId: null,
    lightConditions: "",
    skyConditions: "",
    precipitation: "",
    aircraftDamage: "",
    aircraftDamagePart: "",
    engineDamageInfo: "",
    effectOnFlight: "",
    aircraftInjuriesReported: 0,
    crewInjuredCount: 0,
    passangersInjuredCount: 0,
    vehicleInjuriesReported: 0,
    pedestrianInjuriesReported: 0,
    injuryDesc: "",
    medicalHelpOnboard: 0,
    lawEnforcementContacted: false,
    lawEnforcementName: "",
    lawEnforcementContactInfo: "",
    locationDistance: 0,
    locationRadial: 0,
    locationFacility: null,
    locationFacilityDropdown: "",
    navaid: "",
    navaidTextbox: "",
    terrainAlertActivated: 0,
    closestHorizontalDistance: 0,
    closestVerticalDistance: 0,
    metar: "",
    weatherConditions: "",
    clouds: "N/A",
    distanceVerticalClouds: 0,
    cloudsAboveBelow: "",
    cloudConditions: "",
    sun: "",
    flightVisibility: "",
    incidentSummary: "",
    vehicleId: "",
    vehicleDriverName: "",
    vehicleDriverContactInfo: "",
    vehicleDriverCompany: "",
    vehiclePassengerNames: "",
    vehicleType: "",
    vehicleAtcCommunicating: 0,
    vehicleAtcPosition: "",
    vehicleOtherAtcPosition: "",
    pedestrianName: "",
    pedestrianContactInfo: "",
    pedestrianType: "",
    pedestrianOtherType: "",
    pedestrianCompany: "",
    attachmentPath: "",
    facilityFindings: "",
    facilityManagementEscalate: false,
    correctiveActionPlan: "",
    facilityManagementFindings: "",
    parentFacilityEscalate: false,
    parentFacilityFindings: "",
    organizationEscalate: false,
    organizationFindings: "",
    reportedBy: "",
    reportDatetime: "",
    reporterName: "",
    reporterAddress: "",
    reporterPhoneNumber: "",
    reportingAcCallsign: "",
    reportingAcType: "",
    reportingAcDepartureAerodome: "",
    reportingAcFirstLandingAerodrome: "",
    reportingAcDestinationAerodrome: "",
    submitterUserId: 0,
    submitterSignature: "",
    submissionDatetime: null,
    reportReceiverName: "",
    reportReceivedMethod: "",
    otherReceiptMethod: "",
    atsActionDetails: "",
    vehicleInjuryCount: 0,
    vehicleMedicalHelp: "",
    vehicleInjuryDesc: "",
    pedestrianInjuryCount: 0,
    pedestrianMedicalHelp: "",
    pedestrianInjuryDesc: "",
    runway: "",
    occuranceSurface: "",
    otherOccuranceSurface: "",
    runwaySurfaceDegradation: 0,
    rcrScoreDetails: "",
    aircraftsList: [],
    technologyCategory: "",
    locationCns: "",
    operationalFunction: "",
    weatherFactor: 0,
    weatherDescription: "",
    techopsNotified: 0,
    noteIncident: "",
    outageReason: "",
    maintenanceAction: "",
    outageDurationStart: null,
    outageDurationEnd: null,
    interruptCondition: "",
  },
  setcurrentincidentreport: (currentincidentreport) =>
    set({ currentincidentreport }),
  currentCategory: {},
  setCurrentCategory: (currentCategory) => set({ currentCategory }),
  currentSubCategory: {},
  setaircraftThree: (aircraftThree) => set({ aircraftThree }),
  aircraftThree: {},
  setCurrentSubcategory: (currentSubCategory) => set({ currentSubCategory }),
  currentPosition: {},
  setCurrentPosition: (currentPosition) => set({ currentPosition }),
  currentReportingAcType: {},
  setCurrentReportingAcType: (currentReportingAcType) =>
    set({ currentReportingAcType }),

  // IR History 
  irHistory: {
      lastModified: null,
      irId: 0,
      userId: 0,
      userName: "",
      irHistoryType: 0
  },

  // IR Attachment
  irAttachment: {
      attachmentName: "",
      //attachment: [],
      attachmentFile: null,
      irId: 0,
      tab: 0
  },

  //General info setters
  setIrId: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, irId: e },
    })),
  setreportnumber: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reportNumber: e,
      },
    })),
  setsubmitteruserid: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        submitterUserId: e,
      },
    })),
  setreporttypeid: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reportTypeId: e,
      },
    })),
  setincidentcategoryid: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        incidentCategoryId: e,
      },
    })),
  setsubcategoryid: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        subcategoryId: e,
      },
    })),
  setreportingfacilityid: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reportingFacilityId: e,
      },
    })),
  setincidentdatetime: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        incidentDatetime: e,
      },
    })),
  setreportdatetime: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reportDatetime: e,
      },
    })),
  setreportedby: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, reportedBy: e },
    })),
  setreportername: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reporterName: e,
      },
    })),
  setreporteraddress: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reporterAddress: e,
      },
    })),
  setreporterphonenumber: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reporterPhoneNumber: e,
      },
    })),
  setincidentsummary: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        incidentSummary: e,
      },
    })),
  setreportingAcCallsign: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reportingAcCallsign: e,
      },
    })),
  //Leave actype as string for now, but will need to adjust it on database to be tied to aircraft type table
  setreportingAcType: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reportingAcType: e,
      },
    })),
  setreportingAcDepartureFac: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reportingAcDepartureAerodome: e,
      },
    })),
  setreportingAcFirstLandingFac: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reportingAcFirstLandingAerodrome: e,
      },
    })),
  setreportingAcDestinationFac: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        reportingAcDestinationAerodrome: e,
      },
    })),

  //Additional Info setters
  setsignificantEvent: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        significantEvent: e,
      },
    })),
  setemergencyEquipmentDeployed: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        emergencyEquipmentDeployed: e,
      },
    })),
  setevacuationrequired: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        evacuationRequired: e,
      },
    })),
    setequipmentoutage: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                equipmentOutageContribute: e,
            },
        })),
  settraininginprogress: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        trainingInProgress: e,
      },
    })),
  setairspace: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, airspace: e },
    })),
  setlawenforcementcontacted: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        lawEnforcementContacted: e,
      },
    })),
  setlawenforcementname: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        lawEnforcementName: e,
      },
    })),
  setlawenforcementcontactinfo: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        lawEnforcementContactInfo: e,
      },
    })),
  setinjuryreported: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        aircraftInjuriesReported: e,
      },
    })),
    setcrewinjured: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                crewInjuredCount: e,
            },
        })),
    setpassangersinjured: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                passangersInjuredCount: e,
            },
        })),
  setvehicleinjuriesreported: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        vehicleInjuriesReported: e,
      },
    })),
    setvehicleinjurycount: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleInjuryCount: e,
            },
        })),
    setvehiclemedicalhelp: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleMedicalHelp: e,
            },
        })),
    setvehicleinjurydesc: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleInjuryDesc: e,
            },
        })),
  setpedestrianinjuriesreported: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        pedestrianInjuriesReported: e,
      },
    })),
    setpedestrianinjurycount: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                pedestrianInjuryCount: e,
            },
        })),
    setpedestrianmedicalhelp: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                pedestrianMedicalHelp: e,
            },
        })),
    setpedestrianinjurydesc: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                pedestrianInjuryDesc: e,
            },
        })),
  setinjurydescription: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, injuryDesc: e },
    })),
  setmedicalhelp: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        medicalHelpOnboard: e,
      },
    })),
  setcontroltype: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        controltype: e,
      },
    })),
  setviolationtype: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        violationType: e,
      },
    })),
  setpilotviolation: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        pilotViolation: e,
      },
    })),
  setacasalert: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, acasAlert: e },
    })),
  setmilitarydeviation: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        militaryDeviation: e,
      },
    })),
  setbrasherwarning: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        brasherWarning: e,
      },
    })),
  setissuingfacility: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        issuingFacility: e,
      },
    })),
  setissuingPosition: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        issuingPosition: e,
      },
    })),

  //Weather Info
  setweatherconditions: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        weatherConditions: e,
      },
    })),
  setmetar: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, metar: e },
    })),
  setaconevisibility: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        ac1VisibilityRestrictions: e,
      },
    })),
  setactwovisibility: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        ac2VisibilityRestrictions: e,
      },
    })),
  setclouds: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, clouds: e },
    })),
  setverticaldistancefromcloud: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        distanceVerticalClouds: e,
      },
    })),
  setcloudsabovebelow: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        cloudsAboveBelow: e,
      },
    })),
  setcloudconditions: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        cloudConditions: e,
      },
    })),
  setsun: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, sun: e },
    })),
  currentAircraftOneWeatherVisibility: [],
  addAircraftOneWeather: (currentAircraftOneWeatherVisibility) =>
    set({ currentAircraftOneWeatherVisibility }),
  removeAircraftOneWeather: (currentAircraftOneWeatherVisibility) =>
    set({ currentAircraftOneWeatherVisibility }),
  currentAircraftTwoWeatherVisibility: [],
  addAircraftTwoWeather: (currentAircraftTwoWeatherVisibility) =>
    set({ currentAircraftTwoWeatherVisibility }),
  removeAircraftTwoWeather: (currentAircraftTwoWeatherVisibility) =>
    set({ currentAircraftTwoWeatherVisibility }),

  //Aircraft info
  setnumberofaircraft: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        aircraftCount: e,
      },
    })),
  setflightcategory: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        flightCategory: e,
      },
    })),
  setflightsubcategory: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        flightSubcategory: e,
      },
    })),
  //Vehicle info
    setvehiclecompany: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleDriverCompany: e,
            },
        })),
    setvehiclecontact: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleDriverContactInfo: e,
            },
        })),
    setvehicledrivername: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleDriverName: e,
            },
        })),
    setvehicletype: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleType: e,
            },
        })),
    setvehicleatccommunicating: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleAtcCommunicating: e,
            },
        })),
    setvehicleatcposition: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleAtcPosition: e,
            },
        })),
    setvehicleotheratcposition: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleOtherAtcPosition: e,
            },
        })),
    setvehicleid: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                vehicleId: e,
            },
        })),
    //Pedestrian Info
    setpedestrianname: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                pedestrianName: e,
            },
        })),
    setpedestriantype: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                pedestrianType: e,
            },
        })),
    setpedestrianothertype: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                pedestrianOtherType: e,
            },
        })),
    setpedestriancompany: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                pedestrianCompany: e,
            },
        })),
    setpedestriancontactinfo: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                pedestrianContactInfo: e,
            },
        })),
  //location-conflict Info
  setheading: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        headingRoute: e,
      },
    })),
  setlatitude: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, latitude: e },
    })),
  setlongitude: (e) =>
    set((state) => ({
      currentincidentreport: { ...state.currentincidentreport, longitude: e },
    })),
  setclosesthorizontaldistance: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        closestHorizontalDistance: e,
      },
    })),
    
  setclosestverticaldistance: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        closestVerticalDistance: e,
      },
    })),
  setterrainalertwarning: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        terrainAlertActivated: e,
      },
    })),
    setrunway: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                runway: e,
            },
        })),
    setoccurrenceSurface: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                occuranceSurface: e,
            },
        })),
    setotherOccurrenceSurface: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                otherOccuranceSurface: e,
            },
        })),
    setrunwaySurfaceDegradation: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                runwaySurfaceDegradation: e,
            },
        })),
    setrcrScoreDetails: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                rcrScoreDetails: e,
            },
        })),
    setaircraftsList: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                aircraftsList: e,
            },
        })),
    settechnologyCategory: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                technologyCategory: e,
            },
        })),
    setlocationCns: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                locationCns: e,
            },
        })),
    setoperationalFunction: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                operationalFunction: e,
            },
        })),
    setweatherFactor: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                weatherFactor: e,
            },
        })),
    setweatherDescription: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                weatherDescription: e,
            },
        })),
    settechopsNotified: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                techopsNotified: e,
            },
        })),
    setnoteIncident: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                noteIncident: e,
            },
        })),
    setoutageReason: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                outageReason: e,
            },
        })),
    setmaintenanceAction: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                maintenanceAction: e,
            },
        })),
    setoutageDurationStart: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                outageDurationStart: e,
            },
        })),
    setoutageDurationEnd: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                outageDurationEnd: e,
            },
        })),
    setinterruptCondition: (e) =>
        set((state) => ({
            currentincidentreport: {
                ...state.currentincidentreport,
                interruptCondition: e,
            },
        })),

  //Findings Info
  setfacilityfindings: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        facilityFindings: e,
      },
    })),
  setparentfacilityfindings: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        parentFacilityFindings: e,
      },
    })),
  setorganizationfindings: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        organizationFindings: e,
      },
    })),
  setescalatetofaciiltymanager: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        facilityManagementEscalate: e,
      },
    })),
  setescalatetoorganization: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        organizationEscalate: e,
      },
    })),
  setescalateparentfacility: (e) =>
    set((state) => ({
      currentincidentreport: {
        ...state.currentincidentreport,
        parentFacilityEscalate: e,
      },
    })),
});

const asyncAircraftSlice = (set, get) => ({
  aircrafts: [],
  aircraftOne: {
    irAircraftId: 1,
    irId: 0,
    operator: 0,
    callsign: "",
    type: "",
    typeUnknown: "",
    typeUnknownDesc: "",
    beaconCode: null,
    flightRule: "",
    facility: null,
    position: null,
    altitude: 0,
    aglMsl: "",
    speed: 0,
    flightStage: "",
    bankAngle: "",
    bankDirection: "",
    aircraftLighting: "",
    trafficAvoidance: "",
    trafficAvoidanceDesc: "",
    trafficInformation: "",
    trafficInformationDesc: "",
    acasUsed: "",
    acasType: "",
    identificationMethod: "",
    otherAircraftSighted: "",
    avoidingAction: "",
    flightPlan: "",
    pilotName: "",
    pilotContactInfo: "",
    license: "",
    displayOrder: 1,
    equipmentTypeId: null,
    },

  aircraftTwo: {
    irAircraftId: 2,
    irId: 0,
    operator: 0,
    callsign: "",
    type: "",
    typeUnknown: "",
    typeUnknownDesc: "",
    beaconCode: null,
    flightRule: "",
    facility: null,
    position: null,
    altitude: 0,
    aglMsl: "",
    speed: 0,
    flightStage: "",
    bankAngle: "",
    bankDirection: "",
    aircraftLighting: "",
    trafficAvoidance: "",
    trafficAvoidanceDesc: "",
    trafficInformation: "",
    trafficInformationDesc: "",
    acasUsed: "",
    acasType: "",
    identificationMethod: "",
    otherAircraftSighted: "",
    avoidingAction: "",
    flightPlan: "",
    pilotName: "",
    pilotContactInfo: "",
    license: "",
    displayOrder: 2,
    equipmentTypeId: null,
  },
  aircraftThree: {
    irId: 0,
    operator: 0,
    callsign: "",
    type: "",
    typeUnknown: "",
    typeUnknownDesc: "",
    beaconCode: null,
    flightRule: "",
    facility: null,
    position: null,
    altitude: 0,
    aglMsl: "",
    speed: 0,
    flightStage: "",
    bankAngle: "",
    bankDirection: "",
    aircraftLighting: "",
    trafficAvoidance: "",
    trafficAvoidanceDesc: "",
    trafficInformation: "",
    trafficInformationDesc: "",
    acasUsed: "",
    acasType: "",
    identificationMethod: "",
    otherAircraftSighted: "",
    avoidingAction: "",
    flightPlan: "",
    pilotName: "",
    pilotContactInfo: "",
    license: "",
    displayOrder: 3,
    equipmentTypeId: null,
  },
  //Aircraft Three Info
  setCurrentAircraftThree: (aircraftThree) => set({ aircraftThree }),
  setAircraftThreeIrId: (e) =>
    set((state) => ({ aircraftThree: { ...state.aircraftThree, irId: e } })),
  setAircraftThreeCallsign: (e) =>
    set((state) => ({ aircraftThree: { ...state.aircraftThree, callsign: e } })),

  //Aircraft One Info
  setCurrentAircraftOne: (aircraftOne) => set({ aircraftOne }),
  setAircraftOneIrAircraftId: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, irAircraftId: e } })),
  setAircraftOneIrId: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, irId: e } })),
  setAircraftOneOperator: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, operator: e } })),
  setAircraftOneCallsign: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, callsign: e } })),
  setAircraftOneType: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, type: e } })),
  setAircraftOneTypeUnknown: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, typeUnknown: e } })),
  setAircraftOneTypeUnknownDesc: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, typeUnknownDesc: e },
    })),
  setAircraftOneBeaconCode: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, beaconCode: e } })),
  setAircraftOneFlightRule: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, flightRule: e } })),
  setAircraftOneFacility: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, facility: e } })),
  setAircraftOnePosition: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, position: e } })),
  setAircraftOneAltitude: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, altitude: e } })),
  setAircraftOneAglMsl: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, aglMsl: e } })),
  setAircraftOneSpeed: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, speed: e } })),
  setAircraftOneFlightStage: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, flightStage: e } })),
  setAircraftOneBankAngle: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, bankAngle: e } })),
  setAircraftOneBankDirection: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, bankDirection: e },
    })),
  setAircraftOneAircraftLighting: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, aircraftLighting: e },
    })),
  setAircraftOneTrafficAvoidance: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, trafficAvoidance: e },
    })),
  setAircraftOneTrafficAvoidanceDesc: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, trafficAvoidanceDesc: e },
    })),
  setAircraftOneTrafficInformation: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, trafficInformation: e },
    })),
  setAircraftOneTrafficInformationDesc: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, trafficInformationDesc: e },
    })),
  setAircraftOneAcasUsed: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, acasUsed: e } })),
  setAircraftOneAcasType: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, acasType: e } })),
  setAircraftOneIdentificationMethod: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, identificationMethod: e },
    })),
  setAircraftOneOtherAircraftSighted: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, otherAircraftSighted: e },
    })),
  setAircraftOneAvoidingAction: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, avoidingAction: e },
    })),
  setAircraftOneFlightPlan: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, flightPlan: e } })),
  setAircraftOnePilotName: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, pilotName: e } })),
  setAircraftOnePilotPhoneNumber: (e) =>
    set((state) => ({
      aircraftOne: { ...state.aircraftOne, pilotContactInfo: e },
    })),
  setAircraftOneLicesnse: (e) =>
    set((state) => ({ aircraftOne: { ...state.aircraftOne, license: e } })),
  setAircraftOneEquipmentTypeId: (e) =>
      set((state) => ({ aircraftOne: {...state.aircraftOne, equipmentTypeId: e}})),

  //Aircraft One setters/Getters for Yes/No, and Ids
  aircraftOneAvoidActionTaken: "",
  setAircraftOneAvoidActionTaken: (aircraftOneAvoidActionTaken) =>
    set({ aircraftOneAvoidActionTaken }),
  aircraftOneFac: {},
  setAircraftOneFac: (aircraftOneFac) => set({ aircraftOneFac }),
  aircraftOnePos: {},
  setAircraftOnePos: (aircraftOnePos) => set({ aircraftOnePos }),
  aircraftOneSuffix: "",
  setAircraftOneSuffix: (aircraftOneSuffix) => set({ aircraftOneSuffix }),

  currentAircraftOneType: {},
  setCurrentAircraftOneType: (currentAircraftOneType) =>
    set({ currentAircraftOneType }),
  currentAircraftOneOperator: {},
  setCurrentAircraftOneOperator: (currentAircraftOneOperator) =>
    set({ currentAircraftOneOperator }),
  currentAircraftOneUnknowns: [],
  addAircraftOneUnknowns: (currentAircraftOneUnknowns) =>
    set({ currentAircraftOneUnknowns }),
  removeAircraftOneUnknowns: (currentAircraftOneUnknowns) =>
    set({ currentAircraftOneUnknowns }),
  currentAircraftOneLights: [],
  addAircraftOneLights: (currentAircraftOneLights) =>
    set({ currentAircraftOneLights }),
  removeAircraftOneLights: (currentAircraftOneLights) =>
    set({ currentAircraftOneLights }),

  //Aircraft Two Info
  setCurrentAircraftTwo: (aircraftTwo) => set({ aircraftTwo }),
  setAircraftTwoIrAircraftId: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, irAircraftId: e } })),
  setAircraftTwoIrId: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, irId: e } })),
  setAircraftTwoOperator: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, operator: e } })),
  setAircraftTwoCallsign: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, callsign: e } })),
  setAircraftTwoType: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, type: e } })),
  setAircraftTwoTypeUnknown: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, typeUnknown: e } })),
  setAircraftTwoTypeUnknownDesc: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, typeUnknownDesc: e },
    })),
  setAircraftTwoBeaconCode: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, beaconCode: e } })),
  setAircraftTwoFlightRule: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, flightRule: e } })),
  setAircraftTwoFacility: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, facility: e } })),
  setAircraftTwoPosition: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, position: e } })),
  setAircraftTwoAltitude: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, altitude: e } })),
  setAircraftTwoAglMsl: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, aglMsl: e } })),
  setAircraftTwoSpeed: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, speed: e } })),
  setAircraftTwoFlightStage: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, flightStage: e } })),
  setAircraftTwoBankAngle: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, bankAngle: e } })),
  setAircraftTwoBankDirection: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, bankDirection: e },
    })),
  setAircraftTwoAircraftLighting: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, aircraftLighting: e },
    })),
  setAircraftTwoTrafficAvoidance: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, trafficAvoidance: e },
    })),
  setAircraftTwoTrafficAvoidanceDesc: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, trafficAvoidanceDesc: e },
    })),
  setAircraftTwoTrafficInformation: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, trafficInformation: e },
    })),
  setAircraftTwoTrafficInformationDesc: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, trafficInformationDesc: e },
    })),
  setAircraftTwoAcasUsed: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, acasUsed: e } })),
    setAircraftTwoAcasType: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, acasType: e } })),
  setAircraftTwoIdentificationMethod: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, identificationMethod: e },
    })),
  setAircraftTwoOtherAircraftSighted: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, otherAircraftSighted: e },
    })),
  setAircraftTwoAvoidingAction: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, avoidingAction: e },
    })),
  setAircraftTwoFlightPlan: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, flightPlan: e } })),
  setAircraftTwoPilotName: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, pilotName: e } })),
  setAircraftTwoPilotPhoneNumber: (e) =>
    set((state) => ({
      aircraftTwo: { ...state.aircraftTwo, pilotContactInfo: e },
    })),
  setAircraftTwoLicesnse: (e) =>
    set((state) => ({ aircraftTwo: { ...state.aircraftTwo, license: e } })),
    setAircraftTwoEquipmentTypeId: (e) =>
        set((state) => ({ aircraftTwo: { ...state.aircraftTwo, equipmentTypeId: e } })),

  //Aircraft Two setters/Getters for Yes/No, and Ids
  aircraftTwoAvoidActionTaken: "",
  setAircraftTwoAvoidActionTaken: (aircraftTwoAvoidActionTaken) =>
    set({ aircraftTwoAvoidActionTaken }),
  aircraftTwoFac: {},
  setAircraftTwoFac: (aircraftTwoFac) => set({ aircraftTwoFac }),
  aircraftTwoSuffix: "",
  setAircraftTwoSuffix: (aircraftTwoSuffix) => set({ aircraftTwoSuffix }),
  aircraftTwoPos: {},
  setAircraftTwoPos: (aircraftTwoPos) => set({ aircraftTwoPos }),
  currentAircraftTwoType: {},
    setCurrentAircraftTwoType: (currentAircraftTwoType) => set({ currentAircraftTwoType }),
  currentAircraftTwoOperator: {},
  setCurrentAircraftTwoOperator: (currentAircraftTwoOperator) =>
    set({ currentAircraftTwoOperator }),
  currentAircraftTwoUnknowns: [],
  addAircraftTwoUnknowns: (currentAircraftTwoUnknowns) =>
    set({ currentAircraftTwoUnknowns }),
  removeAircraftTwoUnknowns: (currentAircraftTwoUnknowns) =>
    set({ currentAircraftTwoUnknowns }),
  currentAircraftTwoLights: [],
  addAircraftTwoLights: (currentAircraftTwoLights) =>
    set({ currentAircraftTwoLights }),
  removeAircraftTwoLights: (currentAircraftTwoLights) =>
    set({ currentAircraftTwoLights }),

   //Aircrafts [] array
  setaircrafts: (aircrafts) => set({ aircrafts }),
  addAircrafts: (e) => (state) => ({ aircrafts: [...state.aircrafts, e] }),
  removeAircrafts: (e) => (state) => ({
    aircrafts: { ...state.aircrafts.filter((aircraft) => aircraft.id !== e) },
  }),
  removeAllAircrats: (aircrafts) => set({ aircrafts }),
});

const asyncFacilitySlice = (set, get) => ({
  currentFacility: {
    facilityId: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    typeId: "",
    activeStatus: "",
    parentId: "",
  },
    setCurrentFacility: (currentFacility) => set({ currentFacility }),
    setCurrentFacilityId: (e) =>
        set((state) => ({ currentFacility: { ...state.currentFacility, facilityId: e }, })),
  // For adding/editing facility in Admin Page
  newFacility: {
    facilityId: 0,
    name: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    phone: "",
    latitude: "",
    longitude: "",
    typeId: 0,
    activeStatus: true,
    parentId: null,
    facilityPositionModels: [],
  },
  newFacilityPosition: {
    id: 0,
    posName: "",
    frequency: 0,
    facilityId: 0,
  },
    setNewFacilityId: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, facilityId: e }, })),
    setNewFacilityName: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, name: e }, })),
    setNewFacilityAddress1: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, address1: e }, })),
    setNewFacilityAddress2: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, address2: e }, })),
    setNewFacilityCity: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, city: e }, })),
    setNewFacilityCountry: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, country: e }, })),
    setNewFacilityState: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, state: e }, })),
    setNewFacilityZipcode: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, zipcode: e }, })),
    setNewFacilityLatitude: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, latitude: e }, })),
    setNewFacilityLongitude: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, longitude: e }, })),
    setNewFacilityTypeId: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, typeId: e }, })),
    setNewFacilityActiveStatus: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, activeStatus: e }, })),
    setNewFacilityParentId: (e) =>
        set((state) => ({ newFacility: { ...state.newFacility, parentId: e }, })),
  
  facilityList: [],
    facilityPosList: [],
    setFacilityList: (facilityList) => set({ facilityList }),
    setFacilityPosList: (facilityPosList) => set({ facilityPosList }),

  // For admin adding new Facility Type
    /*
  newFacilityType: {
    typeId: 0,
    typeName: ""
  },
  setNewFacilityTypeId: (e) =>
      set((state) => ({ newFacilityType: { ...state.newFacilityType, typeId: e }, })),
  setNewFacilityTypeName: (e) =>
      set((state) => ({ newFacilityType: { ...state.newFacilityType, typeName: e }, })),*/
});

const asyncToDoSlice = (set, get) => ({
  // Placeholder stuff
  todolist: [
    {
      id: "INC-BWI-0005",
      text: "Process Occurrence Report ",
      date: "10/15/2022",
      label: "Draft",
    },
    {
      id: "+ Safety Assessment",
      text: "Process ",
      report: "INC-BWI-0014",
      reporter: "John Doe",
      date: "10/14/2022",
      label: "Draft",
    },
    {
      id: "INC-BWI-0013",
      text: "Process Occurrence Report.",
      date: "10/14/2022",
      label: "Draft",
    },
    {
      id: "INC-BWI-0016",
      text: "Finish Draft Occurrence Report ",
      date: "10/13/2022",
      label: "Draft",
    },
    {
      id: "INC-BWI-0009",
      text: "Process Occurrence Report.",
      date: "10/14/2022",
      label: "Draft",
    },
    {
      id: "INC-BWI-0004",
      text: "Process Occurrence Report.",
      date: "10/14/2022",
      label: "Draft",
    },
    {
      id: "INC-BWI-0011",
      text: "Finish Draft Occurrence Report ",
      date: "10/13/2022",
      label: "Draft",
    },
    {
      id: "INC-BWI-0012",
      text: "Process Occurrence Report.",
      date: "10/12/2022",
      label: "Draft",
    },
    {
      id: "INC-BWI-0015",
      text: "Process Occurrence Report.",
      date: "10/11/2022",
      label: "Draft",
    },
  ],
});
const asyncLocationSlice = (set, get) => ({
    currentMapFacility: {
        facilityId: "",
        name: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        typeId: "",
        activeStatus: "",
        parentId: "",
    },
    mapFilters: {
        dateFilter: "",
    },
    setDateFilter: (e) =>
        set((state) => ({
            mapFilters: { ...state.mapFilters, dateFilter: e },
        })),
    setCurrentMapFacility: (currentMapFacility) => set({ currentMapFacility }),
    incidentreportsLocations: [
    { id: 'INC-BWI-0001', maptype: 'CNS', type: 'CNS', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Draft", lat: 39.180591, text: "asfsdfsdfsdfsd", lng: -76.677893 },
    { id: 'INC-BWI-0002',maptype: 'CNS',  type: 'CNS', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Draft", lat: 39.176033, text: "asfsdfsdfsdfsd", lng: -76.673384 },
    { id: 'INC-BWI-0003', maptype: 'Airborne', type: 'Airborne', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Draft", lat: 39.176232, text: "asfsdfsdfsdfsd", lng: -76.684721 },
    { id: 'INC-BWI-0004', maptype: 'CNS', type: 'Airborne', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Draft", lat: 39.173937, text: "asfsdfsdfsdfsd", lng: -76.676688 },
    { id: 'INC-BWI-0005', maptype: 'CNS', type: 'CNS', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Draft", lat: 39.173205, text: "asfsdfsdfsdfsd", lng: -76.663676 },
    { id: 'INC-BWI-0006', maptype: 'CNS', type: 'Airborne', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Draft", lat: 39.169845, text: "asfsdfsdfsdfsd", lng: -76.667154 },
    { id: 'INC-BWI-0007', maptype: 'CNS',  type: 'Airborne', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Draft", lat: 39.176632, text: "asfsdfsdfsdfsd", lng: -76.653670 },
    { id: 'INC-BWI-0008', maptype: 'Surface',  type: 'CNS', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Draft", lat: 39.179160, text: "asfsdfsdfsdfsd", lng: -76.673182 },
    { id: 'INC-BWI-0009', maptype: 'CNS', type: 'Airborne', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Draft", lat: 39.179193, text: "asfsdfsdfsdfsd", lng: -76.659516 },
    { id: 'INC-BWI-0010', maptype: 'CNS', type: 'Airborne', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Submitted", lat: 39.167615, text: "asfsdfsdfsdfsd", lng: -76.663338 },
    { id: 'INC-BWI-0011', maptype: 'CNS', type: 'CNS', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Submitted", lat: 39.166351, text: "asfsdfsdfsdfsd", lng: -76.661964 },
    { id: 'INC-BWI-0012', maptype: 'CNS', type: 'Airborne', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Submitted", lat: 39.174103, text: "asfsdfsdfsdfsd", lng: -76.679141 },
    { id: 'INC-BWI-0013', maptype: 'CNS', type: 'Airborne', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Submitted", lat: 39.173937, text: "asfsdfsdfsdfsd", lng: -76.676822 },
    { id: 'INC-BWI-0014', maptype: 'CNS', type: 'CNS', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Submitted", lat: 39.173870, text: "asfsdfsdfsdfsd", lng: -76.674417 },
    { id: 'INC-BWI-0015', maptype: 'CNS', type: 'Airborne', category:'Occurrence Report', reporter: 'John Doe', date: "10/15/2022", label: "Submitted", lat: 39.174602, text: "asfsdfsdfsdfsd", lng: -76.689362 },
  ],
});
const asyncUserSlice = (set, get) => ({
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true' ? true : false,
  setAuth: (isAuthenticated) => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
    set({ isAuthenticated });
  },
  //userloggedin: "No",
  //setuserloggedin: (userloggedin) => set({ userloggedin }),
  userId: 0,
  setUserId: (userId) => set({userId}),
  userFacilityId: 0,
  setUserFacilityId: (userFacilityId) => set({ userFacilityId }),
  reports: true,
  setreports: (reports) => set({ reports }),
  maps: true,
  setmaps: (maps) => set({ maps }),
  training: true,
  settraining: (training) => set({ training }),
  analytics: true,
  setanalytics: (analytics) => set({ analytics }),
  tsap: false,
  settsap: (tsap) => set({ tsap }),
  theme: "light-theme",
  setTheme: (theme) => set({ theme }),
  // spanish: true,
  // setSpanishLanguage: (SpanishLanguage) => set({ SpanishLanguage }),
  activeRole: "",
  setActiveRole: (role) => set({ activeRole: role }),
  // USER
  activeUser: {
      userId: 0,
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      roleId: null,
      //role: null,
      activeStatus: true,
      clientId: null,
      resetRequired: true,
      preferredLanguage: "",
      facilityId: null,
      userPreference: null,
      roles: [],
      facilities: [],
  },
  setActiveUser: (activeUser) => set({ activeUser }),
  // for adding new users in Admin pages
  newUser: {
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      roleId: null,
      //role: null,
      activeStatus: true,
      clientId: null,
      resetRequired: true,
      preferredLanguage: "English",
      facilityId: null,
      roles: [],
      facilities: [],
  },
  setNewUser: (newUser) => set({ newUser }),
  // USER PASSWORD RESET/UPDATE
  userProfilePassword: "",
  setUserProfilePassword: (password) => set({ userProfilePassword: password }),
  userProfilePasswordConfirmation: "",
  setUserProfilePasswordConfirmation: (passwordConfirm) => set({ userProfilePasswordConfirmation: passwordConfirm }),
  userPasswordChange: {
      userId: 0,
      password: "",
  },
  // USER PREFERENCES/SETTINGS
  EnglishLanguage: true,
  setEnglishLanguage: (EnglishLanguage) => set({ EnglishLanguage }),
  WeatherTempMetric: true,
  setWeatherTempMetric: (WeatherTempMetric) => set({ WeatherTempMetric }),
  WeatherWindSpeedMetric: true,
  setWeatherWindSpeedMetric: (WeatherWindSpeedMetric) => set({ WeatherWindSpeedMetric }),
  userPreferences: {
      userId: 0,
      preferredLanguage: "",
      defaultFacilityId: null,
      displayReportsFirst: false,
      displayAnalyticsFirst: false,
      weatherTempMetric: false,
      windSpeedMetric: false
  },
  setCurrentUserPreferences: (userPreferences) =>
    set({ userPreferences }),
  setUserPreferencesUserId: (e) =>
    set((state) => ({userPreferences: { ...state.userPreferences, userId: e },})),
  setUserPreferencesPreferredLanguage: (e) =>
    set((state) => ({userPreferences: { ...state.userPreferences, preferredLanguage: e },})),
  setUserPreferencesDefaultFacilityId: (e) =>
    set((state) => ({userPreferences: { ...state.userPreferences, defaultFacilityId: e },})),
  setUserPreferencesDisplayReportsFirst: (e) =>
    set((state) => ({userPreferences: { ...state.userPreferences, displayReportsFirst: e },})),
  setUserPreferencesDisplayAnalyticsFirst: (e) =>
    set((state) => ({userPreferences: { ...state.userPreferences, displayAnalyticsFirst: e },})),
  setUserPreferencesWeatherTempMetric: (e) =>
    set((state) => ({userPreferences: { ...state.userPreferences, weatherTempMetric: e },})),
  setUserPreferencesWindSpeedMetric: (e) =>
    set((state) => ({userPreferences: { ...state.userPreferences, windSpeedMetric: e },})),
  // ROLES/PERMISSIONS
  userRole: {
      roleName: "",
      facilityTypeId: 0,
      reportsTo: null,
      isAdmin: false,
      permissions: []
  },
  setUserRoleName: (e) =>
    set((state) => ({userRole: { ...state.userRole, roleName: e },})),
  setUserRoleFacilityTypeId: (e) =>
    set((state) => ({userRole: { ...state.facilityTypeId, facilityTypeId: e },})),
  setUserRoleReportsTo: (e) =>
    set((state) => ({userRole: { ...state.reportsTo, reportsTo: e },})),
  userPermissions: {
      roleId: 0,
      fullAccess: 0,
      createFac: 0,
      readFac: 0,
      updateFac: 0,
      deleteFac: 0,
      createUser: 0,
      readUser: 0,
      updateUser: 0,
      deleteUser: 0,
      createRole: 0,
      readRole: 0,
      updateRole: 0,
      deleteRole: 0,
      createReport: 1,
      readReport: 0,
      updateReport: 0,
      deleteReport: 0,
      approveReport: 0,
      escalateReport: 0,
      approveEscalatedReport: 0,
      displayAnalytics: 0,
  },
  setUserPermissionsRoleId: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, roleId: e },})),
  setUserPermissionsFullAccess: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, fullAccess: e },})),
  setUserPermissionsCreateFac: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, createFac: e },})),
  setUserPermissionsReadFac: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, readFac: e },})),
  setUserPermissionsUpdateFac: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, updateFac: e },})),
  setUserPermissionsDeleteFac: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, deleteFac: e },})),
  setUserPermissionsCreateUser: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, createUser: e },})),
  setUserPermissionsReadUser: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, readUser: e },})),
  setUserPermissionsUpdateUser: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, updateUser: e },})),
  setUserPermissionsDeleteUser: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, deleteUser: e },})),
  setUserPermissionsCreateRole: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, createRole: e },})),
  setUserPermissionsReadRole: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, readRole: e },})),
  setUserPermissionsUpdateRole: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, updateRole: e },})),
  setUserPermissionsDeleteRole: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, deleteRole: e },})),
  setUserPermissionsCreateReport: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, createReport: e },})),
  setUserPermissionsReadReport: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, readReport: e },})),
  setUserPermissionsUpdateReport: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, updateReport: e },})),
  setUserPermissionsDeleteReport: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, deleteReport: e },})),
  setUserPermissionsApproveReport: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, approveReport: e },})),
  setUserPermissionsEscalateReport: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, escalateReport: e },})),
  setUserPermissionsApproveEscalatedReport: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, approveEscalatedReport: e },})),
  setUserPermissionsDisplayAnalytics: (e) =>
    set((state) => ({userPermissions: { ...state.userPermissions, displayAnalytics: e },})),
});

    const useStore = (set, get) => ({
    ...asyncUserSlice(set, get),
    ...asyncIncidentReportSlice(set, get),
    ...asyncAircraftSlice(set, get),
    ...asyncFacilitySlice(set, get),
    ...asyncToDoSlice(set, get),
    ...asyncLocationSlice(set, get)

});

export const useCentroStore = create(
  persist(useStore, { name: "centroStore" })
);
export const useReferenceGuideStore = create((set) => ({
  isModalOpen: false,
  openModal: (initialTopic) => set({ isModalOpen: true, initialTopic }),
  closeModal: () => set({ isModalOpen: false }),
  initialTopic: null,
}));