import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';
import { useCategories } from '../../../../store/queries/incidentReportQueries';


export default function IncidentSubCategoryAMANDropdown() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setSubcategoryId = useCentroStore((state) => state.setsubcategoryid);
    const currentCategory = useCentroStore((state) => state.currentCategory);
    const setCurrentSubcategory = useCentroStore((state) => state.setCurrentSubcategory);
    const currentSubcategory = useCentroStore((state) => state.currentSubCategory);
    //const { categories } = useCategories();

    const handleChange = (event) => {
        
        var subcatagory = currentCategory.subcategoryModels.find(s => s.irSubcategoryCode === event.target.value);
        setCurrentSubcategory(subcatagory);
        setSubcategoryId(subcatagory.irSubcategoryId);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentSubcategory.irSubcategoryCode === undefined ? '' : currentSubcategory.irSubcategoryCode}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected === undefined) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
            {EnglishLanguage ? 
            currentCategory.subcategoryModels.filter(s => s.parentCategoryId === currentCategory.irCategoryId).map(({irSubcategoryId, irSubcategoryCode }) => (
            <MenuItem
              key={irSubcategoryId}
              value={irSubcategoryCode}
            >
              {irSubcategoryCode}
            </MenuItem>
            ))
            :
            currentCategory.subcategoryModels.filter(s => s.parentCategoryId === currentCategory.irCategoryId).map(({irSubcategoryId, irSubcategoryCodeEs }) => (
            <MenuItem
              key={irSubcategoryId}
              value={irSubcategoryCodeEs}
            >
              {irSubcategoryCodeEs}
            </MenuItem>
            ))
            }
        </Select>
      </FormControl>

    </Box>
  );
}

