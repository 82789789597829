// import * as React from 'react';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import SignIn from './SignIn';
// import CentroLogo from "../../assets/images/centro-logo_whitegold.png"


// export default function LoginForm() {
//   return (
//     <Card className="sign-in-wrap" sx={{ maxHeight: '570px', borderRadius: '0', position:"absolute", top: "calc(50vh - 257px)", left: "calc(50vw - 195.5px)", border: "1px solid #ffffff3d"}}>


//       <CardContent sx={{ background:"#FFF!important", paddingTop: "2rem"}}>
//         <Typography variant='h1' sx={{fontWeight:"500"}} textTransform="uppercase" >
//         <img src={CentroLogo} className="logo"/>
//         </Typography>
//         <SignIn />
//       </CardContent>

//     </Card>
//   );
// }
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import SignIn from "./SignIn";
import CentroLogo from "../../assets/images/centro-logo_whitegold-login.png";
import PoweredBy from "../PoweredBy";
import { Grid } from "@mui/material";

export default function LoginForm() {
  return (
    <span className="animation-helper">
      
        <div className="login-page-wrap " > 
<Grid container xs={12} className="login-title-text">
  <Grid item xs={12}>
  <img src={CentroLogo} className="logo animate-ping animate-pulse smokeEffect" />
  </Grid>
  <Grid item xs={12} mr={10}>
    <Grid container>
      <Grid item xs={8}>
        
      </Grid>
      <Grid item xs={4}>
      <PoweredBy />
      </Grid>
    </Grid>
  </Grid>
</Grid>
       
        
        <Card
          className="sign-in-wrap"
          sx={{
            borderRadius: "0",
            border: "1px solid #ffffff3d",
            background: "transparent!important",
            backdropFilter: "none!important",
            border: "none",
          }}
        >
          <CardContent
            sx={{ background: "#FFF!important", padding: "0rem" }}
            id="login-hover-wrap"
          >
            <Typography
              variant="h1"
              sx={{ fontWeight: "500" }}
              textTransform="uppercase"
            ></Typography>
            <SignIn />
          </CardContent>
        </Card>
        </div>

    </span>
  );
}