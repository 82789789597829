// import * as React from 'react';
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import { useCentroStore } from '../../../../store';
// import { debounce } from 'lodash';
// import { useDebouncedAircraftTypeSearch } from '../../../../store/queries/aircraftQueries';


// export default function AircraftTwoTypeDynamicDropdown() {

//     const [model, setModel] = React.useState("");
//     const setAircraftTwotype = useCentroStore((state) => state.setAircraftTwoType);
//     const aircraftTwoType = useCentroStore((state) => state.currentAircraftTwoType);
//     const setCurrentAircraftTwoType = useCentroStore((state) => state.setCurrentAircraftTwoType);
//     const { types } = useDebouncedAircraftTypeSearch(model);

//     const handleChange = (event) => {
//         if (event.target.value !== null || event.target.value !== '') {
//             var aircraftType = types?.data?.data.find(t => t.model === event.target.value);
//             if (aircraftType !== undefined) {
//                 setCurrentAircraftTwoType(aircraftType);
//                 setAircraftTwotype(aircraftType.id);
//             }
//         }
//     };

//     async function searchTypes(search) {
//         if (search !== "") {
//             setModel(search);
//         }
//     }

//     const debouncedSearch = React.useRef(
//         debounce(async (search) => {
//             searchTypes(search);
//         }, 1000)).current;

//     React.useEffect(() => {
//         return () => {
//             debouncedSearch.cancel();
//         };
//     }, [debouncedSearch]);

//     function handleSetSearch(e) {
//         if ((e !== null && e.type !== 'blur' && e.type !== 'click')) {
//             debouncedSearch(e.target.value);
//         }
//     }

//     return (
//         <Autocomplete
//             id="size-small-standard"
//             size="small"
//             options={types?.data?.data === undefined || types.isError && types.failureCount === 3 ? [] : types?.data?.data}
//             onInputChange={handleSetSearch}
//             onBlur={handleChange}
//             filterOptions={(x) => x}
//             limitTags={10}
//             loading={types.isLoading}
//             value={!aircraftTwoType ? {} : aircraftTwoType}
//             renderOption={(props, option) => {
//                 return (
//                     <li {...props} key={option.id}>
//                         {option.model}
//                     </li>
//                 );
//             }}
//             getOptionLabel={(option) => option.model || ""}
            
//             renderInput={(params) => (
//                 <TextField
//                     {...params}
//                     variant="standard"
//                     className="dynamic-dropdown-input-text"
//                     label="Type/Select"
//                     sx={{ opacity: '1', color: "#ffffff", fontStyle: "italic", textTransform: "capitalize" }}

//                 />
//             )}
//         />
//     );
// }



import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
import AircraftTwo from './AircraftTwo';

function AircraftTwoTypeDynamicDropdown({ unknown }) {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const setAircraftTwotype = useCentroStore((state) => state.setAircraftTwoType);
    const currentAircraftTwotype = useCentroStore((state) => state.currentAircraftTwoType);
    const aircraft = useCentroStore((state) => state.aircraftTwo);
    const setCurrentAircraftTwotype = useCentroStore((state) => state.setCurrentAircraftTwoType);

    const handleChange = (event) => {
        const value = event.target.value;
        if (value !== null || value !== '') {
            setCurrentAircraftTwotype({ model: value });
            setAircraftTwotype(value);
        }
    };

    function handleSetSearch(e) {
        if (e !== null && e.type !== 'blur' && e.type !== 'click') {
            handleChange(e);
        }
    }
    React.useEffect(() => {
        if (unknown) {
          setCurrentAircraftTwotype({ model: 'Unknown' });
          setAircraftTwotype('Unknown');
          var tempAircraftList = [];
          tempAircraftList[1] = aircraft;
          setaircraftsList(tempAircraftList);
        }
      }, [unknown, setCurrentAircraftTwotype, setAircraftTwotype]);
    return (
        <TextField
        id="standard-multiline-number"
        variant="standard"
        // className="dynamic-dropdown-input-text"
        fullWidth
            rows={1}
        label={EnglishLanguage ? "Type/Select" : "Tipo/Seleccione"}
        sx={{ opacity: '1', color: '#ffffff', fontStyle: 'italic', textTransform: 'capitalize' }}
        value={unknown ? 'Unknown' : aircraft.type}
        defaultValue={aircraft.type}
        onChange={handleSetSearch}
        onBlur={handleChange}
        disabled={unknown}
        />
    );
}
export default AircraftTwoTypeDynamicDropdown;