import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
// import { useCentroStore } from '../../../../store';

export default function VehicleDriverName() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setvehicledrivername = useCentroStore((state) => state.setvehicledrivername);
 const handleChange = (event) => {

     setvehicledrivername(event.target.value);
 };


  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? "Text" : "Texto"}
          sx={{color:"#848484", fontFamily:"'Montserrat', Ariel!important"}}
          fullWidth
          rows={1}
          onBlur={handleChange}
          defaultValue={currentIncidentReport.vehicleDriverName}
          variant="standard"
        />
  );
};