import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
'Automation',
'Communication',
'Information Services',
'Navigation',
'Surveillance',
'Unknown',
];

const optionsSpanish = [
'Automatizaci\xF3n',
'Comunicaci\xF3n',
'Servicios de informaci\xF3n',
'Navegaci\xF3n',
'Vigilancia',
'Desconocido',
];

export default function TechnologyCategory() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const settechnologyCategory = useCentroStore((state) => state.settechnologyCategory);
    const setoperationalFunction = useCentroStore((state) => state.setoperationalFunction);

    const handleChange = (event) => {
        setoperationalFunction(""); // Reset Operational Function dropdown if changing Technology Category
        settechnologyCategory(event.target.value);
    };

  return (
    <Box sx={{ minWidth: 120 }}>
    <FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          //defaultValue=""
          value={currentIncidentReport.technologyCategory}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }
            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccione")}</em>
          </MenuItem>
          {EnglishLanguage ? 
           options.map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              {category}
            </MenuItem>
           ))
           :
           optionsSpanish.map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              {category}
            </MenuItem>
           ))}
        </Select>
    </FormControl>

    </Box>
  );
}