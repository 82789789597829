import * as React from 'react';
//import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
//import { debounce } from 'lodash';
//import { useDebouncedAircraftTypeSearch } from '../../../../store/queries/aircraftQueries';

export default function ReportingAcType() {

    //const [model, setModel] = React.useState("");
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setreportingAcType = useCentroStore((state) => state.setreportingAcType);
    //const setCurrentReportingAcType = useCentroStore((state) => state.setCurrentReportingAcType);
    //const currentReportingAcType = useCentroStore((state) => state.currentReportingAcType);
    //const { types } = useDebouncedAircraftTypeSearch(model);

    const blurHandler = (event) => {
        //currentIncidentReport.reporterName = event.target.value;
        setreportingAcType(event.target.value);
    }

    return (
        <TextField
            id="standard-multiline-static"
            label={EnglishLanguage ? "Name" : "Nombre"}
            sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
            fullWidth
            rows={1}
            defaultValue={currentIncidentReport.reportingAcType}
            variant="standard"
            onBlur={blurHandler}
        />
    );
};