import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { useCentroStore } from "../../../../store";
import DoneIcon from '@mui/icons-material/Done';

import VehicleID from "./VehicleID";
import VehicleDriverName from "./PedestrianName";
import VehicleDriverContactInformation from "./PedestrianContactInformation";
import VehicleDriverType from "./PedestrianType";
import VehicleDriverCompany from "./PedestrianCompany";
import PedestrianName from "./PedestrianName";
import PedestrianType from "./PedestrianType";
import PedestrianCompany from "./PedestrianCompany";
import PedestrianContactInformation from "./PedestrianContactInformation";
import OtherPedestrianType from "./OtherPedestrianType";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

export default function AirCraftPed() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const aircrafts = useCentroStore((state) => state.aircrafts);
    const addToAircrafts = useCentroStore((state) => state.addAircrafts);
    const aircraft = useCentroStore((state) => state.aircraftOne);
    const setAircraftOneIrId = useCentroStore((state) => state.setAircraftOneIrId);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    // const aircraftOneFac = useCentroStore((state) => state.aircraftOneFac);
    // const aircraftOnePos = useCentroStore((state) => state.aircraftOnePos);

    // const currentAircraftOneOperator = useCentroStore((state) => state.currentAircraftOneOperator);
    // const currentAircraftOneType = useCentroStore((state) => state.currentAircraftOneType);
  // const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => {

  //       setOpen(true);
  //       setAircraftOneIrId(currentIncidentReport.irId);
  //   }
  //   const handleClose = () => {
  //       setOpen(false);
  //       //Add currentaircraft to aircrafts list
  //       aircrafts.push(aircraft);
  //       addToAircrafts(aircrafts);
  //   }

  return (
    <>
      <Grid container py={0} spacing={0} mt={0} className="ir-form-wrapper">
        <Grid xs={12}>
          <Item elevation={0} sx={{ padding: "0" }}>
            <Grid xs={12}>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ background: "#00000033", borderRadius: ".5rem", padding: "10px 10px 15px" }}
                className="ir-form-wrapper"
                xs={12}
              >
                <Grid xs={12} mt={0} pb={0} className="table-header-wrap">
                  <Item elevation={0}>
                    <DirectionsWalkIcon />
                  </Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        paddingBottom: ".25rem",
                        color: "#f7b01a!important",
                        cursor: "pointer",
                      }}
                      variant="h6"
                    >
                      {EnglishLanguage ? ("Pedestrian 1 (edit)") : ("Peat\xF3n 1 (editar)")}
                    </Typography>
                  </Item>
                </Grid>
                <Grid
            container
            justifyContent="space-between"
            spacing={0}
            sx={{ marginTop: "1rem" }}
            className="ir-form-wrapper"
          >

            {/* General Information Header */}

            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Pedestrian Name") : ("Nombre del peat\xF3n")}
                      {currentIncidentReport.pedestrianName !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                     <PedestrianName />
                  </Item>
                </Grid>
              </Item>
            </Grid>


            <Grid container className="table-header-wrap" xl={12}>
            <Grid>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Pedestrian Type") : ("Tipo de peat\xF3n")}
                      {currentIncidentReport.pedestrianType !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <PedestrianType />
                  </Item>
                </Grid>
              </Item>
            </Grid>


            {(currentIncidentReport.pedestrianType === "Other" ||
              currentIncidentReport.pedestrianType === "Otro") && (
            <Grid>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Other Pedestrian Type") : ("Otra tipo de peat\xF3n")}
                      {currentIncidentReport.pedestrianOtherType !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <OtherPedestrianType />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            )}
            </Grid>
            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Pedestrian Company
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <PedestrianCompany />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}

            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Pedestrian Contact Information
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <PedestrianContactInformation />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
          </Grid>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
