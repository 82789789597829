import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
export default function ReportingAcDestination() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setreportingAcDestinationFac = useCentroStore((state) => state.setreportingAcDestinationFac);

    const blurHandler = (event) => {
        //currentIncidentReport.reporterName = event.target.value;
        setreportingAcDestinationFac(event.target.value);
    }
    return (
        <TextField
            id="standard-multiline-static"
            label={EnglishLanguage ? "Name" : "Nombre"}
            sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
            fullWidth
            rows={1}
            defaultValue={currentIncidentReport.reportingAcDestinationAerodrome}
            variant="standard"
            onBlur={blurHandler}
        />
    );
};