import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from "../../store";
import { useFacilities } from '../../store/queries/facilityQueries';
import { useUser } from '../../store/queries/userQueries';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from "react-query";

export default function FacilityDropdown() {

    const currentFacility = useCentroStore((state) => state.currentFacility);
    const setCurrentFacility = useCentroStore((state) => state.setCurrentFacility);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const userFacilityId = useCentroStore((state) => state.userFacilityId);
    const setCurrentMapFacility = useCentroStore((state) => state.setCurrentMapFacility);
    const { facilities, activeFacilities } = useFacilities();
    const userId = useCentroStore((state) => state.userId);
    const { userData } = useUser(userId);
    //const userDataPermissions = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0];
    const userFacilities = userData?.data?.data.facilities; // Retrieve the user's list of facilities from Facility Users table
    const location = useLocation(); // What URL the user is at, helps determine if a refetch of facUsers query is needed
    const queryClient = useQueryClient();

    //if (currentFacility.facilityId === "" && activeFacilities.isSuccess) {
    if (currentFacility.facilityId === "" && userData.isSuccess) {
        //console.log(currentFacility);
        // var fac = activeFacilities?.data?.data.find(facility => facility.facilityId === userFacilityId);
        var fac = userFacilities.find(facility => facility.facilityId === userFacilityId);
        if (fac !== undefined) {
            setCurrentFacility(fac);
            setCurrentMapFacility(fac);
        }
    }

    // Check for if selected fac is not in assigned userFacilities list, auto assign to the user's default fac
    if (userFacilities.find(facility => facility.facilityId === currentFacility.facilityId) === undefined) {
        var fac = userFacilities.find(facility => facility.facilityId === parseInt(userData?.data?.data.userPreference.defaultFacilityId));
        if (fac !== undefined) {
            setCurrentFacility(fac);
            setCurrentMapFacility(fac);
        }
    }
    
    const handleChange = (event) => {
        if (event.target.value !== '') {
            //var fac = activeFacilities?.data?.data.find(facility => facility.facilityId === event.target.value);
            var fac = userFacilities.find(facility => facility.facilityId === event.target.value);
            setCurrentFacility(fac);
            setCurrentMapFacility(fac);
            // Check if the user is on the Manage Users page, refetch the FacUsers query
            if (location.pathname === "/user-list") {
                queryClient.refetchQueries({ queryKey: ["facUsers", fac.facilityId], type: 'active' }); // Refetch fac user data
            }
        }
    };

    return (
    <Box  className="facility-text-wrap">
            <FormControl variant="standard">

                    <Select
                        className='select-wrap'
                        displayEmpty
                    value={currentFacility.facilityId}
                    
                    onChange={handleChange }
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ position: "relative", left: "10px", marginTop: ".25rem"}}
                >
                    <MenuItem disabled value=''>
                    {EnglishLanguage ? ("Select") : ("Seleccione")} 
                    </MenuItem>
                    {/* This displays all the facilities in the database, rather than the facilities that the user is assigned to
                     * activeFacilities?.data?.data.map(({ facilityId, name }) =>
                        <MenuItem
                            key={facilityId}
                            value={facilityId}>
                            {name}
                        </MenuItem>

                    )*/}
                    {userFacilities.map(({ facilityId, name }) =>
                        <MenuItem
                            key={facilityId}
                            value={facilityId}>
                            {name}
                        </MenuItem>

                    )}
                    </Select>
      </FormControl>
        </Box>
    );
}

