import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
    'Pilot',
    'Controller Providing Services',
    'Controller In Charge',
    'External Facility Referral',
    'Hotline',
    'Internal Facility Review',
    'FLM',
    'Other'
];

const optionsSpanish = [
    'Piloto',
    'Controlador que Presta Servicios',
    'Controlador a cargo',
    'Referencia de Instalaciones Externas',
    'L\xEDnea Directa',
    'Revisi\xF3n Interna de Instalaciones',
    'FLM',
    'Otro'
];

export default function IncidentCategoryDropdown() {

    //const [age, setAge] = React.useState('');
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setReportedBy = useCentroStore((state) => state.setreportedby);

    const handleChange = (event) => {
        //currentIncidentReport.reportedBy = event.target.value;
        setReportedBy(event.target.value);
    //setAge(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentIncidentReport.reportedBy}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }
            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
           options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))
          :
          optionsSpanish.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

