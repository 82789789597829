import { useCentroStore } from "../../store";

export function SetupCurrentIncidentReport(report, aircraftsFromDb, categories, facilities) {
    var incidentReport = {
        currentAircraftOne: {},
        aircraftOneType: {},
        aircraftOneFac: {},
        aircraftOnePos: {},
        aircraftOneUnknowns: [],
        aircraftOneLights: [],

        currentAircraftTwo: {},
        aircraftTwoType: {},
        aircraftTwoFac: {},
        aircraftTwoPos: {},
        aircraftTwoUnknowns: [],
        aircraftTwoLights: [],

        currentAircraftThree: {},

        currentCategory: {},
        currentSubcategory: {},
        currentIncidentType: "",
        isControlType: '',
        currentPosition: {},
        aircraftOneWeather: [],
        aircraftTwoWeather: [],
    };

    const initialCurrentAircraft = {
        irId: 0,
        irAircraftId: 1,
        callsign: "",
        type: "",
        typeUnknown: "",
        typeUnknownDesc: "",
        beaconCode: 0,
        flightRule: "",
        facility: null,
        position: null,
        altitude: 0,
        aglMsl: "",
        speed: 0,
        flightStage: "",
        bankAngle: "",
        bankDirection: "",
        aircraftLighting: "",
        trafficAvoidance: "",
        trafficAvoidanceDesc: "",
        trafficInformation: "",
        trafficInformationDesc: "",
        acasUsed: "",
        acasType: "",
        flightPlan: "",
        pilotName: "",
        pilotContactInfo: "",
        license: "",
        equipmentTypeId: null,
    };
    const initialCurrentAircraft2 = {
        irId: 0,
        irAircraftId: 2,
        callsign: "",
        type: "",
        typeUnknown: "",
        typeUnknownDesc: "",
        beaconCode: 0,
        flightRule: "",
        facility: null,
        position: null,
        altitude: 0,
        aglMsl: "",
        speed: 0,
        flightStage: "",
        bankAngle: "",
        bankDirection: "",
        aircraftLighting: "",
        trafficAvoidance: "",
        trafficAvoidanceDesc: "",
        trafficInformation: "",
        trafficInformationDesc: "",
        acasUsed: "",
        acasType: "",
        flightPlan: "",
        pilotName: "",
        pilotContactInfo: "",
        license: "",
        equipmentTypeId: null,
    };
        try {
            var orderedAircraft = aircraftsFromDb.sort((a, b) => a.displayOrder - b.displayOrder);
            
            if (orderedAircraft.length !== 0) {
                // Aircraft One Setup
                incidentReport.currentAircraftOne = orderedAircraft[0]?.aircraft;
                //console.log("currentAircraftOne");
                //console.log(incidentReport.currentAircraftOne);
                incidentReport.aircraftOneType = orderedAircraft[0]?.aircraftType;
                incidentReport.aircraftOneFac = orderedAircraft[0]?.facModel;

                // Null check for unfilled field aircraftOnePos
                if (incidentReport.currentAircraftOne !== undefined) {
                    if (incidentReport.currentAircraftOne.position === null || orderedAircraft[0]?.facModel === undefined) {
                        incidentReport.aircraftOnePos = null;
                    }
                    else {
                        incidentReport.aircraftOnePos = orderedAircraft[0]?.facModel.facilityPositionModels.find(p => p.id === incidentReport.currentAircraftOne.position);
                    }
                }
                else {
                    incidentReport.aircraftOnePos = null;
                }

                var acOneUnknowns = orderedAircraft[0]?.aircraft?.typeUnknown?.split(".");
                if (acOneUnknowns !== undefined) {
                    var unknownObjs1 = [];
                    acOneUnknowns.forEach((item) => {
                        var unknown = { title: item };
                        unknownObjs1.push(unknown);
                    });
                    incidentReport.aircraftOneUnknowns = unknownObjs1;
                }
                var acOneLighting = orderedAircraft[0]?.aircraft?.aircraftLighting?.split(".");
                if (acOneLighting !== undefined) {
                    var lightingObjs1 = [];
                    acOneLighting.forEach((item) => {
                        var light = { title: item };
                        lightingObjs1.push(light);
                    });
                    incidentReport.aircraftOneLights = lightingObjs1;
                }

                // Aircraft Two Check For Undefined 2nd Aircraft
                if (orderedAircraft.length < 2) {
                    incidentReport.currentAircraftTwo = initialCurrentAircraft2;
                }
                else {
                    // Aircraft Two Setup
                    incidentReport.currentAircraftTwo = orderedAircraft[1]?.aircraft;
                    //console.log("currentAircraftTwo");
                    //console.log(incidentReport.currentAircraftTwo);
                    incidentReport.aircraftTwoType = orderedAircraft[1]?.aircraftType;
                    incidentReport.aircraftTwoFac = orderedAircraft[1]?.facModel;

                    // Null check for unfilled field aircraftTwoPos
                    if (incidentReport.currentAircraftTwo !== undefined) {
                        if (incidentReport.currentAircraftTwo.position === null || orderedAircraft[1]?.facModel === undefined) {
                            incidentReport.aircraftTwoPos = null;
                        }
                        else {
                            incidentReport.aircraftTwoPos = orderedAircraft[1]?.facModel.facilityPositionModels.find(p => p.id === incidentReport.currentAircraftTwo.position);
                        }
                    }
                    else {
                        incidentReport.aircraftTwoPos = null;
                    }

                    var acTwoUnknowns = orderedAircraft[1]?.aircraft.typeUnknown?.split(".");
                    if (acTwoUnknowns !== undefined) {
                        var unknownObjs2 = [];
                        acTwoUnknowns.forEach((item) => {
                            var unknown = { title: item };
                            unknownObjs2.push(unknown);
                        });
                        incidentReport.aircraftTwoUnknowns = unknownObjs2;
                    }
                    var acTwoLighting = orderedAircraft[1]?.aircraft?.aircraftLighting?.split(".");
                    if (acTwoLighting !== undefined) {
                        var lightingObjs2 = [];
                        acTwoLighting.forEach((item) => {
                            var light = { title: item };
                            lightingObjs2.push(light);
                        });
                        incidentReport.aircraftTwoLights = lightingObjs2;
                    }
                }
            }
            else {
                incidentReport.currentAircraftOne = initialCurrentAircraft;
                incidentReport.currentAircraftTwo = initialCurrentAircraft2;
            }
            //set incident type
            if (report.reportTypeId === 1) {
                incidentReport.currentIncidentType = "airborne";
            }
            else if (report.reportTypeId === 2) {
                incidentReport.currentIncidentType = "cns";
            }
            else {
                incidentReport.currentIncidentType = "surface";
            }
            incidentReport.isControlType = report.controlType;


            var currentCategory = categories.find(c => c.irCategoryId === report.incidentCategoryId);
            if (currentCategory !== undefined) {
                incidentReport.currentCategory = currentCategory;
            }
            else {
                incidentReport.currentCategory = {};
            }
            var currentSubCategory = currentCategory.subcategoryModels.find(s => s.irSubcategoryId === report.subcategoryId);
            if (currentSubCategory !== undefined) {
                incidentReport.currentSubcategory = currentSubCategory;
            }
            else {
                incidentReport.currentSubcategory = {};
            }
            var currentPosition = facilities.forEach(f=> {
                return f.facilityPositionModels.find(p => p.id === report.issuingPosition);
            });//positions.find(p => p.id === report.issuingPosition);
            
            if (currentPosition !== undefined) {
                incidentReport.currentPosition = currentPosition;
            }
            else {
                incidentReport.currentPosition = {};
            }

            if (report.ac1VisibilityRestrictions !== undefined && report.ac1VisibilityRestrictions.length !== 0) {
                var acOneRestrictions = report.ac1VisibilityRestrictions.split('.');
                if (acOneRestrictions.length > 0) {
                    var restrictions1 = [];
                    acOneRestrictions.forEach((item) => {
                        if (item.length !== 0) {
                            var restriction = { title: item };
                            restrictions1.push(restriction);
                        }
                    });
                    incidentReport.aircraftOneWeather = restrictions1;
                }
            }
            else {
                incidentReport.aircraftOneWeather = [];
            }
            if (report.ac2VisibilityRestrictions !== undefined && report.ac2VisibilityRestrictions.length !== 0) {
                var acTwoRestrictions = report.ac2VisibilityRestrictions.split('.');
                if (acTwoRestrictions.length > 0) {
                    var restrictions2 = [];
                    acTwoRestrictions.forEach((item) => {
                        if (item.length !== 0) {
                            var restriction = { title: item };
                            restrictions2.push(restriction);
                        }
                    });
                    incidentReport.aircraftTwoWeather = restrictions2;
                }
            }
            else {
                incidentReport.aircraftTwoWeather = [];
            }
            
        } catch (error) {
            console.log(error);
    }
    return incidentReport;
}

export function useClearState() {
    //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
    const setCurrentIncidentType = useCentroStore((state) => state.setcurrentincidenttype);
    const setCurrentIncidentReport = useCentroStore((state) => state.setcurrentincidentreport);
    const setCurrentReportingAcType = useCentroStore((state) => state.setCurrentReportingAcType);
    const aircrafts = useCentroStore((state) => state.aircrafts);
    //const removeAllDeviations = useCentroStore((state) => state.removeAllDeviations);
    const setCurrentCategory = useCentroStore((state) => state.setCurrentCategory);
    const setCurrentSubcategory = useCentroStore((state) => state.setCurrentSubcategory);
    const setCurrentPosition = useCentroStore((state) => state.setCurrentPosition);
    const setIsLawContacted = useCentroStore((state) => state.setIsLawContacted);
    const setIsBrasherWarning = useCentroStore((state) => state.setIsBrasherWarning);
    const setIsControlType = useCentroStore((state) => state.setIsControlType);

    const setnumberofaircraft = useCentroStore((state) => state.setnumberofaircraft);
    //aircraft clearing
    const removeAllAircrats = useCentroStore((state) => state.removeAllAircrats);
    //Aircraft 1
    const removeAircraftOneWeather = useCentroStore((state) => state.removeAircraftOneWeather);
    const setCurrentAircraftOne = useCentroStore((state) => state.setCurrentAircraftOne);
    const setAircraftOneFac = useCentroStore((state) => state.setAircraftOneFac);
    const setAircraftOnePos = useCentroStore((state) => state.setAircraftOnePos);
    const setCurrentAircraftOneType = useCentroStore((state) => state.setCurrentAircraftOneType);
    const removeAircraftOneUnknowns = useCentroStore((state) => state.removeAircraftOneUnknowns);
    const removeAircraftOneLights = useCentroStore((state) => state.removeAircraftOneLights);
    //Aircraft 2
    const removeAircraftTwoWeather = useCentroStore((state) => state.removeAircraftTwoWeather);
    const setCurrentAircraftTwo = useCentroStore((state) => state.setCurrentAircraftTwo);
    const setAircraftTwoFac = useCentroStore((state) => state.setAircraftTwoFac);
    const setAircraftTwoPos = useCentroStore((state) => state.setAircraftTwoPos);
    const setCurrentAircraftTwoType = useCentroStore((state) => state.setCurrentAircraftTwoType);
    const removeAircraftTwoUnknowns = useCentroStore((state) => state.removeAircraftTwoUnknowns);
    const removeAircraftTwoLights = useCentroStore((state) => state.removeAircraftTwoLights);

    const initialCurrentIncidentReport = {
        irId: 0,
        reportNumber: "",
        reportTypeId: 0,
        incidentCategoryId: 0,
        subcategoryId: null,
        incidentDatetime: "",
        reportingFacilityId: 0,
        pilotViolation: 0,
        significantEvent: 0,
        trainingInProgress: 0,
        emergencyEquipmentDeployed: 0,
        evacuationRequired: 0,
        equipmentOutageContribute: "",
        airspace: "",
        acasAlert: 0,
        militaryDeviation: false,
        violationType: "",
        controlType: "",
        brasherWarning: false,
        issuingFacility: null,
        issuingPosition: null,
        aircraftCount: 0,
        flightCategory: "",
        flightSubcategory: "",
        headingRoute: "",
        latitude: 0,
        longitude: 0,
        ac1VisibilityRestrictions: "",
        ac2VisibilityRestrictions: "",
        pilotBirdWarning: false,
        flightPhase: "",
        birdSize: "",
        birdsSightedCount: 0,
        birdStruckCount: 0,
        wildlifeTypeId: null,
        lightConditions: "",
        skyConditions: "",
        precipitation: "",
        aircraftDamage: "",
        aircraftDamagePart: "",
        engineDamageInfo: "",
        effectOnFlight: "",
        aircraftInjuriesReported: 0,
        crewInjuredCount: 0,
        passangersInjuredCount: 0,
        vehicleInjuriesReported: 0,
        pedestrianInjuriesReported: 0,
        injuryDesc: "",
        medicalHelpOnboard: 0,
        lawEnforcementContacted: false,
        lawEnforcementName: "",
        lawEnforcementContactInfo: "",
        locationDistance: 0,
        locationRadial: 0,
        locationFacility: null,
        locationFacilityDropdown: "",
        navaid: "",
        navaidTextbox: "",
        terrainAlertActivated: 0,
        closestHorizontalDistance: 0,
        closestVerticalDistance: 0,
        metar: "",
        weatherConditions: "",
        clouds: "N/A",
        distanceVerticalClouds: 0,
        cloudsAboveBelow: "",
        cloudConditions: "",
        sun: "",
        flightVisibility: "",
        incidentSummary: "",
        vehicleId: "",
        vehicleDriverName: "",
        vehicleDriverContactInfo: "",
        vehicleDriverCompany: "",
        vehiclePassengerNames: "",
        vehicleType: "",
        vehicleAtcCommunicating: 0,
        vehicleAtcPosition: "",
        vehicleOtherAtcPosition: "",
        pedestrianName: "",
        pedestrianContactInfo: "",
        pedestrianType: "",
        pedestrianOtherType: "",
        pedestrianCompany: "",
        attachmentPath: "",
        facilityFindings: "",
        facilityManagementEscalate: false,
        correctiveActionPlan: "",
        facilityManagementFindings: "",
        parentFacilityEscalate: false,
        parentFacilityFindings: "",
        organizationEscalate: false,
        organizationFindings: "",
        reportedBy: "",
        reportDatetime: "",
        reporterName: "",
        reporterAddress: "",
        reporterPhoneNumber: "",
        reportingAcCallsign: "",
        reportingAcType: "",
        reportingAcDepartureAerodome: "",
        reportingAcFirstLandingAerodrome: "",
        reportingAcDestinationAerodrome: "",
        submitterUserId: 0,
        submitterSignature: "",
        submissionDatetime: null,
        reportReceiverName: "",
        reportReceivedMethod: "",
        otherReceiptMethod: "",
        atsActionDetails: "",
        vehicleInjuryCount: 0,
        vehicleMedicalHelp: "",
        vehicleInjuryDesc: "",
        pedestrianInjuryCount: 0,
        pedestrianMedicalHelp: "",
        pedestrianInjuryDesc: "",
        runway: "",
        occuranceSurface: "",
        otherOccuranceSurface: "",
        runwaySurfaceDegradation: 0,
        rcrScoreDetails: "",
        aircraftsList: [],
        technologyCategory: "",
        locationCns: "",
        operationalFunction: "",
        weatherFactor: 0,
        weatherDescription: "",
        techopsNotified: 0,
        noteIncident: "",
        outageReason: "",
        maintenanceAction: "",
        outageDurationStart: null,
        outageDurationEnd: null,
        interruptCondition: "",
    };
    const initialCurrentAircraft = {
        irId: 0,
        irAircraftId: 1,
        callsign: "",
        type: "",
        typeUnknown: "",
        typeUnknownDesc: "",
        beaconCode: 0,
        flightRule: "",
        facility: null,
        position: null,
        altitude: 0,
        aglMsl: "",
        speed: 0,
        flightStage: "",
        bankAngle: "",
        bankDirection: "",
        aircraftLighting: "",
        trafficAvoidance: "",
        trafficAvoidanceDesc: "",
        trafficInformation: "",
        trafficInformationDesc: "",
        acasUsed: "",
        acasType: "",
        flightPlan: "",
        pilotName: "",
        pilotContactInfo: "",
        license: "",
        equipmentTypeId: null,
    };

    const initialCurrentAircraft2 = {
        irId: 0,
        irAircraftId: 2,
        callsign: "",
        type: "",
        typeUnknown: "",
        typeUnknownDesc: "",
        beaconCode: 0,
        flightRule: "",
        facility: null,
        position: null,
        altitude: 0,
        aglMsl: "",
        speed: 0,
        flightStage: "",
        bankAngle: "",
        bankDirection: "",
        aircraftLighting: "",
        trafficAvoidance: "",
        trafficAvoidanceDesc: "",
        trafficInformation: "",
        trafficInformationDesc: "",
        acasUsed: "",
        acasType: "",
        flightPlan: "",
        pilotName: "",
        pilotContactInfo: "",
        license: "",
        equipmentTypeId: null,
    };

    function ClearAC2() {
        removeAircraftTwoWeather([]);
        setCurrentAircraftTwo(initialCurrentAircraft2);
        setAircraftTwoFac({});
        setAircraftTwoPos({});
        setCurrentAircraftTwoType({});
        removeAircraftTwoUnknowns([]);
        removeAircraftTwoLights([]);
        if (aircrafts.length >= 2) {
            aircrafts.pop();
        }
        //setnumberofaircraft(1);
    }


    function ClearReport() {
        //setuserloggedin("No");
        setCurrentIncidentType("");
        setCurrentIncidentReport(initialCurrentIncidentReport);
        setIsControlType("");
        setCurrentReportingAcType({});
        setCurrentCategory({});
        setCurrentSubcategory({});
        setCurrentPosition({});
        setIsLawContacted("");
        setIsBrasherWarning("");
        removeAllAircrats([]);
        removeAircraftOneWeather([]);
        removeAircraftTwoWeather([]);
        setCurrentAircraftOne(initialCurrentAircraft);
        setCurrentAircraftTwo(initialCurrentAircraft2);
        setAircraftOneFac({});
        setAircraftTwoFac({});
        setAircraftOnePos({});
        setCurrentAircraftOneType({});
        removeAircraftOneUnknowns([]);
        removeAircraftOneLights([]);
        setAircraftTwoPos({});
        setCurrentAircraftTwoType({});
        removeAircraftTwoUnknowns([]);
        removeAircraftTwoLights([]);
    }
    
    return {ClearReport, ClearAC2}
}