import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { useCentroStore } from "../../store";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function ControlledCheckbox() {
  const setreports = useCentroStore((state) => state.setreports);
  const setmaps = useCentroStore((state) => state.setmaps);
  const settraining = useCentroStore((state) => state.settraining);
  const setanalytics = useCentroStore((state) => state.setanalytics);
  const settsap = useCentroStore((state) => state.settsap);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  const [checkedReports, setCheckedReports] = React.useState(true);
  const [checkedMaps, setCheckedMaps] = React.useState(true);
  const [checkedTraining, setCheckedTraining] = React.useState(true);
  const [checkedAnalytics, setCheckedAnalytics] = React.useState(true);
  const [checkedTSAP, setCheckedTSAP] = React.useState(false);

  const handleChangeReports = (event) => {
    setCheckedReports(event.target.checked);
    setreports(event.target.checked);
  };
  const handleChangeMaps = (event) => {
    setCheckedMaps(event.target.checked);
    setmaps(event.target.checked);
  };
  const handleChangeTraining = (event) => {
    setCheckedTraining(event.target.checked);
    settraining(event.target.checked);
  };
  const handleChangeAnalytics = (event) => {
    setCheckedAnalytics(event.target.checked);
    setanalytics(event.target.checked);
  };
  const handleChangeTSAP = (event) => {
    setCheckedTSAP(event.target.checked);
    settsap(event.target.checked);
  };

  return (
    <FormGroup>
       {EnglishLanguage ? (
        <FormControlLabel
        control={
          <Checkbox
            
            checked={checkedReports}
            onChange={handleChangeReports}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Reports"
      />
       ) : (
        <FormControlLabel
        control={
          <Checkbox
            
            checked={checkedReports}
            onChange={handleChangeReports}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Informes"
      />
       )}
      
      {EnglishLanguage ? (
        <FormControlLabel
        control={
          <Checkbox
            
            checked={checkedMaps}
            onChange={handleChangeMaps}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Maps"
      />
      ) :
      (
<FormControlLabel
        control={
          <Checkbox
            
            checked={checkedMaps}
            onChange={handleChangeMaps}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Mapas"
      />
      
      )}
         {EnglishLanguage ? (
            <FormControlLabel
            control={
              <Checkbox
                
                checked={checkedTraining}
                onChange={handleChangeTraining}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Training"
          />
         ) : (
          <FormControlLabel
          control={
            <Checkbox
              
              checked={checkedTraining}
              onChange={handleChangeTraining}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Capacitación"
        />
         )}


      {EnglishLanguage ? (
        <FormControlLabel
        control={
          <Checkbox
            
            checked={checkedAnalytics}
            onChange={handleChangeAnalytics}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Analytics"
      />
      ) : (
        <FormControlLabel
        control={
          <Checkbox
            
            checked={checkedAnalytics}
            onChange={handleChangeAnalytics}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Analítica"
      />
      )}
      

      {EnglishLanguage ? (
         <FormControlLabel
         control={
           <Checkbox
             defaultChecked={false}
             checked={checkedTSAP}
             onChange={handleChangeTSAP}
             inputProps={{ "aria-label": "controlled" }}
           />
         }
         label="TSAP"
       />
      ) : (
        <FormControlLabel
        control={
          <Checkbox
            defaultChecked={false}
            checked={checkedTSAP}
            onChange={handleChangeTSAP}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="TSAP"
      />
      )}
      
    </FormGroup>
  );
}
