import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function NoteIncident() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setnoteIncident = useCentroStore((state) => state.setnoteIncident);

    const blurHandler = (event) => {
        setnoteIncident(event.target.value);
    }
    return (
        <TextField
            id="standard-multiline-static"
            label={EnglishLanguage ? "Text" : "Texto"}
            sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
            fullWidth
            rows={1}
            defaultValue={currentIncidentReport.noteIncident}
            variant="standard"
            onBlur={blurHandler}
        />
    );
};