import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HistoryIcon from "@mui/icons-material/History";
import { ListItem, ListItemText } from '@mui/material';
import { useCentroStore } from "../store";
import { useCustodyTrails } from "../store/queries/incidentReportQueries";
import {Tooltip} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  color: "#55819b",
  bgcolor: "rgb(255 255 255)!important",
  border: "1px solid hsla(0,0%,100%,.3)",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

export default function CustodyTrailModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  const { custodyTrail, custodyTrailPending } = useCustodyTrails(currentIncidentReport.irId);

  return (
    <>
      <Tooltip title={EnglishLanguage ? "View Custody Trail" : "Ver Rastro de Custodia"}>
      <HistoryIcon sx={{ fill: "#f7b01a!important" }} onClick={handleOpen} />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h4" component="h4" mb={3}   sx={{color:"#55819b!important"}}>
          {EnglishLanguage ? "Assigned To: " : "Asignado A: "}
          </Typography>
          <Typography variant="h6" component="h6"  sx={{color:"#55819b!important"}}>
           {EnglishLanguage ? "Pending" : "Pendiente"}
          </Typography>
           {custodyTrailPending?.data?.data.length === 0 ? <ListItem>N/A</ListItem> : (custodyTrailPending?.data?.data.map((userName) =>
               <ListItem>
                   {userName}
               </ListItem>))}
          <br />
          <Typography variant="h6" component="h6" sx={{color:"#55819b!important"}}>
           {EnglishLanguage ? "Previous" : "Previo"}
          </Typography>

          {custodyTrail?.data?.data.length === 0 ? <ListItem>N/A</ListItem> : (custodyTrail?.data?.data.map(({ irHistoryId, lastModifiedDate, lastModifiedTime, userName }) =>
               <ListItem key={irHistoryId}
                  value={irHistoryId}>
                  {userName} - {lastModifiedDate}<br />{lastModifiedTime} (UTC)
               </ListItem>
              ))}
        </Box>
      </Modal>
      </>
  );
}