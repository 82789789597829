import * as React from "react";
import { useCentroStore } from "../../store";
import { useUser, useUpdateUserPreferences } from "../../store/queries/userQueries";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { usePrefersReducedMotion } from "@chakra-ui/react";

export default function LanguageSelect() {
  const setTemperature = useCentroStore((state) => state.setWeatherTempMetric);
  const userId = useCentroStore((state) => state.userId);
  //const { userData, useUpdateUserPreferences } = useUser(userId);
  const userPreferences = useCentroStore((state) => state.userPreferences);
  const setUserPreferencesWeatherTempMetric = useCentroStore((state) => state.setUserPreferencesWeatherTempMetric);

  const handleChangeTempSelect = async (event) => {
      // Radio button event target values return as string rather than boolean, so they need to be converted
      if (event.target.value.toLowerCase() === "true") {
          setTemperature("");
          setUserPreferencesWeatherTempMetric(true);
      }
      else {
          setTemperature(true);
          setUserPreferencesWeatherTempMetric(false);
      }
  };


  return (

<FormControl>
<RadioGroup
  aria-labelledby="demo-controlled-radio-buttons-group"
  name="controlled-radio-buttons-group"
  defaultValue={userPreferences.weatherTempMetric}
  onChange={handleChangeTempSelect}
>
  <FormControlLabel value={false}  control={<Radio />} label="Celsius" />
  <FormControlLabel value={true} control={<Radio />} label="Fahrenheit" />
</RadioGroup>
</FormControl>
  );
}