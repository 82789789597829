import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useCentroStore } from "../../../../store";

export default function AircraftTypeUnknownCheckboxes() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setAircraftOneUnknownType = useCentroStore((state) => state.setAircraftOneTypeUnknown);
    const addUnknowns = useCentroStore((state) => state.addAircraftOneUnknowns);
    const currentAircraftOneUnknowns = useCentroStore((state) => state.currentAircraftOneUnknowns);
    const removeUnknowns = useCentroStore((state) => state.removeAircraftOneUnknowns);

    const aircraft = useCentroStore((state) => state.aircraftOne);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);

    const handleChange = (event, values) => {
        if (values.length > 0) {
            addUnknowns(values);
        }
        else {
            removeUnknowns([]);
        }
};
    const handleBlur = (event) => {
        var unknownTypes = currentAircraftOneUnknowns.map(({ title }) => title);
        var unknownsForInsert = "";
        unknownTypes.forEach((item, i, arr) => {
            if (unknownsForInsert === "") {
                unknownsForInsert = item+'.';
            }
            else if (arr.length - 1 === i) {
                unknownsForInsert += item;
            }
            else {
                unknownsForInsert += item + '.';
            }
        });
        setAircraftOneUnknownType(unknownsForInsert);
        var tempAircraftList = [];
        tempAircraftList[0] = aircraft;
        setaircraftsList(tempAircraftList);
    }

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={EnglishLanguage ? types : typesSpanish}
      disableCloseOnSelect
      size="small"
      limitTags='3'
      
      color="error"
          getOptionLabel={(option) => option.title}
          value={ currentAircraftOneUnknowns === [] ? "" : currentAircraftOneUnknowns }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            style={{ margin:0, marginRight: 8, padding: 0, color: "#f7b01a", background:"white!important", width: "auto"}}
            checked={selected}
          />
          {option.title}
        </li>
      )}
          onChange={handleChange}
          onBlur={handleBlur }
      style={{ width: "auto" }}
      renderInput={(params) => (
        <TextField {...params} label={EnglishLanguage ? ("Select") : ("Seleccionar")} placeholder={EnglishLanguage ? ("Select") : ("Seleccionar")}  />
      )}
    />
  );
}
const types = [
  { title: "High Wing" },
  { title: "Mid Wing" },
  { title: "Low Wing"},
  { title: "Rotocraft"},
  { title: "1 Engine" },
  { title: "2 Engine" },
  { title: "3 Engine" },
  { title: "4 Engine" },
  { title: "> 4 Engine" },
];

const typesSpanish = [
    { title: "Ala Alta" },
    { title: "Ala Media" },
    { title: "Ala Baja" },
    { title: "Ala Rotatoria" },
    { title: "1 Motor" },
    { title: "2 Motor" },
    { title: "3 Motor" },
    { title: "4 Motor" },
    { title: "> 4 Motor" },
];