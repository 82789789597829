import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";




import { useCentroStore } from '../../store/index'
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import FacilityDropdown from "./map-filters/FacilityDropdown";
import DateRange from "./map-filters/DateRange";
import EventTypeDropdown from "./map-filters/EventTypeDropdown";
import SeverityDropdown from "./map-filters/SeverityDropdown";
import ContributingFactorDropdown from "./map-filters/ContributingFactorDropdown";
import DayNightDropdown from "./map-filters/DayNightDropdown";
import IMCVMCDropdown from "./map-filters/IMCVMCDropdown";
import FARPartDropdown from "./map-filters/FARPartDropdown";
import DateRangeTo from "./map-filters/DateRangeTo";
//import { useEffect } from "react";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function MapFilters() {
  //const showairporttwo = useCentroStore((state) => state.showairporttwo);
  const currentIncidentReport = useCentroStore(
    (state) => state.currentincidentreport
  );
  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
  //const cloudsdropdown = useCentroStore((state) => state.cloudsdropdown);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  //useEffect(() => { setuserloggedin("Yes") })

  return (
    <Grid container py={0} spacing={0} mt={0} className="ir-form-wrapper maps-filter-wrapper">
      <Grid xs={12}>
        <Item elevation={0} sx={{ padding: "0" }}>
          <Grid xs={12}>
            <Grid xs={12} className="step-three-wrap" mb={1}>
              <Grid
                container
                className="ir-form-wrapper"
                justifyContent="space-between"
                spacing={1}
                sx={{
                  borderRadius: ".5rem",
                  padding: "10px 10px 15px",
                }}
              >
                {/* General Information Section */}
                <Grid container className="section-wrapper" xl={12}>
                  <Grid>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage
                              ? "Facility"
                              : "Instalaciones"}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FacilityDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                       {EnglishLanguage? "Date Range" : "Rango de fechas"}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <Grid container flexDirection="row" className="date-range-map">
                            <Grid item>
                            <DateRange />
                            </Grid>
                            {/* <Grid item>
                            <DateRangeTo />
                            </Grid> */}
                          </Grid>
                       
                         
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>

                  <Grid>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage
                                  ? "Occurrence Type"
                                  : "Tipo de evento"}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <EventTypeDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                      {/* <Grid>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                               {EnglishLanguage ? "Severity" : "Gravedad"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                                <SeverityDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid> */}

                  <Grid>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? "Contrib. Factor" : "Factor contribuyente"}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <ContributingFactorDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>

                  <Grid>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? "Day/Night" : "Día/Noche"}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <DayNightDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                      <Grid>
                        <Item elevation={0}>
                          <Grid>
                            {/* <Item className="conditional-arrow">
                              <KeyboardDoubleArrowRight />
                            </Item> */}
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "IMC/VMC"
                                  : "IMC/VMC"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <IMCVMCDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>

                      {/* <Grid>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Far Part"
                                  : "Far Part"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <FARPartDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid> */}
                      
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  );
}
