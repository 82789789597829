import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
export default function FacilityFindingsText() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setFacilityFindings = useCentroStore((state) => state.setfacilityfindings);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const blurHandler = (event) => {
        setFacilityFindings(event.target.value);
    }

  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? "Text" : "Texto"}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          rows={1}
          defaultValue={currentIncidentReport.facilityFindings }
          variant="standard"
          onBlur={blurHandler }
        />
  );
};