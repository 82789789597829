import * as React from "react";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import { Grid, MenuItem, Typography } from "@mui/material";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
import CellTowerTwoToneIcon from "@mui/icons-material/CellTowerTwoTone";
import {
  usePopupState,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";
//import OutlinedCard from './OutlinedCard';

const MyMarker = ({ id, type, category, date, time, label, text, isClickedLocation }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoMenu",
  });

  return (
    <React.Fragment key={id}>
      {type === "Airborne" && (
        <AirplanemodeActiveTwoToneIcon
          className="map-marker"
          sx={{
            fill: "#51a0ff!important",
            background: "black",
            fontSize: "1.5.rem!important",
            position: "absolute",
            transform: "translate(-50%, -100%)",
            padding: ".2rem",
          }}
          {...bindToggle(popupState)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        ></AirplanemodeActiveTwoToneIcon>
      )}
      {type === "CNS" && (
        <CellTowerTwoToneIcon
          className="map-marker"
          sx={{
            fill: "orange!important",
            background: "black",
            fontSize: "1.5.rem!important",
            position: "absolute",
            transform: "translate(-50%, -100%)",
            padding: ".2rem",
          }}
          {...bindToggle(popupState)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        ></CellTowerTwoToneIcon>
      )}
      {type === "Surface" && (
        <LocationOnTwoToneIcon
          className="map-marker"
          sx={{
            fill: "#00c24d!important",
            background: "black",
            fontSize: "1.5.rem!important",
            position: "absolute",
            transform: "translate(-50%, -100%)",
            padding: ".2rem",
          }}
          {...bindToggle(popupState)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        ></LocationOnTwoToneIcon>
      )}
{isClickedLocation && (
  <LocationOnTwoToneIcon
    className="map-marker"
    sx={{
      fill: "white!important", // choose a color that stands out
      background: "white",
      fontSize: "1.5.rem!important",
      position: "absolute",
      transform: "translate(-50%, -100%)",
      padding: ".2rem",
    }}
  ></LocationOnTwoToneIcon>
)}
      <HoverMenu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Grid
          container
          className="map-popup-wrapper"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          sx={{ maxWidth: "450px" }}
        >
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="popup-header-wrap"

          >
            <Grid Item className="map-popup-item" xs={10}>
              <span className="popup-cat-text">{id}</span>
            </Grid>
            <Grid Item className="map-popup-item" xs={2} flex justifyContent="flex-end">
              <MenuItem
              className="map-close"
                onClick={popupState.close}
                sx={{ lineHeight: "initial" }}
              
              >
                X
              </MenuItem>
            </Grid>
          </Grid>
          <Grid container className="popup-body-wrap">
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="start"
              my={0.5}
            >
              <Grid Item className="map-popup-item">
                <Typography className="popup-cat-text" >Type:</Typography>
              </Grid>
              <Grid Item className="map-popup-item ml-10">
              <Typography className="map-popup-body-text">{type}</Typography>
              </Grid>
            </Grid>
            {/* <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="start"
              my={0.5}
            >
              <Grid Item className="map-popup-item">
                <Typography className="popup-cat-text">Category:</Typography>
              </Grid>
              <Grid Item className="map-popup-item ml-10">
                <Typography className="map-popup-body-text">{category}</Typography>
              </Grid>
            </Grid> */}
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="start"
              my={0.5}
            >
              <Grid Item className="map-popup-item">
                <Typography className="popup-cat-text">Date (UTC):</Typography>
              </Grid>
              <Grid Item className="map-popup-item ml-10">
                <Typography className="map-popup-body-text">{date}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="start"
              my={0.5}
            >
             <Grid Item className="map-popup-item">
                <Typography className="infina-gold">Time (UTC):</Typography>
              </Grid>
              <Grid Item className="map-popup-item ml-10">
                <Typography className="map-popup-body-text">{time}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="start"
              my={0.5}
            >
              <Grid Item className="map-popup-item">
                <Typography className="popup-cat-text">Status:</Typography>
              </Grid>
              <Grid Item className="map-popup-item ml-10">
                <Typography className="map-popup-body-text">{label}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="start"
              my={0.5}
            >
              <Grid Item className="map-popup-item">
                <Typography className="popup-cat-text">Notes:</Typography>
              </Grid>
              <Grid Item className="map-popup-item ml-10">
                <Typography className="map-popup-body-text">{text}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </HoverMenu>
    </React.Fragment>
  );
};
export default MyMarker;
