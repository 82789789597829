import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function AircraftTwoOtherTrafficInformation() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const aircraft = useCentroStore((state) => state.aircraftTwo);
    const setAircraftTwoTrafficInformation = useCentroStore((state) => state.setAircraftTwoTrafficInformationDesc);
const handleChange = (event) => {
    setAircraftTwoTrafficInformation(event.target.value);
};


  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? "Text" : "Texto"}
          sx={{color:"#848484", fontFamily:"'Montserrat', Ariel!important"}}
          fullWidth
          rows={1}
          onBlur={handleChange}
          defaultValue={aircraft.trafficInformationDesc}
          variant="standard"
        />
  );
};