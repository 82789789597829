import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function AircraftTwoCallsign() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const aircraftOne = useCentroStore((state) => state.aircraftOne);
    const aircraftTwo = useCentroStore((state) => state.aircraftTwo);
    const setAircraftTwoCallsign = useCentroStore((state) => state.setAircraftTwoCallsign);
    const handleChange = (event) => {
        setAircraftTwoCallsign(event.target.value);
    };


  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? ("Text") : ("Texto")}
          sx={{color:"#848484", fontFamily:"'Montserrat', Ariel!important"}}
          fullWidth
          rows={1}
          onBlur={handleChange}
          defaultValue={aircraftTwo.callsign}
          variant="standard"
        />
  );
};