import centroClientApi from "../centroApi";
import axios from 'axios';


//Authentication Enpoints
export const userLogin = async (loginCredentials) => {
    return await centroClientApi.post('/Authenticate/authenticate', loginCredentials)
        .catch((error) => {
            return error.response;
        });
}

export const refreshToken = async () => {
    return await centroClientApi.post('/Authenticate/refresh-token')
        .catch((error) => {
            return error.response;
        });
}

//User Endpoints
export const fetchUserData = async (userId) => {
    return await centroClientApi.get('/User/' + userId);
}

export const createUser = async (user) => {
    var payload = JSON.stringify(user);
    return await centroClientApi.post('/User/user', payload);
}

export const createMultipleUsers = async (usersJSON) => {
    //var payload = JSON.stringify(users);
    return await centroClientApi.post('/User/users', usersJSON);
}

export const updateUser = async (user) => {
    var payload = JSON.stringify(user);
    return await centroClientApi.post('/User/user-edit', payload);
}

export const updateUserPassword = async (user) => {
    var payload = JSON.stringify(user);
    return await centroClientApi.post('/User/user-update-password', payload);
}

export const fetchAllUsers = async () => {
    const response = await centroClientApi.get('/User/users');
    // console.log(response.data);  // to confirm data structure
    return response.data;
}

export const fetchFacilityUsers = async (facId) => {
    const response = await centroClientApi.get('/User/users/' + facId);
    return response.data;
}

//User Preference Endpoints
export const updateUserPreferences = async (userPreferenceModel) => {
    var payload = JSON.stringify(userPreferenceModel);
    return await centroClientApi.put('/User/user-preferences/', payload);
}

//Role Endpoints
export const createRole = async (role) => {
    var payload = JSON.stringify(role);
    return await centroClientApi.post('/Role/role', payload);
}

export const updateRole = async (role) => {
    var payload = JSON.stringify(role);
    return await centroClientApi.put('/Role/role', payload);
}

export const fetchAllRoles = async () => {
    const response = await centroClientApi.get('/Role/Roles');
    // console.log(response.data);  // to confirm data structure
    return response.data;
}

//Facility Endpoints
export const fetchFacilities = async () => {
    return await centroClientApi.get('/Facility/facilities');
    //return await centroClientApi.get('/api/facilities');
}

export const fetchActiveFacilities = async () => {
    return await centroClientApi.get('/Facility/facilities-active');
}

export const createFacility = async (facility) => {
    var payload = JSON.stringify(facility);
    return await centroClientApi.post('/Facility/facility', payload);
}

export const updateFacility = async (facility) => {
    var payload = JSON.stringify(facility);
    return await centroClientApi.put('/Facility/facility', payload);
}

export const fetchAllFacilities = async () => {
    const response = await centroClientApi.get('/Facility/facilities');
    // console.log(response.data);  // to confirm data structure
    return response.data;
}
export const fetchAllFacilityTypes = async () => {
    const response = await centroClientApi.get('/FacilityType/facility-types');
    //console.log(response.data);  // to confirm data structure
    return response.data;
}

export const createFacilityType = async (facilityType) => {
    var payload = JSON.stringify(facilityType);
    return await centroClientApi.post('/FacilityType/facility-type', payload);
}

export const updateFacilityType = async (facilityType) => {
    var payload = JSON.stringify(facilityType);
    return await centroClientApi.put('/FacilityType/facility-type', payload);
}

export const deleteFacilityType = async (id) => {
    return await centroClientApi.delete('/FacilityType/' + id);
}

export const fetchFacilityPositions = async () => {
    return await centroClientApi.get('/Facility/positions');
    //return await centroClientApi.get('/api/Facility/Positions')
}

export const fetchWeather = async (facId) => {
    return await centroClientApi.get('/WeatherForecast/'+facId);
}

//Aircraft Endpoints
export const fetchAircraftsByIncidentReportId = async (irId) => {
    return await centroClientApi.get('/IrAircraft/' + irId +'/aircrafts');
}

export const fetchAircraftTypesByName = async (model) => {
    return await centroClientApi.get('/IrAircraft/aircraft-type-by-model', {
        params: {
            modelName: model
        }
    });
}

export const fetchOperatorsByName = async (airline) => {
    return await centroClientApi.get('/IrAircraft/operator-code-by-airline', {
        params: {
            airline: airline
        }
    });
}

export const fetchEquipmentTypes = async () => {
    return await centroClientApi.get('/IrAircraft/equipment-types');
    //return await centroClientApi.get('api/EquipmentTypes');
}

/*
export const addAircraftsToDraft = async (aircrrafts) => {
    return await centroClientApi.post('/IrAircraft/aircraft', aircrrafts);
    //return await centroClientApi.post('/api/IrAircraft', aircrrafts);
}
*/

/*
export const updateAircraftsOnDraft = async (aircrrafts) => {
    return await centroClientApi.put('/IrAircraft/aircraft', aircrrafts);
    //return await centroClientApi.put('/api/IrAircraft', aircrrafts);
}
*/

//Occurrence Report Endpoints
export const fetchReportsByFacility = async (facId) => {
    return await centroClientApi.get('/OccurrenceReporting/' + facId + '/occurrence-reports');
    //return await centroClientApi.get('/api/Incident-Reportings/Facility', {
    //    params: {
    //        facilityId: facId
    //    }
    //});
}

export const fetchReportsByUserFacility = async (helperObj) => {
    return await centroClientApi.get('/OccurrenceReporting/occurrence-reports-user-facility', helperObj);
}

export const fetchCategories = async () => {
    return await centroClientApi.get('/OccurrenceReporting/occurrence-categories');
    //return await centroClientApi.get('/api/IrCategories');
}

export const fetchSubcategories = async () => {
    return await centroClientApi.get('/OccurrenceReporting/occurrence-subcategories');
    //return await centroClientApi.get('/api/IrSubcategories');
}

export const saveReportDraft = async (currentIncidentReport) => {
    var payload = JSON.stringify(currentIncidentReport);
    return await centroClientApi.post('/OccurrenceReporting/draft', payload);
    //return await centroClientApi.post('/api/Incident-Reporting/Drafts', payload);
}

export const updateReportDraft = async (currentIncidentReport) => {
    var payload = JSON.stringify(currentIncidentReport);
    return await centroClientApi.put('/OccurrenceReporting/occurrence-report', payload);
    //return await centroClientApi.put('/api/Incident-Reporting', payload);
}

export const submitReportDraft = async (currentIncidentReport) => {
    var payload = JSON.stringify(currentIncidentReport);
    return await centroClientApi.post('/OccurrenceReporting/submit', payload);
    //return await centroClientApi.post('/api/Incident-Reporting/Submit', payload);
}

export const approveReportSubmitted = async (approvalObj) => {
    return await centroClientApi.put('/OccurrenceReporting/approve', approvalObj);
    //return await centroClientApi.put('/api/Incident-Reporting/Approve', approvalObj);
}

export const rejectReportSubmitted = async (rejectionObj) => {
    return await centroClientApi.put('/OccurrenceReporting/reject', rejectionObj);
    //return await centroClientApi.put('/api/Incident-Reporting/Reject', rejectionObj);
}


export const createReportHistory = async (model) => {
    var payload = JSON.stringify(model);
    return await centroClientApi.post('/OccurrenceReporting/create-history', payload);
    //return await centroClientApi.post('/api/Incident-Reporting/Create-History-Airborne', payload);
}

export const fetchReportHistory = async (id) => {
    return await centroClientApi.get('OccurrenceReporting/get-history/' + id);
    //return await centroClientApi.get('/api/Incident-Reporting/Get-History', {
    //    params: {
    //        irId: id
    //    }
    //});
}

export const fetchReportPending = async (id) => {
    return await centroClientApi.get('/OccurrenceReporting/get-pending/' + id);
    //return await centroClientApi.get('/api/Incident-Reporting/Get-Pending', {
    //    params: {
    //        irId: id
    //    }
    //});
}

// File Upload Attachment End Points
export const createUploadAttachment = async (model) => {
    return await centroClientApi.post('/OccurrenceReporting/upload-attachment', model, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const fetchReportAttachments = async (helperObj) => {
    return await centroClientApi.get('/OccurrenceReporting/get-attachments', helperObj);
}

export const deleteAttachments = async (id) => {
    return await centroClientApi.delete('/OccurrenceReporting/delete-attachment/' + id);
}

//To Do List Endpoints
export const fetchToDoForUser = async (userId) => {
    return await centroClientApi.get('/ToDoList/' + userId);
}

//Mail/Email Endpoints
export const sendPasswordResetMail = async (userEmail) => {
    //var payload = JSON.stringify(userEmail);
    return await centroClientApi.put('/Mail/password-reset/' + userEmail);
}

export const sendPasswordChangeConfirmation = async (userId) => {
    return await centroClientApi.post('/Mail/password-change/' + userId);
}

//Data Analytics Endpoints
export const fetchICAOCountryCodes = async () => {
    return await centroClientApi.get('/DataAnalytics/icao-country-codes');
}

export const fetchICAOIncidents = async () => {
    return await centroClientApi.get('/DataAnalytics/icao-incidents');
}

export const fetchICAOIncidentsModelData = async (year) => {
    return await centroClientApi.get('/DataAnalytics/icao-incidents-model-data/' + year);
}

export const fetchICAOIncidentsLocationData = async (year) => {
    return await centroClientApi.get('/DataAnalytics/icao-incidents-location-data/' + year);
}

export const fetchTowerOpsData = async () => {
    return await centroClientApi.get('/DataAnalytics/tower-ops');
}

export const fetchTRACONOpsData = async () => {
    return await centroClientApi.get('/DataAnalytics/tracon-ops');
}

