import { useCentroStore } from "../store";

export default function StepTextOne () {
// const currentincidenttype = useCentroStore((state) => state.currentincidenttype);
const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
const currentincidenttype = useCentroStore(
    (state) => state.currentincidenttype
  );
return (
    // <div>{currentincidenttype}</div>
    <div>{EnglishLanguage ? currentincidenttype
        : currentincidenttype === "airborne" ? ("En Vuelo")
        : currentincidenttype === "surface" ? ("Superficie")
        : ("")}</div>
)};