import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function AircraftOtherTrafficInformation() {


//const othertrafficinfotext = useCentroStore((state) => state.othertrafficinfotext);
    //    const setothertrafficinfotext = useCentroStore((state) => state.setothertrafficinfotext);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const aircraft = useCentroStore((state) => state.aircraftOne);
    const setAircraftOneTrafficInfoDesc = useCentroStore((state) => state.setAircraftOneTrafficInformationDesc);
const handleChange = (event) => {
  //setothertrafficinfotext(event.target.value);
    setAircraftOneTrafficInfoDesc(event.target.value);
};


  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? "Text" : "Texto"}
          sx={{color:"#848484", fontFamily:"'Montserrat', Ariel!important"}}
          fullWidth
          rows={1}
          onBlur={handleChange}
          defaultValue={aircraft.trafficInformationDesc}
          variant="standard"
        />
  );
};