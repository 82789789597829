import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
'Class A',
'Class B',
'Class C',
'Class D',
'Class E',
'Class G',
'TFR',
'Special Use',
];

const optionsSpanish = [
    'Clase A',
    'Clase B',
    'Clase C',
    'Clase D',
    'Clase E',
    'Clase G',
    'TFR',
    'Uso Especial',
];

export default function AirspaceDropdown() {

    //const [age, setAge] = React.useState('');
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
    const setAirspace = useCentroStore((state) => state.setairspace);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);

  const handleChange = (event) => {
      //setAge(event.target.value);
      setAirspace(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentIncidentReport.airspace}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
          options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))
          :
          optionsSpanish.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}

