import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function AircraftCallsign() {


//const callsigntext = useCentroStore((state) => state.callsigntext);
//    const setcallsigntext = useCentroStore((state) => state.setcallsigntext);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const aircraftOne = useCentroStore((state) => state.aircraftOne);
    const aircraftTwo = useCentroStore((state) => state.aircraftTwo);
    const setAircraftOneCallsign = useCentroStore((state) => state.setAircraftOneCallsign);
    const handleChange = (event) => {
      //setcallsigntext(event.target.value);
        setAircraftOneCallsign(event.target.value);
    };


  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? ("Text") : ("Texto")}
          sx={{color:"#848484", fontFamily:"'Montserrat', Ariel!important"}}
          fullWidth
          rows={1}
          onBlur={handleChange}
          defaultValue={aircraftOne.callsign}
          variant="standard"
        />
  );
};