import * as React from 'react';
import { TextareaAutosize } from '@mui/material';
import { useCentroStore } from '../../../../store';
export default function MetarReading() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setMetar = useCentroStore((state) => state.setmetar);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const blurHandler = (event) => {
        setMetar(event.target.value);
    }

  return (
<TextareaAutosize
          id="standard-multiline-static"
          placeholder={EnglishLanguage ? "Text" : "Texto"}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          multiline
          rows={1}
          defaultValue={currentIncidentReport.metar}
          variant="standard"
          onBlur={blurHandler}
        />
  );
};