import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import DoneIcon from '@mui/icons-material/Done';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FacilityFindingsText from "./general-informtation/FacilityFindingsText";
// import ParentFacilityFindingsText from "./general-informtation/ParentFacilityFindingsText";
// import OrganizationFindingsText from "./general-informtation/OrganizationFindingsText";
// import FacilityManagementFindingsText from "./general-informtation/FacilityManagementFindingsText";
import EscalateToFM from "./general-informtation/EscalateToFM";
import FFUploadButton from "./general-informtation/FFUploadButton";
import OutageReason from "./cns/OutageReason";
import OutageDurationStart from "./cns/OutageDurationStart";
import OutageDurationEnd from "./cns/OutageDurationEnd"
import MaintenanceAction from "./cns/MaintenanceAction";
import InterruptCondition from "./cns/InterruptCondition";
import { useCentroStore } from "../../../store";
//import { useAttachments } from "../../../store/queries/incidentReportQueries";

// import EscalateToPF from "./general-informtation/EscalateToPF";
// import EscalateToORG from "./general-informtation/EscalateToORG";
// import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",

}));

export default function IncidentReportStepSeven() {
    const setIsDraft = useCentroStore((state) => state.setIsDraft);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    //const { deleteAttachment, reportAttachments } = useAttachments(currentIncidentReport.irId);

    React.useEffect(() => {
        setIsDraft(false);
    });


  return ( currentIncidentReport.reportTypeId !== 2 ?
    <Grid container py={0} spacing={2} mt={0} className="ir-form-wrapper">
      <Grid xs={12}>
        <Item elevation={0} sx={{ padding: "0" }}>
        <Grid xs={12}>
         <Grid xs={12} className="step-three-wrap" mb={1}>           
                <Grid
                  container
                  className="ir-form-wrapper"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{background: "#00000033", borderRadius: ".5rem", padding: "10px 10px 15px"}}
                >    
            {/* <Grid xl={12}  className="table-header-wrap">
                <Item elevation={0}>
                   <SummarizeIcon />
                  </Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        paddingBottom: ".25rem",
                        whiteSpace: "nowrap",
                      }}
                      variant="h6"
                    >
                      Findings
                    </Typography>
                  </Item>
                </Grid> */}
                <Grid container  className="section-wrapper step-seven" xl={12}>
                  <Grid lg={6}>
                    <Item elevation={0}>
                      <Grid>
                      
                        <Item elevation={0} style={{ minWidth: "235px" }}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: ".9rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Facility Findings") : ("Observaciones de la Instalación")} 
                            {currentIncidentReport.facilityFindings !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FacilityFindingsText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  
                  <Grid lg={2}>
                    <Item elevation={0}>
                      <Grid display="flex" sx={{alignItems: "flex-start!important"}}>
                      <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <EscalateToFM />
                        </Item>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: ".9rem!important",
                            }}
                          >
                             {EnglishLanguage ? ("Escalate to Facility Management") : ("Escalar a la Gestión de Instalaciones")} 
                          </Typography>
                        </Item>
  
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid lg={2}>
                    <Item elevation={0}>
                      <Grid display="flex" sx={{alignItems: "flex-start!important"}}>
                      <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <EscalateToFM />
                        </Item>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: ".9rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Create Corrective Action Plan") : ("Crear Plan de Acción Correctiva")} 
                          </Typography>
                        </Item>

                      </Grid>
                    </Item>
                  </Grid>
                  <Grid lg={1} xs={12}>
                    <Item elevation={0}>
                      <Grid display="flex" sx={{alignItems: "space-between!important"}}>
                        <Item
                          elevation={0}
                          sx={{
                       
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FFUploadButton />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  {/* <Grid lg={6}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} style={{ minWidth: "235px" }}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: ".9rem!important",
                            }}
                          >
                            Facility Management Findings
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FacilityManagementFindingsText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid lg={4}>
                    <Item elevation={0}>
                      <Grid display="flex" sx={{alignItems: "flex-start!important"}}>
                      <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <EscalateToPF />
                        </Item>
                        <Item className="conditional-arrow-up"><KeyboardDoubleArrowRight /></Item>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: ".9rem!important",
                            }}
                          >
                            Escalate to Parent Facility
                          </Typography>
                        </Item>
                        
                      </Grid>
                    </Item>
                  </Grid>
                  
                  <Grid lg={1}>
                    <Item elevation={0}>
                      <Grid display="flex" sx={{alignItems: "space-between!important"}}>
                        <Item
                          elevation={0}
                          sx={{
                       
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FFUploadButton />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  </>
                
            
                  <>
                  <Grid lg={6}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: ".9rem!important",
                            }}
                          >
                            Parent Facility Findings
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <ParentFacilityFindingsText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid lg={4}>
                    <Item elevation={0}>
                      <Grid display="flex" sx={{alignItems: "flex-start!important"}}>
                      <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <EscalateToORG />
                        </Item>
                        <Item className="conditional-arrow-up"><KeyboardDoubleArrowRight /></Item>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: ".9rem!important",
                            }}
                          >
                            Escalate to Organization
                          </Typography>
                        </Item>
                        
                      </Grid>
                    </Item>
                  </Grid>
                  
                  <Grid lg={1}>
                    <Item elevation={0}>
                      <Grid display="flex" sx={{alignItems: "space-between!important"}}>
                        <Item
                          elevation={0}
                          sx={{
                       
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FFUploadButton />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  </>
             

                  <Grid lg={6}>
                    <Item elevation={0}>
                      <Grid>

                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: ".9rem!important",
                            }}
                          >
                            Organization Findings
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                  
                          }}
                        >
                          <OrganizationFindingsText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>



                  <Grid lg={4}>
                    <Item elevation={0}>
                      <Grid display="flex" sx={{alignItems: "flex-start!important"}}>
                      <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                        
                        </Item>
                        <Item className="conditional-arrow-up org-findings" ><KeyboardDoubleArrowRight /></Item>
                        <Item elevation={0}>

                        </Item>
                        
                      </Grid>
                    </Item>
                  </Grid>
                  
                  <Grid lg={1}>
                    <Item elevation={0}>
                      <Grid display="flex" sx={{alignItems: "space-between!important"}}>
                        <Item
                          elevation={0}
                          sx={{
                       
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FFUploadButton />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>

 */}   
               </Grid>   
               </Grid>
          </Grid>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  :
    <Grid container py={0} spacing={2} mt={0} className="ir-form-wrapper">
        {/* CNS Fields */}
        <Grid xs={12}>
            <Item elevation={0} sx={{ padding: "0" }}>
                <Grid xs={12}>
                    <Grid xs={12} className="step-three-wrap" mb={1}>
                        <Grid
                            container
                            className="ir-form-wrapper"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{ background: "#00000033", borderRadius: ".5rem", padding: "10px 10px 15px" }}
                        >
                            <Grid container className="section-wrapper step-seven" xl={12}>
                                <Grid lg={6}>
                                    <Item elevation={0}>
                                        <Grid>
                                            <Item elevation={0} style={{ minWidth: "235px" }}>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                        textAlign: "left",
                                                        fontSize: ".9rem!important",
                                                    }}
                                                >
                                                    {EnglishLanguage ? ("Outage Reason") : ("Motivo de la interrupci\xF3n")}
                                                    {currentIncidentReport.outageReason !== "" && (<DoneIcon className="checkbox-valdation-icon" />)}
                                                </Typography>
                                            </Item>
                                            <Item
                                                elevation={0}
                                                sx={{
                                                    textAlign: "left",
                                                    paddingTop: "0",
                                                    marginTop: "-10px",
                                                }}
                                            >
                                                <OutageReason />
                                            </Item>
                                        </Grid>
                                    </Item>
                                  </Grid>
                                  <Grid lg={6}>
                                    <Item elevation={0}>
                                        <Grid>
                                            <Item elevation={0} style={{ minWidth: "235px" }}>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                        textAlign: "left",
                                                        fontSize: ".9rem!important",
                                                    }}
                                                >
                                                    {EnglishLanguage ? ("Maintenance Action") : ("Acci\xF3n de mantenimiento")}
                                                    {currentIncidentReport.maintenanceAction !== "" && (<DoneIcon className="checkbox-valdation-icon" />)}
                                                </Typography>
                                            </Item>
                                            <Item
                                                elevation={0}
                                                sx={{
                                                    textAlign: "left",
                                                    paddingTop: "0",
                                                    marginTop: "-10px",
                                                }}
                                            >
                                                <MaintenanceAction />
                                            </Item>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid lg={6}>
                                    <Item elevation={0}>
                                        <Grid>
                                            <Item elevation={0} style={{ minWidth: "235px" }}>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                        textAlign: "left",
                                                        fontSize: ".9rem!important",
                                                    }}
                                                >
                                                    {EnglishLanguage ? ("Outage Duration Start (UTC)") : ("Inicio de la Duraci\xF3n de la interrupci\xF3n (UTC)")}
                                                    {currentIncidentReport.outageDurationStart !== null && (<DoneIcon className="checkbox-valdation-icon" />)}
                                                </Typography>
                                            </Item>
                                            <Item
                                                elevation={0}
                                                sx={{
                                                    textAlign: "left",
                                                    paddingTop: "0",
                                                    marginTop: "-10px",
                                                }}
                                            >
                                                <OutageDurationStart />
                                            </Item>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid lg={6}>
                                    <Item elevation={0}>
                                        <Grid>
                                            <Item elevation={0} style={{ minWidth: "235px" }}>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                        textAlign: "left",
                                                        fontSize: ".9rem!important",
                                                    }}
                                                >
                                                    {EnglishLanguage ? ("Outage Duration End (UTC)") : ("Fin de Inicio de la Duraci\xF3n de la interrupci\xF3n (UTC)")}
                                                    {currentIncidentReport.outageDurationEnd !== null && (<DoneIcon className="checkbox-valdation-icon" />)}
                                                </Typography>
                                            </Item>
                                            <Item
                                                elevation={0}
                                                sx={{
                                                    textAlign: "left",
                                                    paddingTop: "0",
                                                    marginTop: "-10px",
                                                }}
                                            >
                                                <OutageDurationEnd />
                                            </Item>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid lg={6}>
                                    <Item elevation={0}>
                                        <Grid>
                                            <Item elevation={0} style={{ minWidth: "235px" }}>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                        textAlign: "left",
                                                        fontSize: ".9rem!important",
                                                    }}
                                                >
                                                    {EnglishLanguage ? ("Interrupt Condition") : ("Condici\xF3n de interrupci\xF3n")}
                                                    {currentIncidentReport.interruptCondition !== "" && (<DoneIcon className="checkbox-valdation-icon" />)}
                                                </Typography>
                                            </Item>
                                            <Item
                                                elevation={0}
                                                sx={{
                                                    textAlign: "left",
                                                    paddingTop: "0",
                                                    marginTop: "-10px",
                                                }}
                                            >
                                                <InterruptCondition />
                                            </Item>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid lg={6}>
                                    <Item elevation={0}>
                                        <Grid>

                                            <Item elevation={0} style={{ minWidth: "235px" }}>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                        textAlign: "left",
                                                        fontSize: ".9rem!important",
                                                    }}
                                                >
                                                    {EnglishLanguage ? ("Facility Findings") : ("Observaciones de la Instalación")}
                                                    {currentIncidentReport.facilityFindings !== "" && (<DoneIcon className="checkbox-valdation-icon" />)}
                                                </Typography>
                                            </Item>
                                            <Item
                                                elevation={0}
                                                sx={{
                                                    textAlign: "left",
                                                    paddingTop: "0",
                                                    marginTop: "-10px",
                                                }}
                                            >
                                                <FacilityFindingsText />
                                            </Item>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid lg={2}>
                                    <Item elevation={0}>
                                        <Grid display="flex" sx={{ alignItems: "flex-start!important" }}>
                                            <Item
                                                elevation={0}
                                                sx={{
                                                    textAlign: "left",
                                                    paddingTop: "0",
                                                    marginTop: "-10px",
                                                }}
                                            >
                                                <EscalateToFM />
                                            </Item>
                                            <Item elevation={0}>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                        textAlign: "left",
                                                        fontSize: ".9rem!important",
                                                    }}
                                                >
                                                    {EnglishLanguage ? ("Escalate to Facility Management") : ("Escalar a la Gestión de Instalaciones")}
                                                </Typography>
                                            </Item>

                                        </Grid>
                                    </Item>
                                </Grid>

                                <Grid lg={1} xs={12}>
                                    <Item elevation={0}>
                                        <Grid display="flex" sx={{ alignItems: "space-between!important" }}>
                                            <Item
                                                elevation={0}
                                                sx={{

                                                    paddingTop: "0",
                                                    marginTop: "-10px",
                                                }}
                                            >
                                                <FFUploadButton />
                                            </Item>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Item>
        </Grid>
    </Grid>
  );
}
