import * as React from 'react';
import Typography from '@mui/material/Typography';
import safetyassessment from "../../assets/icons/create-safety-assessment-button@2x.png";
import { Link } from "react-router-dom";
import { useCentroStore } from '../../store';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import { Tooltip } from '@mui/material';

export default function SafetyAssessment() {

  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  return (
    <Tooltip title={EnglishLanguage ? "Create a new Safety Assessment (Disabled for Beta)." : "Cree una nueva Evaluaci\xF3n de seguridad (Deshabilitado para Beta)."}>
      <span>
    <div   className="action-btn disabled">
     
      <Link to="#" className="ir-sa-button-link safety-assessment">
                <HealthAndSafetyOutlinedIcon  sx={{fill: "white"}}/><span className='ir-sa-plus-icon'>+</span>
                <Typography variant="h3" sx={{ fontWeight: "500" }}>
                {EnglishLanguage ? (<span>Safety Assessment </span>) : (<span>Evaluación de la Seguridad</span>)} 
                </Typography>
      </Link>  
                 
    </div>
    </span>
    </Tooltip> 
  );
}
