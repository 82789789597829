import * as React from "react";
import { Button, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useCentroStore } from "../store";
import { usePasswordChange } from "../store/queries/mailQueries";
import { useUser } from "../store/queries/userQueries";
import CircularProgress from '@mui/material/CircularProgress';

import DoneIcon from '@mui/icons-material/Done';
import HeaderSection from "./header/HeaderSection";
import UsernameTextfield from "./user-profile/UsernameTextfield";
import PasswordTextfield from "./user-profile/PasswordTextfiled";
import PasswordTextfieldMatch from "./user-profile/PasswordTextfiledMatch";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function UserProfile() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const currentCategory = useCentroStore((state) => state.currentCategory);
  const userId = useCentroStore((state) => state.userId);
  const { userData, useUpdatePassword } = useUser(userId);
  const [errorCode, setErrorCode] = React.useState();
  const [errorMsg, setErrorMsg] = React.useState("");
  const navigate = useNavigate();
  const passwordChange = usePasswordChange(); // Email function
  //const [userPassword, setUserPassword] = React.useState("");
  const [emailInProgress, setEmailInProgress] = React.useState(false); // Variable to help detect if a password change email is in progress
  const [userResetRequired, setUserResetRequired] = React.useState(false); // Indicates whether prompt shows up when user tries to leave the page but they require a reset
  const userProfilePassword = useCentroStore((state) => state.userProfilePassword);
  const userProfilePasswordConfirmation = useCentroStore((state) => state.userProfilePasswordConfirmation);
  const userPasswordChange = useCentroStore((state) => state.userPasswordChange); // State object for using in updatePassword()
  const setUserProfilePassword = useCentroStore((state) => state.setUserProfilePassword);
  const setUserProfilePasswordConfirmation = useCentroStore((state) => state.setUserProfilePasswordConfirmation);

  // For running on first render and providing directions if password reset required variable is true
  React.useEffect(() => {
      if (userData?.data?.data.resetRequired === true) {
          setUserResetRequired(true);
          setErrorCode(400);
          if (EnglishLanguage) {
              setErrorMsg("You must update your password before you can navigate to the rest of the site");
          }
          else {
              setErrorMsg("Debe actualizar su contrasena antes de poder navegar al resto del sitio");
          }
      } else {
          setUserResetRequired(false);
          setErrorCode(0);
          setErrorMsg("");
      }
  }, []);

  const handleUpdatePassword = async (event) => {
        // Check to make sure that both passwords entered match
      console.log(userProfilePassword);
        if (userProfilePassword !== "" && userProfilePasswordConfirmation !== ""){
            if (userProfilePassword !== userProfilePasswordConfirmation) {
                setErrorCode(400);
                if (EnglishLanguage) {
                    setErrorMsg("Passwords do not match");
                }
                else {
                    setErrorMsg("Las contrase\xF1as no coinciden");
                }
                return;
            }
            else {
                userPasswordChange.userId = userId;
                userPasswordChange.password = userProfilePassword
            }
        }

        // Check for blanks
        if (userProfilePassword === "" || userProfilePasswordConfirmation === ""){
            setErrorCode(400);
            if (EnglishLanguage) {
                setErrorMsg("Password fields cannot be blank");
            }
            else {
                setErrorMsg("Los campos de contrase\xF1as no pueden estar en blanco");
            }
            return;
        }

        try {
            setEmailInProgress(true);
            var response = await useUpdatePassword.mutateAsync(userPasswordChange);
            if (response.status !== 200) {
                setEmailInProgress(false);
                setErrorCode(response.status);
                if (EnglishLanguage) {
                    setErrorMsg("An error occurred during user profile update. Contact system administrator for assistance");
                    alert("User Profile Update Failed");
                }
                else {
                    setErrorMsg("Se ha producido un error durante la actualizaci\xF3n del perfil de usuario. P\xF3ngase en contacto el administrador del sistema para obtener ayuda");
                    alert("Error al actualizar el perfil de usuario");
                }
            } else { // Success
                setErrorCode(0);
                setErrorMsg("");
                response = await passwordChange.mutateAsync(userId);
                setEmailInProgress(false);
                setUserResetRequired(false);
                setUserProfilePassword("");
                setUserProfilePasswordConfirmation("");
                if (EnglishLanguage) {
                    alert("User Profile Update Successful");
                }
                else {
                    alert("Actualizaci\xF3n correcta del perfil de usuario");
                }
                navigate('/home');

            }

        } catch (error) {
            setEmailInProgress(false);
            console.error("API call failed: ", error);
            setErrorCode(500);
            if (EnglishLanguage) {
                setErrorMsg("An error occurred during user profile update. Contact system administrator for assistance");
            }
            else {
                setErrorMsg("Se ha producido un error durante la actualizaci\xF3n del perfil de usuario. P\xF3ngase en contacto el administrador del sistema para obtener ayuda");
            }
        }
    };

  return (
    <>
<Container component="main" maxWidth="none" id="incident-reports" sx={{padding: "0!important"}}>
      <HeaderSection className="content-wrap" sectionName="Occurrence Reports" sectionNameES="Informes De Incidencia"/>
      <Grid
       className="page-wrap"
        container
        xs={12}
        mr={2}
        alignContent="flex-start"
        sx={{ position: "relative", left: ".5rem" }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          justifyContent="flex-start"

        >
        {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>{errorMsg}</Grid></Grid> : null}
        {/*userData?.data?.data.resetRequired === true ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>You must update your password before you can navigate to the rest of the site.</Grid></Grid> : null*/}
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                {EnglishLanguage ? ("User Profile") : ("Perfil del Usuario")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >


              <Item
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  top: "9px",
                  right: "10px",
                  position: "relative",
                }} 
              >
    
              </Item>
            </Grid>
          </Grid>
          <Grid xs={12}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",

                  }}>
                  {EnglishLanguage ? "Username (Read-Only)" : "Nombre de Usuario (Solo-Lectura)"}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <UsernameTextfield />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid xs={12}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",

                  }}>
                  {EnglishLanguage ? "Update Password" : "Actualiza Contrase\xF1a"}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <PasswordTextfield />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid xs={12}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",

                  }}>
                  {EnglishLanguage ? "Confirm Password" : "Confirmar Contrase\xF1a"}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <PasswordTextfieldMatch />
              </Item>
            </Grid>
          </Item>
        </Grid>
        {emailInProgress && (<CircularProgress />)}
        {!emailInProgress && <Button
              onClick={handleUpdatePassword}
              type="submit"
              fullWidth
              id="login-button-id"
              className="login-button"
              variant="contained"
              sx={{ mt: 2, mb: 3, margin: "2rem 1rem 1rem auto", maxWidth:"300px", color: "#F7B01A",  fontFamily: "'Montserrat'!important", fontWeight: "500", fontSize: "1.25rem", background: "#00000033!important", borderRadius: "1rem!important",  height: "100%", border: "1px solid rgba(255, 255, 255, 0.3)",     backgroundColor: "rgb(0 0 0 / 33%)!important", backdropFilter: "blur(4.8px)" }}
            >
              {EnglishLanguage ? "Update User Profile" : "Actualizar Perfil de Usuario"}
            </Button> }
        </Grid>
        
      </Grid>
    </Container>





    {/* <Container component="main" maxWidth="none" id="technical">
       <HeaderSection className="content-wrap" sectionName="Technical" sectionNameES="Técnica"/>
       <Grid
        className="page-wrap"
        container
        sx={{
          padding: "0",
          margin: "0",

          width: "calc(100vw - 20rem)",
          height: "calc(100vh - 8.9rem)"
        }}

      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}

        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                {EnglishLanguage ? ("User Profile") : ("Técnica ")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            ></Grid>
          </Grid>
      <Grid container className="section-wrapper ir-form-wrapper step-three ir-in-step-wrap" xl={12}>
      <Grid container  xs={12}>
        <Grid xs={12}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",

                  }}>
                  {EnglishLanguage ? "Username" : "Categoría de Ocurrencia"} {currentCategory.irCategory1 ? <DoneIcon className="checkbox-valdation-icon"/> : ""}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <IncidentCategoryAirborneDropdown />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid xs={12}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",

                  }}>
                  {EnglishLanguage ? "Password" : "Categoría de Ocurrencia"} {currentCategory.irCategory1 ? <DoneIcon className="checkbox-valdation-icon"/> : ""}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <IncidentCategoryAirborneDropdown />
              </Item>
            </Grid>
          </Item>
        </Grid>
        </Grid>
        </Grid>
        </Grid>
        </Grid>
        </Container > */}
        </>
        )
            }
