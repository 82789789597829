import { Typography } from "@mui/material";
import React, { useState } from 'react';
import { useClearState } from "../Helpers/HelperFunctions";
import { useCentroStore } from "../../store";
import { Link } from "react-router-dom";


export default function OccurrenceReportTipsText() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
    const { ClearReport } = useClearState();

    const handleNewIncidentClick = async () => {
        ClearReport();
    };

    if (EnglishLanguage) {
        return (
            <>
                <Typography className="tips-text">To <b>create a new report</b> click the
                    <Link to="/create-new-incident-report" sx={{ textDecoration: "none" }} onClick={handleNewIncidentClick}>
                        <Typography variant="a"  >
                            {' '}Occurrence Report{' '}
                        </Typography>
                    </Link>
                    button. </Typography><br />
                <Typography className="tips-text">To view older reports, click on the     <Link to="/incident-reports" sx={{ textDecoration: "none" }} onClick={handleNewIncidentClick}>
                    <Typography variant="a"  >
                        {' '}Reports{' '}
                    </Typography>
                </Link> menu.</Typography>

            </>
        )
    }
    else {
        return (
            <>
                <Typography className="tips-text">Para <b>crear un nuevo informe</b>, seleccione el bot{"\xF3n"}
                    <Link to="/create-new-incident-report" sx={{ textDecoration: "none" }} onClick={handleNewIncidentClick}>
                        <Typography variant="a"  >
                            {' '}Informe de Ocurrencias{' '}
                        </Typography>
                    </Link>
                    </Typography><br />
                <Typography className="tips-text">Para ver informes anteriores, seleccione el men{"\xFA"}     <Link to="/incident-reports" sx={{ textDecoration: "none" }} onClick={handleNewIncidentClick}>
                    <Typography variant="a"  >
                        {' '}Reportes{' '}
                    </Typography>
                </Link></Typography>

            </>
        )
    }
};