import { Container } from "@mui/material";
//import Breadcrumbs from "@mui/material/Breadcrumbs";
//import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom"
import Grid from "@mui/material/Grid";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
//import TopNavBar from "./TopNavBar";
//import FacilityDropdown from "./forms/FacilityDropdown";
//import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useCentroStore } from "../store";
import HeaderSection from "./header/HeaderSection";
import FacilityListTables from "./FacilityListTables";
import { useUser, useAllFacilities, useAllFacilityTypes } from '../store/queries/userQueries';
import { useFacilities } from '../store/queries/facilityQueries';
import { Dialog, DialogTitle, DialogContent, TextField, Select, MenuItem, FormControl, FormControlLabel, Switch, Button, DialogActions, Tooltip, Typography } from '@mui/material';
//import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from "@mui/icons-material/Add";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const FacilityList = (props) => {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const userId = useCentroStore((state) => state.userId);
    const { userData } = useUser(userId);
    const userDataPermissions = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0];
    const { data: FacilitiesData, refetch, isLoading } = useAllFacilities();
    const { data: FacilityTypeData } = useAllFacilityTypes();
    const { addFacility } = useFacilities();

    const [open, setOpen] = React.useState(false);
    const [currentFacility, setCurrentFacility] = React.useState(null);
    const newFacility = useCentroStore((state) => state.newFacility);
    //const newFacilityPosition = useCentroStore((state) => state.newFacilityPosition);
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState("");

    // Columns for Facility Positions datagrid in modal
    const columnsPositions = [
        { field: 'posName', headerName: 'Position Name', flex: 1, editable: true },
        { field: 'frequency', headerName: 'Frequency', flex: 1, editable: true, type: 'number' },
        //{ field: 'facilityId', headerName: 'Facility', flex: 1 },
        { field: 'delete', headerName: 'Delete', flex: 1, renderCell: (params) => (<ClearIcon className="checkbox-clear-icon" style={{ cursor: 'pointer' }} onClick={(event) => { event.stopPropagation(); handleDeletePos(params.row); }} />) }
    ];

    const columnsPositionsSpanish = [
        { field: 'posName', headerName: 'Nombre de la Posici\xF3n', flex: 1.5, editable: true },
        { field: 'frequency', headerName: 'Frecuencia', flex: 1, editable: true, type: 'number' },
        //{ field: 'facilityId', headerName: 'Facility', flex: 1 },
        { field: 'delete', headerName: 'Borrar', flex: 1, renderCell: (params) => (<ClearIcon className="checkbox-clear-icon" style={{ cursor: 'pointer' }} onClick={(event) => { event.stopPropagation(); handleDeletePos(params.row); }} />) }
    ];

    const handleOpen = () => {
        if (userDataPermissions.updateFac === 1) {
            setCurrentFacility(newFacility);
            setOpen(true);
        }
        else {
            if (EnglishLanguage) {
                alert("You do not have permission to add facilities.");
            }
            else {
                alert("No tiene permiso para agregar instalaciones.");
            }
        }
    };

    const handleClose = () => {
        setErrorCode(0);
        setCurrentFacility(null);
        setOpen(false);
    };

    const handleSave = async () => {
        //console.log(currentFacility); // to log the updated Facility data
        setErrorCode(0);
        // Check to make sure duplicate facility names aren't being added
        const facilityNameExistsInDb = FacilitiesData.find(element => element.name.toLowerCase() === currentFacility.name.toLowerCase());
        if (facilityNameExistsInDb !== undefined) {
            setErrorCode(400);
            if (EnglishLanguage) {
                setErrorMsg("There was an error creating New Facility. Name/Acronym is already tied to another Facility.");
            } else {
                setErrorMsg("Hubo un error al crear la nueva instalaci\xF3n. El nombre/acr\xF3nimo ya est\xE1 vinculado a otra instalaci\xF3n.");
            }
            return;
        }

        let response;
        response = await addFacility.mutateAsync(currentFacility).catch(function (error) {
            setErrorCode(error.response.status);
            if (error.response.status === 400) {
                if (EnglishLanguage) {
                    setErrorMsg("There was an error creating New Facility. Required fields are missing.");
                }
                else {
                    setErrorMsg("Hubo un error al crear la nueva instalaci\xF3n. Faltan campos obligatorios.");
                }
            }
            else {
                setErrorMsg(error.message);
            }
        });
        if (response.status === 200) {
            refetch(); // Refetch query to update datagrid
            if (EnglishLanguage) {
                alert("Facility has been successfully added.");// Successful save message
            }
            else {
                alert("La instalaci\xF3n se ha agregado con \xE9xito.");// Successful save message
            }
        }
        else {
            setErrorCode(response.status);
            setErrorMsg(response.statusText);
            if (EnglishLanguage) {
                alert("There was an error adding Facility. Facility could not be added.");// Error
            }
            else {
                alert("Hubo un error al agregar la instalaci\xF3n. No se pudo agregar la instalaci\xF3n.");// Error
            }
        }
        handleClose();
    };

    // Assist with deleting positions from data grid
    const handleDeletePos = (Position) => {
        if (userDataPermissions.deleteFac === 1) {
            if (EnglishLanguage) {
                if (window.confirm("Confirm deleting this Facility Position? - '" + Position.posName + "'") == true) {
                    var index = currentFacility.facilityPositionModels.indexOf(Position);
                    if (index > -1) { // only splice array when item is found
                        var tempFacPositions = [...currentFacility.facilityPositionModels];
                        tempFacPositions.splice(index, 1);
                        setCurrentFacility({
                            ...currentFacility,
                            ['facilityPositionModels']: tempFacPositions,
                        });
                    }
                }
            }
            else {
                if (window.confirm("Confirmar la eliminaci\xF3n de esta Posici\xF3n de la Instalaci\xF3n? - '" + Position.posName + "'") == true) {
                    var index = currentFacility.facilityPositionModels.indexOf(Position);
                    if (index > -1) { // only splice array when item is found
                        var tempFacPositions = [...currentFacility.facilityPositionModels];
                        tempFacPositions.splice(index, 1);
                        setCurrentFacility({
                            ...currentFacility,
                            ['facilityPositionModels']: tempFacPositions,
                        });
                    }
                }
            }
        }
        else {
            if (EnglishLanguage) {
                alert("You do not have permission to delete facility positions.");
            }
            else {
                alert("No tiene permiso para eliminar posiciones de instalaciones.");
            }
        }
    };

    // Add new empty row to the facility position data grid
    const handleAddPos = () => {
        const randomId = Math.floor(Math.random() * 100000);
        var tempFacPositions = [...currentFacility.facilityPositionModels];
        tempFacPositions.push({ id: randomId, posName: "", frequency: "", facilityId: currentFacility.id });
        setCurrentFacility({
            ...currentFacility,
            ['facilityPositionModels']: tempFacPositions,
        });
    }

    // Editing row info for facility positions
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        var tempFacPositions = [...currentFacility.facilityPositionModels];
        const index = tempFacPositions.findIndex(object => {
            return object.id === updatedRow.id;
        });
        if (index !== -1) {
            tempFacPositions[index].posName = updatedRow.posName;
            tempFacPositions[index].frequency = updatedRow.frequency;
            tempFacPositions[index].facilityId = updatedRow.facilityId;
            setCurrentFacility({
                ...currentFacility,
                ['facilityPositionModels']: tempFacPositions,
            });
        }

        return updatedRow;
    };

    const handleTextFieldChange = (event, field) => {
        setCurrentFacility(prevState => ({
            ...prevState,
            [field]: event.target.value
        }));
    };

    const handleSelectChange = (event, field) => {
        setCurrentFacility({
            ...currentFacility,
            [field]: event.target.value,
        });
    };

    const modalBody = currentFacility ? (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ textTransform: "capitalize" }}>{EnglishLanguage ? "Add New Facility" : "Agregar Nueva Instalaci\xF3n"}</DialogTitle>
            <DialogContent>
                <Typography>{EnglishLanguage ? "Name" : "Nombre"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Name" : "Nombre"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'name')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Address 1" : "Direcci\xF3n 1"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Address 1" : "Direcci\xF3n 1"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'address1')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Address 2" : "Direcci\xF3n 2"}:</Typography>
                <TextField label={EnglishLanguage ? "Address 2" : "Direcci\xF3n 2"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'address2')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "City" : "Ciudad"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "City" : "Ciudad"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'city')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "State" : "Estado/Regi\xF3n"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "State" : "Estado/Regi\xF3n"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'state')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Country" : "La Regi\xF3n"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Country" : "La Regi\xF3n"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'country')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Zip Code" : "El C\xF3digo Postal"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Zip Code" : "El C\xF3digo Postal"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'zipcode')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Phone Number" : "N\xFAmero de Tel\xE9fono"}:</Typography>
                <TextField label={EnglishLanguage ? "Phone Number" : "N\xFAmero de Tel\xE9fono"} variant="standard" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'phone')} fullWidth margin="normal" />
                <Tooltip title={EnglishLanguage ? "Assign which Facility Type this role belongs to. If there are no Types, they will need to be created in the Facility Types page."
                    : "Asigne a qu\xE9 tipo de instalaci\xF3n pertenece este rol. Si no hay tipos, deber\xE1n crearse en la p\xE1gina Tipos de instalaciones."} arrow>
                    <Typography><br />{EnglishLanguage ? "Facility Type" : "Tipos de Instalaciones"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                </Tooltip>
                <FormControl fullWidth margin="normal">
                    <Select variant="filled" defaultValue={""} onChange={(event) => handleSelectChange(event, 'typeId')} >
                        <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Type" : "Seleccione Tipo"}</em></MenuItem>
                        {FacilityTypeData?.map((type) => (<MenuItem key={type.typeId} value={type.typeId}>{type.typeName}</MenuItem>))}
                    </Select>
                </FormControl>
                <Typography><br />{EnglishLanguage ? "Latitude" : "Latitud"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Latitude" : "Latitud"} variant="standard" type="number" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'latitude')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Longitude" : "Longitud"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Longitude" : "Longitud"} variant="standard" type="number" defaultValue={""} onChange={(event) => handleTextFieldChange(event, 'longitude')} fullWidth margin="normal" />
                <Typography><br />{EnglishLanguage ? "Facility Positions: (Double-Click Row To Edit)"
                    : "Posiciones de las Instalaciones: (Haga Doble-Clic en la fila para editar)"}<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleAddPos}>
                    {EnglishLanguage ? "Add Empty Record" : "A\xF1adir registro vac\xEDo"}
                </Button>
                <div style={{ width: '100%' }}>
                    <DataGrid
                        editMode="row"
                        rows={currentFacility.facilityPositionModels}
                        //onRowEditStop={(params, event) =>  handleDataGridChange(event, 'facilityPositionModels')}
                        processRowUpdate={processRowUpdate} // Detects when a change is made to a row
                        experimentalFeatures={{ newEditingApi: true }} // For processRowUpdate to work
                        getRowId={(row) => row.id}
                        columns={EnglishLanguage ? columnsPositions : columnsPositionsSpanish}
                        autoHeight={true}
                        rowsPerPageOptions={[10]}
                        //autoPageSize={true}
                        components={{ Toolbar: GridToolbar }}
                        //onRowClick={FacilityDataSetup} 
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: {
                                    debounceMs: 500,
                                },
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                            },
                        }}
                        localeText={{
                            noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                            noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                            toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                            toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                            toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                            toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir",
                            MuiTablePagination: {
                                labelDisplayedRows: ({ from, to, count }) =>
                                    EnglishLanguage ?
                                        `${from}-${to} of ${count}` :
                                        `${from}-${to} de ${count}`,
                            },
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>{errorMsg}</Grid></Grid> : null}
                <Button onClick={handleClose} color="primary">{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
                <Button onClick={handleSave} color="primary">{EnglishLanguage ? "Save" : "Guardar"}</Button>
            </DialogActions>
        </Dialog>
    ) : null;

  return (
    <Container component="main" maxWidth="none" id="incident-reports" sx={{padding: "0!important"}}>
      {modalBody}
      <HeaderSection className="content-wrap" sectionName="Facilities" sectionNameES="instalaciones"/>
      <Grid
       className="page-wrap"
        container
        xs={12}
        mr={2}
        sx={{ position: "relative", left: ".5rem" }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                {EnglishLanguage ? ("Facilities List") : ("lista de instalaciones")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >
              <Item elevation={0} sx={{ background: "transparent" }} onClick={handleOpen} >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "500" }}
                    mb={3}
                    mt={1.25}
                    className="add-text-ir"
                  >
                   {EnglishLanguage ? "Add Facility" : "Agregar Instalaci\xF3n"}
                  </Typography>{" "}
              </Item>

              <Item
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  top: "9px",
                  right: "10px",
                  position: "relative",
                }} 
                onClick={handleOpen}
              >
                  <PlaylistAddIcon className="add-todo-icon" />
              </Item>
            </Grid>
          </Grid>
          <Grid lg={12} px={2} mt={3} sx={{ height: "calc(100% - 66px)" }}>
            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
              <FacilityListTables />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FacilityList;
