import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function AircraftTwoAltitude() {


//const altitudetext = useCentroStore((state) => state.altitudetext);
//const setaltitudetext = useCentroStore((state) => state.setaltitudetext);
    const aircraft = useCentroStore((state) => state.aircraftTwo);
    const setAircraftTwoAltitude = useCentroStore((state) => state.setAircraftTwoAltitude);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
const handleChange = (event) => {
  //setaltitudetext(event.target.value);
    setAircraftTwoAltitude(parseInt(event.target.value));
};
  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? "Number" : "N\xFAmero"}
          sx={{color:"#848484", fontFamily:"'Montserrat', Ariel!important"}}
          fullWidth
          rows={1}
          onBlur={handleChange}
          defaultValue={(isNaN(aircraft.altitude)|| aircraft.altitude === 0) ? "": aircraft.altitude}
          variant="standard"
        />
  );
};