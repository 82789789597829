import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    fetchUserData, fetchFacilityUsers, fetchAllUsers, fetchAllRoles, fetchAllFacilities, fetchAllFacilityTypes, userLogin,
    createUser, updateUser, createMultipleUsers,
    createRole, updateRole,
    refreshToken, updateUserPassword, updateUserPreferences
} from '../api/apiEndpoints/apiEndpoints';


export const useAddUserCredentials = () => {
    const mutation = useMutation(userLogin);
    return mutation;
}

export const useRefreshToken = () => {
    const newToken = useMutation(refreshToken);
    return newToken;
}

export function useUser(userId) {
    const queryClient = useQueryClient();
    const userData = useQuery(["user", userId], () => fetchUserData(userId), { enabled: userId !== 0, refetchOnWindowFocus: false, staleTime: 60 * 5 * 1000 });

    const addUser = useMutation(createUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(["user", userId])
        },
    });

    const addMultipleUsers = useMutation(createMultipleUsers, {
        onSuccess: () => {
            queryClient.invalidateQueries(["user", userId])
        },
    });

    const editUser = useMutation(updateUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(["user", userId])
        },
    });

    const useUpdatePassword = useMutation(updateUserPassword, {
        onSuccess: () => {
            queryClient.invalidateQueries(["user", userId])
        },
    });

    const useUpdateUserPreferences = useMutation(updateUserPreferences);

    return { addUser, addMultipleUsers, editUser, userData, useUpdatePassword, useUpdateUserPreferences };
}

export function useFacUsers(facId) {
    return useQuery(["facUsers", facId], () => fetchFacilityUsers(facId), { enabled: facId !== 0, refetchOnWindowFocus: false, staleTime: 60 * 5 * 1000 });
}

export function useAllUsers() {
    return useQuery('users', fetchAllUsers, { refetchOnWindowFocus: false, staleTime: 60 * 5 * 1000 });
}

export function useAllRoles() {
    return useQuery('roles', fetchAllRoles, { refetchOnWindowFocus: false, staleTime: 60 * 5 * 1000 });
}

export function useRole() {
    const queryClient = useQueryClient();

    const addRole = useMutation(createRole, {
        onSuccess: () => {
            queryClient.invalidateQueries(["roles"])
        },
    });

    const editRole = useMutation(updateRole, {
        onSuccess: () => {
            queryClient.invalidateQueries(["roles"])
        },
    });

    return { addRole, editRole };
}

export function useAllFacilities() {
    return useQuery('facilitiesAdmin', fetchAllFacilities, { refetchOnWindowFocus: false, staleTime: 60 * 5 * 1000 });
}
export function useAllFacilityTypes() {
    return useQuery('facilityTypes', fetchAllFacilityTypes, { refetchOnWindowFocus: false, staleTime: 60 * 5 * 1000 });
}
