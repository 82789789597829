import * as React from 'react';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useCentroStore } from '../store';
import { Link, useNavigate } from "react-router-dom";
import { SetupCurrentIncidentReport } from './Helpers/HelperFunctions';
import { useCategories, useReports } from '../store/queries/incidentReportQueries';
import { useFacilities } from '../store/queries/facilityQueries';
import { Stack } from '@mui/material';
import { useAircrafts } from '../store/queries/aircraftQueries';


export default function DataTable() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
    const setIsFromHome = useCentroStore((state) => state.setFromHome);
    //const userFacilityId = useCentroStore((state) => state.userFacilityId);
    const userId = useCentroStore((state) => state.userId);
    const activeRole = useCentroStore((state) => state.activeRole);
    const currentFacility = useCentroStore((state) => state.currentFacility);
    const setcurrentincidentreport = useCentroStore((state) => state.setcurrentincidentreport);
    const [reportId, setReportId] = React.useState();
    const { reports, reportsByUserFacility, aircraftsFromDb } = useReports(currentFacility.facilityId, reportId,
    { params: { facId: currentFacility.facilityId, userId: userId, roleId: activeRole } });
    const [report, setReport] = React.useState();
    const { categories } = useCategories();
    //const { facilityPositions } = useFacilityPositions();
    const { facilities } = useFacilities();

    const removeAllAircrafts = useCentroStore((state) => state.removeAllAircrats);

    //const { aircraftsFromDb } = useAircrafts(reportId);

    const setCurrentAircraftOne = useCentroStore((state) => state.setCurrentAircraftOne);
    //const setCurrentAircraftOneOperator = useCentroStore((state) => state.setCurrentAircraftOneOperator);
    const setCurrentAircraftOneType = useCentroStore((state) => state.setCurrentAircraftOneType);
    const setAircraftOneFac = useCentroStore((state) => state.setAircraftOneFac);
    const setAircraftOnePos = useCentroStore((state) => state.setAircraftOnePos);
    const addAircraftOneUnknowns = useCentroStore((state) => state.addAircraftOneUnknowns);
    const addAircraftOneLights = useCentroStore((state) => state.addAircraftOneLights);
    //const setAircraftOneAvoidActionTaken = useCentroStore((state) => state.setAircraftOneAvoidActionTaken);

    const setCurrentAircraftTwo = useCentroStore((state) => state.setCurrentAircraftTwo);
    //const setCurrentAircraftTwoOperator = useCentroStore((state) => state.setCurrentAircraftTwoOperator);
    const setCurrentAircraftTwoType = useCentroStore((state) => state.setCurrentAircraftTwoType);
    const setAircraftTwoFac = useCentroStore((state) => state.setAircraftTwoFac);
    const setAircraftTwoPos = useCentroStore((state) => state.setAircraftTwoPos);
    const addAircraftTwoUnknowns = useCentroStore((state) => state.addAircraftTwoUnknowns);
    const addAircraftTwoLights = useCentroStore((state) => state.addAircraftTwoLights);
    //const setAircraftTwoAvoidActionTaken = useCentroStore((state) => state.setAircraftTwoAvoidActionTaken);

    const setCurrentCategory = useCentroStore((state) => state.setCurrentCategory);
    const setCurrentSubcategory = useCentroStore((state) => state.setCurrentSubcategory);
    const setcurrentincidenttype = useCentroStore((state) => state.setcurrentincidenttype);
    //const setIsSignificantEvent = useCentroStore((state) => state.setIsSignificantEvent);
    //const setIsTrainingInProgress = useCentroStore((state) => state.setIsTrainingInProgress);
    //const setIsEquipmentDeployed = useCentroStore((state) => state.setIsEmergencyEquipmentDeployed);
    //const setIsEvacuationRequired = useCentroStore((state) => state.setIsEvacuationRequired);
    //const setIsEquipmentOutageContribute = useCentroStore((state) => state.setIsEquipmentOutage);
    //const setIsAcasAlert = useCentroStore((state) => state.setIsAcasAlert);
    const setIsControlType = useCentroStore((state) => state.setIsControlType);
    //const setIsInjury = useCentroStore((state) => state.setIsInjury);
    //const setIsMedicalHelp = useCentroStore((state) => state.setIsMedicalHelp);
    //const setIsLawContacted = useCentroStore((state) => state.setIsLawContacted);
    //const setIsPilotViolation = useCentroStore((state) => state.setIsPilotViolation);
    //const setIsBrasherWarning = useCentroStore((state) => state.setIsBrasherWarning);
    const setCurrentPosition = useCentroStore((state) => state.setCurrentPosition);

    //const addDeviations = useCentroStore((state) => state.addDeviation);
    const addAircraftOneWeather = useCentroStore((state) => state.addAircraftOneWeather);
    const addAircraftTwoWeather = useCentroStore((state) => state.addAircraftTwoWeather);

    //const setIncidentReports = useCentroStore((state) => state.setIncidentReports);
    const navigate = useNavigate();

    if (aircraftsFromDb.isSuccess) {
        var incidentReport = SetupCurrentIncidentReport(report, aircraftsFromDb?.data?.data, categories?.data?.data, facilities?.data?.data);

        //console.log(incidentReport.aircraftOneType);
        setCurrentAircraftOne(incidentReport.currentAircraftOne);
        //setCurrentAircraftOneOperator(incidentReport.currentAircraftOneOperator);
        setCurrentAircraftOneType(incidentReport.aircraftOneType);
        setAircraftOneFac(incidentReport.aircraftOneFac);
        setAircraftOnePos(incidentReport.aircraftOnePos);
        addAircraftOneUnknowns(incidentReport.aircraftOneUnknowns);
        addAircraftOneLights(incidentReport.aircraftOneLights);
        //setAircraftOneAvoidActionTaken(incidentReport.aircraftOneAvoidAction);

        setCurrentAircraftTwo(incidentReport.currentAircraftTwo);
        //setCurrentAircraftTwoOperator(incidentReport.currentAircraftTwoOperator);
        setCurrentAircraftTwoType(incidentReport.aircraftTwoType);
        setAircraftTwoFac(incidentReport.aircraftTwoFac);
        setAircraftTwoPos(incidentReport.aircraftTwoPos);
        addAircraftTwoUnknowns(incidentReport.aircraftTwoUnknowns);
        addAircraftTwoLights(incidentReport.aircraftTwoLights);
        //setAircraftTwoAvoidActionTaken(incidentReport.aircraftTwoAvoidAction);

        setCurrentCategory(incidentReport.currentCategory);
        setCurrentSubcategory(incidentReport.currentSubcategory);
        setcurrentincidenttype(incidentReport.currentIncidentType);
        //setIsSignificantEvent(incidentReport.isSignificantEvent);
        //setIsTrainingInProgress(incidentReport.isTrainingInProgress);
        //setIsInjury(incidentReport.isInjury);
        //setIsMedicalHelp(incidentReport.isMedicalHelp);
        //setIsLawContacted(incidentReport.isLawContacted);
        //setIsPilotViolation(incidentReport.isPilotViolation);
        //setIsBrasherWarning(incidentReport.isBrasherWarning);
        setCurrentPosition(incidentReport.currentPosition);
        //setIsEquipmentDeployed(incidentReport.isEmergencyEquipmentDeployed);
        //setIsEvacuationRequired(incidentReport.isEvacuationRequired);
        //setIsEquipmentOutageContribute(incidentReport.isEquipmentOutageContribute);
        //setIsAcasAlert(incidentReport.isAcasAlert);
        setIsControlType(incidentReport.isControlType);


        //addDeviations(incidentReport.deviations);
        addAircraftOneWeather(incidentReport.aircraftOneWeather);
        addAircraftTwoWeather(incidentReport.aircraftTwoWeather);

        var tempAircraftList = [incidentReport.currentAircraftOne, incidentReport.currentAircraftTwo];
        report.aircraftsList = tempAircraftList;
        setcurrentincidentreport(report);
        navigate("/create-new-incident-report");
    }

    const CurrentIncidentReportSetup = async (params) => {
        setIsFromHome(false);
        removeAllAircrafts([]);
        var report = reportsByUserFacility?.data?.data.find(r => r.irId === params.row.irId);
        setReportId(report.irId);
        setReport(report);
    }

    const columns = [
        {
            field: 'reportNumber', headerName: 'Number', flex: 1,
            renderCell: (params) => (

                <Link>
                    {params.value}
                </Link>
            )
        },
        { field: 'reportTypeName', headerName: 'Type', flex: 1 },
        { field: 'reporterName', headerName: 'Reporter', flex: 1 },
        { field: 'reportDatetime', headerName: 'Date (UTC)', flex: 1 ,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
            },
        },
        { field: 'status', headerName: 'Status', flex: 1, },

    ];

    const columnsSpanish = [
        {
            field: 'reportNumber', headerName: 'N\xFAMERO', flex: 1,
            renderCell: (params) => (

                <Link>
                    {params.value}
                </Link>
            )
        },
        { field: 'reportTypeName', headerName: 'TIPO', flex: 1,
            valueGetter: (params) => {
                if (params.value === "Airborne") {
                    return "En Vuelo";
                }
                if (params.value === "Surface") {
                    return "Superficie";
                }
                if (params.value === "CNS") {
                    return "CNS";
                }
                return params.value;
            },
        },
        { field: 'reporterName', headerName: 'REPORTADE DE', flex: 1 },
        { field: 'reportDatetime', headerName: 'FECHA (UTC)', flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
            },
        },
        { field: 'status', headerName: 'ESTADO', flex: 1,
            valueGetter: (params) => {
                if (params.value === "Approved") {
                    return "Aprobado";
                }
                if (params.value === "Submitted") {
                    return "Presentado";
                }
                if (params.value === "Escalated") {
                    return "Escalada";
                }
                return "Borrador";
            },
        },
    ];

    return (
        <div style={{ height: "calc(100vh - 17rem)", width: '100%' }}>
            <DataGrid
                className="ir-table-wrapper"
                rows={reportsByUserFacility?.data?.data === undefined ? [] : reportsByUserFacility?.data?.data}
                getRowId={(row) => row.irId}
                columns={EnglishLanguage ? columns : columnsSpanish}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                    NoRowsOverlay: () => (
                        <Stack>
                        </Stack>
                    ),
                    Toolbar: GridToolbar
                }} 
                onRowClick={CurrentIncidentReportSetup}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                localeText={{
                    noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                    noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                    toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                    toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                    toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                    toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir", 
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            EnglishLanguage ? 
                            `${from}-${to} of ${count}` : 
                            `${from}-${to} de ${count}`, 
                    },
                }}

            />
        </div>
    );
}
