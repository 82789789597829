import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Narrative from "./reporter-information/Narrative";
import ReportedBy from "./reporter-information/ReportedBy";
//import LocalTime from "./general-information/LocalTime";
import ReportedOnDate from "./reporter-information/ReportedOnDate";
//import ReportedLocalTime from "./reporter-information/ReportedLocalTime";
import ReporterName from "./reporter-information/ReporterName";
import ReporterAddress from "./reporter-information/ReporterAddress";
import ReporterNumber from "./reporter-information/ReporterNumber";
import ReportingAc from "./reporter-information/ReportingAc";
import ReportingAcType from "./reporter-information/ReportingAcType";
import ReportingAcDeparture from "./reporter-information/ReportingAcDeparture";
import ReportingAcFirstLanding from "./reporter-information/ReportingAcFirstLanding";
import ReportingAcDestination from "./reporter-information/ReportingAcDestination";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import { useCentroStore } from "../../../store";
import IncidentFacilityDropdown from "./general-information/IncidentFacilityDropdown";
import DateOfOccurrence from "./general-information/DateOfOccurrence";
import IncidentCategoryAirborneDropdown from "./general-information/IncidentCategoryAirborneDropdown";
import IncidentSubCategoryAMANDropdown from "./general-information/IncidentSubCategoryAMANDropdown";
import IncidentSubCategoryMACDropdown from "./general-information/IncidentSubCategoryMACDropdown";
import IncidentSubCategoryADRMDropdown from "./general-information/IncidentSubCategoryADRMDropdown";
import FFUploadButtonGeneral from "./general-information/FFUploadButtonGeneral";
import OperationalFunction from "./cns/OperationalFunction";
import TechnologyCategory from "./cns/TechnologyCategory";
import LocationCNS from "./cns/LocationCNS";
import LongitudeText from "../step-6-location-conflicts/general-informtation/LongitudeText";
import LatitudeText from "../step-6-location-conflicts/general-informtation/LatitudeText";
import MapCoords from "../step-6-location-conflicts/general-informtation/MapCoords";
import MapDisplaySurface from "../step-6-location-conflicts/general-informtation/MapDisplaySurface";

import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
//import { useCategories } from "../../../store/queries/incidentReportQueries";
import { useReports } from "../../../store/queries/incidentReportQueries";
import { useReferenceGuideStore } from "../../../store";
//import React, { useEffect, useState } from "react";
import DoneIcon from '@mui/icons-material/Done';
import TipsComponent from "../../TipsComponent";
import StepsCompleteNotification from "../../StepOneIsCompleteNotification";
import StepTwoIsCompleteNotification from "../../StepTwoIsCompleteNotification";
import { Button, Tooltip } from "@mui/material";
import ReferenceGuide from "../../reference-guide/ReferenceGuide";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function IncidentReportStepTwo() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  const currentCategory = useCentroStore((state) => state.currentCategory);
  //const { subcategories } = useCategories();
  const currentSubcategory = useCentroStore((state) => state.currentSubCategory);
  const areStepsCompleted = (EnglishLanguage ? currentCategory.irCategory1 : currentCategory.irCategoryEs) && currentSubcategory && currentIncidentReport.reportingFacilityId && currentIncidentReport.incidentDatetime && currentIncidentReport.reportDatetime ;   // && currentIncidentReport.reportedBy && currentIncidentReport.reporterName && currentIncidentReport.incidentSummary
  const openModal = useReferenceGuideStore(state => state.openModal);

  const currentIrStatus = currentIncidentReport.status;
  const userFacilityId = useCentroStore((state) => state.userFacilityId);
  const aircrafts = useCentroStore((state) => state.aircrafts);
  const setIrId = useCentroStore((state) => state.setIrId);
  const setreportnumber = useCentroStore((state) => state.setreportnumber);
  const userId = useCentroStore((state) => state.userId);
  const setLoadingIncidentReports = useCentroStore((state) => state.setLoadingIncidentReports);
  const { addReport, updateReport } = useReports(userFacilityId, currentIncidentReport.irId);

  const handleOpenReferenceGuide = () => {
    openModal('Occurrence Category');
  };
  //const currentincidenttype = useCentroStore((state) => state.currentincidenttype);
  //const reportedBy = useCentroStore((state) => state.reportedBy);

    const checkAirborne = () => {

        if (currentCategory !== undefined && currentCategory.categoryAirborne === 1 && currentIncidentReport.reportTypeId === 1) {
            var subcatFound = currentCategory.subcategoryModels.find(s => s.parentCategoryId === currentCategory.irCategoryId);
            if (subcatFound !== undefined) {
                return true;
            }
        }
        return false;
    }
    const checkSurface = () => {
        if (currentCategory !== undefined && currentCategory.categorySurface === 1 && currentIncidentReport.reportTypeId === 3) {
            var subcatFound = currentCategory.subcategoryModels.find(s => s.parentCategoryId === currentCategory.irCategoryId);
            if (subcatFound !== undefined) {
                return true;
            }
        }
        return false; 
    }
    const checkCns = () => {
        if (currentCategory !== undefined && currentCategory.categoryCns === 1 && currentIncidentReport.reportTypeId === 2) {
            var subcatFound = currentCategory.subcategoryModels.find(s => s.parentCategoryId === currentCategory.irCategoryId);
            if (subcatFound !== undefined) {
                return true;
            }
        }
        return false; 
    }
  return ( currentIncidentReport.reportTypeId !== 2 ?
    <>
      <Grid container className="section-wrapper ir-form-wrapper step-three ir-in-step-wrap " xl={12}>
      <Grid container className="table-header-wrap" xl={12}>
<ReferenceGuide />
        <Grid lg={4}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
              {/*<Tooltip title={EnglishLanguage ? "Click to search the reference guide about this topic" : "Haga clic para buscar la gu\xEDa de referencia sobre este tema"} placement="top-start">*/}
                <Typography
                //onClick={handleOpenReferenceGuide}
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                    //cursor: "pointer",

                  }}>
                   
                  {EnglishLanguage ? "Occurrence Category" : "Categor\xEDa de Ocurrencia"}{currentCategory.irCategory1 ? <DoneIcon className="checkbox-valdation-icon"/> : <Tooltip title="Required"><span className="validation">*</span></Tooltip>}
                 
                </Typography>
                {/*</Tooltip>*/}
              </Item>
              
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <IncidentCategoryAirborneDropdown />
              </Item>
            </Grid>
          </Item>
        </Grid>
        {checkAirborne() === true && (
          <Grid>
            <Item elevation={0}>
              <Grid>
                <Item className="conditional-arrow">
                  <KeyboardDoubleArrowRight />
                </Item>
                <Item elevation={0}>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      textAlign: "left",
                      fontSize: "1.2rem!important",
                    }}>
                    {EnglishLanguage ? "Sub-Category" : "Sub-Categoría "} {currentIncidentReport.subcategoryId !== null && (<DoneIcon className="checkbox-valdation-icon"/>)}
                  </Typography>
                </Item>
                <Item
                  elevation={0}
                  sx={{
                    textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                  }}>
                  <IncidentSubCategoryAMANDropdown />
                </Item>
              </Grid>
            </Item>
          </Grid>
        )}
        {/*checkCns() === true && (
          <Grid>
            <Item elevation={0}>
              <Grid>
                <Item className="conditional-arrow">
                  <KeyboardDoubleArrowRight />
                </Item>
                <Item elevation={0}>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      textAlign: "left",
                      fontSize: "1.2rem!important",
                    }}>
                    {EnglishLanguage ? "Sub-Category" : "Sub-Categoría "}{currentIncidentReport.subcategoryId !== null && (<DoneIcon className="checkbox-valdation-icon"/>)}
                  </Typography>
                </Item>
                <Item
                  elevation={0}
                  sx={{
                    textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                  }}>
                  <IncidentSubCategoryADRMDropdown />
                </Item>
              </Grid>
            </Item>
          </Grid>
        )*/}
        {checkSurface() === true && (
          <Grid>
            <Item elevation={0}>
              <Grid>
                <Item className="conditional-arrow">
                  <KeyboardDoubleArrowRight />
                </Item>
                <Item elevation={0}>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      textAlign: "left",
                      fontSize: "1.2rem!important",
                    }}>
                    {EnglishLanguage ? "Sub-Category" : "Sub-Categoría "}{currentIncidentReport.subcategoryId !== null && (<DoneIcon className="checkbox-valdation-icon"/>)}
                  </Typography>
                </Item>
                <Item
                  elevation={0}
                  sx={{
                    textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                  }}>
                  <IncidentSubCategoryAMANDropdown />
                </Item>
              </Grid>
            </Item>
          </Grid>
        )}
</Grid>
        <Grid>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Facility" : "Instalación que Emite Informe"}{currentIncidentReport.reportingFacilityId === 0 && (<Tooltip title="Required"><span className="validation">*</span></Tooltip>)}{currentIncidentReport.reportingFacilityId !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <IncidentFacilityDropdown />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid >
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Occurrence Date & Time (UTC)" : "Fecha del Ocurrencia (UTC)"}{currentIncidentReport.incidentDatetime === "" && (<Tooltip title="Required"><span className="validation">*</span></Tooltip>)}{currentIncidentReport.incidentDatetime !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <DateOfOccurrence />
              </Item>
            </Grid>
          </Item>
        </Grid>

        {/*<Grid>*/}
        {/*  <Item elevation={0}>*/}
        {/*    <Grid>*/}
        {/*      <Item elevation={0} style={{ minWidth: "161px" }}>*/}
        {/*        <Typography*/}
        {/*          sx={{*/}
        {/*            color: "text.secondary",*/}
        {/*            textAlign: "left",*/}
        {/*            fontSize: "1.2rem!important",*/}
        {/*          }}>*/}
        {/*          {EnglishLanguage ? "Occurrence Time (UTC)" : "Hora del Ocurrencia"}{currentIncidentReport.incidentDatetime === "" && (<Tooltip title="Required"><span className="validation">*</span></Tooltip>)}{currentIncidentReport.incidentDatetime !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}*/}
        {/*        </Typography>*/}
        {/*      </Item>*/}
        {/*      <Item*/}
        {/*        elevation={0}*/}
        {/*        sx={{*/}
        {/*          textAlign: "left",*/}
        {/*          paddingTop: "0",*/}
        {/*          marginTop: "-10px",*/}
        {/*        }}>*/}
        {/*        <LocalTime />*/}
        {/*      </Item>*/}
        {/*    </Grid>*/}
        {/*  </Item>*/}
        {/*</Grid>*/}
        <Grid>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Reported Date & Time (UTC)" : "Fecha (UTC)"}{currentIncidentReport.reportDatetime === "" && (<Tooltip title="Required"><span className="validation">*</span></Tooltip>)}{currentIncidentReport.reportDatetime !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <ReportedOnDate />
              </Item>
            </Grid>
          </Item>
        </Grid>

        {/*<Grid>*/}
        {/*  <Item elevation={0}>*/}
        {/*    <Grid>*/}
        {/*      <Item elevation={0}>*/}
        {/*        <Typography*/}
        {/*          sx={{*/}
        {/*            color: "text.secondary",*/}
        {/*            textAlign: "left",*/}
        {/*            fontSize: "1.2rem!important",*/}
        {/*          }}>*/}
        {/*          {EnglishLanguage ? "Reported Time (UTC)" : "Hora del Informe"}{currentIncidentReport.reportDatetime === "" && (<Tooltip title="Required"><span className="validation">*</span></Tooltip>)}{currentIncidentReport.reportDatetime !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}*/}
        {/*        </Typography>*/}
        {/*      </Item>*/}
        {/*      <Item*/}
        {/*        elevation={0}*/}
        {/*        sx={{*/}
        {/*          textAlign: "left",*/}
        {/*          paddingTop: "0",*/}
        {/*          marginTop: "-10px",*/}
        {/*        }}>*/}
        {/*        <ReportedLocalTime />*/}
        {/*      </Item>*/}
        {/*    </Grid>*/}
        {/*  </Item>*/}
        {/*</Grid>*/}
        <Grid container className="table-header-wrap" xl={12}>
        <Grid>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0} style={{ minWidth: "161px" }}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Reported By Job Title" : "Reportado de"}{currentIncidentReport.reportedBy !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <ReportedBy />
              </Item>
            </Grid>
          </Item>
        </Grid>


        {(currentIncidentReport.reportedBy === "Pilot" || currentIncidentReport.reportedBy === "Piloto") && (
          <>
            <Grid>
              <Item elevation={0}>
                <Grid>
                  <Item className="conditional-arrow">
                    <KeyboardDoubleArrowRight />
                  </Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? "A/C Callsign" : "Indicativo de Aeronave"}{currentIncidentReport.reportingAcCallsign !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px"
                    }}>
                    <ReportingAc />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid>
              <Item elevation={0}>
                
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? "A/C Type" : "Tipo de Aeronave"}{currentIncidentReport.reportingAcType !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                        paddingTop: "0",
                        marginTop: "-10px"
                    }}>
                    <ReportingAcType />
                  </Item>
                </Grid>
              </Item>
            </Grid>
            <Grid>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                        whiteSpace: "nowrap",
                      }}>
                      {EnglishLanguage ? "A/C Departure Aerodrome" : "Aeródromo de Partida"}{currentIncidentReport.reportingAcDepartureAerodome !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px"
                    }}>
                    <ReportingAcDeparture />
                  </Item>
                </Grid>
              </Item>
            </Grid>

            {/* <Grid lg={2}>
                          <Item elevation={0}>
                            <Grid>
                              <Item elevation={0}>
                                <Typography
                                  sx={{
                                    color: "text.secondary",
                                    textAlign: "left",
                                    fontSize: "1.2rem!important",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {EnglishLanguage
                                    ? "A/C First Landing"
                                    : "Primer Aeródomo de Aterrizaje de la Aeronave que Reporta"}
                                </Typography>
                              </Item>
                              <Item
                                elevation={0}
                                sx={{
                                  textAlign: "left",
                                  paddingTop: "0",
                                  
                                }}
                              >
                                <ReportingAcFirstLanding />
                              </Item>
                            </Grid>
                          </Item>
                        </Grid> */}
            <Grid>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                        whiteSpace: "nowrap",
                      }}>
                      {EnglishLanguage ? "A/C Destination Aerodrome" : "Aeródromo de Destino"}{currentIncidentReport.reportingAcDestinationAerodrome !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px"
                    }}>
                    <ReportingAcDestination />
                  </Item>
                </Grid>
              </Item>
            </Grid>
          </>
        )}

        {/* <Grid>
                      <Item elevation={0}>
                        <Grid>
                          <Item elevation={0}>
                            <Typography
                              sx={{
                                color: "text.secondary",
                                textAlign: "left",
                                fontSize: "1.2rem!important",
                              }}
                            >
                              {EnglishLanguage
                                ? "Reported by Address"
                                : "Dirección de quien Reporta"}
                            </Typography>
                          </Item>
                          <Item
                            elevation={0}
                            sx={{
                              textAlign: "left",
                              paddingTop: "0",
                              
                            }}
                          >
                            <ReporterAddress />
                          </Item>
                        </Grid>
                      </Item>
                    </Grid> */}

        {/* <Grid>
                      <Item elevation={0}>
                        <Grid>
                          <Item elevation={0} >
                            <Typography
                              sx={{
                                color: "text.secondary",
                                textAlign: "left",
                                fontSize: "1.2rem!important",
                              }}
                            >
                              {EnglishLanguage
                                ? "Reporter #"
                                : "Número de Contacto de quien Reporta"}
                            </Typography>
                          </Item>
                          <Item
                            elevation={0}
                            sx={{
                              textAlign: "left",
                              paddingTop: "0",
                              
                            }}
                          >
                            <ReporterNumber />
                          </Item>
                        </Grid>
                      </Item>
                    </Grid> */}
                    </Grid>
         <Grid>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Reported by Name" : "Nombre"}{currentIncidentReport.reporterName !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <ReporterName />
              </Item>
            </Grid>
          </Item>
        </Grid>

        <Grid>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0} style={{ minWidth: "161px" }}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Narrative/Summary of Occurrence" : "Resumen del Ocurrencia"}{currentIncidentReport.incidentSummary !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <Narrative />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid>
          <Item elevation={0}>
            <Grid display="flex" sx={{ alignItems: "space-between!important" }}>
              <Item
                elevation={0}
                sx={{
                  padding: "0!important",
                  margin: "0!important",
                  position: "relative",
                  top: "-1rem!important"
                }}>
                <FFUploadButtonGeneral />
              </Item>
            </Grid>
          </Item>
        </Grid>
      </Grid>
        {/* Display the content when both conditionals are rendered */}
  {areStepsCompleted && (
    <StepTwoIsCompleteNotification />
  )}
      </>
      :
    <> 
     <Grid container className="section-wrapper ir-form-wrapper step-three ir-in-step-wrap " xl={12}>
        {/* CNS Fields */}
        <Grid>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Facility" : "Instalación que Emite Informe"}{currentIncidentReport.reportingFacilityId === 0 && (<Tooltip title="Required"><span className="validation">*</span></Tooltip>)}{currentIncidentReport.reportingFacilityId !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <IncidentFacilityDropdown />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid >
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Occurrence Date & Time (UTC)" : "Fecha del Ocurrencia (UTC)"}{currentIncidentReport.incidentDatetime === "" && (<Tooltip title="Required"><span className="validation">*</span></Tooltip>)}{currentIncidentReport.incidentDatetime !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <DateOfOccurrence />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Reported Date & Time (UTC)" : "Fecha (UTC)"}{currentIncidentReport.reportDatetime === "" && (<Tooltip title="Required"><span className="validation">*</span></Tooltip>)}{currentIncidentReport.reportDatetime !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <ReportedOnDate />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Reported by Name" : "Nombre"}{currentIncidentReport.reporterName !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <ReporterName />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid lg={4}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
              <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                    //cursor: "pointer",

                  }}>
                  {EnglishLanguage ? "CNS Incident Category" : "Categor\xEDa Incidente CNS"}{currentCategory.irCategory1 ? <DoneIcon className="checkbox-valdation-icon"/> : <Tooltip title="Required"><span className="validation">*</span></Tooltip>}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <IncidentCategoryAirborneDropdown />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid lg={4}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
              <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Technology Category" : "Categor\xEDa Tecnolog\xEDa"}{currentIncidentReport.technologyCategory ? (<DoneIcon className="checkbox-valdation-icon"/>) : ""}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <TechnologyCategory />
              </Item>
            </Grid>
          </Item>
        </Grid>
        {(currentIncidentReport.technologyCategory !== "" &&
          currentIncidentReport.technologyCategory !== "Automatizaci\xF3n" && currentIncidentReport.technologyCategory !== "Automation" &&
          currentIncidentReport.technologyCategory !== "Unknown" && currentIncidentReport.technologyCategory !== "Desconocido") &&
            <Grid lg={4}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                  <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? "Operational Function" : "Funci\xF3n Operativa "}{currentIncidentReport.operationalFunction !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <OperationalFunction />
                  </Item>
                </Grid>
              </Item>
            </Grid>
        }
        <Grid>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Location" : "Ubicaci\xF3n"}{currentIncidentReport.locationCns !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <LocationCNS />
              </Item>
            </Grid>
          </Item>
        </Grid>
        <Grid xs={4}>
            <Item elevation={0}>
                <Grid>
                <Item elevation={0}>
                    <Typography
                    sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                    }}
                    >
                        {EnglishLanguage ? ("Latitude") : ("Latitud")} 
                        {currentIncidentReport.latitude !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                </Item>
                <Item
                    elevation={0}
                    sx={{
                    textAlign: "left",
                    paddingTop: "0",
                    marginTop: "-10px",
                    }}
                >
                    <LatitudeText />
                </Item>
                </Grid>
            </Item>
        </Grid>
        <Grid xs={4}>
            <Item elevation={0}>
                <Grid>
                <Item elevation={0}>
                    <Typography
                    sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                    }}
                    >
                        {EnglishLanguage ? ("Longitude") : ("Longitud")} 
                        {currentIncidentReport.longitude !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                </Item>
                <Item
                    elevation={0}
                    sx={{
                    textAlign: "left",
                    paddingTop: "0",
                    }}
                >
                    <LongitudeText />
                </Item>
                </Grid>
            </Item>
        </Grid>
        <Grid xs={4} sx={{display: "flex", marginLeft: "-.5rem"}}>
            <Item elevation={0}>
            <Grid>
                <Item sx={{ boxShadow: "none!important",}}>
                <MapCoords />
                </Item>
            </Grid>
            </Item>
        </Grid>
        {currentIncidentReport.latitude ?(<Tooltip title={EnglishLanguage ? "This displays the location of the pin on the airport. You can update this location by updating the latitude and longitude or using the Place from Map feature."
        : "Esto muestra la ubicaci\xF3n del pin en el aeropuerto. Puede actualizar esta ubicaci\xF3n actualizando la latitud y la longitud o utilizando la funci\xF3n Lugar Desde el Mapa" }>
        <Grid xs={6} pr={0.5} pl={1} mt={0} className="custom-grid">
            <Item
            sx={{
                background: "transparent!important",
                boxShadow: "none!important",
            }}>
            <MapDisplaySurface />
            </Item>
        </Grid></Tooltip>) : ""  }
     </Grid>
    </>
  );
}
