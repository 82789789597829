import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
'Taxi',
'Takeoff',
'Landing'
];

const optionsSpanish = [
'Taxi',
'Despegue',
'Aterrizaje'
];

export default function AircraftFlightStageSurface() {

    //const setflightstageselect = useCentroStore((state) => state.setflightstageselect);
    const setAircraftOneFlightStage = useCentroStore((state) => state.setAircraftOneFlightStage);
    const aircraft = useCentroStore((state) => state.aircraftOne);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  //const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    //setAge(event.target.value);
    //setflightstageselect(event.target.value);
      setAircraftOneFlightStage(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={aircraft.flightStage}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
              if (selected === undefined || selected === null || selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
          options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          )) 
          :
          optionsSpanish.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}

