import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { Button, Tooltip } from "@mui/material";
import FlightIcon from '@mui/icons-material/Flight';
import CellTowerIcon from '@mui/icons-material/CellTower';
import PlaceIcon from '@mui/icons-material/Place';
import { useCentroStore } from "../../../store";
import StepsCompleteNotification from "../../StepOneIsCompleteNotification";
import StepOneIsCompleteNotification from "../../StepOneIsCompleteNotification";
import DoneIcon from '@mui/icons-material/Done';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

export default function IncidentReportStepOne() {

  const setReportTypeId = useCentroStore((state) => state.setreporttypeid);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
    const currentincidenttype = useCentroStore((state) => state.currentincidenttype);
    const setcurrentincidenttype = useCentroStore((state) => state.setcurrentincidenttype);
    const setsubmitteruserid = useCentroStore((state) => state.setsubmitteruserid);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const [selectedButton, setSelectedButton] = React.useState(null);
    const areStepsCompleted = currentincidenttype;
    const userId = useCentroStore((state) => state.userId);

    function handleClick(incidentType) {
        if (incidentType === "airborne") {
            setcurrentincidenttype(incidentType);
            setReportTypeId(1);
        }
        else if (incidentType === "cns") {
            setcurrentincidenttype(incidentType);
            setReportTypeId(2);
        }
        else {
            setcurrentincidenttype(incidentType);
            setReportTypeId(3);
        }
        setsubmitteruserid(userId);
        setSelectedButton(incidentType);
    }
    function isButtonSelected(incidentType) {
        return selectedButton === incidentType ? "selected" : "";
      }

    return (
    

        <>
            <Grid container spacing={2}  alignContent="stretch" className="ir-in-step-wrap step-one" >
              <Grid container>
              <Grid xs={12} mt={0} px={0}>
                <Item elevation={0} sx={{paddingTop: "0"}}>
                  <Typography
                    m={1}
                    component="h6"
                    sx={{ textAlign: "center", fontSize:"1.5rem!important" }}
                  >
                    {EnglishLanguage ? ("What Type of Occurrence Report? ") : ("¿Qué tipo de informe de ocurrencias?")}{currentincidenttype ? <DoneIcon className="checkbox-valdation-icon"/> : <Tooltip title="Required"><span className="validation">*</span></Tooltip>}
                  </Typography>
                </Item>
              </Grid>
              </Grid>
              <Grid container sx={{height: "calc(100vh - 26rem)"}}>
            <Grid xs={12} sm={4}>
                {currentIncidentReport.reportTypeId === 0 || currentIncidentReport.reportTypeId === 1 ? (
                    <Item
                        xs={12}
                        sm={4}
                        className={`incident-type-btn-bg airborne ${isButtonSelected("airborne")}`}
                        elevation={3}
                    >

                        <Button onClick={() => handleClick("airborne")} sx={{ width: "100%", height: "100%" }}>
                            <Card sx={{ minHeight: "7rem", background: "transparent", }} className="ir-type-wrap">
                                <FlightIcon className="first-step-icons" />
                                <Typography

                                    component="h1"
                                    sx={{ textAlign: "center" }}
                                >
                                    {EnglishLanguage ? ("Airborne") : ("En Vuelo")}
                                </Typography>
                            </Card>
                        </Button>

                    </Item>
                    
                ) : currentIncidentReport.reportTypeId === 2 || currentIncidentReport.reportTypeId === 3 ? (
                        <Item
                            xs={12}
                            sm={4}
                            className={`incident-type-btn-bg airborne ${isButtonSelected("airborne")}`}
                            elevation={3}
                        >

                            <Button onClick={() => handleClick("airborne")} sx={{ width: "100%", height: "100%" }}>
                                <Card sx={{ minHeight: "7rem", background: "transparent", }} className="ir-type-wrap">
                                    <FlightIcon className="first-step-icons" />
                                    <Typography

                                        component="h1"
                                        sx={{ textAlign: "center" }}
                                    >
                                        {EnglishLanguage ? ("Airborne") : ("En Vuelo")}
                                    </Typography>
                                </Card>
                            </Button>

                        </Item>
                        ):null}







              </Grid>
            <Grid xs={12} sm={4}>
                {currentIncidentReport.reportTypeId === 0 || currentIncidentReport.reportTypeId === 3 ? (
                    <Item
                        xs={12}
                        sm={4}

                        className={`incident-type-btn-bg surface ${isButtonSelected("surface")}`}
                        elevation={3}
                    >
                        <Button onClick={() => handleClick("surface")} sx={{ width: "100%", height: "100%" }}>
                            <Card sx={{ minHeight: "7rem", background: "transparent", }} className="ir-type-wrap">
                                <PlaceIcon className="first-step-icons" />
                                <Typography

                                    component="h1"
                                    sx={{ textAlign: "center" }}
                                >
                                    {EnglishLanguage ? ("Surface") : ("Superficie")}
                                </Typography>
                            </Card>
                        </Button>
                    </Item>
                ) : currentIncidentReport.reportTypeId === 1 || currentIncidentReport.reportTypeId === 2 ? (
                        <Item
                            xs={12}
                            sm={4}

                            className={`incident-type-btn-bg surface ${isButtonSelected("surface")}`}
                            elevation={3}
                        >
                            <Button onClick={() => handleClick("surface")} sx={{ width: "100%", height: "100%" }}>
                                <Card sx={{ minHeight: "7rem", background: "transparent", }} className="ir-type-wrap">
                                    <PlaceIcon className="first-step-icons" />
                                    <Typography

                                        component="h1"
                                        sx={{ textAlign: "center" }}
                                    >
                                        {EnglishLanguage ? ("Surface") : ("Superficie")}
                                    </Typography>
                                </Card>
                            </Button>
                        </Item>
                        ):null}
              {/*<Item */}
              {/*    xs={12} */}
              {/*    sm={6} */}
                  
              {/*    className="incident-type-btn-bg surface"*/}
              {/*    elevation={3}*/}
              {/*  >*/}
              {/*      <Button onClick={() => handleClick("surface")} sx={{ width: "100%", height: "100%" }} disabled={currentIncidentReport.reportNumber !== "" && currentIncidentReport.reportTypeId === 1 || currentIncidentReport.reportTypeId === 2 ? true : false }>*/}
              {/*    <Card sx={{minHeight: "7rem", background: "transparent",}} className="ir-type-wrap">*/}
              {/*    <PlaceIcon className="first-step-icons"/>*/}
              {/*      <Typography*/}
            
              {/*      component="h1"*/}
              {/*      sx={{ textAlign: "center" }}*/}
              {/*    >*/}
              {/*      {EnglishLanguage ? ("Surface") : ("Superficie")}*/}
              {/*    </Typography>*/}
              {/*    </Card>*/}
              {/*    </Button>*/}
              {/*  </Item>*/}
              </Grid>
                    <Grid xs={12} sm={4}>
                {currentIncidentReport.reportTypeId === 0 || currentIncidentReport.reportTypeId === 2 ? (
              <Item 
                  xs={12} 
                  sm={4} 
                  
                  className={`incident-type-btn-bg cns ${isButtonSelected("cns")}`}
                  elevation={3}
                >
                    <Button onClick={() => handleClick("cns")} fullWidth sx={{width: "100%", height: "100%"}}>
                  <Card sx={{minHeight: "7rem", background: "transparent",}} className="ir-type-wrap">
                  <CellTowerIcon className="first-step-icons"/>  
                    <Typography
        
                    component="h1"
                    sx={{ textAlign: "center" }}
                  >
                    CNS
                  </Typography>
                  </Card>
                  </Button>
                            </Item>
                ) : currentIncidentReport.reportTypeId === 1 || currentIncidentReport.reportTypeId === 3 ? (
                <Item 
                  xs={12} 
                  sm={4} 
                  
                  className={`incident-type-btn-bg cns ${isButtonSelected("cns")}`}
                  elevation={3}
                >
                    <Button onClick={() => handleClick("cns")} fullWidth sx={{width: "100%", height: "100%"}}>
                  <Card sx={{minHeight: "7rem", background: "transparent",}} className="ir-type-wrap">
                  <CellTowerIcon className="first-step-icons"/>  
                    <Typography
        
                    component="h1"
                    sx={{ textAlign: "center" }}
                  >
                    CNS
                  </Typography>
                  </Card>
                  </Button>
                            </Item>
                        ) : null}
              </Grid>
              </Grid>
            </Grid>
            {areStepsCompleted && (
              <StepOneIsCompleteNotification />
              )}
              </>
    );
};
