import React from 'react';
import { Grid, Modal, Box, Typography, TextField, IconButton } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import {useReferenceGuideStore} from '../../store';
import { Close as CloseIcon } from '@mui/icons-material';
import { useEffect } from 'react';
import CentroLogo from "../../assets/images/centro-logo_whitegold-menu.png";

const topics = [
    'Airborne', 'Surface', 'CNS', 'Occurrence Category', 'Facility',
    'Occurrence Date', 'Occurrence Time', 'Reported Date', 'Reported Time',
    'Reported by Job Title', 'Reported by Name', 'Narrative', 'Upload',
    'Significant Event', 'Training in Progress', 'Emergency Equipment Deployed',
    'Evacuation Required', 'Equipment Outage', 'Airspace', 'Violation'
  ].sort().map(topic => ({ label: topic, value: topic }));
  
  const explanations = {
    'Airborne': 'Airborne explanation...',
    'Surface': 'Surface explanation...',
    'Occurrence Category': 'Occurrence Category explanation...',
    // Add explanations for the other topics
  };
  
  const ReferenceGuide = () => {
    const isModalOpen = useReferenceGuideStore(state => state.isModalOpen);
    const closeModal = useReferenceGuideStore(state => state.closeModal);
    const initialTopic = useReferenceGuideStore(state => state.initialTopic);
    const [selectedTopic, setSelectedTopic] = React.useState(null);
  
    const handleTopicChange = (_, selectedOption) => {
      setSelectedTopic(selectedOption);
    };
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        closeModal();
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [closeModal]);

    React.useEffect(() => {
      if (initialTopic) {
        setSelectedTopic(topics.find(topic => topic.value === initialTopic));
      }
    }, [initialTopic]);
    return (
      <Modal open={isModalOpen} onClose={closeModal} id="reference-modal">
        <Box sx={{ width: '90%', maxWidth: 800, margin: 'auto', mt: 5, p: 2 }} className="reference-modal-wrap">
          <Grid container spacing={4} direction="column"  m={0} sx={{width: "auto"}}>
            <Grid item xs={12} className="reference-modal-header" sx={{padding: "0.5rem!important", margin: "0!important"}}>
            <Grid container display="flex" justifyContent="flex-start" alignItems="center" >
              <Grid item>
              <img src={CentroLogo} className="logo" alt="logo" />
              </Grid>
              <Grid item>
              <Typography variant="h5" sx={{color: "#fffdfa"}} className="reference-guide-header-text">Reference Guide</Typography>
              </Grid>
            </Grid>
             
              <IconButton
              edge="end"
              color="inherit"
              onClick={closeModal}
              sx={{ float: 'right', margin: 0, padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
            </Grid>
            <Grid item xs={12} sx={{padding: "0!important", margin: "0!important"}}>
            <Box mt={2}>
                <Autocomplete
                id="size-small-standard"
                size="small"
                variant="standard"
                  options={topics}
                  onChange={handleTopicChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={selectedTopic ? selectedTopic.label : "Search topics"}
                      variant="standard"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} mt={2} sx={{padding: "0!important"}}>
              <Typography variant="h5" mb={2}>Explanation</Typography>
              {selectedTopic && (
                <Typography>
                  {explanations[selectedTopic.label]}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };
  
  export default ReferenceGuide;
