import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useCentroStore } from "../store";



function Settings() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  return (
    <>
          <Grid container spacing={3}>
          <Typography variant = "h4" mt={15}>Settings Page Placeholder </Typography>
        </Grid>
        </>
  );
}

export default Settings;