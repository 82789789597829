import { useMutation, useQuery } from 'react-query';
import { sendPasswordResetMail, sendPasswordChangeConfirmation } from '../api/apiEndpoints/apiEndpoints';


export const usePasswordReset = () => {
    const mutation = useMutation(sendPasswordResetMail);
    return mutation;
}

export const usePasswordChange = () => {
    const mutation = useMutation(sendPasswordChangeConfirmation);
    return mutation;
}
