import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { useCentroStore } from "../../../../store";
import DoneIcon from '@mui/icons-material/Done';

import VehicleID from "./VehicleID";
import VehicleDriverName from "./VehicleDriverName";
import VehicleDriverContactInformation from "./VehicleDriverContactInformation";
import VehicleDriverType from "./VehicleDriverType";
import VehicleDriverCompany from "./VehicleDriverCompany";
import ATCPosition from "./ATCPosition";
import OtherATCPosition from "./OtherATCPosition";
import ATCCommunicatingWithVehicle from "./ATCCommunicatingWithVehicle";
import { useEffect } from "react";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

export default function Vehicle() {

  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  const setvehicleatcposition = useCentroStore((state) => state.setvehicleatcposition);
  const setvehicleotheratcposition = useCentroStore((state) => state.setvehicleotheratcposition);
  useEffect(() => {
    if (currentIncidentReport.vehicleAtcCommunicating === 2) {
      setvehicleatcposition(""); // Reset the state to its default value
      setvehicleotheratcposition(""); // Reset the state to its default value
    }
  }, [currentIncidentReport.vehicleAtcCommunicating]);

  return (
    <>
      <Grid container py={0} spacing={0} mt={0} className="ir-form-wrapper">
        <Grid xs={12}>
          <Item elevation={0} sx={{ padding: "0" }}>
            <Grid xs={12}>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ background: "#00000033", borderRadius: ".5rem", padding: "10px 10px 15px" }}
                className="ir-form-wrapper"
                xs={12}
              >
                <Grid xs={12} mt={0} pb={0} className="table-header-wrap">
                  <Item elevation={0}>
                    <DirectionsBusIcon />
                  </Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        paddingBottom: ".25rem",
                        color: "#f7b01a!important",
                        cursor: "pointer",
                      }}
                      variant="h6"
                    >
                      {EnglishLanguage ? ("Vehicle 1 (edit)") : ("Veh\xEDculo 1 (editar)")}
                    </Typography>
                  </Item>
                </Grid>
                <Grid
            container
            justifyContent="space-between"
            spacing={0}
            sx={{ marginTop: "1rem" }}
            className="ir-form-wrapper"
          >

            {/* General Information Header */}

            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Vehicle ID") : ("ID del veh\xEDculo")}
                      {currentIncidentReport.vehicleId !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                     <VehicleID />
                  </Item>
                </Grid>
              </Item>
            </Grid>

            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Vehicle Driver Name") : ("Nombre de Conductor")}
                      {currentIncidentReport.vehicleDriverName !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <VehicleDriverName />
                  </Item>
                </Grid>
              </Item>
            </Grid>

            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Vehicle Driver Contact
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <VehicleDriverContactInformation />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}









            <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      {EnglishLanguage ? ("Vehicle Type") : ("tipo de veh\xEDculo")}
                      {currentIncidentReport.vehicleType !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <VehicleDriverType />
                  </Item>
                </Grid>
              </Item>
            </Grid>




          {/* Dropdown shows yes or no */}
          <Grid container className="table-header-wrap" xl={12}>
            <Grid>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                     {EnglishLanguage ? ("ATC Communicating With Vehicle") : ("Comunicaci\xF3n ATC con Veh\xEDculo")}
                     {currentIncidentReport.vehicleAtcCommunicating !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <ATCCommunicatingWithVehicle />
                  </Item>
                </Grid>
              </Item>
            </Grid>


                      {/* Only display if yes in atccommunication with vehicle */}
             {currentIncidentReport.vehicleAtcCommunicating === 1 && (
            <Grid>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                     {EnglishLanguage ? ("ATC Position") : ("POSICI\xF3N DE ATC")}
                     {currentIncidentReport.vehicleAtcPosition !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <ATCPosition />
                  </Item>
                </Grid>
              </Item>
            </Grid>
             )}


              {/* Only display if yes in atcposition is other */}
              {(currentIncidentReport.vehicleAtcPosition === "Other" || 
                currentIncidentReport.vehicleAtcPosition === "Otro") && currentIncidentReport.vehicleAtcCommunicating === 1 &&(
                <Grid>
                <Item elevation={0}>
                  <Grid>
                    <Item elevation={0}>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          textAlign: "left",
                          fontSize: "1.2rem!important",
                        }}
                      >
                       {EnglishLanguage ? ("Other ATC Position") : ("Otra POSICI\xF3N DE ATC")}
                       {currentIncidentReport.vehicleOtherAtcPosition !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                      </Typography>
                    </Item>
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                        paddingTop: "0",
                        marginTop: "-10px",
                      }}
                    >
                      <OtherATCPosition />
                    </Item>
                  </Grid>
                </Item>
              </Grid>
              )}
            </Grid>

            {/* <Grid lg={12} xs={12} xl={3}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}
                    >
                      Vehicle Driver Company
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}
                  >
                    <VehicleDriverCompany />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
          </Grid>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
