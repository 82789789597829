// import * as React from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import {useCentroStore} from '../store';
// import { Link } from 'react-router-dom';




// export default function IncidentReportsTables() {

//   const incidentreports = useCentroStore((state) => state.incidentreports);
//   const setcurrentincidentreport = useCentroStore((state) => state.setcurrentincidentreport);

//   return (
//     <TableContainer component={Paper} className="ir-table">
//       <Table sx={{ minWidth: 650, padding: "2rem" }} aria-label="simple table">
//         <TableHead>
//           <TableRow>
//             <TableCell sx={{  fontFamily: "'Montserrat'!important", color: "#f7b01a!important", textTransform: "uppercase", textAlign: "left"}}>Report Number</TableCell>
//             <TableCell align="right" sx={{fontFamily: "'Montserrat'!important", color: "#f7b01a!important", textTransform: "uppercase"}}>Type</TableCell>
//             <TableCell align="right" sx={{fontFamily: "'Montserrat'!important", color: "#f7b01a!important", textTransform: "uppercase"}}>Category</TableCell>
//             <TableCell align="right" sx={{fontFamily: "'Montserrat'!important", color: "#f7b01a!important", textTransform: "uppercase"}}>Reporter</TableCell>
//             <TableCell align="right" sx={{fontFamily: "'Montserrat'!important", color: "#f7b01a!important", textTransform: "uppercase"}}>Date</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {incidentreports.map((item) => (
            
//             <TableRow
//               key={item.id}
//               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell component="th" scope="row"  sx={{color: "#FFF!important", textTransform: "uppercase"}}>
//                 <Link
//                 onClick={() => {
//                   setcurrentincidentreport(item.id); 
//                 }}
//                 to={{
//                   pathname:"/create-new-incident-report",
//                 }} >
//                 {item.id}
//                 </Link>
//               </TableCell>
//               <TableCell align="right" sx={{color: "#FFF!important"}}>{item.type}</TableCell>
//               <TableCell align="right" sx={{color: "#FFF!important"}}>{item.category}</TableCell>
//               <TableCell align="right" sx={{color: "#FFF!important"}}>{item.reporter}</TableCell>
//               <TableCell align="right" sx={{color: "#FFF!important"}}>{item.date}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }





import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useCentroStore } from '../../store';
import { Link, useNavigate } from 'react-router-dom';
import { useToDoItems } from '../../store/queries/toDoItemsQueries';
import { useCategories, useReports } from '../../store/queries/incidentReportQueries';
import { SetupCurrentIncidentReport } from '../Helpers/HelperFunctions';
import { useFacilities, useFacilityPositions } from '../../store/queries/facilityQueries';
import { useUser } from "../../store/queries/userQueries";
import { useAircrafts } from '../../store/queries/aircraftQueries';
import { useEffect } from 'react';





export default function ToDoList() {

  const userId = useCentroStore((state) => state.userId);
  const { userData } = useUser(userId);
  const { toDoList } = useToDoItems(userData?.data?.data.userId);
  //const activeRole = useCentroStore((state) => state.activeRole);
  //const todolist = useCentroStore((state) => state.todolist);
  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
  //useEffect(()=>{setuserloggedin("Yes")})
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 

  //For CurrentIncidentReportSetup
    const setIsFromHome = useCentroStore((state) => state.setFromHome);
    //const userFacilityId = useCentroStore((state) => state.userFacilityId);
    const currentFacility = useCentroStore((state) => state.currentFacility);
    const setcurrentincidentreport = useCentroStore((state) => state.setcurrentincidentreport);
    const [reportId, setReportId] = React.useState();
    const [report, setReport] = React.useState();
    const { reports, aircraftsFromDb } = useReports(currentFacility.facilityId, reportId);
    const { categories, subcategories } = useCategories();
    const { facilityPositions } = useFacilityPositions();
    const { facilities } = useFacilities();

    const removeAllAircrafts = useCentroStore((state) => state.removeAllAircrats);

    //const { aircraftsFromDb } = useAircrafts(reportId);

    const setCurrentAircraftOne = useCentroStore((state) => state.setCurrentAircraftOne);
    //const setCurrentAircraftOneOperator = useCentroStore((state) => state.setCurrentAircraftOneOperator);
    const setCurrentAircraftOneType = useCentroStore((state) => state.setCurrentAircraftOneType);
    const setAircraftOneFac = useCentroStore((state) => state.setAircraftOneFac);
    const setAircraftOnePos = useCentroStore((state) => state.setAircraftOnePos);
    const addAircraftOneUnknowns = useCentroStore((state) => state.addAircraftOneUnknowns);
    const addAircraftOneLights = useCentroStore((state) => state.addAircraftOneLights);
    //const setAircraftOneAvoidActionTaken = useCentroStore((state) => state.setAircraftOneAvoidActionTaken);

    const setCurrentAircraftTwo = useCentroStore((state) => state.setCurrentAircraftTwo);
    //const setCurrentAircraftTwoOperator = useCentroStore((state) => state.setCurrentAircraftTwoOperator);
    const setCurrentAircraftTwoType = useCentroStore((state) => state.setCurrentAircraftTwoType);
    const setAircraftTwoFac = useCentroStore((state) => state.setAircraftTwoFac);
    const setAircraftTwoPos = useCentroStore((state) => state.setAircraftTwoPos);
    const addAircraftTwoUnknowns = useCentroStore((state) => state.addAircraftTwoUnknowns);
    const addAircraftTwoLights = useCentroStore((state) => state.addAircraftTwoLights);
    //const setAircraftTwoAvoidActionTaken = useCentroStore((state) => state.setAircraftTwoAvoidActionTaken);

    const setCurrentCategory = useCentroStore((state) => state.setCurrentCategory);
    const setCurrentSubcategory = useCentroStore((state) => state.setCurrentSubcategory);
    const setcurrentincidenttype = useCentroStore((state) => state.setcurrentincidenttype);
    //const setIsSignificantEvent = useCentroStore((state) => state.setIsSignificantEvent);
    //const setIsTrainingInProgress = useCentroStore((state) => state.setIsTrainingInProgress);
    //const setIsEquipmentDeployed = useCentroStore((state) => state.setIsEmergencyEquipmentDeployed);
    //const setIsEvacuationRequired = useCentroStore((state) => state.setIsEvacuationRequired);
    //const setIsEquipmentOutageContribute = useCentroStore((state) => state.setIsEquipmentOutage);
    //const setIsAcasAlert = useCentroStore((state) => state.setIsAcasAlert);
    const setIsControlType = useCentroStore((state) => state.setIsControlType);
    //const setIsInjury = useCentroStore((state) => state.setIsInjury);
    //const setIsMedicalHelp = useCentroStore((state) => state.setIsMedicalHelp);
    //const setIsLawContacted = useCentroStore((state) => state.setIsLawContacted);
    //const setIsPilotViolation = useCentroStore((state) => state.setIsPilotViolation);
    //const setIsBrasherWarning = useCentroStore((state) => state.setIsBrasherWarning);
    const setCurrentPosition = useCentroStore((state) => state.setCurrentPosition);

    //const addDeviations = useCentroStore((state) => state.addDeviation);
    const addAircraftOneWeather = useCentroStore((state) => state.addAircraftOneWeather);
    const addAircraftTwoWeather = useCentroStore((state) => state.addAircraftTwoWeather);

    //const setIncidentReports = useCentroStore((state) => state.setIncidentReports);
    const navigate = useNavigate();

  if (aircraftsFromDb.isSuccess) {
        // var incidentReport = SetupCurrentIncidentReport(report, aircraftsFromDb?.data?.data, categories?.data?.data, subcategories?.data?.data, facilityPositions?.data?.data);
        var incidentReport = SetupCurrentIncidentReport(report, aircraftsFromDb?.data?.data, categories?.data?.data, facilities?.data?.data);

        //console.log(incidentReport.aircraftOneType);
        setCurrentAircraftOne(incidentReport.currentAircraftOne);
        //setCurrentAircraftOneOperator(incidentReport.currentAircraftOneOperator);
        setCurrentAircraftOneType(incidentReport.aircraftOneType);
        setAircraftOneFac(incidentReport.aircraftOneFac);
        setAircraftOnePos(incidentReport.aircraftOnePos);
        addAircraftOneUnknowns(incidentReport.aircraftOneUnknowns);
        addAircraftOneLights(incidentReport.aircraftOneLights);
        //setAircraftOneAvoidActionTaken(incidentReport.aircraftOneAvoidAction);

        setCurrentAircraftTwo(incidentReport.currentAircraftTwo);
        //setCurrentAircraftTwoOperator(incidentReport.currentAircraftTwoOperator);
        setCurrentAircraftTwoType(incidentReport.aircraftTwoType);
        setAircraftTwoFac(incidentReport.aircraftTwoFac);
        setAircraftTwoPos(incidentReport.aircraftTwoPos);
        addAircraftTwoUnknowns(incidentReport.aircraftTwoUnknowns);
        addAircraftTwoLights(incidentReport.aircraftTwoLights);
       // setAircraftTwoAvoidActionTaken(incidentReport.aircraftTwoAvoidAction);

        setCurrentCategory(incidentReport.currentCategory);
        setCurrentSubcategory(incidentReport.currentSubcategory);
        setcurrentincidenttype(incidentReport.currentIncidentType);
        //setIsSignificantEvent(incidentReport.isSignificantEvent);
        //setIsTrainingInProgress(incidentReport.isTrainingInProgress);
        //setIsInjury(incidentReport.isInjury);
        //setIsMedicalHelp(incidentReport.isMedicalHelp);
        //setIsLawContacted(incidentReport.isLawContacted);
        //setIsPilotViolation(incidentReport.isPilotViolation);
        //setIsBrasherWarning(incidentReport.isBrasherWarning);
        setCurrentPosition(incidentReport.currentPosition);
        //setIsEquipmentDeployed(incidentReport.isEmergencyEquipmentDeployed);
        //setIsEvacuationRequired(incidentReport.isEvacuationRequired);
        //setIsEquipmentOutageContribute(incidentReport.isEquipmentOutageContribute);
        //setIsAcasAlert(incidentReport.isAcasAlert);
        setIsControlType(incidentReport.isControlType);


        //addDeviations(incidentReport.deviations);
        addAircraftOneWeather(incidentReport.aircraftOneWeather);
        addAircraftTwoWeather(incidentReport.aircraftTwoWeather);

        var tempAircraftList = [incidentReport.currentAircraftOne, incidentReport.currentAircraftTwo];
        report.aircraftsList = tempAircraftList;
        setcurrentincidentreport(report);
        navigate("/create-new-incident-report");
  }

  const ToDoItemSetup = async (params) => {
      // === 1 for Occurrence Report
      if (params.row.itemType === 1) {
          setIsFromHome(false);
          removeAllAircrafts([]);
          var report = reports?.data?.data.find(r => r.irId === params.row.itemId);
          setReportId(report.irId);
          setReport(report);
      }
      // === 2 for Safety Assessment TBA
      else {

      }
  }

  const columns = [
    { field: 'item', headerName: 'Item', flex: 1,
      renderCell: (params) => (
         <Link>
          {params.value}
         </Link>
      ) }, 
    { field: 'details', headerName: 'Details',  flex: 1 },
    { field: 'dateCreated', headerName: 'Date (UTC)', flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
        },
      },

      // valueGetter: (params) =>
      //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  ];

  const columnsSpanish = [
    { field: 'item', headerName: 'Asunto', flex: 1,
      renderCell: (params) => (
         <Link>
          {params.value}
         </Link>
      ) }, 
    { field: 'details', headerName: 'DETALLES',  flex: 1.5,
        valueGetter: (params) => {
            if (params.value === "Process Occurrence Report") {
                return "Informe de Ocurrencia de Proceso";
            }
            else if (params.value === "Finish Draft Occurrence Report") {
                return "Finalizar borrador de Informe de Ocurrencia";
            }
            else {
                return params;
            }
        },
    },
    { field: 'dateCreated', headerName: 'FECHA (UTC)', flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
        },
      },
  ];

  return (
    <div className="data-grid-container">
      <DataGrid
       //className={`ir-table-wrapper todo-fix ${activeRole === "ATCO" || activeRole === "CNS Tech" ||activeRole === "Engineer" ? " full-height" : ""}`}
        className={`ir-table-wrapper todo-fix`}
        rows={toDoList?.data?.data === undefined ? [] : toDoList?.data?.data.filter(t => t.facilityId === currentFacility.facilityId)}
        getRowId={(row) => row.itemId}
        columns={EnglishLanguage ? columns : columnsSpanish}
        pageSize={25}
        //checkboxSelection
        rowsPerPageOptions={[25]}
        components={{ Toolbar: GridToolbar }} 
        onRowClick={ToDoItemSetup}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        localeText={{
                    noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                    noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                    toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                    toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                    toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                    toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir", 
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            EnglishLanguage ? 
                            `${from}-${to} of ${count}` : 
                            `${from}-${to} de ${count}`, 
                    },
        }}
      />
    </div>
  );
}
