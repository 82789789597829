// import * as React from 'react';
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import { useCentroStore } from '../../../../store';
// import { debounce } from 'lodash';
// import { useDebouncedAircraftTypeSearch } from '../../../../store/queries/aircraftQueries';

// export default function AircraftTypeDynamicDropdown() {

//     const [model, setModel] = React.useState("");
//     const setAircraftOnetype = useCentroStore((state) => state.setAircraftOneType);
//     const currentAircraftOneType = useCentroStore((state) => state.currentAircraftOneType);
//     const setCurrentAircraftOneType = useCentroStore((state) => state.setCurrentAircraftOneType);
//     const { types } = useDebouncedAircraftTypeSearch(model);

//     const handleChange = (event) => {
//         if (event.target.value !== null || event.target.value !== '') {
//             var aircraftType = types?.data?.data.find(t => t.model === event.target.value);
//             if (aircraftType !== undefined) {
//                 setCurrentAircraftOneType(aircraftType);
//                 setAircraftOnetype(aircraftType.id);
//             }
//         }
//     };

//     async function searchTypes(search) {
//         if (search !== "") {
//             setModel(search);
//         }
//     }

//     const debouncedSearch = React.useRef(
//         debounce(async (search) => {
//             searchTypes(search);
//         }, 1000)).current;

//     React.useEffect(() => {
//         return () => {
//             debouncedSearch.cancel();
//         };
//     }, [debouncedSearch]);

//     function handleSetSearch(e) {
//         if ((e !== null && e.type !== 'blur' && e.type !== 'click')) {
//             debouncedSearch(e.target.value);
//         }
//     }

//     return (
//         <Autocomplete
//             id="size-small-standard"
//             size="small"
//             options={types?.data?.data === undefined || types.isError && types.failureCount === 3 ? [] : types?.data?.data}
//             onInputChange={handleSetSearch}
//             onBlur={handleChange}
//             filterOptions={(x) => x}
//             limitTags={10}
//             loading={types.isLoading}
//             value={currentAircraftOneType === undefined || currentAircraftOneType === null ? {} : currentAircraftOneType}
//             renderOption={(props, option) => {
//                 return (
//                     <li {...props} key={option.id}>
//                         {option.model}
//                     </li>
//                 );
//             }}
//             getOptionLabel={(option) => option.model || ""}
            
//             renderInput={(params) => (
//                 <TextField
//                     {...params}
//                     variant="standard"
//                     className="dynamic-dropdown-input-text"
//                     label="Type/Select"
//                     sx={{ opacity: '1', color: "#ffffff", fontStyle: "italic", textTransform: "capitalize" }}

//                 />
//             )}
//         />
//     );
// }




// import * as React from 'react';
// import TextField from '@mui/material/TextField';
// import { useCentroStore } from '../../../../store';

// export default function AircraftTypeDynamicDropdown() {

//     const setAircraftOnetype = useCentroStore((state) => state.setAircraftOneType);
//     const currentAircraftOneType = useCentroStore((state) => state.currentAircraftOneType);
//     const setCurrentAircraftOneType = useCentroStore((state) => state.setCurrentAircraftOneType);

//     const handleChange = (event) => {
//         const value = event.target.value;
//         if (value !== null || value !== '') {
//             setCurrentAircraftOneType({ model: value });
//             setAircraftOnetype(value);
//         }
//     };

//     function handleSetSearch(e) {
//         if (e !== null && e.type !== 'blur' && e.type !== 'click') {
//             handleChange(e);
//         }
//     }

//     return (
//         <TextField
//             id="size-small-standard"
//             size="small"
//             variant="standard"
//             className="dynamic-dropdown-input-text"
//             label="Type/Select"
//             sx={{ opacity: '1', color: "#ffffff", fontStyle: "italic", textTransform: "capitalize" }}
//             value={currentAircraftOneType === undefined || currentAircraftOneType === null ? "" : currentAircraftOneType.model}
//             onChange={handleSetSearch}
//             onBlur={handleChange}
//         />
//     );
// }


import React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

function AircraftTypeDynamicDropdown({ unknown }) {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
  const setAircraftOnetype = useCentroStore((state) => state.setAircraftOneType);
  const currentAircraftOneType = useCentroStore((state) => state.currentAircraftOneType);
  const aircraft = useCentroStore((state) => state.aircraftOne);
  const setCurrentAircraftOneType = useCentroStore((state) => state.setCurrentAircraftOneType);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value !== null || value !== '') {
      setCurrentAircraftOneType({ model: value });
      setAircraftOnetype(value);
    }
  };

  function handleSetSearch(e) {
    if (e !== null && e.type !== 'blur' && e.type !== 'click') {
      handleChange(e);
    }
  }

  React.useEffect(() => {
    if (unknown) {
      setCurrentAircraftOneType({ model: 'Unknown' });
      setAircraftOnetype('Unknown');
      var tempAircraftList = [];
      tempAircraftList[0] = aircraft;
      setaircraftsList(tempAircraftList);
    }
  }, [unknown, setCurrentAircraftOneType, setAircraftOnetype]);

  return (
    <TextField
      id="standard-multiline-number"
      variant="standard"
      // className="dynamic-dropdown-input-text"
      fullWidth
          rows={1}
      label={EnglishLanguage ? "Type/Select" : "Tipo/Seleccione"}
      sx={{ opacity: '1', color: '#ffffff', fontStyle: 'italic', textTransform: 'capitalize' }}
      value={unknown ? 'Unknown' : aircraft.type}
      defaultValue={aircraft.type}
      onChange={handleSetSearch}
      onBlur={handleChange}
      disabled={unknown}
    />
  );
}

export default AircraftTypeDynamicDropdown;
