import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCentroStore } from "../../store";
import { useRefreshToken } from "../../store/queries/userQueries";

function useInterval(callbackItem, delay) {

    const savedCallbackItem = useRef();

    useEffect(() => {
        savedCallbackItem.current = callbackItem;
    }, [callbackItem]);

    useEffect(() => {
        function ticks() {
            savedCallbackItem.current();
        }
        if (delay !== null) {
            let id = setInterval(ticks, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export function RefreshToken() {
    const newToken = useRefreshToken();
    const [count, setCount] = useState(0);
    const isAuthenticated = useCentroStore((state) => state.isAuthenticated);
    const setAuth = useCentroStore((state) => state.setAuth);
    const setUserId = useCentroStore((state) => state.setUserId);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const handleLogout = () => {
        try {
            setAuth(false);
            setUserId(0);
            queryClient.clear();
            sessionStorage.clear();
            navigate("/");
        }
        catch (err) {
            console.log(err);
        }
    };

    useInterval(async () => {
        if (count !== 0) {
            //console.log("New Token " + count);
            var response = await newToken.mutateAsync();
            //console.log("Response Status " + response.status);
            if (response.status === 401) {
                setCount(0);
                handleLogout();
            }
        }
        if (isAuthenticated) {
            //console.log("Setting Count " + (count + 1));
            setCount(count + 1);
        }
    }, 5 * 1000 * 60);
    //}, 14 * 1000 * 60);
}