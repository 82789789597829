import { Typography } from "@mui/material";
import React, { useState } from 'react';
import { useClearState } from "../Helpers/HelperFunctions";
import { useCentroStore } from "../../store";
import { Link } from "react-router-dom";
    


export default function StepIsComplete () {
    const { ClearReport } = useClearState();
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

    const handleNewIncidentClick = async () => {
        ClearReport();
    };
return (
   <>
   {EnglishLanguage ? <Typography className="tips-text">Note: You will <b>not</b> be able to change your <b>Occurrenct Report Type</b> after you leave this step. <br /><br /> Please click the <b>Save/Advance</b> button to advance to the next step.</Typography>
    : <Typography className="tips-text">Nota: <b>no</b> podr{"\xE1"} cambiar su <b>tipo de informe de ocurrencia</b> despu{"\xE9"}s de abandonar este paso. <br /><br /> Haga clic en el bot{"\xF3"}n <b>Guardar/Avanzar</b> para avanzar al siguiente paso.</Typography> }
    
              
    </>
)};