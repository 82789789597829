import { useCentroStore } from "../store";

export default function StepTextThree () {
// const currentincidenttype = useCentroStore((state) => state.currentincidenttype);
const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
const currentincidenttype = useCentroStore(
    (state) => state.currentincidenttype
  );
return (
    // <div>{currentincidenttype}</div>
    <div>{currentincidenttype === "surface" ? (
      <div>A/C, Veh, Ped</div>
      
    ) : currentincidenttype === "cns" ? (
      <div>{EnglishLanguage ? ("Aircraft") : ("Aeronave")}</div>
      
    ) : (
      <div>{EnglishLanguage ? ("Aircraft") : ("Aeronave")}</div>
    )}</div>
)};