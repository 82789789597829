import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useCentroStore } from '../../../../store';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, es } from "date-fns/locale";

export default function OutageDurationStart() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setoutageDurationEnd = useCentroStore((state) => state.setoutageDurationEnd);

    return (
        <LocalizationProvider locale={EnglishLanguage ? enUS : es} dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
                fullWidth
                label={EnglishLanguage ? "End DateTime Info" : "Informaci\xF3n de Fecha y Hora de finalizaci\xF3n"}
                sx={{ minWidth: "500px!important" }}
                placeholder={EnglishLanguage ? "MM/DD/YYYY" : "DD/MM/AAAA"}
                ampm={false}
                value={currentIncidentReport.outageDurationEnd}
                onChange={(newValue) => {
                    setoutageDurationEnd(newValue); 
                }}
                minDateTime={currentIncidentReport.outageDurationStart}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box className="date-picker-wrapper" sx={{ display: 'flex', alignItems: 'center', border: 'none' }}>
                        <TextField ref={inputRef} {...inputProps} placeholder={EnglishLanguage ? "MM/DD/YYYY" : "DD/MM/AAAA"} sx={{ width: "100%" }} className="date-padding-fix time-input" />
                        {InputProps?.endAdornment}
                    </Box>
                )}
            />
        </LocalizationProvider>
    );
}