import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom"
import Grid from "@mui/material/Grid";
import TopNavBar from "./TopNavBar";
import FacilityDropdown from "./forms/FacilityDropdown";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useCentroStore } from "../store";
//import { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import HeaderSection from "./header/HeaderSection";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const Technical = (props) => {
  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
  //useEffect(() => {
  //  setuserloggedin("Yes");
  //});
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  return (
    <Container component="main" maxWidth="none" id="technical">
       <HeaderSection className="content-wrap" sectionName="Technical" sectionNameES="Técnica"/>
      <Grid
        className="page-wrap"
        container
        sx={{
          padding: "0",
          margin: "0",

          width: "calc(100vw - 20rem)",
        }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                {EnglishLanguage ? ("Technical") : ("REFERENCIAS TÉCNICAS Y RECURSOS")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            ></Grid>
          </Grid>
          <Grid lg={12} px={2} mt={3}>
            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
              <Typography
                id="modal-modal-title"
                component="h6"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                {EnglishLanguage ? ("Technical References and Resources") : ("")}
              </Typography>
            </Item>
          </Grid>
          <Grid
            lg={12}
            px={2}
            mt={3}
            sx={{
              textAlign: "left",
              height: "calc(100% - 8rem)",
              justifyContent: "flex-start",
            }}
          >
            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
              <List className="technical-list-wrap">
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://store.icao.int/en/annex-19-safety-management"
                    target="_blank"
                  >
                    <ListItemText primary="ICAO Annex 19: Safety Management" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.icao.int/safety/SafetyManagement/Pages/default.aspx"
                    target="_blank"
                  >
                    <ListItemText primary="ICAO Safety Management Resources: Policies, Standards, and Implementation Guidance" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.icao.int/NACC/Documents/Meetings/2021/RASP/NACCRASP-En.pdf"
                    target="_blank"
                  >
                    <ListItemText primary="ICAO Safety Plan: North America, Central America and Caribbean" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.icao.int/safety/Documents/ICAO_SR_2022.pdf"
                    target="_blank"
                  >
                    <ListItemText primary="ICAO 2022 Safety Report" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.icao.int/safety/RunwaySafety/Documents%20and%20Toolkits/GRSAP_Final_Edition01_2017-11-27.pdf"
                    target="_blank"
                  >
                    <ListItemText primary="ICAO Runway Safety Program - Global Runway Safety Action Plan" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.icao.int/WACAF/AFIRAN08_Doc/9734_parta_cons_en.pdf"
                    target="_blank"
                  >
                    <ListItemText primary="ICAO Doc 9734 Safety Oversight Manual - Part A" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.icao.int/safety/Implementation/Library/9734_partb_cons_en.pdf"
                    target="_blank"
                  >
                    <ListItemText primary="ICAO Doc 9734 Safety Oversight Manual - Part B" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.spilve.lv/library/various/Doc%209713.pdf"
                    target="_blank"
                  >
                    <ListItemText primary="ICAO Doc 9713 International Civil Aviation Vocabulary - 3rd Ed" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.faa.gov/about/initiatives/sms"
                    target="_blank"
                  >
                    <ListItemText primary="FAA Safety Management System (SMS)" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.faa.gov/about/initiatives/iasa"
                    target="_blank"
                  >
                    <ListItemText primary="International Aviation Safety Assessment (IASA)" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    href="https://www.faa.gov/about/initiatives/iasa/checklists"
                    target="_blank"
                  >
                    <ListItemText primary="IASA Assessment Checklists" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Technical;
