import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    fetchToDoForUser
} from '../api/apiEndpoints/apiEndpoints';

export function useToDoItems(userId) {
    const queryClient = useQueryClient();

    const toDoList = useQuery(['toDoList', userId], () => fetchToDoForUser(userId), { refetchOnWindowFocus: false, refetchInterval: 60 * 2000, enabled: !!userId });

    return { toDoList };
}