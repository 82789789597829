import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
'Non-Commercial Business Aviation', 
'Aerial Work - Agriculture',
'Aerial Work - Construction',
'Aerial Work - Photography',
'Aerial Work - Surveying',
'Aerial Work - Observation and Patrol',
'Aerial Work - Search and Rescue',
'Aerial Work - Aerial Advertisement',
'Aerial Work - Other Aerial Work',
'Instructional Flying',
'Pleasure Flying',
'Other Flying Aviation',
'Other On Demand',
'Other Non-Scheduled',
];

const optionsSpanish = [
'Aviaci\xF3n Comercial No Comercial',
'Trabajo A\xE9reo - Agricultura',
'Trabajo A\xE9reo - Construcci\xF3n',
'Trabajo A\xE9reo - Fotograf\xEDa',
'Trabajo A\xE9reo - Topograf\xEDa',
'Trabajo A\xE9reo - Observaci\xF3n y Patrullaje',
'Trabajo A\xE9reo - B\xFAsqueda y Rescate',
'Trabajo A\xE9reo - Anuncio A\xE9reo',
'Trabajo A\xE9reo - Otro Trabajo A\xE9reo',
'Vuelo Instructivo',
'Vuelo Recreativo',
'Otra Aviaci\xF3n De Vuelo',
'Otros a Pedido',
'Otros No Programados',
];

export default function FlightSubCategoryDropdown() {

    //const [age, setAge] = React.useState('');
    const setsubcategory = useCentroStore((state) => state.setflightsubcategory);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

  const handleChange = (event) => {
      //setAge(event.target.value);
      //var id = options.indexOf(event.target.value);
      setsubcategory(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentIncidentReport.flightSubcategory}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
          options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          )) 
          :
          optionsSpanish.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}


