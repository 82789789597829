import React from "react";

import GoogleMapReact from "google-map-react";
import MyMarker from "../../../MyMarker";
import { useCentroStore } from "../../../../store";

// implementation of this function is needed for codesandbox example to work
// you can remove it otherwise
const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    // return distance between the marker and mouse pointer
    return Math.sqrt(
      (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
    );
  }
};

export default function MapLocationFind() {

  const incidentreportsLocations = useCentroStore((state) => state.incidentreportsLocations);
  const setLongitude = useCentroStore((state) => state.setlongitude);
  const setLatitude = useCentroStore((state) => state.setlatitude);
  const currentFacility = useCentroStore((state) => state.currentFacility);
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);

  // Initialize clickedLocation to the currentIncidentReport location if it's not 0, 0
  const [clickedLocation, setClickedLocation] = React.useState(
    currentIncidentReport.latitude !== 0 && currentIncidentReport.longitude !== 0
      ? { lat: currentIncidentReport.latitude, lng: currentIncidentReport.longitude }
      : null
  );

  return (
    <div className="incident-map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDlOA6BKZWCiRuCQIa-resTmtNtixwHRcM",
          language: "en",
          region: "US"
        }}
        defaultCenter={{ lat: currentFacility.latitude, lng: currentFacility.longitude }}
        defaultZoom={15}
        distanceToMouse={distanceToMouse}
        onClick={ev => {
          setLongitude(ev.lng.toFixed(4));
          setLatitude(ev.lat.toFixed(4));
          setClickedLocation({ lat: ev.lat, lng: ev.lng });
        }}
        // heatmap={{incidentreports}}
        options={map => ({ mapTypeId: map.MapTypeId.SATELLITE, fullscreenControl: false })}
      >
        {clickedLocation && <MyMarker lat={clickedLocation.lat} lng={clickedLocation.lng}  isClickedLocation={true}/>}
        {incidentreportsLocations.map(({ lat, lng, id }) => {
          return (
            <MyMarker  id={id} lat={lat} lng={lng} isClicked />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}
