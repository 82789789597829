import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import SignificantEventDropdown from "./general-information/SignificantEventDropdown";
import AirspaceDropdown from "./general-information/AirspaceDropdown";
import TrainingInProgressDropdown from "./general-information/TrainingInProgressDropdown";
//import OfficerContactInfo from "./law-enforcement/OfficerContactInfo";
//import LawEnforcementContactedDropdown from "./law-enforcement/LawEnforcementContactedDropdown";
//import OfficerName from "./law-enforcement/OfficerName";
//import { InjuriesReportedDropdown, VehicleInjuryReportedDropdown, PedestrianInjuryReportedDropdown } from "./injuries/InjuryReportedDropdown";
//import { NumberInjured, NumberCrewInjured, VehicleNumberInjured, PedestrianNumberInjured } from "./injuries/NumberInjured";
//import { MedicalHelpDropdown, VehicleMedicalHelpDropDown, PedestrianMedicalHelpDropDown } from "./injuries/MedicalHelpDropdown";
//import { InjuryDescription, VehicleInjuryDescription, PedestrianInjuryDescription } from "./injuries/InjuryDescription";
//import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PilotViolationDropdown from "./pilot-violation/PilotViolationDropdown";
import DeviationTypeDropdown from "./pilot-violation/DeviationTypeDropdown";
import ControlTypeDropdown from "./pilot-violation/ControlTypeDropdown";
//import BrasherWarningDropdown from "./pilot-violation/BrasherWarningDropdown";
//import TCASCheckboxes from "./pilot-deviation/TCASCheckboxes";
//import InfoIcon from "@mui/icons-material/Info";
//import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
//import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight';
import { useCentroStore } from "../../../store";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DoneIcon from '@mui/icons-material/Done';
//import FacilityDropdown from "./general-information/FacilityDropdown";
//import PoisitionDropdown from "./pilot-violation/PositionDropdown";
//import VehicleInjuryReportedDropdown from "./injuries/VehicleInjuryReportedDropdown";
//import PedestrianInjuryReportedDropdown from "./injuries/PedestrianInjuryReportedDropdown";
//import EmergencyEquipmentDeployedDropdown from "./general-information/EmergencyEquipmentDeployedDropdown";
//import EvacuationRequiredDropdown from "./general-information/EvacuationRequiredDropdown";
//import PilotViolationDropdown from "./general-information/PilotViolationDropdown";
import EquipmentOutageDropdown from "./general-information/EquipmentOutageDropdown";
//import ACASAlert from "./general-information/ACASAlert";
import DeviationTypeSufaceDropdown from "./pilot-violation/DeviationTypeSurfaceDropdown";
import TechOpsNotified from "./cns/TechOpsNotified";
import WeatherDescription from "./cns/WeatherDescription";
import WeatherFactor from "./cns/WeatherFactor";
import { useReferenceGuideStore } from "../../../store";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
}));

export default function IncidentReportStepThree() {

    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
    const isEquipmentOutage = useCentroStore((state) => state.isEquipmentOutage);
    //const setIsControlType = useCentroStore((state) => state.setIsControlType);
    //const setAcasAlert = useCentroStore((state) => state.setacasalert);
    const setViolationType = useCentroStore((state) => state.setviolationtype);
    const currentincidenttype = useCentroStore(
        (state) => state.currentincidenttype
    );
    useEffect(() => {
      if (currentIncidentReport.pilotViolation === 2) {
        //setAcasAlert("")
        setViolationType(""); // Reset the state to its default value
        //setIsControlType(""); // Reset the state to its default value
      }
    }, [currentIncidentReport.pilotViolation]);
    //const openModal = useReferenceGuideStore(state => state.openModal);

  return ( currentIncidentReport.reportTypeId !== 2 ?
    <Grid container className="section-wrapper ir-form-wrapper step-three ir-in-step-wrap" xl={12}>

      <Grid lg={4}>
        <Item elevation={0}>
          <Grid>
            <Item elevation={0} style={{ minWidth: "235px" }}>
              <Typography
                sx={{
                  color: "text.secondary",
                  textAlign: "left",
                  fontSize: "1.2rem!important",
  
                }}>
                  
                {EnglishLanguage ? "Significant event?" : "\u00bfEvento Significativo?"}{currentIncidentReport.significantEvent !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
              
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                textAlign: "left",
                paddingTop: "0",
                marginTop: "-10px",
              }}>
              <SignificantEventDropdown />
            </Item>
          </Grid>
        </Item>
      </Grid>
      <Grid lg={4}>
        <Item elevation={0}>
          <Grid>
            <Item elevation={0}>
              <Typography
                sx={{
                  color: "text.secondary",
                  textAlign: "left",
                  fontSize: "1.2rem!important",
                }}>
                {EnglishLanguage ? "Training in progress?" : "\u00bfEntrenamiento en progreso?"}{currentIncidentReport.trainingInProgress !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                textAlign: "left",
                paddingTop: "0",
                marginTop: "-10px",
              }}>
              <TrainingInProgressDropdown />
            </Item>
          </Grid>
        </Item>
      </Grid>



      {/* <Grid lg={4}>
        <Item elevation={0}>
          <Grid>
            <Item elevation={0}>
              <Typography
                sx={{
                  color: "text.secondary",
                  textAlign: "left",
                  fontSize: "1.2rem!important",
                }}>
                {EnglishLanguage ? "Emergency Equipment Deployed?" : "Espacio Aéreo"}{currentIncidentReport.emergencyEquipmentDeployed !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                textAlign: "left",
                paddingTop: "0",
                marginTop: "-10px",
              }}>
              <EmergencyEquipmentDeployedDropdown />
            </Item>
          </Grid>
        </Item>
      </Grid> */}

      {/* <Grid lg={4}>
        <Item elevation={0}>
          <Grid>
            <Item elevation={0}>
              <Typography
                sx={{
                  color: "text.secondary",
                  textAlign: "left",
                  fontSize: "1.2rem!important",
                }}>
                {EnglishLanguage ? "Evacuation Required?" : "Espacio Aéreo"}{currentIncidentReport.evacuationRequired !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                textAlign: "left",
                paddingTop: "0",
                marginTop: "-10px",
              }}>
              <EvacuationRequiredDropdown />
            </Item>
          </Grid>
        </Item>
      </Grid> */}

        {/* <Grid lg={4}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Did an Equipment Outage contribute to this Event? " : "Espacio Aéreo"}{isEquipmentOutage !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <EquipmentOutageDropdown />
              </Item>
            </Grid>
          </Item>
        </Grid> */}
      
      {currentincidenttype === "airborne" && (
        <Grid lg={4}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Airspace" : "Espacio Aéreo"}{currentIncidentReport.airspace !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <AirspaceDropdown />
              </Item>
            </Grid>
          </Item>
        </Grid>
      )}   

<Grid xl={12} container className="table-header-wrap">

                  <Grid lg={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Was there a violation?") : ("\u00bfDesviación de Piloto?")} 
                            {currentIncidentReport.pilotViolation !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <PilotViolationDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  {currentIncidentReport.pilotViolation === 1 && (
                    <>
                  {/* <Grid lg={8}>
                  
                    <Item elevation={0}>
                      <Grid>
                      <Item className="conditional-arrow"><KeyboardDoubleArrowRightIcon /></Item>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("ACAS Alert") : ("Otra Información")} 
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <ACASAlert />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid> */}

            <Grid lg={6}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0} style={{ minWidth: "161px" }}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? "Violation Type" : "Tipo de Desviaci\xF3n"}
                      {currentIncidentReport.violationType !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    {currentincidenttype === "airborne" && <DeviationTypeDropdown />}
                    {currentincidenttype === "surface" && <DeviationTypeSufaceDropdown />}
                  </Item>
                </Grid>
              </Item>
            </Grid>
            {/* <Grid lg={6}>
              <Item elevation={0}>
                <Grid>
                  <Item elevation={0} style={{ minWidth: "161px" }}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
                      }}>
                      {EnglishLanguage ? "Control Type" : "Superficie de Control"}
                    </Typography>
                  </Item>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      paddingTop: "0",
                      marginTop: "-10px",
                    }}>
                    <ControlTypeDropdown />
                  </Item>
                </Grid>
              </Item>
            </Grid> */}
            {/* <Grid lg={5}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} style={{ minWidth: "161px" }}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                           {EnglishLanguage ? ("Brasher Warning Issued") : ("¿Notificación Brasher Emitido?")}   
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <BrasherWarningDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid> */}
            {/* {currentIncidentReport.brasherWarning === true && (
                    <>
                  <Grid lg={2}>
                    <Item elevation={0}>
                      <Grid>
                      <Item className="conditional-arrow"><KeyboardDoubleArrowRightIcon /></Item>
                        <Item elevation={0} style={{ minWidth: "161px" }}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Facility") : ("Instalación Emisor")}  
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FacilityDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid lg={2}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} style={{ minWidth: "161px" }}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Position") : ("Posición Emisor")}  
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <PoisitionDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  </>
                  )} */}
          </>
        )}
      </Grid>
      </Grid>
    :
    <Grid container className="section-wrapper ir-form-wrapper step-three ir-in-step-wrap " xl={12}>
        {/* CNS Fields */}
        <Grid lg={4}>
        <Item elevation={0}>
          <Grid>
            <Item elevation={0} style={{ minWidth: "235px" }}>
              <Typography
                sx={{
                  color: "text.secondary",
                  textAlign: "left",
                  fontSize: "1.2rem!important",
  
                }}>
                {EnglishLanguage ? "Was Weather a Factor?" : "\u00bfClima fue un factor?"}{currentIncidentReport.weatherFactor !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                textAlign: "left",
                paddingTop: "0",
                marginTop: "-10px",
              }}>
              <WeatherFactor />
            </Item>
          </Grid>
        </Item>
        </Grid>
        {/* Only if Weather Was a Factor - Yes is selected */}
        {currentIncidentReport.weatherFactor === 1 ? 
        <Grid lg={4}>
        <Item elevation={0}>
          <Grid>
            <Item elevation={0} style={{ minWidth: "235px" }}>
              <Typography
                sx={{
                  color: "text.secondary",
                  textAlign: "left",
                  fontSize: "1.2rem!important",
  
                }}>
                {EnglishLanguage ? "Weather Description" : "Descripc\xF3n del clima"}{currentIncidentReport.weatherDescription !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                textAlign: "left",
                paddingTop: "0",
                marginTop: "-10px",
              }}>
              <WeatherDescription />
            </Item>
          </Grid>
        </Item>
        </Grid>
         : ""}
        <Grid lg={4}>
        <Item elevation={0}>
          <Grid>
            <Item elevation={0} style={{ minWidth: "235px" }}>
              <Typography
                sx={{
                  color: "text.secondary",
                  textAlign: "left",
                  fontSize: "1.2rem!important",
  
                }}>
                {EnglishLanguage ? "Significant event?" : "\u00bfEvento Significativo?"}{currentIncidentReport.significantEvent !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                textAlign: "left",
                paddingTop: "0",
                marginTop: "-10px",
              }}>
              <SignificantEventDropdown />
            </Item>
          </Grid>
        </Item>
        </Grid>
        <Grid lg={4}>
        <Item elevation={0}>
          <Grid>
            <Item elevation={0} style={{ minWidth: "235px" }}>
              <Typography
                sx={{
                  color: "text.secondary",
                  textAlign: "left",
                  fontSize: "1.2rem!important",
  
                }}>
                {EnglishLanguage ? "CNS Technician Notified?" : "\u00bfCNS fue notificado?"}{currentIncidentReport.techopsNotified !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                textAlign: "left",
                paddingTop: "0",
                marginTop: "-10px",
              }}>
              <TechOpsNotified />
            </Item>
          </Grid>
        </Item>
        </Grid>
        <Grid lg={4}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "Did an Equipment Outage contribute to this Event? " : "\u00bfLa interrupci\xF3n del equipo contribuy\xF3 potencialmente a este evento?"}{currentIncidentReport.equipmentOutageContribute !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <EquipmentOutageDropdown />
              </Item>
            </Grid>
          </Item>
        </Grid>
    </Grid>
  );
}
