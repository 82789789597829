// import * as React from "react";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Unstable_Grid2";
// import Paper from "@mui/material/Paper";
// import { styled } from "@mui/material/styles";
// import InstrumentVisualWeatherDropdown from "./general-information/InstrumentVisualWeatherDropdown";
// import MetarReading from "./general-information/MetarReading";
// import ACOneVisibilityCheckboxes from "./general-information/ACOneVisibilityCheckboxes";
// import ACTwoVisibilityCheckboxes from "./general-information/ACTwoVisibilityCheckboxes";
// import CloudsDropdown from "./general-information/CloudsDropdown";
// import VerticalDistanceFromClouds from "./general-information/VerticalDistanceFromClouds";
// import AboveBelowDropdown from "./general-information/AboveBelowDropdown";
// import InCloudConditionsDropdown from "./general-information/InCloudConditionsDropdown";
// import InfoIcon from "@mui/icons-material/Info";
// import { useCentroStore } from "../../../store";
// import SunDropdown from "./general-information/SunDropdown";
// import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
// //import { useEffect } from "react";


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "transparent",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",

// }));

// export default function IncidentReportStepFive() {

//     //const showairporttwo = useCentroStore((state) => state.showairporttwo);
//     const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
//     //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
//   //const cloudsdropdown = useCentroStore((state) => state.cloudsdropdown);
//   const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
//     //useEffect(() => { setuserloggedin("Yes") })
//     const currentincidenttype = useCentroStore(
//       (state) => state.currentincidenttype
//     );

//   return (
//     <Grid container py={0} spacing={2} mt={0} className="ir-form-wrapper">

//       <Grid xs={12}>
//         <Item elevation={0} sx={{ padding: "0" }}>
//         <Grid xs={12}>
//          <Grid xs={12} className="step-three-wrap" mb={1}>           
//                 <Grid
//                   container
//                   className="ir-form-wrapper"
//                   justifyContent="space-between"
//                   spacing={1}
//                   sx={{background: "#00000033", borderRadius: ".5rem", padding: "10px 10px 15px"}}
//                 >
//             {/* General Information Header */}      
//             {/* <Grid xl={12}  className="table-header-wrap">
//                 <Item elevation={0}>
//                    <InfoIcon />
//                   </Item>
//                   <Item elevation={0}>
//                     <Typography
//                       sx={{
//                         textAlign: "left",
//                         paddingBottom: ".25rem",
//                         whiteSpace: "nowrap",
//                       }}
//                       variant="h6"
//                     >
//                       Additional Information
//                     </Typography>
//                   </Item>
//                 </Grid> */}

//             {/* General Information Section */}
//                  <Grid container  className="section-wrapper" xl={12}>
//                  {/* <Grid lg={3}>
//                     <Item elevation={0}>
//                       <Grid>
//                         <Item elevation={0} style={{ minWidth: "235px" }}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                              {EnglishLanguage ? ("Instrument or Visual Weather") : ("¿Condiciones Meteorológicas Instrumentales o Visuales?")}  
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
//                             marginTop: "-10px",
//                           }}
//                         >
//                           <InstrumentVisualWeatherDropdown />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid> */}
//                   <Grid lg={12}>
//                     <Item elevation={0}>
//                       <Grid>
//                         <Item elevation={0}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                             {EnglishLanguage ? ("METAR") : ("METAR")}   
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
//                             marginTop: "-10px",
//                           }}
//                         >
//                           <MetarReading />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid>
                
//                   {/* <Grid lg={3}>
//                     <Item elevation={0}>
//                       <Grid>
//                         <Item elevation={0}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                              {EnglishLanguage ? ("Aircraft 1 Visibility Restrictions") : ("Aeronave 1 Restricciones de Visibilidad")}
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
                  
//                           }}
//                         >
//                           <ACOneVisibilityCheckboxes />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid> */}
//                   {/* {currentIncidentReport.aircraftCount === 2 && (
//                     <>
//                   <Grid lg={3}>
//                     <Item elevation={0}>
//                       <Grid>
//                         <Item elevation={0}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                              {EnglishLanguage ? ("Aircraft 2 Visibility Restrictions") : ("Aeronave 2 Restricciones de Visibilidad")}  
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
                      
//                           }}
//                         >
//                           <ACTwoVisibilityCheckboxes />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid>
//                   </>
//                   )}
                 
                  

                  
                  
//                   <Grid lg={3}>
//                     <Item elevation={0}>
//                       <Grid>
//                         <Item elevation={0} style={{ minWidth: "161px" }}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                              {EnglishLanguage ? ("Sun") : ("Sol")}  
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
//                             marginTop: "-10px",
//                           }}
//                         >
//                           <SunDropdown />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid>
                
//                   <Grid lg={3}>
//                     <Item elevation={0}>
//                       <Grid>
//                         <Item elevation={0} style={{ minWidth: "161px" }}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                             {EnglishLanguage ? ("Clouds") : ("Nubes")}   
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
//                             marginTop: "-10px",
//                           }}
//                         >
//                           <CloudsDropdown />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid>
//                   {currentIncidentReport.clouds !== "N/A" && (
//                     <>
//                   <Grid lg={3}>
//                     <Item elevation={0}>
//                       <Grid>
//                       <Item className="conditional-arrow"><KeyboardDoubleArrowRight /></Item>
//                         <Item elevation={0}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                              {EnglishLanguage ? ("Vertical Distance from Cloud") : ("Distancia Vertical desde las nubes")}  
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
//                             marginTop: "-10px",
//                           }}
//                         >
//                       <VerticalDistanceFromClouds />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid>

//                   <Grid lg={3}>
//                     <Item elevation={0}>
//                       <Grid>
//                         <Item elevation={0}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                             {EnglishLanguage ? ("Above/Below") : ("Encima/Debajo")}  
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
//                             marginTop: "-10px",
//                           }}
//                         >
//                           <AboveBelowDropdown />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid>
//                   <Grid lg={3}>
//                     <Item elevation={0}>
//                       <Grid>
//                         <Item elevation={0}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                              {EnglishLanguage ? ("In Cloud Conditions") : ("Condiciones dentro de las nubes")}  
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
//                             marginTop: "-10px",
//                           }}
//                         >
//                           <InCloudConditionsDropdown />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid>
//                   </>
//                   )} */}
//                </Grid>   
                  
//                </Grid>
          

                  

                  

              
 
            
//           </Grid>
//           </Grid>
//         </Item>
//       </Grid>





//     </Grid>
//   );
// }

// import * as React from "react";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Unstable_Grid2";
// import Paper from "@mui/material/Paper";
// import { styled } from "@mui/material/styles";
// import InstrumentVisualWeatherDropdown from "./general-information/InstrumentVisualWeatherDropdown";
// import MetarReading from "./general-information/MetarReading";
// import ACOneVisibilityCheckboxes from "./general-information/ACOneVisibilityCheckboxes";
// import ACTwoVisibilityCheckboxes from "./general-information/ACTwoVisibilityCheckboxes";
// import CloudsDropdown from "./general-information/CloudsDropdown";
// import VerticalDistanceFromClouds from "./general-information/VerticalDistanceFromClouds";
// import AboveBelowDropdown from "./general-information/AboveBelowDropdown";
// import InCloudConditionsDropdown from "./general-information/InCloudConditionsDropdown";
// import InfoIcon from "@mui/icons-material/Info";
// import { useCentroStore } from "../../../store";
// import SunDropdown from "./general-information/SunDropdown";
// import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
// //import { useEffect } from "react";


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "transparent",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",

// }));

// export default function IncidentReportStepFive() {

//     //const showairporttwo = useCentroStore((state) => state.showairporttwo);
//     const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
//     //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
//   //const cloudsdropdown = useCentroStore((state) => state.cloudsdropdown);
//   const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
//     //useEffect(() => { setuserloggedin("Yes") })
//     const currentincidenttype = useCentroStore(
//       (state) => state.currentincidenttype
//     );

//   return (

//                   <Grid container className="section-wrapper ir-form-wrapper step-three ir-in-step-wrap" xl={12}>
//                   <Grid container className="table-header-wrap" xl={12}>
//                   <Grid lg={12}>
//                     <Item elevation={0}>
//                       <Grid>
//                         <Item elevation={0}>
//                           <Typography
//                             sx={{
//                               color: "text.secondary",
//                               textAlign: "left",
//                               fontSize: "1.2rem!important",
//                             }}
//                           >
//                             {EnglishLanguage ? ("METAR") : ("METAR")}   
//                           </Typography>
//                         </Item>
//                         <Item
//                           elevation={0}
//                           sx={{
//                             textAlign: "left",
//                             paddingTop: "0",
//                             marginTop: "-10px",
//                           }}
//                         >
//                           <MetarReading />
//                         </Item>
//                       </Grid>
//                     </Item>
//                   </Grid>
//                 </Grid>
//                 </Grid>
                 
//   );
// }

import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MetarReading from "./general-information/MetarReading";
import { useCentroStore } from "../../../store";
import DoneIcon from "@mui/icons-material/Done";
import InCloudConditionsDropdown from "./general-information/InCloudConditionsDropdown";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function IncidentReportStepFive() {
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const currentincidenttype = useCentroStore((state) => state.currentincidenttype);

  // Calculate whether both conditionals are rendered

  const areStepsCompleted = currentIncidentReport.metar;

  return (
    <Grid container className="section-wrapper ir-form-wrapper step-three ir-in-step-wrap" xl={12}>
      <Grid container className="table-header-wrap" xl={12}>
        <Grid xs={12}>
          <Item elevation={0}>
            <Grid>
              <Item elevation={0}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "1.2rem!important",
                  }}>
                  {EnglishLanguage ? "METAR" : "METAR"}{" "}{currentIncidentReport.metar ? <DoneIcon className="checkbox-valdation-icon" /> : ""}
                </Typography>
              </Item>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingTop: "0",
                  marginTop: "-10px",
                }}>
                <MetarReading />
              </Item>
            </Grid>
          </Item>
        </Grid>
       
      </Grid>
      {/* Display the content when both conditionals are rendered */}
      {/* {areStepsCompleted && (
        <Grid container>
          <Typography>
            This content will be displayed when both conditionals are rendered.
          </Typography>
        </Grid>
      )} */}
    </Grid>
  );
}
