import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";
import { useCentroStore } from "../store";
import { useNavigate } from "react-router-dom";
import { useUser, useAllFacilityTypes } from '../store/queries/userQueries';
import { useFacilityTypes } from '../store/queries/facilityQueries';
import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';

export default function FacilityListTypes() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const userId = useCentroStore((state) => state.userId);
    const { userData } = useUser(userId);
    const userDataPermissions = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0];
    const { editFacilityType, removeFacilityType } = useFacilityTypes();
    const { data: FacilityTypeData, refetch, isLoading } = useAllFacilityTypes();
    //const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [currentFacilityType, setCurrentFacilityType] = React.useState(null);
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState("");

    const handleOpen = (type) => {
        if (userDataPermissions.updateFac === 1) {
            setCurrentFacilityType({
                typeId: type.typeId,
                typeName: type.typeName,
                dateCreated: type.dateCreated
            });
            setOpen(true);
        }
        else {
            if (EnglishLanguage) {
                alert("You do not have permission to edit facility types.");
            }
            else {
                alert("No tienes permiso para editar tipos de instalaciones.");
            }
        }
    };

    const handleClose = () => {
        setErrorCode(0);
        setCurrentFacilityType(null);
        setOpen(false);
    };

    const handleTextFieldChange = (event, field) => {
        setCurrentFacilityType(prevState => ({
            ...prevState,
            [field]: event.target.value
        }));
    };

    const handleSave = async () => {
        //console.log(currentFacilityType); // to log the updated Facility data
        let response;
        response = await editFacilityType.mutateAsync(currentFacilityType).catch(function (error) {
            setErrorCode(error.response.status);
            if (error.response.status === 400) {
                if (EnglishLanguage) {
                    setErrorMsg("There was an error saving changes to Facility Type. Required fields are missing.");
                }
                else {
                    setErrorMsg("Se produjo un error al guardar los cambios en el tipo de instalaci\xF3n. Faltan campos obligatorios.");
                }
            }
            else {
                setErrorMsg(error.message);
            }
        });
        if (response.status === 200) {
            setErrorCode(0);
            refetch(); // Refetch query to update datagrid
            if (EnglishLanguage) {
                alert("Facility Type has been successfully saved.");// Successful save message
            }
            else {
                alert("El tipo de instalaci\xF3n se ha guardado correctamente.");
            }
        }
        else {
            setErrorCode(response.status);
            setErrorMsg(response.statusText);
            if (EnglishLanguage) {
                alert("There was an error saving Facility Type. Facility Type could not be saved.");// Error
            }
            else {
                alert("Hubo un error al guardar el tipo de instalaci\xF3n. No se pudo guardar el tipo de instalaci\xF3n.");
            }
        }
        handleClose();
    };

    const modalBody = currentFacilityType ? (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ textTransform: "capitalize" }}>{EnglishLanguage ? "Edit Facility Type" : "Editar Tipo de Instalaci\xF3n"}</DialogTitle>
            <DialogContent>
                {/*<TextField label="ID" defaultValue={currentFacilityType.typeId} onChange={(event) => handleTextFieldChange(event, 'typeId')} fullWidth variant="standard" margin="normal" />*/}
                <Typography>{EnglishLanguage ? "Facility Type Name" : "Nombre de Tipo de Instalaci\xF3n"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Facility Type Name" : "Nombre de Tipo de Instalaci\xF3n"} defaultValue={currentFacilityType.typeName} onChange={(event) => handleTextFieldChange(event, 'typeName')} fullWidth variant="standard" margin="normal" />
                <Typography><br />{EnglishLanguage ? "Created Date (UTC, Read-Only)" : "Fecha de creaci\xF3n (UTC, solo-lectura)"}:</Typography>
                <TextField label={EnglishLanguage ? "Created Date" : "Fecha de creaci\xF3n"} defaultValue={currentFacilityType.dateCreated} InputProps={{ readOnly: true, }} fullWidth variant="standard" margin="normal" />
            </DialogContent>
            <DialogActions>
                {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>{errorMsg}</Grid></Grid> : null}
                <Button onClick={handleClose} color="primary">{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
                <Button onClick={handleSave} color="primary">{EnglishLanguage ? "Save" : "Guardar"}</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    const columns = [
        //{ field: 'typeId', headerName: 'ID', flex: 1 },
        { field: 'typeName', headerName: 'Type Name', flex: 1 },
        { field: 'dateCreated', headerName: 'Created Date (UTC)', flex: 1 ,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
            },
        },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <EditIcon
                        onClick={() => handleOpen(params.row)}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                    />
                );
            }
        },
    ];

    const columnsSpanish = [
        //{ field: 'typeId', headerName: 'ID', flex: 1 },
        { field: 'typeName', headerName: 'NOMBRE DE TIPO DE INSTALACI\xF3N', flex: 1 },
        { field: 'dateCreated', headerName: 'FECHA CREADA (UTC)', flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
            },
        },
        {
            field: "edit",
            headerName: "EDITAR",
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <EditIcon
                        onClick={() => handleOpen(params.row)}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                    />
                );
            }
        },
    ];

    return (
        <div style={{ height: "calc(100vh - 17rem)", width: '100%' }}>
            {modalBody}
            {isLoading ? <div>Loading...</div> : (
                <DataGrid
                    rows={FacilityTypeData}
                    getRowId={(row) => row.typeId}
                    columns={EnglishLanguage ? columns : columnsSpanish}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    components={{ Toolbar: GridToolbar }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    localeText={{
                        noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                        noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                        toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                        toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                        toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                        toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir",
                        MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                EnglishLanguage ?
                                    `${from}-${to} of ${count}` :
                                    `${from}-${to} de ${count}`,
                        },
                    }}
                />
            )}
        </div>
    );
}
