import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';
import { useClearState } from '../../../Helpers/HelperFunctions';
import { useReports } from '../../../../store/queries/incidentReportQueries';

const options = [
'1','2'
];

export default function NumberAircraftDropdown() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
    const setaircraftcount = useCentroStore((state) => state.setnumberofaircraft);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const currentFacility = useCentroStore((state) => state.currentFacility);
    const { aircraftsFromDb } = useReports(currentFacility.facilityId, currentIncidentReport.irId);
    const aircrafts = useCentroStore((state) => state.aircrafts);
    const setaircrafts = useCentroStore((state) => state.setaircrafts);
    const { ClearAC2 } = useClearState();

    const handleChange = (event) => {
        var count = parseInt(event.target.value);
        //console.log(aircrafts.length);
        if (count === 1 && currentIncidentReport.aircraftCount === 2) {
            ClearAC2();
        }
        setaircraftcount(parseInt(event.target.value));
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentIncidentReport.aircraftCount === 0 ? "": currentIncidentReport.aircraftCount}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}

