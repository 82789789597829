import { Typography } from "@mui/material";
import React, { useState } from 'react';
import { useClearState } from "../Helpers/HelperFunctions";
import { useCentroStore } from "../../store";
import { Link } from "react-router-dom";
    


export default function StepIsComplete () {
    const { ClearReport } = useClearState();
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

    const handleNewIncidentClick = async () => {
        ClearReport();
    };
return (
   <>
    {EnglishLanguage ? <Typography className="tips-text">All <b>required</b> fields have been filled out. Please click the <b>Save/Advance</b> button to advance to the next step.</Typography>
    : <Typography className="tips-text">Se han rellenado todos los campos obligatorios. Haga clic en el bot{"\xF3"}n <b>Guardar/Avanzar</b> para avanzar al siguiente paso.</Typography>}
        <br />       
    </>
)};