import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
export default function LatitudeText() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setLatitude = useCentroStore((state) => state.setlatitude);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const blurHandler = (event) => {
      setLatitude(event.target.value);
    }

  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? "Number" : "N\xFAmero"}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          rows={1}
          defaultValue={currentIncidentReport.latitude === 0 || isNaN(currentIncidentReport.latitude) ? "" : currentIncidentReport.latitude }
          variant="standard"
          onBlur={blurHandler}
          onInput={e => setLatitude(e.target.value)}
          value={currentIncidentReport.latitude}
        />
  );
};