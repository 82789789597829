import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const optionsCommunication = [
    'Air to Ground Communication',
    'Ground to Ground Communication',
    'Low Safety Impact',
];
const optionsCommunicationSpanish = [
    'Comunicaci\xF3n aire-tierra',
    'Comunicaci\xF3n tierra a tierra',
    'Bajo impacto de seguridad',
];

const optionsInformation = [
    'Aeronautical Information',
    'Flight Processing Data',
    'Meteorlogical Information',
    'Low Safety Impact',
];
const optionsInformationSpanish = [
    'Informaci\xF3n aeron\xE1utica',
    'Datos de procesamiento de vuelos',
    'Informaci\xF3n Meteorol\xF3gica',
    'Bajo impacto de seguridad',
];

const optionsNavigation = [
    'Instrument Navigation',
    'Satellite Navigation',
    'Visual Navigation',
    'Low Safety Impact',
];
const optionsNavigationSpanish = [
    'Navegaci\xF3n por instrumentos',
    'Navegaci\xF3n satelital',
    'Navegaci\xF3n visual',
    'Bajo impacto de seguridad',
];

const optionsSurveillance = [
    'Air Surveillance',
    'Surface Surveillance',
    'Surveillance Data Processing',
    'Low Safety Impact',
];
const optionsSurveillanceSpanish = [
    'Vigilancia a\xE9rea',
    'Vigilancia de superficie',
    'Procesamiento de datos de vigilancia',
    'Bajo impacto de seguridad',
];

export default function OperationalFunction() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setoperationalFunction = useCentroStore((state) => state.setoperationalFunction);

    const handleChange = (event) => {
        setoperationalFunction(event.target.value);
    };

  return (
    // Display field if currentIncidentReport.technologyCategory is not empty
    currentIncidentReport.technologyCategory ?
        // Technology Category === Communication
        (currentIncidentReport.technologyCategory === "Communication" || currentIncidentReport.technologyCategory === "Comunicaci\xF3n") ?
            <Box sx={{ minWidth: 120 }}>
            <FormControl  fullWidth variant="standard">
                <Select
                className='select-wrap'
                  defaultValue=""
                  value={currentIncidentReport.operationalFunction}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
                    }
                    return selected;
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem disabled value="">
                    <em>{EnglishLanguage ? ("Select") : ("Seleccione")}</em>
                  </MenuItem>
                  {EnglishLanguage ? 
                   optionsCommunication.map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                   ))
                   :
                   optionsCommunicationSpanish.map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                   ))}
                </Select>
            </FormControl>
            </Box>
        : 
        // Technology Category === Information Services
        (currentIncidentReport.technologyCategory === "Information Services" || currentIncidentReport.technologyCategory === "Servicios de informaci\xF3n") ? 
            <Box sx={{ minWidth: 120 }}>
            <FormControl  fullWidth variant="standard">
                <Select
                className='select-wrap'
                  defaultValue=""
                  value={currentIncidentReport.operationalFunction}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem disabled value="">
                    <em>{EnglishLanguage ? ("Select") : ("Seleccione")}</em>
                  </MenuItem>
                  {EnglishLanguage ? 
                   optionsInformation.map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                   ))
                   :
                   optionsInformationSpanish.map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                   ))}
                </Select>
            </FormControl>
            </Box>
        :
        // Technology Category === Navigation
        (currentIncidentReport.technologyCategory === "Navigation" || currentIncidentReport.technologyCategory === "Navegaci\xF3n") ? 
            <Box sx={{ minWidth: 120 }}>
            <FormControl  fullWidth variant="standard">
                <Select
                className='select-wrap'
                  defaultValue=""
                  value={currentIncidentReport.operationalFunction}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
                    }
                    return selected;
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem disabled value="">
                    <em>{EnglishLanguage ? ("Select") : ("Seleccione")}</em>
                  </MenuItem>
                  {EnglishLanguage ? 
                   optionsNavigation.map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                   ))
                   :
                   optionsNavigationSpanish.map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                   ))}
                </Select>
            </FormControl>
            </Box>
        :
        // Technology Category === Surveillance
        (currentIncidentReport.technologyCategory === "Surveillance" || currentIncidentReport.technologyCategory === "Vigilancia") ? 
            <Box sx={{ minWidth: 120 }}>
            <FormControl  fullWidth variant="standard">
                <Select
                className='select-wrap'
                  defaultValue=""
                  value={currentIncidentReport.operationalFunction}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
                    }
                    return selected;
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem disabled value="">
                    <em>{EnglishLanguage ? ("Select") : ("Seleccione")}</em>
                  </MenuItem>
                  {EnglishLanguage ? 
                   optionsSurveillance.map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                   ))
                   :
                   optionsSurveillanceSpanish.map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                   ))}
                </Select>
            </FormControl>
            </Box>
        :
        // Technology Category === Unknown or Automation, display nothing
        ""
    : 
    // Display nothing if currentIncidentReport.technologyCategory is empty
    ""
  );
}