import * as React from "react";
import { useCentroStore } from "../../store";
import { useUser, useUpdateUserPreferences } from "../../store/queries/userQueries";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function LanguageSelect() {
    const setWindSpeed = useCentroStore((state) => state.setWeatherWindSpeedMetric);
    const userId = useCentroStore((state) => state.userId);
    //const { userData, useUpdateUserPreferences } = useUser(userId);
    const userPreferences = useCentroStore((state) => state.userPreferences);
    const setUserPreferencesWindSpeedMetric = useCentroStore((state) => state.setUserPreferencesWindSpeedMetric);

    const handleChangeWindSpeed = (event) => {
        // Radio button event target values return as string rather than boolean, so they need to be converted
        if (event.target.value.toLowerCase() === "true") {
            setWindSpeed("");
            setUserPreferencesWindSpeedMetric(true);
        }
        else {
            setWindSpeed(true);
            setUserPreferencesWindSpeedMetric(false);
        }
    };


    return (

        <FormControl>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue={userPreferences.windSpeedMetric}
                onChange={handleChangeWindSpeed}
            >
                <FormControlLabel value={false} control={<Radio />} label="KM/H" />
                <FormControlLabel value={true} control={<Radio />} label="MPH" />
            </RadioGroup>
        </FormControl>
    );
}