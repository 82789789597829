import * as React from "react";
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useCentroStore } from "../../store";
import { useState } from "react";


export default function PasswordTextfieldMatch() {

  //const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  //const userProfilePasswordConfirmation = useCentroStore((state) => state.userProfilePasswordConfirmation);
  const setUserProfilePasswordConfirmation = useCentroStore((state) => state.setUserProfilePasswordConfirmation);
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = React.useState(false); // For password visibility functionality
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const blurHandler = (event) => {
    //currentIncidentReport.reporterName = event.target.value;
    setUserProfilePasswordConfirmation(event.target.value);
  };
  return (
    <Input
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          endAdornment={
              <InputAdornment position="end">
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                  >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
              </InputAdornment>
          }
          label="Re-type Password"
          sx={{ fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          onBlur={blurHandler}
          />
  );
}