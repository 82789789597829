import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';
//import { useFacilityPositions } from '../../../../store/queries/facilityQueries';


export default function AircraftPoisitionDropdown() {

    const setAircraftOnePosition = useCentroStore((state) => state.setAircraftOnePosition);
    //const positionList = useCentroStore((state) => state.facilityPosList);
    //const { facilityPositions } = useFacilityPositions();
    const aircraftOneFac = useCentroStore((state) => state.aircraftOneFac);
    const aircraftPos = useCentroStore((state) => state.aircraftOnePos);
    const setAircraftPos = useCentroStore((state) => state.setAircraftOnePos);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

    const handleChange = (event) => {
        var position = aircraftOneFac?.facilityPositionModels?.find(p => p.id === event.target.value); //facilityPositions?.data?.data.find(p => p.id === event.target.value);
        setAircraftPos(position);
      setAircraftOnePosition(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={aircraftPos === undefined || aircraftPos === null || aircraftPos.posName === undefined ? "" : aircraftPos.posName+'-'+aircraftPos.frequency}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
              if (selected === undefined || selected === null || selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
                  {/*{facilityPositions?.data?.data.map(({id, posName, frequency }) => (*/}
                  {aircraftOneFac?.facilityPositionModels?.map(({id, posName, frequency}) =>(
            <MenuItem
              key={id}
              value={id}
            >
              {posName}-{frequency}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}

