import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function AircraftTwoTypeUnknownDescription() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const aircraftTwo = useCentroStore((state) => state.aircraftTwo);
    const setAircraftTwoTypeUnknownDesc = useCentroStore((state) => state.setAircraftTwoTypeUnknownDesc);
    const blurHandler = (event) => {
        setAircraftTwoTypeUnknownDesc(event.target.value);
    };

  return (
    <TextField
      id="standard-multiline-number"
      label={EnglishLanguage ? "Text" : "Texto"}
      sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
      fullWidth
      rows={1}
      onBlur={blurHandler}
      defaultValue={aircraftTwo.typeUnknownDesc}
      variant="standard"
    />
  );
}