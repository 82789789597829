import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
'Full',
'Partial',
'None',
];

const optionsSpanish = [
'Completo',
'Parcial',
'Ninguno',
];

export default function MaintenanceAction() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setinterruptCondition = useCentroStore((state) => state.setinterruptCondition);

    const handleChange = (event) => {
        setinterruptCondition(event.target.value);
    };

  return (
    <Box sx={{ minWidth: 120 }}>
    <FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          defaultValue=""
          value={currentIncidentReport.interruptCondition}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }
            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccione")}</em>
          </MenuItem>
          {EnglishLanguage ? 
           options.map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              {category}
            </MenuItem>
           ))
           :
           optionsSpanish.map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              {category}
            </MenuItem>
           ))}
        </Select>
    </FormControl>
    </Box>
  );
}