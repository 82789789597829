import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
  'Runway 1',
  'Runway 2',
  'Runway 3',
];

const optionsSpanish = [
    'Pista 1',
    'Pista 2',
    'Pista 3',
];

export default function RunwayDropdown() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setrunway = useCentroStore((state) => state.setrunway);
    const currentincidentreport = useCentroStore ((state) => state.currentincidentreport);

  //const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    
      setrunway(event.target.value);
      //if (event.target.value === 'Yes') {
      //    setterrianalertwarning(true);
      //}
      //else {
      //    setterrianalertwarning(false);
      //}
  };


  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentincidentreport.runway}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected === "") {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
          options.map((options) => (
            <MenuItem
              key={options}
              value={options}
            >
              {options}
            </MenuItem>
          ))
          :
          optionsSpanish.map((options) => (
            <MenuItem
              key={options}
              value={options}
            >
              {options}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}

