import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function AircraftBeacon() {

  //const beacontext = useCentroStore((state) => state.beacontext);
    //const setbeacontext = useCentroStore((state) => state.setbeacontext);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const aircraft = useCentroStore((state) => state.aircraftOne);
    const setAircraftOneBeaconCode = useCentroStore((state) => state.setAircraftOneBeaconCode);
  const handleChange = (event) => {
    //setbeacontext(event.target.value);
      var beaconcode = parseInt(event.target.value);
      if (isNaN(beaconcode)) {
          beaconcode = 0;
      }

      //console.log(beaconcode);
      setAircraftOneBeaconCode(beaconcode);
  };

  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? ("Number") : ("N\xFAmero")}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          rows={1}
          onBlur={handleChange}
          defaultValue={aircraft.beaconCode === 0 ? "" : aircraft.beaconCode}
          variant="standard"

          onKeyDown={(e) => {
              var expression = new RegExp(/^[0-9\b]+$/);
              //Check needed to ensure deletion of beacon
              if (e.key === "Backspace" || e.key === "Delete") {
                  //console.log(e.key);
              }
              else if (!expression.test(e.key) || e.target.value.length >= 4) {
                  e.preventDefault()
              }
          } }
        />
  );
};