import React, { useEffect, useState } from "react";
import { useCentroStore } from "../../store";
import { useReports } from "../../store/queries/incidentReportQueries";
import { useAircrafts } from "../../store/queries/aircraftQueries";


const AutosaveReport = () => {
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const currentIrStatus = currentIncidentReport.status;
    const userFacilityId = useCentroStore((state) => state.userFacilityId);
    //const aircrafts = useCentroStore((state) => state.aircrafts);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    //const addAircrafts = useCentroStore((state) => state.addAircrafts);
    const aircraftOne = useCentroStore((state) => state.aircraftOne);
    const aircraftTwo = useCentroStore((state) => state.aircraftTwo);
    const setIrId = useCentroStore((state) => state.setIrId);
    const setreportnumber = useCentroStore((state) => state.setreportnumber);
    const userId = useCentroStore((state) => state.userId);
    const setLoadingIncidentReports = useCentroStore((state) => state.setLoadingIncidentReports);
    const { addReport, updateReport } = useReports(userFacilityId, currentIncidentReport.irId);
    const { aircraftsFromDb } = useReports(currentIncidentReport.reportingFacilityId, currentIncidentReport.irId)
    const setAircraftOneIrAircraftId = useCentroStore((state) => state.setAircraftOneIrAircraftId);
    const setAircraftTwoIrAircraftId = useCentroStore((state) => state.setAircraftTwoIrAircraftId);

    useEffect(() => {
        async function handleCreateReport() {
            let response;

            response = await addReport.mutateAsync(currentIncidentReport);
            setIrId(response?.data?.reportId);
            setreportnumber(response?.data?.reportNumber);
            setLoadingIncidentReports(false);

            var tempAircraftList = [];
            tempAircraftList[0] = aircraftOne;
            tempAircraftList[1] = aircraftTwo;
            setaircraftsList(tempAircraftList); // Update currentIncidentReport.aircraftsList with blank aircrafts
        }

        async function handleAutosave() {
            let response;

            console.log("AUTOSAVING");
            // Check for existing aircrafts
            if (aircraftsFromDb?.data?.data !== undefined && aircraftsFromDb?.data?.data.length !== 0) {
                console.log("aircrafts from db exists");
                // irAircraftId checks to avoid bugs when creating new aircrafts and updating them in the same sitting
                if (aircraftOne.irAircraftId !== aircraftsFromDb?.data?.data[0]?.aircraft.irAircraftId) {
                    console.log("setting aircraft one id");
                    setAircraftOneIrAircraftId(aircraftsFromDb?.data?.data[0].aircraft.irAircraftId);
                    return;
                }
                if (aircraftsFromDb?.data?.data.length === 2 &&
                    aircraftTwo.irAircraftId !== aircraftsFromDb?.data?.data[1]?.aircraft.irAircraftId) {
                    console.log("setting aircraft two id");
                    setAircraftTwoIrAircraftId(aircraftsFromDb?.data?.data[1].aircraft.irAircraftId);
                    return;
                }
            }

            //console.log(currentIncidentReport.aircraftsList);
            response = await updateReport.mutateAsync(currentIncidentReport);
            console.log("AUTOSAVE DONE");
        }

        // Check if report number exists, if yes then perform autosave, if not then try to create report
        if (currentIncidentReport.reportNumber === undefined || currentIncidentReport.reportNumber === '') {
            // Check for required fields necessary for creating report number
            if (currentIncidentReport.incidentCategoryId !== 0 && currentIncidentReport.reportingFacilityId !== 0 &&
                currentIncidentReport.incidentDatetime !== "" && currentIncidentReport.reportDatetime !== "") {
                handleCreateReport();
            }
        }
        else {
            handleAutosave();
        }
    }, [currentIncidentReport]);

    /* // useEffect() contains individual fields as dependencies rather than the whole object in state, as it can help avoid certain bugs and issues
       [currentIncidentReport.status, currentIncidentReport.incidentCategoryId, currentIncidentReport.subcategoryId, currentIncidentReport.incidentDatetime,
        currentIncidentReport.reportingFacilityId, currentIncidentReport.pilotViolation, currentIncidentReport.significantEvent, currentIncidentReport.trainingInProgress,
        currentIncidentReport.emergencyEquipmentDeployed, currentIncidentReport.evacuationRequired, currentIncidentReport.equipmentOutageContribute,
        currentIncidentReport.airspace, currentIncidentReport.acasAlert, currentIncidentReport.violationType, currentIncidentReport.controlType, currentIncidentReport.aircraftCount,
        currentIncidentReport.flightCategory, currentIncidentReport.flightSubcategory, currentIncidentReport.headingRoute, currentIncidentReport.latitude, currentIncidentReport.longitude,
        currentIncidentReport.terrainAlertActivated, currentIncidentReport.closestHorizontalDistance, currentIncidentReport.closestVerticalDistance, currentIncidentReport.metar,
        currentIncidentReport.incidentSummary, currentIncidentReport.vehicleId, currentIncidentReport.vehicleDriverName, currentIncidentReport.vehicleType,
        currentIncidentReport.vehicleAtcCommunicating, currentIncidentReport.vehicleAtcPosition, currentIncidentReport.vehicleOtherAtcPosition, currentIncidentReport.pedestrianName,
        currentIncidentReport.pedestrianType, currentIncidentReport.pedestrianOtherType, currentIncidentReport.runwaySurfaceDegradation, currentIncidentReport.rcrScoreDetails,
        currentIncidentReport.facilityFindings, currentIncidentReport.facilityManagementEscalate, currentIncidentReport.facilityManagementFindings,
        currentIncidentReport.reportedBy, currentIncidentReport.reportDatetime, currentIncidentReport.reporterName, currentIncidentReport.reportingAcCallsign,
        currentIncidentReport.reportingAcType, currentIncidentReport.reportingAcDepartureAerodome, currentIncidentReport.reportingAcDestinationAerodrome,
        currentIncidentReport.atsActionDetails, currentIncidentReport.aircraftsList]
    */
}
export default AutosaveReport;