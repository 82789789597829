import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
//import Breadcrumbs from "@mui/material/Breadcrumbs";
//import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom"
import Grid from "@mui/material/Grid";
//import TopNavBar from "./TopNavBar";
//import FacilityDropdown from "./forms/FacilityDropdown";
//import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IncidentReportsTables from "./IncidentReportsTables";
import { useCentroStore } from "../store";
import { useUser, useAllFacilityTypes } from '../store/queries/userQueries';
import { useFacilityTypes } from '../store/queries/facilityQueries';
import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions, Tooltip } from '@mui/material';
import HeaderSection from "./header/HeaderSection";
import { useClearState } from "./Helpers/HelperFunctions";
import FacilityListTables from "./FacilityListTypes";
import FacilityListTypes from "./FacilityListTypes";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const FacilityType = (props) => {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const userId = useCentroStore((state) => state.userId);
    const { userData } = useUser(userId);
    const userDataPermissions = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0];
    const { addFacilityType } = useFacilityTypes();
    const { data: FacilityTypeData, refetch, isLoading } = useAllFacilityTypes();
    //const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [newFacilityType, setNewFacilityType] = React.useState(null);
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState("");

    const handleOpen = () => {
        if (userDataPermissions.createFac === 1) {
            setNewFacilityType({
                typeId: 0,
                typeName: null
            });
            setOpen(true);
        }
        else {
            if (EnglishLanguage) {
                alert("You do not have permission to add facility types.");
            }
            else {
                alert("No tiene permiso para agregar tipos de instalaciones.");
            }
        }
    };

    const handleClose = () => {
        setErrorCode(0);
        setNewFacilityType(null);
        setOpen(false);
    };

    const handleTextFieldChange = (event, field) => {
        setNewFacilityType(prevState => ({
            ...prevState,
            [field]: event.target.value
        }));
    };

    const handleAdd = async () => {
        console.log(newFacilityType); // to log the updated Facility data
        let response;
        response = await addFacilityType.mutateAsync(newFacilityType).catch(function (error) {
            setErrorCode(error.response.status);
            if (error.response.status === 400) {
                if (EnglishLanguage) {
                    setErrorMsg("There was an error adding New Facility Type. Required fields are missing.");
                }
                else {
                    setErrorMsg("Hubo un error al agregar el nuevo tipo de instalaci\xF3n. Faltan campos obligatorios.");
                }
            }
            else {
                setErrorMsg(error.message);
            }
        });
        if (response.status === 200) {
            setErrorCode(0);
            refetch(); // Refetch query to update datagrid
            if (EnglishLanguage) {
                alert("Facility Type has been successfully added.");// Successful add message
            }
            else {
                alert("El tipo de instalaci\xF3n se ha agregado con \xE9xito.");// Successful add message
            }
        }
        else {
            setErrorCode(response.status);
            setErrorMsg(response.statusText);
            if (EnglishLanguage) {
                alert("There was an error adding Facility Type. Facility Type could not be added.");// Error
            }
            else {
                alert("Hubo un error al agregar el tipo de instalaci\xF3n. No se pudo agregar el tipo de instalaci\xF3n.");// Error
            }
        }
        handleClose();
    };

    const modalBody = newFacilityType ? (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ textTransform: "capitalize" }}>{EnglishLanguage ? "Add New Facility Type" : "Agregar Nuevo Tipo de Instalaci\xF3n"}</DialogTitle>
            <DialogContent>
                {/*<TextField label="ID" defaultValue={newFacilityType.typeId} onChange={(event) => handleTextFieldChange(event, 'typeId')} fullWidth variant="standard" margin="normal" />*/}
                <Typography>{EnglishLanguage ? "Facility Type Name" : "Nombre de Tipo de Instalaci\xF3n"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
                <TextField label={EnglishLanguage ? "Facility Type Name" : "Nombre de Tipo de Instalaci\xF3n"} defaultValue={newFacilityType.typeName} onChange={(event) => handleTextFieldChange(event, 'typeName')} fullWidth variant="standard" margin="normal" />
            </DialogContent>
            <DialogActions>
                {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>{errorMsg}</Grid></Grid> : null}
                <Button onClick={handleClose} color="primary">{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
                <Button onClick={handleAdd} color="primary">{EnglishLanguage ? "Save" : "Guardar"}</Button>
            </DialogActions>
        </Dialog>
    ) : null;

  return (
    <Container component="main" maxWidth="none" id="incident-reports" sx={{padding: "0!important"}}>
      {modalBody}
      <HeaderSection className="content-wrap" sectionName="Facility Types" sectionNameES="Tipo de facilidad"/>
      <Grid
       className="page-wrap"
        container
        xs={12}
        mr={2}
        sx={{ position: "relative", left: ".5rem" }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                 {EnglishLanguage ? ("Facility Types List") : ("lista de tipos de instalaciones")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >
              <Item elevation={0} sx={{ background: "transparent" }} onClick={handleOpen} >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "500" }}
                    mb={3}
                    mt={1.25}
                    className="add-text-ir"
                  >
                    {EnglishLanguage ? ("Add Type") : ("AGREGAR TIPO DE INSTALACI\xF3N")}
                  </Typography>{" "}
              </Item>

              <Item
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  top: "9px",
                  right: "10px",
                  position: "relative",
                }} 
                onClick={handleOpen}
              >
                  <PlaylistAddIcon className="add-todo-icon" />
              </Item>
            </Grid>
          </Grid>
          <Grid lg={12} px={2} mt={3} sx={{ height: "calc(100% - 66px)" }}>
            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
              <FacilityListTypes />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FacilityType;
