import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
export default function ReporterName() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setReporterName = useCentroStore((state) => state.setreportername);

    const blurHandler = (event) => {
        //currentIncidentReport.reporterName = event.target.value;
        setReporterName(event.target.value);
    }
  return (
<TextField
          id="standard-multiline-static"
          label={EnglishLanguage ? "Name" : "Nombre"}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          rows={1}
          defaultValue={currentIncidentReport.reporterName }
          variant="standard"
          onBlur={blurHandler }
        />
  );
};