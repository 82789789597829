import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
'ATC Altitude',
'ATC Course',
'Airspeed',
'Airspace',
'Did Not Close Flight Plan',
'VFR when IFR Required',
'Missed Reporting Point',
'Lateral Track Conformity',
'Vertical Track Conformity',
'Spillout',
'Unauthorized Low Level Flying',
];

const optionsSpanish = [
'Altitud ATC',
'Curso ATC',
'Velocidad del Aire',
'Espacio A\xE9reo',
'Plan de Vuelo no Cerrado',
'VFR Cuando se Requiere IFR',
'Punto de Informe Perdido',
'Conformidad de la V\xEDa Lateral',
'Conformidad de la V\xEDa Vertical ',
'Derrame',
'Vuelo de Bajo Nivel no Autorizado',
];

export default function DeviationTypeDropdown() {

    //const [age, setAge] = React.useState('');
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
    const setViolationType = useCentroStore((state) => state.setviolationtype);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);

  const handleChange = (event) => {
      //setAge(event.target.value);
      setViolationType(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentIncidentReport.violationType}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
           options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))
          :
          optionsSpanish.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}

