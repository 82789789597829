import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TopNavBar from "./TopNavBar";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useCentroStore } from "../store";
//import { useEffect } from "react";
import mapspic from "./../assets/images/maps-pic.png";
import HeaderSection from "./header/HeaderSection";
import MapLocationFind from "./stepper/step-6-location-conflicts/general-informtation/MapLocationFind";
import MapFilters from "./maps/MapsFilters";
import MapsComponent from "./maps/MapsComponent";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const Maps = (props) => {
  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  //useEffect(()=>{setuserloggedin("Yes")})

  return (
    <Container component="main" maxWidth="none" id="maps">
      <HeaderSection className="content-wrap" sectionName="Maps" sectionNameES="Mapas" />

      <Grid
       className="page-wrap"
       container
       xs={12}
       mr={2}
       sx={{ position: "relative", left: ".5rem" }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 217px)" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                {EnglishLanguage ? "Maps" : "Mapas"}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            ></Grid>
          </Grid>
          <Grid lg={12} px={2} mt={1} sx={{ height: "calc(100% - 66px)" }} className="map-wrapper">
            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
              {/* <img
                src={mapspic}
                width="auto"
                className="map-pic"
                alt="inVision pic"
              /> */}<MapFilters />
                      <MapsComponent />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Maps;
