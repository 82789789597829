import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { StepButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import IncidentReportStepOne from "./stepper/step-1-incident-type/IncidentReportStepOne";
import IncidentReportStepTwo from "./stepper/step-2-general/IncidentReportStepTwo";
import IncidentReportStepFive from "./stepper/step-5-weather-visibility/IncidentReportStepFive";
import IncidentReportStepSix from "./stepper/step-6-location-conflicts/IncidentReportStepSix";
import StepTextOne from "./StepTextOne";
import IncidentReportStepSeven from "./stepper/step-7-findings/IncidentReportStepSeven";
import IncidentReportStepEight from "./stepper/step-8-bird-strike/IncidentReportStepEight";
import { useCentroStore } from "../store";
import { useReports } from "../store/queries/incidentReportQueries";
import { useCustodyTrails } from "../store/queries/incidentReportQueries";
// import { useAircrafts } from "../store/queries/aircraftQueries";
import StepTextThree from "./StepTextThree";
import StepTextSix from "./StepTextSix";
import { useUser } from "../store/queries/userQueries";
import IncidentReportStepThree from "./stepper/step-3-aircraft/IncidentReportStepThree";
import IncidentReportStepFour from "./stepper/step-4-additional-information/IncidentReportStepFour";
import AutosaveReport from "./Autosave/AutosaveReport";
import UpdateAircraftList from "./Autosave/UpdateAircraftList";

function getSteps() {
  return [
    <StepTextOne />,
    "General",
    <StepTextThree />,
    "Additional Information",
    "Weather",
    // <StepTextSix />,
    "Location and Conflicts",
    "Findings",
  ];
}

function getStepsSpanish() {
    return [
        <StepTextOne />,
        "General",
        <StepTextThree />,
        "Informaci\xF3n Adicional",
        "Clima y visibilidad",
        // <StepTextSix />,
        "Ubicaci\xF3n y Conflictos",
        "Pesta\xF1a Hallazgos",
    ];
}

function getStepsDraft() {
    return [
        <StepTextOne />,
        "General",
        <StepTextThree />,
        "Additional Information",
        "Weather",
        // <StepTextSix />,
        "Location and Conflicts",
    ];
}

function getStepsDraftSpanish() {
    return [
        <StepTextOne />,
        "General",
        <StepTextThree />,
        "Informaci\xF3n Adicional",
        "Clima y visibilidad",
        // <StepTextSix />,
        "Ubicaci\xF3n y Conflictos",
    ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <IncidentReportStepOne />;
    case 1:
      return <IncidentReportStepTwo />;
    case 2:
      return <IncidentReportStepFour />;
    case 3:
      return <IncidentReportStepThree />;
    case 4:
      return <IncidentReportStepFive />;
    case 5:
      // return <IncidentReportStepEight />;
      return <IncidentReportStepSix />;
    case 6:
      return <IncidentReportStepSeven />;
    // case 7:

    default:
      return "Unknown step";
  }
}

// CNS Headers
function getStepsCNS() {
    return [
        <StepTextOne />,
        "General",
        "Additional Information",
        "Incident Details",
        "Findings",
    ];
}

function getStepsCNSSpanish() {
    return [
        <StepTextOne />,
        "General",
        "Informaci\xF3n Adicional",
        "Detalles del incidente",
        "Pesta\xF1a Hallazgos",
    ];
}

function getStepsCNSDraft() {
    return [
        <StepTextOne />,
        "General",
        "Additional Information",
        "Incident Details",
    ];
}

function getStepsCNSDraftSpanish() {
    return [
        <StepTextOne />,
        "General",
        "Informaci\xF3n Adicional",
        "Detalles del incidente",
    ];
}

function getStepContentCNS(step) {
  switch (step) {
    case 0:
      return <IncidentReportStepOne />;
    case 1:
      return <IncidentReportStepTwo />;
    case 2:
      return <IncidentReportStepThree />;
    case 3:
      return <IncidentReportStepFour />;
    case 4:
      return <IncidentReportStepSeven />;

    default:
      return "Unknown step";
  }
}

export default function IncidentReportWrapper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const currentIncidentReport = useCentroStore(
    (state) => state.currentincidentreport
  );
  const currentIrStatus = currentIncidentReport.status;
  const userFacilityId = useCentroStore((state) => state.userFacilityId);
  //const aircrafts = useCentroStore((state) => state.aircrafts);
  const reportTypeId = currentIncidentReport.reportTypeId;
  const setIrId = useCentroStore((state) => state.setIrId);
  const setreportnumber = useCentroStore((state) => state.setreportnumber);
  const userId = useCentroStore((state) => state.userId);
  const { userData } = useUser(userId);
  const activeUserRole = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId));

  const setLoadingIncidentReports = useCentroStore(
    (state) => state.setLoadingIncidentReports
  );
  const { addReport, updateReport, submitReport, approveReport } = useReports(userFacilityId, currentIncidentReport.irId);
  //const { aircraftsFromDb, addAircraft, updateAircraft } = useAircrafts(currentIncidentReport.irId);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

  const { addCustody } = useCustodyTrails(currentIncidentReport.irId);
  const irHistory = useCentroStore((state) => state.irHistory);
  const navigate = useNavigate(); // for navigating to different pages

    // Conditional accounts for CNS Steps
    const steps = reportTypeId === 2 ? getStepsCNS() : getSteps();
    const stepsDraft = reportTypeId === 2 ? getStepsCNSDraft() : getStepsDraft(); // Custom list of steps for a draft report, that doesn't show them the findings page
    const stepsSpanish = reportTypeId === 2 ? getStepsCNSSpanish() : getStepsSpanish(); // Spanish
    const stepsDraftSpanish = reportTypeId === 2 ? getStepsCNSDraftSpanish() : getStepsDraftSpanish();


    if (activeStep === 0 && currentIncidentReport.irId !== 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleNext = async () => {
        if (activeStep !== 0) {
            let response;

            irHistory.userId = userData?.data?.data.userId; // Grabbing current logged-in userId
            irHistory.irId = currentIncidentReport.irId;

            if (currentIncidentReport.reportNumber === undefined || currentIncidentReport.reportNumber === '') {
                response = await addReport.mutateAsync(currentIncidentReport); //Save Draft
                setIrId(response?.data?.reportId);
                setreportnumber(response?.data?.reportNumber);
                setLoadingIncidentReports(false);
            }
            else if (activeStep !== steps.length - 1) {
                response = await updateReport.mutateAsync(currentIncidentReport);
            }
            /*
            if (activeStep === 3) {
                if (aircrafts.length > 0 && !aircraftsFromDb.data || aircraftsFromDb.data.data.length === 0) {
                    response = await addAircraft.mutateAsync(aircrafts);
                }
                else if (aircrafts.length > 0 && aircraftsFromDb.data.data.length > 0) {
                    response = await updateAircraft.mutateAsync(aircrafts);
                }
            }
            */
            if (activeStep === steps.length - 2) {
                // Status is Draft
                if (currentIrStatus === 'Draft' || currentIncidentReport.status === undefined) {
                    response = await submitReport.mutateAsync(currentIncidentReport); //Submit report
                    if (response.status === 200) {
                        irHistory.irHistoryType = 2; // irHistoryType = 2: Submit
                        await addCustody.mutateAsync(irHistory); //Add Custody
                        alert(EnglishLanguage ? "Report submitted" : "Informe enviado");
                        navigate('/home');
                        //setActiveStep((prevActiveStep) => prevActiveStep + 1); // Skip over Findings page for reporters
                    }
                }
            }
            if (activeStep === steps.length - 1) {
                // Status is Submitted
                if (currentIrStatus === 'Submitted') {
                    if (currentIncidentReport.facilityManagementEscalate) {
                        //Escalate report
                        response = await approveReport.mutateAsync({ entity: currentIncidentReport, userId: userData?.data?.data.userId, userRoleId: userData?.data?.data.roleId}); 
                        if (response.status === 200) {
                            irHistory.irHistoryType = 4; // irHistoryType = 4: Escalate
                            await addCustody.mutateAsync(irHistory); //Add Custody
                            alert(EnglishLanguage ? "Report escalated to Facility Management" : "Informe escalado a Gesti\xF3n de instalaciones");
                            navigate('/home');
                        }
                    }
                    else {
                        //Approve report
                        if (currentIncidentReport.submitterUserId === userId) { // Check for submitter trying to approve/escalate their own report
                            alert(EnglishLanguage ? "Submitter cannot approve their own report" : "El remitente no puede aprobar su propio informe");
                        }
                        response = await approveReport.mutateAsync({ entity: currentIncidentReport, userId: userData?.data?.data.userId, userRoleId: userData?.data?.data.roleId }); 
                        if (response.status === 200) {
                            irHistory.irHistoryType = 3; // irHistoryType = 3: Approve
                            await addCustody.mutateAsync(irHistory); //Add Custody
                            alert(EnglishLanguage ? "Report approved" : "Informe aprobado");
                            navigate('/home');
                        } 
                    }
                }
                // Status is Escalated
                else if (currentIrStatus === 'Escalated') {
                    //Approve report
                    response = await approveReport.mutateAsync({ entity: currentIncidentReport, userId: userData?.data?.data.userId, userRoleId: userData?.data?.data.roleId }); 
                    if (response.status === 200) {
                        irHistory.irHistoryType = 3; // irHistoryType = 3: Approve
                        await addCustody.mutateAsync(irHistory); //Add Custody
                        alert(EnglishLanguage ? "Report approved" : "Informe aprobado");
                        navigate('/home');
                    }
                }
            }
        } 
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  /*
    const handleReject = async () => {
        if (activeStep !== 0) {
            let response;
            irHistory.userId = userInfo.userId; // Grabbing current logged-in userId
            irHistory.irId = currentIncidentReport.irId;
            if (activeStep === steps.length - 1) {
                // Status is Submitted
                if (currentIrStatus === 'Submitted' || currentIrStatus === 'Escalated') {
                    //Reject report
                    response = await rejectReport.mutateAsync({irId: currentIncidentReport.irId, userRoleId: userInfo.userRole});
                    if (response.status === 200) {
                        alert("Report returned to reporter");
                        irHistory.irHistoryType = 5; // irHistoryType = 5: Reject/Return
                        await addCustody.mutateAsync(irHistory); //Add Custody
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                    else {
                        alert("User doesn't have permission to return report");
                    }
                }
            }
        }
    };*/

  // Handles saving/updating the report without incrementing the stepper 
  const handleSave = async () => {
      if (activeStep !== 0) {
          let response;
          if (currentIncidentReport.status === "Draft" || currentIncidentReport.status === undefined) {
              response = await updateReport.mutateAsync(currentIncidentReport);
          }
          else {
              // Prevent changes being made to Submitted/Escalated/Approved Reports
              alert(EnglishLanguage ? "Cannot make changes to this report with status: " + currentIncidentReport.status 
                  : "No se pueden realizar cambios en este informe con estado: " + currentIncidentReport.status);
              this.reject();
          }
      }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

    const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="stepper-wrapper">
      <Grid container flexDirection="column" sx={{overflow: "hidden"}}>
        <Grid item mb={0} className="stepper-box-wrap">
          {(currentIrStatus === "Draft"  || currentIrStatus === undefined || 
           (activeUserRole.permissions[0].approveReport !== 1 && activeUserRole.permissions[0].approveEscalatedReport !== 1 )) ? 
              (<Stepper nonLinear activeStep={activeStep}>
                {EnglishLanguage ? stepsDraft.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  const visited = index < activeStep ? 'visited' : '';

                return (
                  <Step key={label} {...stepProps}>
                    <StepButton color="inherit" onClick={handleStep(index)} className={visited}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </StepButton>
                  </Step>
                );
              }) : stepsDraftSpanish.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  const visited = index < activeStep ? 'visited' : '';

                return (
                  <Step key={label} {...stepProps}>
                    <StepButton color="inherit" onClick={handleStep(index)} className={visited}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          ) : (
            <Stepper nonLinear activeStep={activeStep}>
              {EnglishLanguage ? steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                const visited = index < activeStep ? 'visited' : '';

                return (
                  <Step key={label} {...stepProps}>
                    <StepButton color="inherit" onClick={handleStep(index)} className={visited}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </StepButton>
                  </Step>
                );
              }) : stepsSpanish.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                const visited = index < activeStep ? 'visited' : '';

                return (
                  <Step key={label} {...stepProps}>
                    <StepButton color="inherit" onClick={handleStep(index)} className={visited}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </Grid>
        <Grid item >
        
        {activeStep === steps.length ? (
          <div>
            <br/>
            <Typography sx={{color: "#FFF"}}>All steps completed - This Report Has Been Submitted</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
            <Grid container flexDirection="column">
              <Grid item className="steps-wrapper">
              {(activeUserRole.permissions[0].createReport === 1 && (currentIrStatus === "Draft"  || currentIrStatus === undefined)) ? 
                <div>{reportTypeId === 2 ? getStepContentCNS(activeStep) : getStepContent(activeStep)}</div>
                :
                (activeUserRole.permissions[0].approveReport === 1 && currentIrStatus === "Submitted") ?
                <div>{reportTypeId === 2 ? getStepContentCNS(activeStep) : getStepContent(activeStep)}</div>
                :
                (activeUserRole.permissions[0].approveEscalatedReport === 1 && currentIrStatus === "Escalated") ?
                <div>{reportTypeId === 2 ? getStepContentCNS(activeStep) : getStepContent(activeStep)}</div>
                :
                (currentIrStatus === "Approved") ?
                <div className="disabled">{reportTypeId === 2 ? getStepContentCNS(activeStep) : getStepContent(activeStep)}</div>
                :
                (activeUserRole.permissions[0].fullAccess === 1 && currentIrStatus !== "Approved") ? 
                <div>{reportTypeId === 2 ? getStepContentCNS(activeStep) : getStepContent(activeStep)}</div>
                :
                <div className="disabled">{reportTypeId === 2 ? getStepContentCNS(activeStep) : getStepContent(activeStep)}</div>
              }
              </Grid>
              <Grid item>
              <div className="ir-btn-wrapper">
              {activeStep > 1 ? (
              <Button onClick={handleBack}>{EnglishLanguage ? "Back" : "Atr\xE1s"}</Button>
              ) : (
                <Button ></Button>  
              )
              } 
    <Grid container display="flex" justifyContent="flex-end">
              {activeStep === steps.length - 2  ?
                 (currentIrStatus === "Draft"  || currentIrStatus === undefined) ?
                    <Button
                    variant="contained"
                    className="step-btn save"
                    onClick={handleSave}
                    >
                     {EnglishLanguage ? "Save" : "Guardar"}
                   </Button> 
                   :
                   ''
                :
                ''
              }
             
                {(activeStep === steps.length - 2  ?  
                <Button
                variant="contained"
                className="step-btn"
                onClick={handleNext}
                >
                 {(currentIrStatus === "Draft"  || currentIrStatus === undefined) ? 
                 (EnglishLanguage ? "Submit" : "Enviar")
                 : 
                 (EnglishLanguage ? "Save/Advance" : "Guardar/Ventaja")} 
                </Button> 
                : 
                activeStep === steps.length - 1 ? 
                (currentIrStatus === "Approved" ? '' :
                   ((activeUserRole.permissions[0].approveReport === 0 && activeUserRole.permissions[0].approveEscalatedReport === 0) ? 
                    <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled
                    >
                     {EnglishLanguage ? "Approve" : "Aprobar"} 
                    </Button> :
                    <Button
                    variant="contained"
                    className="step-btn"
                    onClick={handleNext}
                    >
                     {EnglishLanguage ? "Approve" : "Aprobar"} 
                    </Button> 
                    )
                )
                : 
                <Button
                variant="contained"
                className="step-btn"
                onClick={handleNext}
                >
                 {EnglishLanguage ? "Save/Advance" : "Guardar/Avanzar"} 
                </Button> )
                }
                </Grid>
            </div>
              </Grid>
            </Grid>
           
        )}
        </Grid>
      </Grid>
      <Grid item>
       <AutosaveReport />
      </Grid>
      <Grid item>
       <UpdateAircraftList />
      </Grid>
    </div>
  );
}
