import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';
import { useFacilities } from '../../../../store/queries/facilityQueries';

export default function AircraftTwoFacilityDropdown() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const aircraftOne = useCentroStore((state) => state.aircraftOne);
    const aircraftTwo = useCentroStore((state) => state.aircraftTwo);
    const setAircraftTwoFacility = useCentroStore((state) => state.setAircraftTwoFacility);
    const setAircraftTwoFac = useCentroStore((state) => state.setAircraftTwoFac);
    const aircraftTwoFac = useCentroStore((state) => state.aircraftTwoFac);
    const { facilities } = useFacilities();
    const handleChange = (event) => {
        var fac = facilities?.data?.data.find(f => f.facilityId === event.target.value);
        setAircraftTwoFac(fac);
        setAircraftTwoFacility(event.target.value);
    };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl fullWidth variant="standard">
              <Select
                  className='select-wrap'
                  displayEmpty
                  value={!aircraftTwoFac ? "": aircraftTwoFac.facilityId}

                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Without label' }}

              >
                  <MenuItem disabled value=''>
                      {EnglishLanguage ? ("Select") : ("Seleccionar")}
                  </MenuItem>
                  {facilities?.data?.data.map(({ facilityId, name }) =>
                      <MenuItem
                          key={facilityId}
                          value={facilityId}>
                          {name}
                      </MenuItem>

                  )}
              </Select>
      </FormControl>

    </Box>
  );
}

