import React, { useState, useEffect } from "react";
import { Grid, Button, IconButton, Snackbar, Checkbox, FormControlLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OccurrenceReportTipsText from "./tips/OccurrenceReportTipsText";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import DropdownTipImage from "./tips/DropdownTipImage";
import DropdownOptionsTipsText from "./tips/DropdownOptionsTipsText";
import { useCentroStore } from "../store";

const tips = [
  {
    image: <WarningRoundedIcon />,
    text: <OccurrenceReportTipsText />,
    },
    /* DISABLED UNTIL GRAPHS ARE IMPLEMENTED
  {
    image: <DropdownTipImage />,
    text: <DropdownOptionsTipsText />,
  },*/
];

const TipsComponent = () => {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [tipIndex, setTipIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpen(true);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDisableTips = (event) => {
    setDisabled(event.target.checked);
    setOpen(false);
  };

  const handleNextTip = () => {
    if (tipIndex < tips.length - 1) {
      setTipIndex(tipIndex + 1);
    } else {
      setOpen(false);
    }
  };

  const handlePrevTip = () => {
    if (tipIndex > 0) {
      setTipIndex(tipIndex - 1);
    }
  };

  if (disabled) {
    return null;
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      message={
        <Grid container direction="column" alignItems="flex-end" sx={{ display: "flex!important" }} spacing={0}>
          <Grid container direction="column">
            <Grid container className="tips-header-container">
            <Grid item justifyContent="flex-end" sx={{ display: "flex!important" }}>
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon fontSize="small" sx={{fill: "#FFF"}} />
              </IconButton>
            </Grid>
            <Grid container alignItems="center" spacing={0} flex justifyContent="space-between" className="tips-image-wrap">
                <Grid item className="tips-image-inner-wrap">
                  {tips[tipIndex].image}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="tips-text-container">

              <Grid container alignItems="center" spacing={0}>
                <Grid item>{tips[tipIndex].text}</Grid>
              </Grid>
            </Grid>
            <Grid item display="flex" justifyContent="space-between">
              <Button size="small" onClick={handlePrevTip} disabled={tipIndex === 0} sx={{ color: "#a8bfcc" }}>
                {EnglishLanguage ? ("Previous tip") : ("Tip anterior")}
              </Button>
              <Button size="small" onClick={handleNextTip} className="tips-link-text">
                {tipIndex < tips.length - 1 ? EnglishLanguage ? ("Next tip") : ("Siguiente tip") : EnglishLanguage ? ("Close tips") : ("Cerrar tip")}
              </Button>
            </Grid>
            <Grid item justifyContent="flex-end" sx={{ display: "flex!important" }}>
              <FormControlLabel
                control={<Checkbox onChange={handleDisableTips} />}
                label={EnglishLanguage ? ("Disable all tips") : ("Desactivar todos los tip")} 
                labelPlacement="start"
                id="tips-disable-wrap"
              />
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export default TipsComponent;
