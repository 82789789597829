import { useMutation, useQuery, useQueryClient } from "react-query";
// import { addAircraftsToDraft, fetchAircraftsByIncidentReportId, fetchAircraftTypesByName, fetchEquipmentTypes, fetchOperatorsByName, updateAircraftsOnDraft } from "../api/apiEndpoints/apiEndpoints";
import { fetchAircraftsByIncidentReportId, fetchAircraftTypesByName, fetchEquipmentTypes, fetchOperatorsByName } from "../api/apiEndpoints/apiEndpoints";

// **** CURRENTLY UNUSED **** //
export function useAircrafts(irId) {
    const queryClient = useQueryClient();

    const aircraftsFromDb = useQuery(["aircrafts", irId], () => fetchAircraftsByIncidentReportId(irId), { refetchOnWindowFocus: false, retry: 2, enabled: (irId !== undefined && irId !== 0), refetchInterval: 60 * 3000 });

    const equipmentTypes = useQuery("equipmentTypes", fetchEquipmentTypes, { refetchOnWindowFocus: false, enabled: irId !== 0, retry: 0, staleTime: 60 * 1000 });

    /*
    const addAircraft = useMutation(addAircraftsToDraft, {
        onSuccess: () => {
            queryClient.invalidateQueries(["aircrafts", irId]);
        }
    });

    const updateAircraft = useMutation(updateAircraftsOnDraft, {
        onSuccess: () => {
            queryClient.invalidateQueries(["aircrafts", irId]);
        }
    });
    */

    // return { aircraftsFromDb, addAircraft, updateAircraft, equipmentTypes };
    return { aircraftsFromDb, equipmentTypes };
}

export function useDebouncedAircraftTypeSearch(model) {

    const types = useQuery(["search-types", model], () => fetchAircraftTypesByName(model), { refetchOnWindowFocus: false, enabled: !!model, staleTime: 60 * 1000 * 5 });

    return { types };
}

export function useDebouncedOperatorSearch(code) {
    const operators = useQuery(["operators", code], () => fetchOperatorsByName(code), { refetchOnWindowFocus: false, enabled: !!code, staleTime: 60 * 1000 * 5 });
    return { operators };
}