import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';
import { useClearState } from '../../../Helpers/HelperFunctions';

const options = [
    {idx: 1, age: "1 Aircraft" },
    { idx: 2, age: "2 Aircraft" },
    { idx: 3, age: " Aircraft and Vehicle" },
    { idx: 4, age: "Aircraft and Pedestrian"}
];

const optionsSpanish = [
    { idx: 1, age: "1 Aeronave" },
    { idx: 2, age: "2 Aeronaves" },
    { idx: 3, age: " Aeronave Y Veh\xEDculo" },
    { idx: 4, age: "Aeronave Y Peat\xF3n" }
];

export default function NumberAircraftSurfaceDropdown() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
    const setaircraftcount = useCentroStore((state) => state.setnumberofaircraft);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const aircrafts = useCentroStore((state) => state.aircrafts);
    const { ClearAC2 } = useClearState();

    const handleChange = (event) => {
        var count = parseInt(event.target.value);
        if ((count === 1 || count === 3 || count === 4) && currentIncidentReport.aircraftCount === 2) {
            ClearAC2();
        }
        setaircraftcount(parseInt(event.target.value));
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentIncidentReport.aircraftCount}
          onChange={handleChange}
          displayEmpty
         
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="0">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
           {EnglishLanguage ? 
            options.map(({ idx, age }) => (
            <MenuItem
              key={idx}
              value={idx}
            >
              {age}
            </MenuItem>
          ))
          :
          optionsSpanish.map(({ idx, age }) => (
            <MenuItem
              key={idx}
              value={idx}
            >
              {age}
            </MenuItem>))}
        </Select>
      </FormControl>

    </Box>
  );
}

