import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import { Link } from 'react-router-dom';
import { useCentroStore } from '../store';
import { useClearState } from './Helpers/HelperFunctions';



export default function SpeedDialTooltipOpen() {
  const { ClearReport } = useClearState();
    
  const handleNewIncidentClick = async () => {
      ClearReport();
  };
  //const userloggedin = useCentroStore((state) => state.userloggedin);
    const isAuthenticated = useCentroStore((state) => state.isAuthenticated);
  const actions = [
    { icon: <Link to="/create-new-incident-report" className="ir-sa-button-link" onClick={handleNewIncidentClick }><PlaylistAdd /></Link>, name: <Link to="/create-new-incident-report" className="ir-sa-button-link" onClick={handleNewIncidentClick }>Occurrence Report</Link> },
    { icon: <PlaylistAdd />, name: 'Safety Assessment' },
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  const currentincidentreport = useCentroStore(
    (state) => state.currentincidentreport
  );
  console.log(currentincidentreport)
  return (
  
    <Box sx={{  flexGrow: 1 }}>
        {(isAuthenticated === true) &&(
          <>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
      </>
       )}
    </Box>
 
  );
}