import { Container, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
//import HomeIcon from "@mui/icons-material/Home";
//import { Link }from "react-router-dom";
import Grid from "@mui/material/Grid";
import TopNavBar from "./TopNavBar";
import FacilityDropdown from "./forms/FacilityDropdown";

import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IncidentReportSelector from "./IncidentReportSelector";
import IncidentReportWrapper from "./IncidentReportWrapper";
import { useCentroStore } from "../store";
import { useEffect } from "react";
import ResponsiveDialog from "./ResponsiveDialog";
//import IncidentReportWrapperES from "./IncidentReportWrapperES";
import HeaderSection from "./header/HeaderSection";
import CustodyTrailModal from "./CustodyTrailModal";
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import TipsComponent from "./TipsComponent";


const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const CreateNewIncidentReport = (props) => {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const currentincidentreport = useCentroStore(
    (state) => state.currentincidentreport
  );
  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);

  //useEffect(() => {
  //    //setuserloggedin("Yes");
  //    if (isFromHome === true) {
  //    //    //clear all the saved info
  //        setCurrentIncidentReport(initialCurrentIncidentReport);
  //        setCurrentReportingAcType({});
  //        removeAllDeviations([]);
  //        setCurrentCategory({});
  //        setCurrentSubcategory({});
  //        setCurrentPosition({});
  //        setIsTrainingInProgress("");
  //        setIsSignificantEvent("");
  //        setIsInjury("");
  //        setIsMedicalHelp("");
  //        setIsLawContacted("");
  //        setIsBrasherWarning("");
  //        setIsTerrainWarning("");
  //        setIsPilotDeviation("");
  //        removeAllAircrats([]);
  //        removeAircraftOneWeather([]);
  //        removeAircraftTwoWeather([]);
  //        setCurrentAircraftOne(initialCurrentAircraft);
  //        setCurrentAircraftTwo(initialCurrentAircraft);
  //        setAircraftOneAvoidActionTaken("");
  //        setAircraftTwoAvoidActionTaken("");
  //        setAircraftOneFac({});
  //        setAircraftTwoFac({});
  //        setAircraftOnePos({});
  //        setCurrentAircraftOneType({});
  //        setCurrentAircraftOneOperator({});
  //        removeAircraftOneUnknowns([]);
  //        removeAircraftOneLights([]);
  //        setAircraftTwoPos({});
  //        setCurrentAircraftTwoType({});
  //        setCurrentAircraftTwoOperator({});
  //        removeAircraftTwoUnknowns([]);
  //        removeAircraftTwoLights([]);
  //        setLoadingIncidentReports(true);
  //    }
  //},[isFromHome])
  const isDraft = useCentroStore((state) => state.isDraft);
  return (
    <Container component="main" maxWidth="none">

      <HeaderSection className="content-wrap"
        sectionName="Create New Report"
        sectionNameES="Crear Nuevo Informe de Ocurrencias"
      />
<Grid container className="form-footer">
          <Grid xs={12} md={9} xl={9} display="flex">
   
                <Item elevation={0} sx={{ background: "transparent", paddingLeft: "0!important" }} display="flex">
                  <Typography
                    id="modal-modal-title"
                    component="h4"
                    sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
                    class="left-ir-number"
                  >
                    {currentincidentreport.reportNumber === "" ? (EnglishLanguage ? "Unsaved Occurrence Report" : "Informe de Ocurrencias no Guardados") : currentincidentreport.reportNumber}
                  </Typography>
                </Item>
            
              {currentincidentreport.reportNumber !== "" && (
       
                <Item display="flex" elevation={0} id="status-button-wrap" sx={{ background: "transparent", cursor: "pointer" }}>
                  <CustodyTrailModal />
                  
                </Item>
           
              )}

              {currentincidentreport.status === "Submitted" && (
       
                <Item display="flex" elevation={0} id="status-button-wrap" sx={{ background: "transparent"}}>
         <Tooltip title={EnglishLanguage ? "Submitted (Editable by Reviewers) " : "Enviado (Editable por revisores)"}>
        <SaveAltOutlinedIcon sx={{fill:"green!important"}} />
        </Tooltip>
                </Item>
           
              )}
              {currentincidentreport.status === "Escalated" && (
       
                <Item display="flex" elevation={0} id="status-button-wrap" sx={{ background: "transparent"}}>
         <Tooltip title={EnglishLanguage ? "Escalated (Editable by Upper Management) " : "Editable por la alta direcci\xF3n"}>
        <SaveAltOutlinedIcon sx={{fill:"green!important"}} />
        </Tooltip>
                </Item>
           
              )}
               {currentincidentreport.status === "Approved" && (
       
                <Item display="flex" elevation={0} id="status-button-wrap" sx={{ background: "transparent"}}>
         <Tooltip title={EnglishLanguage ? "Approved (Non-Editable) " : "Aprobado (no-editable)"}>
        <SaveAltOutlinedIcon sx={{fill:"green!important"}} />
        </Tooltip>
                </Item>
           
              )}
                {currentincidentreport.status === "Draft"  && (
       
       <Item display="flex" elevation={0} id="status-button-wrap" sx={{ background: "transparent" }}>
         <Tooltip title={EnglishLanguage ? "Draft (Editable) " : "Borrador (Editable)"}>
        <PendingActionsOutlinedIcon />
        </Tooltip>
       </Item>
  
     )}
            </Grid>
          <Grid md={3} lg={3}>
            <Item
              elevation={0}
              sx={{ background: "transparent" }}
              className="search-box-wrapper"
            >
              <Typography id="modal-modal-title" component="h4">
                <IncidentReportSelector />
              </Typography>
            </Item>
            </Grid>
          </Grid>
      <Grid
        className="page-wrap"
        container
        sx={{ padding: "0", margin: "0", width: "calc(100vw - 20rem)", borderTopLeftRadius: "0!important", borderTopRightRadius: "0!important" }}
      >
        <Grid container spacing={0} xl={12} padding={1} sx={{height: "calc(100vh - 12.15rem)!important"}}>
        <Grid lg={12} sx={{height: "auto",}}>
            <Item sx={{ background: "transparent!important", boxShadow: "none!important" }}>
             
            {/*EnglishLanguage ? (<IncidentReportWrapper />) : (<IncidentReportWrapperES />)*/}
              <IncidentReportWrapper />
            </Item>
          </Grid>
          

          
        </Grid>
      </Grid>
      
    </Container>
    
  );
};

export default CreateNewIncidentReport;
