import React, { useEffect, useState, useRef } from "react";
import { useCentroStore } from "../../store";
import { useReports } from "../../store/queries/incidentReportQueries";
import { useAircrafts } from "../../store/queries/aircraftQueries";

// useEffect() for updating currentIncidentReport.aircraftsList[] whenever an aircraft field is updated
const UpdateAircraftList = () => {
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const currentIrStatus = currentIncidentReport.status;
    const userFacilityId = useCentroStore((state) => state.userFacilityId);
    const aircrafts = useCentroStore((state) => state.aircrafts);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const addAircrafts = useCentroStore((state) => state.addAircrafts);
    const aircraftOne = useCentroStore((state) => state.aircraftOne);
    const aircraftTwo = useCentroStore((state) => state.aircraftTwo);
    const setIrId = useCentroStore((state) => state.setIrId);
    const { aircraftsFromDb } = useReports(currentIncidentReport.reportingFacilityId, currentIncidentReport.irId)

    const isMounted = useRef(false); // For help not running during First Render

    useEffect(() => {
        if (isMounted.current) {
            var tempAircraftList = [];
            tempAircraftList[0] = aircraftOne;
            tempAircraftList[1] = aircraftTwo;
            setaircraftsList(tempAircraftList); // Update currentIncidentReport.aircraftsList

            console.log("UPDATE AIRCRAFT LIST");
            console.log(tempAircraftList);
        } else {
            isMounted.current = true;
            return;
        }
    }, [aircraftOne, aircraftTwo]);
    /*
     aircraftOne.callsign, aircraftOne.type, aircraftOne.typeUnknown, aircraftOne.typeUnknownDesc, aircraftOne.beaconCode, aircraftOne.flightRule, aircraftOne.facility,
        aircraftOne.position, aircraftOne.altitude, aircraftOne.aglMsl, aircraftOne.flightStage, aircraftOne.trafficAvoidance, aircraftOne.trafficInformation,
        aircraftOne.acasUsed, aircraftOne.acasType, aircraftOne.equipmentTypeId,
        aircraftTwo.callsign, aircraftTwo.type, aircraftTwo.typeUnknown, aircraftTwo.typeUnknownDesc, aircraftTwo.beaconCode, aircraftTwo.flightRule, aircraftTwo.facility,
        aircraftTwo.position, aircraftTwo.altitude, aircraftTwo.aglMsl, aircraftTwo.flightStage, aircraftTwo.trafficAvoidance, aircraftTwo.trafficInformation,
        aircraftTwo.acasUsed, aircraftTwo.acasType, aircraftTwo.equipmentTypeId
     */
}
export default UpdateAircraftList;