import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";
//import { useNavigate } from "react-router-dom";
import { useCentroStore } from "../store";
//import { useQueryClient } from "react-query";
import { useUser, useFacUsers, useAllUsers, useAllRoles, useAllFacilities } from '../store/queries/userQueries';
import { Dialog, DialogTitle, DialogContent, TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Switch,  Button, DialogActions, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';


export default function UserListTables() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const userId = useCentroStore((state) => state.userId);
    const { userData, editUser } = useUser(userId);
    const userDataPermissions = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0];
    const currentFacility = useCentroStore((state) => state.currentFacility);
    const { data: facUsersData, refetch, isLoading } = useFacUsers(currentFacility.facilityId)
    //const { data: usersData, refetch, isLoading } = useAllUsers();
    const { data: rolesData } = useAllRoles();
    const { data: facilitiesData } = useAllFacilities();
    //const navigate = useNavigate();
    //const queryClient = useQueryClient();
    const [defaultRoleField, setDefaultRoleField] = React.useState("");
    const [defaultFacField, setDefaultFacField] = React.useState("");

    const [open, setOpen] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState(null);
    const [currentUserRoleIds, setCurrentUserRoleIds] = React.useState([]);
    const [currentUserFacIds, setCurrentUserFacIds] = React.useState([]);
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [currentUserEmail, setCurrentUserEmail] = React.useState(""); // For detecing email changes

    const columns = [
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'roleId', headerName: 'Default Role', flex: 1,
          valueGetter: (params) => {
                return rolesData?.find(element => element.id === params.value).name;
          },
        },
        { field: 'firstName', headerName: 'First Name', flex: 1 },
        { field: 'lastName', headerName: 'Last Name', flex: 1 },
        {
            field: 'resetRequired', headerName: 'Password Reset Required?', flex: 1.5,
            valueGetter: (params) => {
                if (params.value === true) {
                    return "True";
                }
                return "False";
            },
        },
        { field: 'activeStatus', headerName: 'Status', flex: 1,
            valueGetter: (params) => {
                if (params.value === true) {
                    return "Active";
                }
                return "Inactive";
            },
        },
        { field: 'createdDate', headerName: 'Created Date (UTC)', flex: 1 ,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
            },
        },
        { 
            field: 'edit', 
            headerName: 'Edit', 
            flex: 1, 
            renderCell: (params) => (
                <EditIcon 
                    style={{cursor: 'pointer'}}
                    onClick={(event) => {
                        event.stopPropagation();
                        handleOpen(params.row);
                    }}
                />
            )
        }
    ];

    const columnsSpanish = [
        { field: 'email', headerName: 'CORREO ELECTR\xF3NICO', flex: 1.5 },
        {
            field: 'roleId', headerName: 'ROL PREDETERMINADO', flex: 1.5,
            valueGetter: (params) => {
                return rolesData?.find(element => element.id === params.value).name;
            },
        },
        { field: 'firstName', headerName: 'NOMBRE', flex: 1 },
        { field: 'lastName', headerName: 'APELLIDO', flex: 1 },
        {
            field: 'resetRequired', headerName: 'RESTABLECIMIENTO DE CONTRASE\xF1A REQUERIDO?', flex: 2.75,
            valueGetter: (params) => {
                if (params.value === true) {
                    return "Verdadero";
                }
                return "Falso";
            },
        },
        {
            field: 'activeStatus', headerName: 'ESTADO', flex: 0.75,
            valueGetter: (params) => {
                if (params.value === true) {
                    return "Activo";
                }
                return "Inactivo";
            },
        },
        { field: 'createdDate', headerName: 'FECHA CREADA (UTC)', flex: 1.5,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
            },
        },
        {
            field: 'edit',
            headerName: 'EDITAR',
            flex: 1,
            renderCell: (params) => (
                <EditIcon
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => {
                        event.stopPropagation();
                        handleOpen(params.row);
                    }}
                />
            )
        }
    ];

    const handleOpen = (user) => {
        //console.log(user);
        if (userDataPermissions.updateUser === 1) {
            setCurrentUser(user);
            setCurrentUserEmail(user.email);
            setCurrentUserRoleIds(user.roles.map(a => a.id)); // Current User Role Ids
            setCurrentUserFacIds(user.facilities.map(a => a.facilityId)); // Current User Fac Ids
            setDefaultRoleField(user.roleId);
            setDefaultFacField(user.facilityId); //setDefaultFacField(user.userPreference.defaultFacilityId);
            setOpen(true);
        }
        else {
            if (EnglishLanguage) {
                alert("You do not have permission to edit users.");
            } else {
                alert("No tienes permiso para editar usuarios.");
            }
        }
    };

    const handleClose = () => {
        setErrorCode(0);
        setCurrentUser(null);
        setCurrentUserEmail("");
        setDefaultRoleField("");
        setDefaultFacField("");
        setOpen(false);
    };

    const handleTextFieldChange = (event, field) => {
        setCurrentUser(prevState => ({
            ...prevState,
            [field]: event.target.value
        }));
    };

    const handleSelectRoleChange = (event, field) => {
        setDefaultRoleField(event.target.value);
        setCurrentUser({
            ...currentUser,
            [field]: event.target.value,
        });
    };

    const handleSelectMultiRoleChange = (event, field) => {
        //console.log(event.target.value);
        //console.log(currentUser.roles);
        // Check for if user tries to remove Admin role from an Admin
        var adminRoleId = null; 
        //var currentUsersData = usersData.find(u => u.userId === currentUser.userId);
        var currentUsersData = facUsersData.find(u => u.userId === currentUser.userId);
        for (let i = 0; i < currentUsersData.roles.length; i++) {
            if (currentUsersData.roles[i].isAdmin === true) {
                adminRoleId = currentUsersData.roles[i].id;
                break;
            }
        }
        if (adminRoleId !== null) {
            var foundAdminRoleId = false;
            for (let i = 0; i < event.target.value.length; i++) {
                if (event.target.value[i] === adminRoleId) {
                    foundAdminRoleId = true;
                    break;
                }
            }
            if (foundAdminRoleId === false) {
                if (EnglishLanguage) 
                    alert("You may not unassign the following Administrator Role: " + '\n' + rolesData.find(item => item.id === adminRoleId).name);
                else 
                    alert("No puede desasignar la Siguiente Funci�n de Administrador: " + '\n' + rolesData.find(item => item.id === adminRoleId).name);
                return;
            }
        }

        setCurrentUserRoleIds(event.target.value);
        let tempValues = [];
        (event.target.value).forEach(element => tempValues.push(rolesData.find(item => item.id === element)));
        // Automatically reassign default role field if it was taken away from assigned roles list
        if (tempValues.includes(rolesData.find(item => item.id === defaultRoleField)) === false) {
            setDefaultRoleField(tempValues[tempValues.length - 1].id);
            setCurrentUser({
                ...currentUser,
                [field]: tempValues,
                ['roleId']: tempValues[tempValues.length - 1].id,
            });
        }
        else {
            setCurrentUser({
                ...currentUser,
                [field]: tempValues,
            });
        }
    };

    const handleSelectFacChange = (event, field) => {
        setDefaultFacField(event.target.value);
        setCurrentUser({
            ...currentUser,
            [field]: event.target.value,
        });
    };

    const handleSelectMultiFacChange = (event, field) => {
        //console.log(event.target.value);
        //console.log(currentUser.facilities);
        setCurrentUserFacIds(event.target.value);
        let tempValues = [];
        (event.target.value).forEach(element => tempValues.push(facilitiesData.find(item => item.facilityId === element)));
        // Auto reassign currently selected fac field in the top dropdown if it was taken away from assigned fac list

        // Automatically reassign default fac field if it was taken away from assigned fac list
        if (tempValues.includes(facilitiesData.find(item => item.facilityId === defaultFacField)) === false) {  
            setDefaultFacField(tempValues[tempValues.length - 1].facilityId);
            setCurrentUser({
                ...currentUser,
                [field]: tempValues,
                ['facilityId']: tempValues[tempValues.length - 1].facilityId,
            });
        }
        else {
            setCurrentUser({
                ...currentUser,
                [field]: tempValues,
            });
        }
    };

    const handleSwitchChange = (event, field) => {
        if (userDataPermissions.deleteUser === 1) {
            setCurrentUser(prevState => ({
                ...prevState,
                [field]: event.target.checked
            }));
        }
        else {
            if (EnglishLanguage) {
                alert("You do not have permission to deactivate users.");
            } else {
                alert("No tienes permiso para desactivar usuarios.");
            }
        }
        
    };

    const handleSave = async () => {
        //console.log(currentUser); // to log the updated user data
        setErrorCode(0);
        // Check to make sure duplicate e-mail addresses aren't being added
        //const emailExistsInDb = usersData.find(element => element.email.toLowerCase() === currentUser.email.toLowerCase());
        const emailExistsInDb = facUsersData.find(element => element.email.toLowerCase() === currentUser.email.toLowerCase());
        if (emailExistsInDb !== undefined && currentUserEmail !== currentUser.email) {
            setErrorCode(400);
            if (EnglishLanguage) {
                setErrorMsg("There was an error saving changes to User. This E-mail Address is already tied to another User.");
            } else {
                setErrorMsg("Se produjo un error al guardar los cambios en Usuario. Esta direcci�n de correo electr�nico ya est� vinculada a otro usuario.");
            }
            return;
        }

        // Check for Assigned Roles or Assigned Facilities not being selected
        if (currentUser.facilityId === 0 || currentUser.facilities.length === 0 || currentUser.roles.length === 0) {
            setErrorCode(400);
            if (EnglishLanguage) {
                setErrorMsg("There was an error saving changes to User. Required fields are missing.");
            }
            else {
                setErrorMsg("Se produjo un error al guardar los cambios en Usuario. Faltan campos obligatorios.");
            }
            return;
        }

        let response;
        response = await editUser.mutateAsync(currentUser).catch(function (error) {
            setErrorCode(error.response.status);
            if (error.response.status === 400) {
                if (EnglishLanguage) {
                    setErrorMsg("There was an error saving changes to User. Required fields are missing.");
                } else {
                    setErrorMsg("Se produjo un error al guardar los cambios en Usuario. Faltan campos obligatorios.");
                }
            }
            else {
                setErrorMsg(error.message);
            }
        });
        if (response.status === 200) {
            refetch(); // Refetch query to update datagrid
            if (EnglishLanguage) {
                alert("User changes have been successfully saved.");// Successful save message
            } else {
                alert("Los cambios de usuario se han guardado correctamente.");// Successful save message
            }
        }
        else {
            setErrorCode(response.status);
            setErrorMsg(response.statusText);
            if (EnglishLanguage) {
                alert("There was an error saving changes to User. User changes could not be saved.");// Error
            } else {
                alert("Se produjo un error al guardar los cambios en Usuario.No se pudieron guardar los cambios de usuario.");// Successful save message
            }
        }
        handleClose();
      };

const modalBody = currentUser ? (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ textTransform: "capitalize" }}>{EnglishLanguage ? "Edit User Details" : "Editar Detalles de Usuario"}</DialogTitle>
        <DialogContent>
            <Typography>{EnglishLanguage ? "First Name" : "Nombre"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
            <TextField label={EnglishLanguage ? "First Name" : "Nombre"} variant="standard" defaultValue={currentUser.firstName} onChange={(event) => handleTextFieldChange(event, 'firstName')} fullWidth margin="normal" />
            <Typography><br />{EnglishLanguage ? "Last Name" : "Apellido"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
            <TextField label={EnglishLanguage ? "Last Name" : "Apellido"} variant="standard" defaultValue={currentUser.lastName} onChange={(event) => handleTextFieldChange(event, 'lastName')} fullWidth margin="normal" />
            <Typography><br />{EnglishLanguage ? "Email Address" : "Correo Electr\xF3nico"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
            <TextField label={EnglishLanguage ? "Email Address" : "Correo Electr\xF3nico"} variant="standard" defaultValue={currentUser.email} onChange={(event) => handleTextFieldChange(event, 'email')} fullWidth margin="normal" />
            <Typography><br />{EnglishLanguage ? "Position Title" : "T\xEDtulo del Puesto"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
            <TextField label={EnglishLanguage ? "Position Title" : "T\xEDtulo del Puesto"} variant="standard" defaultValue={currentUser.title} onChange={(event) => handleTextFieldChange(event, 'title')} fullWidth margin="normal" />
            <Tooltip title={EnglishLanguage ? "Assign single or multiple Roles to this user. If there are no Roles, they will need to be created in the Roles page."
                : "Asigne uno o varios roles a este usuario. Si no hay roles, deber\xE1n crearse en la p\xE1gina Roles"} arrow> 
            <Typography><br />{EnglishLanguage ? "Assigned Roles" : "Roles Asignados"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
            </Tooltip>
            <FormControl fullWidth margin="normal">
                <Select variant="filled" multiple value={currentUserRoleIds || [""]} onChange={(event) => handleSelectMultiRoleChange(event, 'roles')} >
                    <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Roles" : "Seleccionar Roles"}</em></MenuItem>
                    {rolesData.map((role) => (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>))}
                </Select>
            </FormControl>
            <Tooltip title={EnglishLanguage ? "Assign Default Role to this user that they will view on login. If there are no Roles, they need to be added to the Assigned Roles list."
                : "Asigne la funci\xF3n predeterminada a este usuario que ver\xE1 al iniciar sesi\xF3n. Si no hay roles, deben agregarse a la lista de roles asignados."} arrow>                
                <Typography><br />{EnglishLanguage ? "Default Role" : "Rol Predeterminado"}:</Typography>
            </Tooltip>
            <FormControl fullWidth margin="normal">
                <Select variant="filled" value={defaultRoleField} onChange={(event) => handleSelectRoleChange(event, 'roleId')} >
                    <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Default Role" : "Seleccionar rol predeterminado"}</em></MenuItem>
                    {currentUser.roles.map((role) => (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>))}
                </Select>
            </FormControl>
            <Tooltip title={EnglishLanguage ? "Assign single or multiple Facilities to this user. If there are no Facilities, they will need to be created in the Facilities page."
                : "Asigne instalaciones \xFAnicas o m\xFAltiples a este usuario. Si no hay instalaciones, ser\xE1 necesario crearlas en la p\xE1gina Instalaciones."} arrow> 
            <Typography><br />{EnglishLanguage ? "Assigned Facilities" : "Instalaciones Asignadas"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
            </Tooltip>
            <FormControl fullWidth margin="normal">
                <Select variant="filled" multiple value={currentUserFacIds || [""]} onChange={(event) => handleSelectMultiFacChange(event, 'facilities')} >
                    <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Facilities" : "Seleccionar Instalaciones"}</em></MenuItem>
                    {facilitiesData.map((fac) => (<MenuItem key={fac.facilityId} value={fac.facilityId}>{fac.name}</MenuItem>))}
                </Select>
            </FormControl>
            <Tooltip title={EnglishLanguage ? "Assign Default Facility to this user that they will view on login. If there are no Facilities, they need to be created in the Facilities page."
                : "Asigne la instalaci\xF3n predeterminada a este usuario que ver\xE1 al iniciar sesi\xF3n. Si no hay instalaciones, deben crearse en la p\xE1gina Instalaciones."} arrow> 
            <Typography><br />{EnglishLanguage ? "Default Facility" : "Instalaci\xF3n Predeterminada"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
            </Tooltip>
            <FormControl fullWidth margin="normal">
                <Select variant="filled" value={defaultFacField} onChange={(event) => handleSelectFacChange(event, 'facilityId')} >
                    <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Default Facility" : "Seleccionar instalaci\xF3n predeterminada"}</em></MenuItem>
                    {currentUser.facilities.map((fac) => (<MenuItem key={fac.facilityId} value={fac.facilityId}>{fac.name}</MenuItem>))}
                </Select>
            </FormControl>
            <Typography><br />{EnglishLanguage ? "Created Date (UTC, Read-Only)" : "Fecha de creaci\xF3n (UTC, solo-lectura)"}:</Typography>
            <TextField label={EnglishLanguage ? "Created Date" : "Fecha de creaci\xF3n"} defaultValue={currentUser.createdDate} InputProps={{ readOnly: true, }} fullWidth variant="standard" margin="normal" />
            <Typography><br />{EnglishLanguage ? "User Status" : "Estatus de usuario"}:</Typography>
            <FormControlLabel
                control={<Switch defaultChecked={Boolean(currentUser.activeStatus)} onChange={(event) => handleSwitchChange(event, 'activeStatus')} />}
                label={EnglishLanguage ? "Active Status" : "Estado Activo"}
            />
            <br />
            <FormControlLabel
                control={<Switch checked={Boolean(currentUser.resetRequired)} />}
                label={EnglishLanguage ? "Password Reset Required (Read-Only)" : "Restablecimiento de Contrase\xF1a Requerido (Solo-Lectura)"}
                InputProps={{ readOnly: true, }}
            />
        </DialogContent>
        <DialogActions>
            {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>{errorMsg}</Grid></Grid> : null}
    <Button onClick={handleClose} color="primary">{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
    <Button onClick={handleSave} color="primary">{EnglishLanguage ? "Save" : "Guardar"}</Button>
  </DialogActions>
    </Dialog>
) : null;
    return (
        <div style={{ height: "calc(100vh - 17rem)", width: '100%' }}>
            {modalBody}
            {isLoading ? <div>Loading...</div> : (
                <DataGrid
                    //rows={usersData}
                    rows={facUsersData}
                    getRowId={(row) => row.userId}
                    columns={EnglishLanguage ? columns : columnsSpanish}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    components={{ Toolbar: GridToolbar }}
                    //onRowClick={UserDataSetup}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    localeText={{
                        noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                        noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                        toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                        toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                        toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                        toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir",
                        MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                EnglishLanguage ?
                                    `${from}-${to} of ${count}` :
                                    `${from}-${to} de ${count}`,
                        },
                    }}
                />
            )}
        </div>
    );
}
