import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';
import { useFacilityPositions } from '../../../../store/queries/facilityQueries';


export default function AircraftTwoPoisitionDropdown() {

    const setAircraftTwoPosition = useCentroStore((state) => state.setAircraftTwoPosition);
    //const positionList = useCentroStore((state) => state.facilityPosList);
    //const { facilityPositions } = useFacilityPositions();
    const aircraftTwoFac = useCentroStore((state) => state.aircraftTwoFac);
    const setAircraftTwoPos = useCentroStore((state) => state.setAircraftTwoPos);
    const aircraftTwoPos = useCentroStore((state) => state.aircraftTwoPos);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

  const handleChange = (event) => {
      var position = aircraftTwoFac?.facilityPositionModels?.find(p => p.id === event.target.value);
      setAircraftTwoPos(position);
    setAircraftTwoPosition(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={aircraftTwoPos === undefined || aircraftTwoPos === null || aircraftTwoPos.posName === undefined ? "" : aircraftTwoPos.posName+'-'+aircraftTwoPos.frequency}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
              if (selected === undefined || selected === null || selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
                  {aircraftTwoFac?.facilityPositionModels?.map(({ id, posName, frequency }) => (
                      <MenuItem
                          key={id}
                          value={id}
                      >
                          {posName}-{frequency}
                      </MenuItem>
                  ))}
        </Select>
      </FormControl>

    </Box>
  );
}

