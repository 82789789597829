import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom"
import Grid from "@mui/material/Grid";
import TopNavBar from "./TopNavBar";
import FacilityDropdown from "./forms/FacilityDropdown";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useCentroStore } from "../store";
//import { useEffect } from "react";
import TransferList from "./menu-setup/TransferList";
import HeaderSection from "./header/HeaderSection";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const MenuSetup = (props) => {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
  //useEffect(()=>{setuserloggedin("Yes")})

  return (
    <Container component="main" maxWidth="none" className="menu-setup">
            <HeaderSection className="content-wrap" sectionName="Menu Setup" sectionNameES="Configuración Del Menú"/>
      <Grid
       className="page-wrap"
        container
        sx={{
          padding: "0",
          margin: "0",

          width: "calc(100vw - 20rem)",
        }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                {EnglishLanguage ? ("Menu Setup") : ("Configuración Del Menú")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >



            </Grid>
          </Grid>
          <Grid lg={12} px={2} mt={3} sx={{ height: "calc(100% - 66px)" }}>
          <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                component="h6"
              >
                {EnglishLanguage ? ("Display what menu items are shown and hidden on your Quick Menu:") : ("Muestre qué elementos del menú se muestran y ocultan en su menú rápido:")}
              </Typography>
            </Item>

            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
           <TransferList />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MenuSetup;
