import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';

export default function AircraftTypeUnknownDescription() {

  //const beacontext = useCentroStore((state) => state.beacontext);
    //const setbeacontext = useCentroStore((state) => state.setbeacontext);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setaircraftsList = useCentroStore((state) => state.setaircraftsList);
    const aircraft = useCentroStore((state) => state.aircraftOne);
    const setAircraftOnetypeUnknownDesc = useCentroStore((state) => state.setAircraftOneTypeUnknownDesc);
    const blurHandler = (event) => {
        setAircraftOnetypeUnknownDesc(event.target.value);
    };

  return (
<TextField 
          id="standard-multiline-number"
          label={EnglishLanguage ? "Text" : "Texto"}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          rows={1}
          onBlur={blurHandler}
          defaultValue={aircraft.typeUnknownDesc}
          variant="standard"
        />
  );
};