import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";
import { useCentroStore } from "../../../../store";

const options = [
  "Yes, based on ATS Surveillance System",
  "Yes, based on Visual Sighting",
  "Yes, based on Other Information",
  "No",
];

const optionsSpanish = [
  "S\xED, basado en el Sistema de Vigilancia ATS",
  "S\xED, basado en Visualizaci\xF3n",
  "S\xED, basado en Otra Informaci\xF3n",
  "No",
];

export default function AircraftTrafficInfoUsedDropdown() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  //const settrafficinformationselect = useCentroStore((state) => state.settrafficinformationselect);
  const setAircraftOneTrafficInformation = useCentroStore(
    (state) => state.setAircraftOneTrafficInformation
  );
  const aircraft = useCentroStore((state) => state.aircraftOne);
  //const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    //setAge(event.target.value);
    setAircraftOneTrafficInformation(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="standard">
        <Select
          className="select-wrap"
          value={aircraft.trafficInformation}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (!selected || selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
          options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          )) 
          :
          optionsSpanish.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
