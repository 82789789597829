import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    createReportHistory, fetchCategories, fetchReportHistory, fetchReportPending, fetchReportsByFacility, fetchReportsByUserFacility, fetchSubcategories,
    saveReportDraft, submitReportDraft, updateReportDraft, approveReportSubmitted, rejectReportSubmitted, createUploadAttachment,
    fetchReportAttachments, deleteAttachments,
    fetchAircraftsByIncidentReportId, fetchEquipmentTypes
} from '../api/apiEndpoints/apiEndpoints';

export function useReports(facId, irId, userFacObj) {
    const queryClient = useQueryClient();
    const reports = useQuery(['reports', facId], () => fetchReportsByFacility(facId), { refetchOnWindowFocus: false, refetchInterval: 60 * 2000, enabled: !!facId });
    const reportsByUserFacility = useQuery(['reportsUser', userFacObj], () => fetchReportsByUserFacility(userFacObj), { refetchOnWindowFocus: false, refetchInterval: 60 * 2000, enabled: !!facId });
    const aircraftsFromDb = useQuery(["aircrafts", irId], () => fetchAircraftsByIncidentReportId(irId), { refetchOnWindowFocus: false, retry: 2, enabled: (irId !== undefined && irId !== 0), refetchInterval: 60 * 3000 });
    const equipmentTypes = useQuery("equipmentTypes", fetchEquipmentTypes, { refetchOnWindowFocus: false, enabled: irId !== 0, retry: 0, staleTime: 60 * 1000 });

    const addReport = useMutation(saveReportDraft, {
        onSuccess: () => {
            //Invalidates the cache and forces a refetch on the reports list
            queryClient.invalidateQueries(["reports", facId]);
            queryClient.invalidateQueries(["reportsUser", facId]);
            queryClient.invalidateQueries(["aircrafts", irId]);
        },
    });

    const updateReport = useMutation(updateReportDraft, {
        onSuccess: () => {
            //Invalidates the cache and forces a refetch on the reports list
            queryClient.invalidateQueries(["reports", facId]);
            queryClient.invalidateQueries(["reportsUser", facId]);
            queryClient.invalidateQueries(["aircrafts", irId]);
        },
    });

    const submitReport = useMutation(submitReportDraft, {
        onSuccess: () => {
            queryClient.invalidateQueries(["reports", facId])
            queryClient.invalidateQueries(["reportsUser", facId]);
            queryClient.invalidateQueries(["aircrafts", irId]);
        },
    });

    const approveReport = useMutation(approveReportSubmitted, {
        onSuccess: () => {
            queryClient.invalidateQueries(["reports", facId])
            queryClient.invalidateQueries(["reportsUser", facId]);
            queryClient.invalidateQueries(["aircrafts", irId]);
        },
    });

    /*
    const rejectReport = useMutation(rejectReportSubmitted, {
        onSuccess: () => {
            queryClient.invalidateQueries(["reports", facId])
        },
    });
    */

    return { reports, reportsByUserFacility, addReport, updateReport, submitReport, approveReport, aircraftsFromDb, equipmentTypes };
}

export function useAttachments(id) {
    const queryClient = useQueryClient();
    const reportAttachments = useQuery(['attachments', id], () => fetchReportAttachments(id), { refetchOnWindowFocus: false, refetchInterval: 60 * 2000, enabled: !!id });

    const createAttachment = useMutation(createUploadAttachment, {
        onSuccess: () => {
            queryClient.invalidateQueries(["attachments", id])
        },
    });

    const deleteAttachment = useMutation(deleteAttachments, {
        onSuccess: () => {
            queryClient.invalidateQueries(["attachments", id])
        },
    });

    return { reportAttachments, createAttachment, deleteAttachment };
}

export function useCategories() {
    const categories = useQuery("categories", fetchCategories, { refetchOnWindowFocus: false, staleTime: 60 * 1000 * 5 });

    //const subcategories = useQuery("subcategories", fetchSubcategories, { refetchOnWindowFocus: false, staleTime: 60 * 1000 * 5 });

    return { categories };//, subcategories };
}

export function useCustodyTrails(irId) {
    const queryClient = useQueryClient();
    const custodyTrail = useQuery(["custody-trail", irId], () => fetchReportHistory(irId), { refetchOnWindowFocus: false, enabled: irId !== 0 });
    const custodyTrailPending = useQuery(["custody-trail-pending", irId], () => fetchReportPending(irId), { refetchOnWindowFocus: false, enabled: irId !== 0 });

    const addCustody = useMutation(createReportHistory, {
        onSuccess: () => {
            //Invalidates the cache and forces a refetch on the history/custody trail list
            queryClient.invalidateQueries(["custody-trail", irId]);
            queryClient.invalidateQueries(["custody-trail-pending", irId]);
        },
    });

    return { custodyTrail, custodyTrailPending, addCustody};
}