import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';


const options = [
  'Air Taxi', 
  'Charter', 
  'Commercial Business', 
];

const optionsSpanish = [
    'Taxi A\xE9reo',
    'Flete',
    'Negocio Comercial',
];

export default function FlightNonScheduledSubCategoryDropdown() {

    const setsubcategory = useCentroStore((state) => state.setflightsubcategory);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);

  const handleChange = (event) => {
      setsubcategory(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
          value={currentIncidentReport.flightSubcategory}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Select</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
          options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          )) 
          :
          optionsSpanish.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}


