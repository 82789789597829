import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import MapLocationFind from './MapLocationFind';
import { useCentroStore } from '../../../../store';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "calc(100% - 15rem)",
  bgcolor: 'transparent',
  boxShadow: 24,
  p: 0,
};
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "transparent",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
  
  }));
export default function MapCoords() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);


  return (
    <div>
      <Grid sx={{ minWidth: "auto!important", cursor: "pointer" }} onClick={handleOpen}>
      <Item elevation={0}>
        <Typography
          sx={{
            color: "text.secondary",
            textAlign: "center",
            fontSize: "1.2rem!important",
          }}
        >
          {EnglishLanguage ? ("Place From Map") : ("LUGAR DESDE EL MAPA")}
        </Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
    
          </Box>
        </Modal>
      </Item>
      <Item elevation={0}>
        <LocationSearchingIcon className="add-location-button" />
      </Item>
    </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        <Button onClick={handleClose} sx={{ position: 'absolute', right: 0, top: 0, zIndex: "1000" }}>
      <CloseIcon />
    </Button>
        {EnglishLanguage ? <div className="show-coords-on-map">Stored As:<span className="ml-20">Latitude <span className="white">{currentIncidentReport.latitude}</span></span>  <span className="ml-20">Longitude  <span className="white">{currentIncidentReport.longitude}</span></span></div> 
        : <div className="show-coords-on-map">Almacenado como:<span className="ml-20">Latitud <span className="white">{currentIncidentReport.latitude}</span></span>  <span className="ml-20">Longitud  <span className="white">{currentIncidentReport.longitude}</span></span></div>}
        <MapLocationFind />
        </Box>
      </Modal>
    </div>
  );
}