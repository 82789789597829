import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchFacilities, fetchActiveFacilities, fetchWeather, fetchFacilityPositions, createFacility, updateFacility,
    fetchAllFacilityTypes, createFacilityType, updateFacilityType, deleteFacilityType } from '../api/apiEndpoints/apiEndpoints';

export function useFacilities() {
    const queryClient = useQueryClient();
    const facilities = useQuery("facilities", fetchFacilities, { refetchOnWindowFocus: false, staleTime: 60 * 1000 * 5 });
    const activeFacilities = useQuery("facilities-active", fetchActiveFacilities, { refetchOnWindowFocus: false, staleTime: 60 * 1000 * 5 });

    const addFacility = useMutation(createFacility, {
        onSuccess: () => {
            //Invalidates the cache and forces a refetch on the reports list
            queryClient.invalidateQueries(["facilities"]);
            queryClient.invalidateQueries(["facilities-active"]);
        },
    });

    const editFacility = useMutation(updateFacility, {
        onSuccess: () => {
            //Invalidates the cache and forces a refetch on the reports list
            queryClient.invalidateQueries(["facilities"]);
            queryClient.invalidateQueries(["facilities-active"]);
        },
    });

    return { facilities, activeFacilities, addFacility, editFacility };
}

export function useFacilityTypes() {
    const queryClient = useQueryClient();
    const facilityTypes = useQuery("facility-types", fetchAllFacilityTypes, { refetchOnWindowFocus: false, staleTime: 60 * 1000 * 5 });

    const addFacilityType = useMutation(createFacilityType, {
        onSuccess: () => {
            //Invalidates the cache and forces a refetch on the reports list
            queryClient.invalidateQueries(["facility-types"]);
        },
    });

    const editFacilityType = useMutation(updateFacilityType, {
        onSuccess: () => {
            //Invalidates the cache and forces a refetch on the reports list
            queryClient.invalidateQueries(["facility-types"]);
        },
    });

    const removeFacilityType = useMutation(deleteFacilityType, {
        onSuccess: () => {
            queryClient.invalidateQueries(["facility-types"])
        },
    });

    return { facilityTypes, addFacilityType, editFacilityType, removeFacilityType };
}

export function useFacilityPositions(facId) {

    const facilityPositions = useQuery("facility-positions", fetchFacilityPositions, { refetchOnWindowFocus: false, staleTime: 60 * 1000 * 5 });
    //const facilityPositionsById = useQuery(["facility-positions", facId], () => fetchPositions(facId), {refetchOnWindowFocus: false, staleTime: 60*1000*2, enabled: facId !== 0})
    return { facilityPositions };//, facilityPositionsById };
}

export function useWeather(facId) {

    const weatherForcast = useQuery(["weather-forcast", facId], () => fetchWeather(facId), { enabled: !!facId, refetchOnWindowFocus: false, staleTime: 60 * 1000 * 4, refetchInterval: 60 * 1000 * 6 });

    return { weatherForcast };
}