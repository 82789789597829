import { CardActions, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { useCentroStore } from "../store";
import { useDataAnalytics } from "../store/queries/dataAnalyticsQueries";
import * as React from "react";
import ReactECharts from 'echarts-for-react';
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
/*
import MapAnalytics from "./MapAnalytics";
import ReportDateDropdown from "./analytics/ReportDateDropdown";
import ReportTypeDropdown from "./analytics/ReportTypeDropdown";
import ServiceAreaDropdown from "./analytics/ServiceAreaDropdown";
import FacilityTypeDropdown from "./analytics/FacilityTypeDropdown";
import { MORCounts } from "./charts/MORCounts";
import { PARCounts } from "./charts/PARCounts";
import { NQEPARCounts } from "./charts/NQEPARCounts";
import AnalyticOptionsDropdown from "./AnalyticsOptionsDropdown";
import StartDatePicker from "./analytics/StartDatePicker";
import EndDatePicker from "./analytics/EndDatePicker";
*/
//import { useEffect } from "react";
import HeaderSection from "./header/HeaderSection";
import FilterYear from "./analytics/FilterYear";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const Analytics = (props) => {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const [filterYear, setFilterYear] = React.useState(2021);
    const [filterState, setFilterState] = React.useState("All");
    const { icaoCountryCodes, icaoIncidentsModelData, icaoIncidentsLocationData } = useDataAnalytics(filterYear);

    // Add more years to this array if we want to go past 2021
    const optionsFilterYear = [
        '2021',
        '2022',
    ];
    // Handles change event for Year Filter
    const handleChangeFilterYear = (event) => {
        // Reset other filters before setting the Year filter to prevent errors
        setFilterState("All");
        setFilterYear(parseInt(event.target.value));
    };
    // List states from top 10 state of occurrences
    var optionsFilterState;
    if (icaoIncidentsLocationData?.data?.data && Array.isArray(icaoIncidentsLocationData?.data?.data)) // Check for empty list of incidentslocationdata
        optionsFilterState = icaoIncidentsLocationData?.data?.data?.map(e => e.stateOfOccurrence);
    else
        optionsFilterState = [];
    // Handles change event for State Of Occurrence Filter
    const handleChangeFilterState = (event) => {
        setFilterState(event.target.value);
    };

    // Chart data for ICAO Incidents MODEL Data
    const dataICAOModels = genModelData();
    const optionICAOIncidentsModels = {
        title: {
            text: dataICAOModels.seriesData.length !== 0 ?
                (EnglishLanguage ? 'Occurrences Reported to the ICAO by the Aircraft Model Number - ' + filterYear :
                    'Ocurrencias Reportados a la ICAO por el N\xFAmero de Modelo de Aeronave - ' + filterYear) :
                (EnglishLanguage ? "No Data for " + filterYear : "No hay datos para " + filterYear),
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        /*
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 20,
            bottom: 20,
            data: dataICAOModels.legendData
        },*/
        series: [
            {
                name: EnglishLanguage ? "Occurrences Reported:" : "Ocurrencias Reportados:",
                type: 'pie',
                top: '10%',
                height: '600px',
                radius: '80%',
                center: ['50%', '50%'],
                data: dataICAOModels.seriesData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    function genModelData() {
        const legendData = [];
        const seriesData = [];
        for (var i = 0; i < icaoIncidentsModelData?.data?.data.length; i++) {
            legendData.push(icaoIncidentsModelData?.data?.data[i].model);
            seriesData.push({
                name: icaoIncidentsModelData?.data?.data[i].model,
                value: icaoIncidentsModelData?.data?.data[i].modelCount
            });
        }
        return {
            legendData: legendData,
            seriesData: seriesData.reverse() // Since pie charts in echarts displays backwards order
        };
    }

    // Chart Data for ICAO Incidents LOCATION Data
    var dataICAOIncidents = filterState === "All" ? genLocationData() : genLocationDataFiltered();
    const optionICAOIncidentsLocations = {
        title: {
            text: dataICAOIncidents.seriesData[0].data.length !== 0 ?
                (filterState === "All" ?
                (EnglishLanguage ? 'Occurrences Reported to the ICAO by the State of Occurrence in ' + filterYear + " - Top 10"
                    : 'Ocurrencias notificados a la ICAO por el estado de ocurrencia en ' + filterYear + " - Los 10 mejores")
                : (EnglishLanguage ? 'Occurrences Reported to the ICAO in ' + (icaoCountryCodes?.data?.data.find(e => e.code === filterState).country + " (" + filterState + ")") + " by Location - " + filterYear
                        : 'Ocurrencias reportados a la ICAO en ' + (icaoCountryCodes?.data?.data.find(e => e.code === filterState).country + " (" + filterState + ")") + " por localizacion - " + filterYear)
                )
                : (EnglishLanguage ? "No Data for " + filterYear : "No hay datos para " + filterYear),
            left: 'center'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        //legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            inverse: true,
            data: dataICAOIncidents.yAxisData
        },
        series: dataICAOIncidents.seriesData
    };
    function genLocationData() {
        var yAxisData = [];
        var seriesData = [];
        var seriesDataValues = [];
        // Handling empty icaoIncidentsLocationData=
        if (icaoIncidentsLocationData?.data?.data.length === 0 || icaoIncidentsLocationData?.data?.data === undefined) {
            seriesData.push({
                name: (EnglishLanguage ? "Occurrences Reported:" : "Ocurrencias Reportados:"),
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: []
            });
            return {
                yAxisData: yAxisData,
                seriesData: seriesData
            };
        }
        for (var i = 0; i < icaoIncidentsLocationData?.data?.data.length; i++) {
            var stateOfOccurrence = icaoIncidentsLocationData?.data?.data[i].stateOfOccurrence;
            yAxisData.push(icaoCountryCodes?.data?.data.find(e => e.code === stateOfOccurrence).country + " (" + stateOfOccurrence + ")"); // Displays ex: United States (USA)
            seriesDataValues.push(icaoIncidentsLocationData?.data?.data[i].stateCounts);
        }
        seriesData.push({
            name: (EnglishLanguage ? "Occurrences Reported:" : "Ocurrencias Reportados:"),
            type: 'bar',
            stack: 'total',
            label: {
                show: true
            },
            emphasis: {
                focus: 'series'
            },
            data: seriesDataValues
        });
        return {
            yAxisData: yAxisData,
            seriesData: seriesData
        };
    }
    // For when a state of occurrence is selected in the filter
    function genLocationDataFiltered() {
        const yAxisData = [];
        const seriesData = [];
        var seriesDataValues = [];
        var locationCounts = icaoIncidentsLocationData?.data?.data.find(e => e.stateOfOccurrence === filterState).locationCounts;
        if (locationCounts === undefined || locationCounts === null) {
            return {
                yAxisData: [],
                seriesData: []
            };
        }
        for (var [key, value] of Object.entries(locationCounts)) {
            yAxisData.push(key); // Location within the State, ex: Berlin
            seriesDataValues.push(value);
        }
        seriesData.push({
            name: (EnglishLanguage ? "Occurrences Reported:" : "Ocurrencias Reportados:"),
            type: 'bar',
            stack: 'total',
            label: {
                show: true
            },
            emphasis: {
                focus: 'series'
            },
            data: seriesDataValues
        });
        return {
            yAxisData: yAxisData,
            seriesData: seriesData
        };
    }

    // Events for clicking on specified ECharts Sections
    /*
    const onLocationChartClick = (params) => {
        setFilterState(params.name.substr(-4, 3)); // Retrieving the State of Occurrence code at the end of the name
    };
    const onEventsLocations = {
        click: onLocationChartClick,
    };
    */

    return (
        <Container component="main" maxWidth="none" id="Analytics" sx={{ padding: "0!important" }}>
            <HeaderSection className="content-wrap" sectionName="Analytics" sectionNameES="Anal\xEDtica" />
            <Grid
                container
                justifyContent="flex-start"
                className="content-wrap"
                sx={{
                    overflow: "auto",
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar": {
                        width: "0.5rem",
                        borderRadius: "0.5rem",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#dadada",
                        borderRadius: "0.5rem",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#8e8e8e",
                        borderRadius: "0.5rem",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                        borderRadius: "0.5rem",
                    },
                }}>
                <Grid container className="analytics-filter-wrap" >
                    <Grid lg={1} px={0} mt={0} className="executive-dashboard-select" >
                        <Item
                            sx={{
                                background: "transparent!important",
                                boxShadow: "none!important",
                            }}
                        >
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel id="select-small-label">Year</InputLabel>
                                    <Select
                                        labelId="select-small-label"
                                        id="select-small"
                                        value={filterYear.toString()}
                                        label="Year"
                                        onChange={handleChangeFilterYear}
                                    >
                                        {optionsFilterYear.map((filterYear) => (
                                            <MenuItem
                                                key={filterYear}
                                                value={filterYear}
                                            >
                                                {filterYear}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container px={1} xl={10} className="home-padding-fix">
                    <Grid item xs={12} xl={12}>
                        <Card sx={{ borderRadius: "0", marginBottom: "2rem" }} className="m-b-0">
                            <CardContent sx={{}}>
                                <ReactECharts option={optionICAOIncidentsModels} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container px={1} xl={10} className="home-padding-fix">
                    <Grid item xs={12} xl={12}>
                        <Card sx={{ borderRadius: "0", marginBottom: "2rem" }} className="m-b-0">
                            <CardActions>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel id="select-small-label">State Of Occurrence</InputLabel>
                                    <Select
                                        labelId="select-small-label"
                                        id="select-small"
                                        value={filterState}
                                        label="Year"
                                        onChange={handleChangeFilterState}
                                    >
                                        <MenuItem value="All">
                                            All
                                        </MenuItem>
                                        {optionsFilterState === undefined ? "" :
                                            optionsFilterState.map((filterState) => (
                                            <MenuItem
                                                key={filterState}
                                                value={filterState}
                                            >
                                                {filterState}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </CardActions>
                            <CardContent sx={{}}>
                                <ReactECharts option={optionICAOIncidentsLocations} />
                                {/*onEvents={onEventsLocations}*/}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

/*
const Analytics = (props) => {
  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
  //useEffect(()=>{setuserloggedin("Yes")});
  // const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  return (
    <Container component="main" maxWidth="none" id="analytics">
      <HeaderSection className="content-wrap" sectionName="Analytics" sectionNameES="Anal\xEDtica"/>
      <Grid
        container
        className="analytics-wrap page-wrap"
        sx={{
          padding: "0",
          margin: "0",

          width: "calc(100vw - 20rem)",
        }}
      >
        <Grid
          container
          spacing={0}
          className="analytics-wrap-box"
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)" }}
        >
          <Grid xs={12} xl={12}>
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                Analytics
              </Typography>
            </Item>
          </Grid>

          <Grid container lg={12} mt={0} className="analytics-graphs-wrap" display="flex" flexWrap="nowrap">
          <Grid container lg={6} mt={0}>
            <Grid xs={12} px={2} mt={0} className="select-over-map" sx={{position: "absolute", zIndex: "10000"}} >
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >
                
              </Item>
            </Grid> 
            <Grid xs={12} pr={.5} pl={1} mt={0} >
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >
                <MapAnalytics />
              </Item>
            </Grid>
          </Grid>
            <Grid container className="analytics-filter-wrap" >
            <Grid lg={12} px={0} mt={0} className="executive-dashboard-select" >
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >

                <AnalyticOptionsDropdown />
              </Item>
            </Grid>
            <Grid container className="analytics-form-container">
            <Grid lg={4} pl={1} mt={0}>
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >
                <Typography className="">Inc. Cat.</Typography>
                <ReportTypeDropdown />
              </Item>
            </Grid>
            <Grid lg={4} px={2} mt={0}>
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >
                <Typography className="">Region</Typography>
                <ServiceAreaDropdown />
              </Item>
            </Grid>
            <Grid lg={4} pr={1} mt={0}>
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >
                <Typography className="">Facility Type</Typography>
                <FacilityTypeDropdown />
              </Item>
            </Grid>
            <Grid className="date-picker-wrapper">
            <Grid lg={2} px={2} mt={0}>
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >
                <Typography className="">Inc. Cat.</Typography>
                <ReportTypeDropdown /> 
              </Item>
            </Grid>
            <Grid lg={2} px={2} mt={0}>
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >
                <Typography className="">Report Date</Typography>
                <ReportDateDropdown />
              </Item>
            </Grid>
            <Grid lg={4} px={2} mt={0}>
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >
                <Typography className="">Start Date</Typography>
                <StartDatePicker />
              </Item>
            </Grid>
            <Grid lg={4} pr={1} mt={0}>
              <Item
                sx={{
                  background: "transparent!important",
                  boxShadow: "none!important",
                }}
              >
                <Typography className="">End Date</Typography>
                <EndDatePicker />
              </Item>
            </Grid>
            </Grid>
            </Grid>
            <Grid container className="analytics-graph-canvas">
            <Grid lg={12} my={1.5}>
              <MORCounts />
            </Grid>
            <Grid lg={12} my={1.5} >
              <PARCounts />
            </Grid>
            <Grid lg={12} my={1.5}>
            <NQEPARCounts />
            </Grid>
            </Grid>
            </Grid>
            
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};*/

export default Analytics;
