import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
// import ParentFacilityFindingsText from "./general-informtation/ParentFacilityFindingsText";
// import OrganizationFindingsText from "./general-informtation/OrganizationFindingsText";
// import FacilityManagementFindingsText from "./general-informtation/FacilityManagementFindingsText";
import { useCentroStore } from "../../../store";
import EngineDamageInformationCheckboxes from "./general-informtation/EngineDamageInformationCheckboxes";
import PartOfAircraftDamageDropdown from "./general-informtation/PartOfAircraftDamageDropdown";
import AircraftDamageDropdown from "./general-informtation/AircraftDamageDropdown";
import PercipitationDropdown from "./general-informtation/PercipitationDropdown";
import SkyConditionsDropdown from "./general-informtation/SkyConditionsDropdown";
import LightConditionsDropdown from "./general-informtation/LightConditionsDropdown";
import WildlifeTypeDropdown from "./general-informtation/WildlifeTypeDropdown";
import NumberOfBirdsStuckDropdown from "./general-informtation/NumberOfBirdsStuckDropdown";
import NumberOfBirdsDropdown from "./general-informtation/NumberOfBirdsDropdown";
import SizeOfBirdsDropdown from "./general-informtation/SizeOfBirdDropdown";
import PhaseOfFlightDropdown from "./general-informtation/PhaseOfFlightDropdown";
import PilotWarnedDropdown from "./general-informtation/PilotWarnedDropdown";
import FeedIcon from "@mui/icons-material/Feed";

// import EscalateToPF from "./general-informtation/EscalateToPF";
// import EscalateToORG from "./general-informtation/EscalateToORG";
// import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function IncidentReportStepEight() {
  const setIsDraft = useCentroStore((state) => state.setIsDraft);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const currentCategory = useCentroStore((state) => state.currentCategory);
  const currentincidenttype = useCentroStore(
    (state) => state.currentincidenttype
  );

  React.useEffect(() => {
    setIsDraft(false);
  });

  return (
    <Grid container py={0} spacing={2} mt={0} className="ir-form-wrapper">
      <Grid xs={12}>
        <Item elevation={0} sx={{ padding: "0" }}>
          <Grid xs={12} className="step-three-wrap" mb={1}>
            {/* General Information Section */}
            <Grid xs={12} className="step-three-wrap">
              <>
              {currentincidenttype === "airborne" ? (



                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    className="ir-form-wrapper"
                    sx={{
                      background: "#00000033",
                      borderRadius: ".5rem",
                      padding: "10px 20px 15px",
                    }}
                  >
                    <Grid xl={12} className="table-header-wrap">
                      <Item elevation={0}>
                        <FeedIcon />
                      </Item>
                      <Item elevation={0}>
                        <Typography
                          sx={{
                            textAlign: "left",

                            paddingBottom: ".25rem",
                            maxWidth: "fit-content",
                          }}
                          variant="h6"
                        >
                          {EnglishLanguage
                            ? "Bird Strike Information"
                            : "información del reportero"}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid container className="section-wrapper" xl={12}>
                      <Grid lg={3}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Pilot Warned of Birds"
                                  : "Fecha"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <PilotWarnedDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>

                      <Grid lg={3}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Phase of Flight"
                                  : "Hora del Informe"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <PhaseOfFlightDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>

                      <Grid lg={3}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0} style={{ minWidth: "161px" }}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Size of Bird(s)"
                                  : "Reporte De"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <SizeOfBirdsDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>
                      <Grid lg={3}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "# of Birds Seen"
                                  : "Nombre"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <NumberOfBirdsDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>

                      <Grid lg={3}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "# of Birds Struck"
                                  : "Indicativo de la Aeronave que Reporta"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <NumberOfBirdsStuckDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>

                      <Grid lg={3}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Wildlife Type"
                                  : "Tipo de Aeronave que Reporta"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <WildlifeTypeDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>
                      <Grid lg={2}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Light Conditions"
                                  : "Aeródomo de Partida de la Aeronave que Reporta"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <LightConditionsDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>

                      <Grid lg={2}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Sky Condition"
                                  : "Primer Aeródomo de Aterrizaje de la Aeronave que Reporta"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <SkyConditionsDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>
                      <Grid lg={2}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Precipitation"
                                  : "Aeródomo de Destino de la Aeronave que Reporta"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <PercipitationDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>
                      {/* {currentIncidentReport.reportedBy === "Pilot" && (
          </>
        )} */}

                      <Grid lg={3}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Aircraft Damage"
                                  : "Dirección de quien Reporta"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <AircraftDamageDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>

                      <Grid lg={3}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0} style={{ minWidth: "161px" }}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Part of Aircraft Damage"
                                  : "Número de Contacto de quien Reporta"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <PartOfAircraftDamageDropdown />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>

                      <Grid xl={5.85}>
                        <Item elevation={0}>
                          <Grid>
                            <Item elevation={0} style={{ minWidth: "161px" }}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                {EnglishLanguage
                                  ? "Engine Damage Information"
                                  : "Resumen del Ocurrencia"}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "0px",
                              }}
                            >
                              <EngineDamageInformationCheckboxes />
                            </Item>
                          </Grid>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : currentCategory.irCategory1 === "wildlife" ? (
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    className="ir-form-wrapper"
                    sx={{
                      background: "#00000033",
                      borderRadius: ".5rem",
                      padding: "10px 20px 15px",
                    }}
                  >
                    <Grid xl={12} className="table-header-wrap">
                      <Item elevation={0}>
                        <FeedIcon />
                      </Item>
                      <Item elevation={0}>
                        <Typography
                          sx={{
                            textAlign: "left",

                            paddingBottom: ".25rem",
                            maxWidth: "fit-content",
                          }}
                          variant="h6"
                        >
                          {EnglishLanguage
                            ? "Wildlife Information"
                            : "información del reportero"}
                        </Typography>
                      </Item>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    className="ir-form-wrapper disabled"
                    sx={{
                      background: "#00000033",
                      borderRadius: ".5rem",
                      padding: "10px 20px 15px",
                    }}
                  >
                    <Grid xl={12} className="table-header-wrap">
                      <Item elevation={0}>
                        <FeedIcon />
                      </Item>
                      <Item elevation={0}>
                        <Typography
                          sx={{
                            textAlign: "left",

                            paddingBottom: ".25rem",
                            maxWidth: "fit-content",
                          }}
                          variant="h6"
                        >
                          {EnglishLanguage
                            ? "No Bird Strike Information"
                            : "información del reportero"}
                        </Typography>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    className="ir-form-wrapper disabled"
                    sx={{
                      background: "#00000033",
                      borderRadius: ".5rem",
                      padding: "10px 20px 15px",
                      marginTop: "2rem"
                    }}
                  >
                    <Grid xl={12} className="table-header-wrap">
                      <Item elevation={0}>
                        <FeedIcon />
                      </Item>
                      <Item elevation={0}>
                        <Typography
                          sx={{
                            textAlign: "left",

                            paddingBottom: ".25rem",
                            maxWidth: "fit-content",
                          }}
                          variant="h6"
                        >
                          {EnglishLanguage
                            ? "No Wildlife Information"
                            : "información del reportero"}
                        </Typography>
                      </Item>
                    </Grid>
                  </Grid>
                  </Grid>
                )}
              </>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  );
}
