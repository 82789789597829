import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom"
import Grid from "@mui/material/Grid";
import TopNavBar from "./TopNavBar";
import FacilityDropdown from "./forms/FacilityDropdown";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useCentroStore } from "../store";
//import { useEffect } from "react";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const SetUp = (props) => {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
  //useEffect(()=>{setuserloggedin("Yes")})

  return (
    <Container component="main" maxWidth="none">
      <Grid container>
        <Grid container mt={3} mb={0} pr={3} xl={9} flexDirection="column">
          <Grid container justifyContent="flex-start" alignItems="baseline">
            <Grid
              container
              justifyContent="flex-start"
              alignItems="baseline"
              xl={9}
            >
              <Grid item xl={10}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="#FFF" to="/home">
                    <HomeIcon fontSize="30px" sx={{ paddingTop: "5px" }} />
                  </Link>
                  <Typography color="#FFF">Setup</Typography>
                </Breadcrumbs>
              </Grid>
              <Grid container alignContent="center" alignItems="center">
                <Grid item>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "500",
                      textTransform: "uppercase",
                      color: "#FFF",
                      fontSize: "2.75rem!important",
                    }}
                  >
                    Select Facility :
                  </Typography>
                </Grid>
                <Grid item className="title-select-wrap">
                  <FacilityDropdown />
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container my={5} mb={0} mx={0} flexDirection="column">
          <TopNavBar />
        </Grid>
      </Grid>
      <Grid
        container
        className="page-wrap"
        sx={{
          padding: "0",
          margin: "0",

          width: "calc(100vw - 20rem)",
        }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                Setup
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >



            </Grid>
          </Grid>
          <Grid lg={12} px={2} mt={3} sx={{ height: "calc(100% - 66px)" }}>
            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
       
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SetUp;
