import * as React from "react";
import { useCentroStore } from "../../store";
import { useUser, useUpdateUserPreferences } from "../../store/queries/userQueries";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function LanguageSelect() {
  const setEnglishLanguage = useCentroStore((state) => state.setEnglishLanguage);
  const userId = useCentroStore((state) => state.userId);
  const userPreferences = useCentroStore((state) => state.userPreferences);
  const setUserPreferencesPreferredLanguage = useCentroStore((state) => state.setUserPreferencesPreferredLanguage);
  //const { userData, useUpdateUserPreferences } = useUser(userId);

    const handleChangeLanguageEnglish = async (event) => {
    // EnglishLanguage in state and the rest of the front end is setup so that true === English and ""/null === Spanish
    if (event.target.value === "English") {
        setEnglishLanguage(true);
    } 
    else {
        setEnglishLanguage("");
    }
    setUserPreferencesPreferredLanguage(event.target.value);
  };


  return (

<FormControl>
<RadioGroup
  aria-labelledby="demo-controlled-radio-buttons-group"
  name="controlled-radio-buttons-group"
  defaultValue={userPreferences.preferredLanguage}
  onChange={handleChangeLanguageEnglish}
>
  <FormControlLabel value="English"  control={<Radio />} label="English" />
  <FormControlLabel value="Spanish" control={<Radio />} label="Spanish" />
</RadioGroup>
</FormControl>
  );
}
