// import * as React from 'react';
// // import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import { Link, useNavigate } from "react-router-dom";
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// // import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { useCentroStore } from '../../store';
// import PersonIcon from '@mui/icons-material/Person';
// import LockIcon from '@mui/icons-material/Lock';
// import { useAddUserCredentials } from '../../store/queries/userQueries';


// function Copyright(props) {



//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ marginBottom: "1rem", marginTop: "1.5rem", color: "white!important"}}>
//       {'Copyright © '}
//       <Link color="inherit" href="http://infina.net/">
//         Infina
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const theme = createTheme();

// export default function SignIn() {
//     const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
//     const setUserFacilityId = useCentroStore((state) => state.setUserFacilityId);
//     const setsubmitteruserid = useCentroStore((state) => state.setsubmitteruserid);
//     const [errorCode, setErrorCode] = React.useState();
//     const [errorMsg, setErrorMsg] = React.useState("");
//     const navigate = useNavigate();
//     const mutation = useAddUserCredentials();

//   const handleSubmit = async (event) => {
//       event.preventDefault();
//       const data = new FormData(event.currentTarget);
//       var submitdata = {};
//       data.forEach((value, key) => submitdata[key] = value);
//       try {
//           var response = await mutation.mutateAsync(submitdata);
//           setErrorCode(0);
//           setErrorMsg("");
//           setsubmitteruserid(userInfo.userId);
//           setUserFacilityId(userInfo.facilityId);
//           setuserloggedin("Yes");
//           navigate('/home');
//       }
//       catch (error) {
//           console.log(error);
//           setErrorCode(error.response.status);
//           setErrorMsg(error.response.data.detail);
//       }
//     };
//     const errorDivStyle = {
//         color: 'red'
//     };
//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main">
//         <CssBaseline />
//         <Box
//           sx={{
//             marginTop: 0,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Sign in
//           </Typography> */}
//          {errorCode === 404 || 401 ? <div style={errorDivStyle}>{errorMsg}</div> : null}
//           <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//             <Grid container>
//               <Grid item className="login-icons">
//                 <PersonIcon fontSize='2rem' sx={{fill:"white"}}/>
//               </Grid>
//               <Grid item>
//                 <TextField
//                   margin="normal"
                  
//                   fullWidth
//                   id="email"
//                   label="Username"
//                   name="email"
//                   autoComplete="email"
//                   sx={{textTransform: "uppercase"}}
//                   variant="standard"
                  
//                 />
//               </Grid>
//             </Grid>
//             <Grid container>
//               <Grid item className="login-icons">
//                 <LockIcon  sx={{fill:"white", fontSize: "1.5rem!important"}}/>
//               </Grid>
//               <Grid item>
//                 <TextField
//                   margin="normal"
//                   sx={{textTransform: "uppercase"}}
//                   fullWidth
//                   name="password"
//                   label="Password"
//                   type="password"
//                   id="password"
//                   autoComplete="current-password"
//                   variant="standard"
              
//                 />
//               </Grid>
//             </Grid>
//             <FormControlLabel
//               control={<Checkbox labelPlacement="right" value="remember"/>}
//               label="Remember me"
//               className="remember-me"
//               labelPlacement="start"
//             />
//             {/* <Link to="/home" style={{ color: "inherit", font: "inherit", textDecoration: "none", fontFamily: "inherit", }}> */}
//              <Button
//               type="submit"
//               fullWidth
//               className="login-button"
//               variant="contained"
//               sx={{ mt: 3, mb: 4, color: "#F7B01A",  fontFamily: "'Montserrat'!important", fontWeight: "500", fontSize: "1.25rem", background: "#00000033!important", borderRadius: "1rem!important",  height: "100%", border: "1px solid rgba(255, 255, 255, 0.3)",     backgroundColor: "rgb(0 0 0 / 33%)!important", backdropFilter: "blur(4.8px)" }}
//             >
//               Login
//             </Button> 
//             {/* </Link> */}
//             <Grid container flexDirection="row" textAlign="left">
//               <Grid item xs>
//                 <Link to="#" variant="body2">
//                   Forgot password? 
//                 </Link>
//               </Grid>
//               <Grid item className="access-text-wrap">
//                 <Link to="/home" variant="body2">
//                   {"Guest"}
//                 </Link>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//         <Copyright sx={{ mt: 8, mb: 4 }} />
//       </Container>
//     </ThemeProvider>
//     );
// }



import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useCentroStore } from '../../store';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { useAddUserCredentials, useUser } from '../../store/queries/userQueries';
import { usePasswordReset } from "../../store/queries/mailQueries";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


function Copyright(props) {



  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ marginBottom: "1rem", marginTop: "1.5rem", color: "white!important"}}>
      {'Copyright © '}
      <Link color="inherit" href="http://infina.net/">
        Infina
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
    //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
    const setAuth = useCentroStore((state) => state.setAuth);
    const isAuthenticated = useCentroStore((state) => state.isAuthenticated);
    const setUserFacilityId = useCentroStore((state) => state.setUserFacilityId);
    const setsubmitteruserid = useCentroStore((state) => state.setsubmitteruserid);
    const setUserId = useCentroStore((state) => state.setUserId);
    const userId = useCentroStore((state) => state.userId);
    const [errorCode, setErrorCode] = React.useState();
    const [errorMsg, setErrorMsg] = React.useState("");
    const navigate = useNavigate();
    const mutation = useAddUserCredentials();
    const { userData } = useUser(userId);
    const passwordReset = usePasswordReset();
    const [userEmail, setUserEmail] = React.useState("");
    const [emailInProgress, setEmailInProgress] = React.useState(false); // Variable to help detect if a password reset email is in progress
    const [loginInProgress, setLoginInProgress] = React.useState(false); // Variable to help detect if a login attempt is in progress
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
  
    const handleOpenSnackbar = () => {
      setOpenSnackbar(true);
    };
  
    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackbar(false);
    };

    const handleUserEmailChange = event => {
        setUserEmail(event.target.value);
    };

    const handleForgotPassword = async (event) => {
        try {
            setEmailInProgress(true);
            var response = await passwordReset.mutateAsync(userEmail);
            if (response.status !== 200) {
                setErrorCode(response.status);
                setErrorMsg(response.data);
                setEmailInProgress(false);
            } else { // Success
                setErrorCode(0);
                setErrorMsg("");
                setEmailInProgress(false);
            }
            event.preventDefault();

            handleCloseDialog();

            setTimeout(() => {
                handleOpenSnackbar();
            }, 500);

        } catch (error) {
            setEmailInProgress(false);
            console.error("API call failed: ", error);
            setErrorCode(500);
            setErrorMsg("An error occurred during forgot password. Contact system administrator for assistance");
        }
    };

    const handleSubmit = async (event) => {
      setLoginInProgress(true);
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      var submitdata = {};
      data.forEach((value, key) => (submitdata[key] = value));
    
      // Email format validatio
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!emailRegex.test(submitdata.email)) {
        setErrorCode(400);
        setErrorMsg("Invalid email format");
        setLoginInProgress(false);
        return; 
      }
    
      if (submitdata.email === "" || submitdata.password === "") {
        setErrorCode(400);
        setErrorMsg("Email or password missing");
        setLoginInProgress(false);
        return; 
      }
    
      
      try {
        var response = await mutation.mutateAsync(submitdata);
        if (response.status !== 200) {
          setErrorCode(response.status);
          setErrorMsg(response.data);
          setLoginInProgress(false);
        } else {
          setErrorCode(0);
          setErrorMsg("");
          setLoginInProgress(false);
          setsubmitteruserid(response.data.userId);
          setUserId(response.data.userId);
        }
      } catch (error) {
        
        console.error("API call failed: ", error);
        setErrorCode(500);
        setErrorMsg("An error occurred during login");
        setLoginInProgress(false);
      }
    };

    if (userData.isSuccess && isAuthenticated === false) {
        setUserFacilityId(parseInt(userData?.data?.data.userPreference.defaultFacilityId)); // Setting facility based on default fac id in user preferences
        setAuth(true);
        sessionStorage.setItem("isAuthenticated", true);
        sessionStorage.setItem("userId", userId);
        // If Reset Required === false, go to the home page, if === true, go to the user profile page
        if (userData?.data?.data.resetRequired === false) {
            // If user's default role is currently Admin, go to the users admin page, else go to the home page
            if (userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).isAdmin === true) {
               navigate('/user-list');
            }
            else {
               navigate('/home');
            }
        }
        else {
            navigate('/user-profile');
        }
    }

    const errorDivStyle = {
        color: 'red'
    };

  return (
    <>
    <ThemeProvider theme={theme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
         {errorCode !== 0 ? <Grid container justifyContent="center" style={errorDivStyle}><Grid item pt={3} mb={-2}>{errorMsg}</Grid></Grid> : null}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <Grid container>
              <Grid item className="login-icons">
                <PersonIcon fontSize='2rem' sx={{fill:"#55819b!important"}}/>
              </Grid>
              <Grid item>
                <TextField
                  margin="normal"
                  
                  fullWidth
                  id="email"
                  label="Username"
                  name="email"
                  autoComplete="email"
                  sx={{textTransform: "uppercase"}}
                  variant="standard"
                  
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item className="login-icons">
                <LockIcon  sx={{fill:"#55819b!important", fontSize: "1.5rem!important"}}/>
              </Grid>
              <Grid item>
                <TextField
                  margin="normal"
                  sx={{textTransform: "uppercase"}}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  variant="standard"
              
                />
              </Grid>
            </Grid>
            <FormControlLabel
              control={<Checkbox labelPlacement="right" value="remember"/>}
              label="Remember me"
              className="remember-me"
              labelPlacement="start"
            />
            {/* <Link to="/home" style={{ color: "inherit", font: "inherit", textDecoration: "none", fontFamily: "inherit", }}> */}
             {loginInProgress && (<CircularProgress/>)}
             {!loginInProgress &&
             <Button
              type="submit"
              fullWidth
              id="login-button-id"
              className="login-button"
              variant="contained"
              sx={{ mt: 2, mb: 3, color: "#F7B01A",  fontFamily: "'Montserrat'!important", fontWeight: "500", fontSize: "1.25rem", background: "#00000033!important", borderRadius: "1rem!important",  height: "100%", border: "1px solid rgba(255, 255, 255, 0.3)",     backgroundColor: "rgb(0 0 0 / 33%)!important", backdropFilter: "blur(4.8px)" }}
             >
              Login
            </Button> }
            {/* </Link> */}
            <Grid container flexDirection="row" textAlign="center">
              <Grid item xs>
        <Link href="#" variant="body2" onClick={handleOpenDialog}>
          Forgot password?
        </Link>
      </Grid>
              {/* <Grid item className="access-text-wrap">
                <Link to="/home" variant="body2">
                  {"Guest"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>

        <DialogContent>
          <Typography>Forgot your password? Please enter your email address for further instructions.</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Enter Email Address"
            fullWidth
            variant="standard"
            onChange={handleUserEmailChange}
            value={userEmail}
          />
        </DialogContent>
        {emailInProgress && (<CircularProgress/>)}
        <br />
        <DialogActions>
        {!emailInProgress && <Button
              onClick={handleForgotPassword}
              type="submit"
              fullWidth
              id="login-button-id"
              className="login-button"
              variant="contained"
              sx={{ mt: 2, mb: 3, margin: "0 auto 1rem", maxWidth:"300px", color: "#F7B01A",  fontFamily: "'Montserrat'!important", fontWeight: "500", fontSize: "1.25rem", background: "#00000033!important", borderRadius: "1rem!important",  height: "100%", border: "1px solid rgba(255, 255, 255, 0.3)",     backgroundColor: "rgb(0 0 0 / 33%)!important", backdropFilter: "blur(4.8px)" }}
            >
              Submit
            </Button> }
        </DialogActions>
      </Dialog>


    </ThemeProvider>
    <Snackbar
    open={openSnackbar}
    autoHideDuration={6000}
    onClose={handleCloseSnackbar}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  >
    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%', fontSize: "6px", marginLeft: "1.5rem" }}>
      If your email address is in our system you will receive further instructions
    </Alert>
  </Snackbar>
  </>
    );
}