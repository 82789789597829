import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [{ id: 1, option: 'Yes' }, { id: 2, option: 'No' }];
const optionsSpanish = [{ id: 1, option: 'S\xED' }, { id: 2, option: 'No' }];

export default function RunwaySurfaceDegradation() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setrunwaySurfaceDegradation = useCentroStore((state) => state.setrunwaySurfaceDegradation);


   const handleChange = (event) => {

       setrunwaySurfaceDegradation(event.target.value);
   };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
        label={EnglishLanguage ? "Select" : "Seleccionar"}
          value={currentIncidentReport.runwaySurfaceDegradation}
          onChange={handleChange}
          // displayEmpty
          // renderValue={(selected) => {
          //   if (selected.length === 0) {
          //     return <em>Select</em>;
          //   }

          //   return selected;
          // }}
          // inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="0">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
           options.map(({ id, option }) => ( <MenuItem key={id} value={id} > {option} </MenuItem> ))
           :
           optionsSpanish.map(({ id, option }) => ( <MenuItem key={id} value={id} > {option} </MenuItem> ))}
        </Select>
      </FormControl>

    </Box>
  );
}

