import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import DoneIcon from '@mui/icons-material/Done';
import NumberAircraftDropdown from "./general-information/NumberAircraftDropdown";
import FlightCategoryDropdown from "./general-information/FlightCategoryDropdown";
import FlightSubCategoryDropdown from "./general-information/FlightSubCategoryDropdown";
import AirplaneTabs from "./AirplaneTabs"
import { useCentroStore } from "../../../store";
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import Narrative from "../step-2-general/reporter-information/Narrative";
import FFUploadButtonCNSIncidentDetails from "./cns/FFUploadButtonCNSIncidentDetails";
import NoteIncident from "./cns/NoteIncident";

import InfoIcon from "@mui/icons-material/Info";
import FlightNonScheduledSubCategoryDropdown from "./general-information/FlightNonScheduldedtSubCategoryDropdown";
import NumberAircraftSurfaceDropdown from "./general-information/NumberAircraftSurfaceDropdown";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function IncidentReportStepFour() {

  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  const currentincidenttype = useCentroStore(
      (state) => state.currentincidenttype
  );
  return ( currentIncidentReport.reportTypeId !== 2 ?
      <>
                {/* <Grid xl={12} mt={0} className="table-header-wrap">
                  <Item elevation={0}>
                    <InfoIcon />
                  </Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        paddingBottom: ".25rem",
                      }}
                      variant="h6"
                    >
                      General
                    </Typography>
                  </Item>
                </Grid> */}

                <Grid container className="section-wrapper ir-form-wrapper step-three ir-in-step-wrap" xl={12}>
                  <Grid lg={4}>
                    <Item elevation={0}>
                      <Grid>


                      {currentincidenttype === "airborne" ? (
                        <>
                              <Item elevation={0} style={{ minWidth: "235px" }}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                 {EnglishLanguage ? ("How Many A/C?") : ("\u00bfCuántas Aeronaves Involucradas?")}
                                 {currentIncidentReport.aircraftCount !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <NumberAircraftDropdown />
                            </Item>
                            </>
                              
                            ) : currentincidenttype === "cns" ? (
                              <>
                              <Item elevation={0} style={{ minWidth: "235px" }}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                 {EnglishLanguage ? ("How Many A/C?") : ("\u00bfCuántas Aeronaves Involucradas?")} 
                                 {currentIncidentReport.aircraftCount !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <NumberAircraftDropdown />
                            </Item>
                            </>
                              
                            ) : (
                              <>
                              <Item elevation={0} style={{ minWidth: "235px" }}>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                  textAlign: "left",
                                  fontSize: "1.2rem!important",
                                }}
                              >
                                 {EnglishLanguage ? ("A/C, Vehicles, Pedestrians Involved?") : ("\u00bfCuántas Aeronaves Involucradas?")} 
                                 {currentIncidentReport.aircraftCount !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                              </Typography>
                            </Item>
                            <Item
                              elevation={0}
                              sx={{
                                textAlign: "left",
                                paddingTop: "0",
                                marginTop: "-10px",
                              }}
                            >
                              <NumberAircraftSurfaceDropdown />
                            </Item>
                            </>
                            )}
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid lg={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                           {EnglishLanguage ? ("Flight Category") : ("Categoría de Vuelo")} 
                           {currentIncidentReport.flightCategory !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FlightCategoryDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>


              {(currentIncidentReport.flightCategory === "General Aviation" ||
                  currentIncidentReport.flightCategory === "Aviaci\xF3n General") && (
                  <Grid lg={4}>
                    <Item elevation={0}>
                      <Grid>
                      <Item className="conditional-arrow"><KeyboardDoubleArrowRight /></Item>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Flight Sub-Category") : ("Sub-Categoría de Vuelo")}
                           {currentIncidentReport.flightSubcategory !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FlightSubCategoryDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  )}
              {(currentIncidentReport.flightCategory === "Non-Scheduled Commercial Air Transport Services" ||
                  currentIncidentReport.flightCategory === "Servicios de Transporte A\xE9reo Comercial No Regular") && (
                  <Grid lg={4}>
                    <Item elevation={0}>
                      <Grid>
                      <Item className="conditional-arrow"><KeyboardDoubleArrowRight /></Item>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Flight Sub-Category") : ("Sub-Categoría de Vuelo")}
                            {currentIncidentReport.flightSubcategory !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <FlightNonScheduledSubCategoryDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  )}
                </Grid>
      <Grid
        container
        xl={12}
        className="ir-form-wrapper aircraft-container-wrapper"
        flexWrap="nowrap"
        sx={{ padding: "0 8px" }}
      >
      <AirplaneTabs />
      </Grid>
      </>
      :
      <>
       <Grid container className="section-wrapper ir-form-wrapper step-three ir-in-step-wrap " xl={12}>
        {/* CNS Fields */}
        <Grid lg={4}>
            <Item elevation={0}>
                <Grid>
                <Item elevation={0} style={{ minWidth: "235px" }}>
                    <Typography
                    sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
  
                    }}>
                    {EnglishLanguage ? "Summary of Occurrence" : "Resumen del Ocurrencia"}{currentIncidentReport.incidentSummary !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                </Item>
                <Item
                    elevation={0}
                    sx={{
                    textAlign: "left",
                    paddingTop: "0",
                    marginTop: "-10px",
                    }}>
                    <Narrative />
                </Item>
                </Grid>
            </Item>
        </Grid>
        <Grid lg={4}>
            <Item elevation={0}>
                <Grid>
                <Item elevation={0} style={{ minWidth: "235px" }}>
                    <Typography
                    sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "1.2rem!important",
  
                    }}>
                    {EnglishLanguage ? "Note" : "Nota"}{currentIncidentReport.noteIncident !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                    </Typography>
                </Item>
                <Item
                    elevation={0}
                    sx={{
                    textAlign: "left",
                    paddingTop: "0",
                    marginTop: "-10px",
                    }}>
                    <NoteIncident />
                </Item>
                </Grid>
            </Item>
        </Grid>
        <Grid lg={4}>
          <Item elevation={0}>
            <Grid display="flex" sx={{ alignItems: "space-between!important" }}>
              <Item
                elevation={0}
                sx={{
                  padding: "0!important",
                  margin: "0!important",
                  position: "relative",
                  top: "0rem!important"
                }}>
                <FFUploadButtonCNSIncidentDetails />
              </Item>
            </Grid>
          </Item>
        </Grid>
       </Grid>
      </>
  );
}
