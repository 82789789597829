import * as React from 'react';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import { useCentroStore } from '../../../../store';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function EscalateToFM() {

    //const setescalatetofmcheckbox = useCentroStore((state) => state.setescalatetofmcheckbox);
    const setEscalateToFacilityManager = useCentroStore((state) => state.setescalatetofaciiltymanager);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const handleChange = (event) => {
        //setescalatetofmcheckbox(event.target.value);
        setEscalateToFacilityManager(event.target.checked === true ? true : false);
        //if (event.target.checked) {
        //    setEscalateToFacilityManager(true);
        //}
        //else {
        //    setEscalateToFacilityManager(false);
        //}
    };

  return (
    <div>
      <Checkbox
      defaultChecked ={currentIncidentReport.facilityManagementEscalate}
      onChange={handleChange}
        {...label}
        sx={{
          color: "#f7b01a",
          '&.Mui-checked': {
            color: pink[600],
          },
        }}
      />
    </div>
  );
}