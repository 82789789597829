import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom"
import Grid from "@mui/material/Grid";
import TopNavBar from "./TopNavBar";
import FacilityDropdown from "./forms/FacilityDropdown";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useCentroStore } from "../store";
import { useUser, useUpdateUserPreferences } from "../store/queries/userQueries";
import { useEffect } from "react";
import { useQueryClient } from "react-query";

import LanguageSelect from "./user-settings/LanguageSelect";
import DefaultFacilitySelect from "./user-settings/DefaultFacilitySelect";
import DarkMode from "./DarkMode";
import TemperatureSelect from "./user-settings/TemperatureSelect";
import WindSpeedSelect from "./user-settings/WindSpeedSelect";
import HeaderSection from "./header/HeaderSection";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

const UserSettings = (props) => {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  const userId = useCentroStore((state) => state.userId);
  const { userData, useUpdateUserPreferences } = useUser(userId);
  const userPreferences = useCentroStore((state) => state.userPreferences);
  const isMounted = React.useRef(false); // For help not running during First Render
  const queryClient = useQueryClient();
  const currentFacility = useCentroStore((state) => state.currentFacility);

  //const setuserloggedin = useCentroStore((state) => state.setuserloggedin);
  //useEffect(()=>{setuserloggedin("Yes")})
    
  useEffect(() => {
        async function handleSavePreferences() {
            let response;
            console.log("Saving User Preferences");
            //console.log(userPreferences);
            response = await useUpdateUserPreferences.mutateAsync(userPreferences);
            if (response.status === 200) {
                console.log("Successfully saved User Preferences");
                //queryClient.refetchQueries({ queryKey: ["user"], type: 'active' });
                queryClient.refetchQueries({ queryKey: ["users"], type: 'active' });
                // Refetching weather for if language changed
                var weatherInputModel = {
                    facId: currentFacility.facilityId,
                    language: (userPreferences.preferredLanguage === "English" ? true : false)
                };
                queryClient.refetchQueries({ queryKey: ["weather-forcast", currentFacility.facilityId], type: 'active' }); 
            }
        }

      if (isMounted.current) {
          handleSavePreferences();
      } else {
          isMounted.current = true;
          return;
      }
  }, [userPreferences.defaultFacilityId, userPreferences.preferredLanguage, userPreferences.weatherTempMetric, userPreferences.windSpeedMetric]);


  return (
    <Container component="main" maxWidth="none" className="settings-setup">
      <HeaderSection sectionName="FAQ" sectionNameES="FAQ"/>
      <Grid
       className="page-wrap"
        container
        sx={{
          padding: "0",
          margin: "0",

          width: "calc(100vw - 20rem)",
        }}
      >
        <Grid
          container
          spacing={0}
          xl={12}
          padding={1}
          sx={{ height: "calc(100vh - 7.9rem)",  alignContent: "flex-start" }}
        >
          <Grid
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                id="modal-modal-title"
                component="h4"
                sx={{ textAlign: "center", padding: "1rem 0.65rem 0" }}
              >
                 {EnglishLanguage ? ("User Settings") : ("Ajustes de Usuario")}
              </Typography>
            </Item>

            <Grid
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="link-hover-home"
            >



            </Grid>
          </Grid>
          <Grid lg={12} px={2} mt={3} pb={4} overflow="hidden!important">
          <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                component="h6"
              >
                  {EnglishLanguage ? ("Default Facility") : ("instalaci\xF3n por defecto")} 
              </Typography>
            </Item>

            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
            <DefaultFacilitySelect />
            </Item>
          </Grid>
          <Grid lg={12} px={2} mt={3} pb={4} overflow="hidden!important">
          <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                component="h6"
              >
                {EnglishLanguage ? ("Preferred Language") : ("Idioma preferido")} 
              </Typography>
            </Item>

            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
           <LanguageSelect />
            </Item>
          </Grid>
          {/* Dark Theme Mode will be implemented in the future **
          <Grid lg={12} px={2} mt={3}  pb={4} overflow="hidden!important">
          <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                component="h6"
              >
                

                {EnglishLanguage ? (<span>Enable Dark Mode? </span>) : (<span>habilitar el modo oscuro? </span>)}
                
              </Typography>
            </Item>

            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
           <DarkMode />
            </Item>
          </Grid>
          */}
          <Grid lg={12} px={2} mt={3}  pb={4} overflow="hidden!important">
          <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                component="h6"
              >
                

                {EnglishLanguage ? (<span>Temperature Scale </span>) : (<span>Escala de Temperatura </span>)}
                
              </Typography>
            </Item>

            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
           <TemperatureSelect />
            </Item>
          </Grid>
          <Grid lg={12} px={2} mt={3}  pb={4} overflow="hidden!important">
          <Item elevation={0} sx={{ background: "transparent" }}>
              <Typography
                component="h6"
              >
                

                {EnglishLanguage ? (<span>Wind Speed </span>) : (<span>Velocidad Del Viento </span>)}
                
              </Typography>
            </Item>

            <Item
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
              }}
            >
           <WindSpeedSelect />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserSettings;
