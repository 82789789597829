import React, { useState, useEffect } from "react";
import { Grid, Button, IconButton, Snackbar, Checkbox, FormControlLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OccurrenceReportTipsText from "./tips/OccurrenceReportTipsText";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DropdownTipImage from "./tips/DropdownTipImage";
import DropdownOptionsTipsText from "./tips/DropdownOptionsTipsText";
import StepOneIsComplete from "./tips/StepOneIsComplete";

const tips = [
  {
    image: <PriorityHighIcon />,
    text: <StepOneIsComplete />,
  },
];

const StepOneIsCompleteNotification = () => {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [tipIndex, setTipIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpen(true);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };


  if (disabled) {
    return null;
  }

  return (
    <Snackbar
      open={open}

      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      className="steps-complete-wrap"
      message={
        <Grid container direction="column" alignItems="flex-end" sx={{ display: "flex!important" }} spacing={0}>
          <Grid container direction="column">
            <Grid container className="tips-header-container">
            <Grid item justifyContent="flex-end" sx={{ display: "flex!important" }}>
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon fontSize="small" sx={{fill: "#FFF"}} />
              </IconButton>
            </Grid>
            <Grid container alignItems="center" spacing={0} flex justifyContent="space-between" className="step-one-complete-image-wrap">
                <Grid item className="tips-image-inner-wrap">
                  {tips[tipIndex].image}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="tips-text-container">

              <Grid container alignItems="center" spacing={0}>
                <Grid item>{tips[tipIndex].text}</Grid>
              </Grid>
            </Grid>
            
          </Grid>
        </Grid>
      }
    />
  );
};

export default StepOneIsCompleteNotification;
