import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import DoneIcon from '@mui/icons-material/Done';
// import InstrumentVisualWeatherDropdown from "./general-information/InstrumentVisualWeatherDropdown";
// import MetarReading from "./general-information/MetarReading";
// import ACOneVisibilityCheckboxes from "./general-information/ACOneVisibilityCheckboxes";
// import ACTwoVisibilityCheckboxes from "./general-information/ACTwoVisibilityCheckboxes";
// import CloudsDropdown from "./general-information/CloudsDropdown";
// import VerticalDistanceFromClouds from "./general-information/VerticalDistanceFromClouds";
// import AboveBelowDropdown from "./general-information/AboveBelowDropdown";
// import InCloudConditionsDropdown from "./general-information/InCloudConditionsDropdown";
import InfoIcon from "@mui/icons-material/Info";
import HeadingRouteText from "./general-informtation/HeadingRouteText";
import LongitudeText from "./general-informtation/LongitudeText";
import LatitudeText from "./general-informtation/LatitudeText";
import ClosestHorizontalText from "./general-informtation/ClosestHorizontalText";
import ClosestVerticalText from "./general-informtation/ClosestVerticalText";
import TerrainAlertWarningDropdown from "./general-informtation/TerrainAlertWarningDropdown";
import { useCentroStore } from "../../../store";
import MapCoords from "./general-informtation/MapCoords";
import OccurrenceSurfaceDropdown from "./general-informtation/OccurrenceSurfaceDropdown";
import RunwayDropdown from "./general-informtation/RunwayDropdown";
import OtherOccurranceSurfaceDropdown from "./general-informtation/OtherOccurranceSurface";
import OtherOccurranceSurface from "./general-informtation/OtherOccurranceSurface";
import RCRScoreText from "./general-informtation/RCRScoreText";
import RunwaySurfaceDegradation from "./general-informtation/RunwaySurfaceDegradation";
import MapAnalytics from "../../MapAnalytics";
import MapSurfaceWrap from "./general-informtation/MapSurfaceWrap";
import MapDisplaySurface from "./general-informtation/MapDisplaySurface";
import MapLocationFind from "./general-informtation/MapLocationFind";
import { Tooltip } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function IncidentReportStepSix() {
  //const showairporttwo = useCentroStore((state) => state.showairporttwo);
  const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const isOccurrenceSurface = useCentroStore((state) => state.isOccurrenceSurface);
  const currentincidenttype = useCentroStore((state) => state.currentincidenttype);

  return (
    <Grid container py={0} spacing={2} mt={0} className="ir-form-wrapper">
      {currentincidenttype === "airborne" && (
        <Grid xs={12}>
          <Item elevation={0} sx={{ padding: "0" }}>
            <Grid xs={12}>
              <Grid xs={12} className="step-three-wrap" mb={1}>
                <Grid
                  container
                  className="ir-form-wrapper"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{background: "#00000033", borderRadius: ".5rem", padding: "10px 10px 15px"}}
                >


            {/* General Information Section */}
                <Grid container  className="section-wrapper" sm={12}>
                  <Grid xs={4} >
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} style={{ minWidth: "235px" }}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Heading Route") : ("Encabezamiento/Ruta")} 
                            {currentIncidentReport.headingRoute !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <HeadingRouteText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                {/*
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                              {EnglishLanguage ? ("Latitude") : ("Latitud")} 
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <LatitudeText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                
                  
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                             {EnglishLanguage ? ("Longitude") : ("Longitud")} 

                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                  
                          }}
                        >
                          <LongitudeText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                */}
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Terrain Alert Warning?") : ("\u00bfAdvertencia Alerta de Terreno?")}
                            {currentIncidentReport.terrainAlertActivated !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                  
                          }}
                        >
                          <TerrainAlertWarningDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  {currentIncidentReport.aircraftCount === 2 && (
                    <>
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Closest Horizontal Distance") : ("Distancia horizontal m\xE1s cercana")}
                            {currentIncidentReport.closestHorizontalDistance !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                      
                          }}
                        >
                          <ClosestHorizontalText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>

                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Closest Vertical Distance") : ("Distancia vertical m\xE1s cercana")}
                            {currentIncidentReport.closestVerticalDistance !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <ClosestVerticalText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  </>
                  )}
               </Grid>   
                  
               </Grid>
          
          </Grid>
          </Grid>
        </Item>
      </Grid>)}
       {currentincidenttype === "surface" && (
      <Grid xs={12}>
        <Item elevation={0} sx={{ padding: "0" }}>
        <Grid xs={12}>
         <Grid xs={12} className="step-three-wrap" mb={1}>           
                <Grid
                  container
                  className="ir-form-wrapper"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ background: "#00000033", borderRadius: ".5rem", padding: "10px 10px 15px" }}>
                  {/* General Information Header */}
                  {/* <Grid xl={12}  className="table-header-wrap">
                <Item elevation={0}>
                   <InfoIcon />
                  </Item>
                  <Item elevation={0}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        paddingBottom: ".25rem",
                        whiteSpace: "nowrap",
                      }}
                      variant="h6"
                    >
                      Surface Additional Information
                    </Typography>
                  </Item>
                </Grid> */}

            {/* General Information Section */}
                <Grid container  className="section-wrapper" xl={12}>
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} style={{ minWidth: "235px" }}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Heading Route") : ("Encabezamiento/Ruta")} 
                            {currentIncidentReport.headingRoute !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <HeadingRouteText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                              {EnglishLanguage ? ("Latitude") : ("Latitud")} 
                              {currentIncidentReport.latitude !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <LatitudeText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                
                  
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                             {EnglishLanguage ? ("Longitude") : ("Longitud")} 
                             {currentIncidentReport.longitude !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                  
                          }}
                        >
                          <LongitudeText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
               
                  <Grid xs={4} sx={{display: "flex", marginLeft: "-.5rem"}}>

                      <Item elevation={0}>
                        <Grid>
                          <Item sx={{ boxShadow: "none!important",}}>
                            <MapCoords />
                          </Item>
                        </Grid>
                      </Item>
                      </Grid>
                      
                    {currentIncidentReport.latitude ?(<Tooltip title={EnglishLanguage ? "This displays the location of the pin on the airport. You can update this location by updating the latitude and longitude or using the Place from Map feature."
                     : "Esto muestra la ubicaci\xF3n del pin en el aeropuerto. Puede actualizar esta ubicaci\xF3n actualizando la latitud y la longitud o utilizando la funci\xF3n Lugar Desde el Mapa" }>
                     <Grid xs={6} pr={0.5} pl={1} mt={0} className="custom-grid">
                    
                      <Item
                        sx={{
                          background: "transparent!important",
                          boxShadow: "none!important",
                        }}>
                          
                        <MapDisplaySurface />
                        
                      </Item>
                    </Grid></Tooltip>) : ""  }
                    {/* <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            Terrain Alert Warning?
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                  
                          }}
                        >
                          <TerrainAlertWarningDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid> */}
                  {currentIncidentReport.aircraftCount === 2 && (
                    <>
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0}>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Closest Horizontal Distance") : ("Distancia horizontal m\xE1s cercana")}
                            {currentIncidentReport.closestHorizontalDistance !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                      
                          }}
                        >
                          <ClosestHorizontalText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>

                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Closest Horizontal Distance") : ("Distancia vertical m\xE1s cercana")}
                            {currentIncidentReport.closestVerticalDistance !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <ClosestVerticalText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>  
                  </>
                  )}        
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Occurrence Surface") : ("SUPERFICIE DE OCCURENCIA")}
                            {currentIncidentReport.occuranceSurface !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <OccurrenceSurfaceDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  {(currentIncidentReport.occuranceSurface === "Runway" || currentIncidentReport.occuranceSurface === "Pista") && (
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Runway") : ("Pista")}
                            {currentIncidentReport.runway !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <RunwayDropdown />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  )}
                  {/* <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            Gate
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <ClosestVerticalText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid> */}
                    {(currentIncidentReport.occuranceSurface === "Other" || currentIncidentReport.occuranceSurface === "Otro")&& (
                  <Grid xs={4}>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Other Occurrence Surface") : ("Superficie de Otra Incidencia")}
                            {currentIncidentReport.otherOccuranceSurface !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <OtherOccurranceSurface />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                    )}
                  <Grid>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("Runway Surface Degradation") : ("Degradaci\xF3n de Pista")}
                            {currentIncidentReport.runwaySurfaceDegradation !== 0 && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <RunwaySurfaceDegradation />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid>
                    <Item elevation={0}>
                      <Grid>
                        <Item elevation={0} >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              textAlign: "left",
                              fontSize: "1.2rem!important",
                            }}
                          >
                            {EnglishLanguage ? ("RCR Score Details") : ("DETALLES DE LA PUNTUACI\xF3N RCR")}
                            {currentIncidentReport.rcrScoreDetails !== "" && (<DoneIcon className="checkbox-valdation-icon"/>)}
                          </Typography>
                        </Item>
                        <Item
                          elevation={0}
                          sx={{
                            textAlign: "left",
                            paddingTop: "0",
                            marginTop: "-10px",
                          }}
                        >
                          <RCRScoreText />
                        </Item>
                      </Grid>
                    </Item>
                  </Grid>
               </Grid>   
                  
               </Grid>
          
          </Grid>
          </Grid>
        </Item>
      </Grid>)}
    </Grid>
  );
}
