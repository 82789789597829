import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
export default function RCRScoreText() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setrcrScoreDetails = useCentroStore((state) => state.setrcrScoreDetails);

    const blurHandler = (event) => {
        //currentIncidentReport.rcrScoreDetails = event.target.value;
        setrcrScoreDetails(event.target.value);
    }
  return (
<TextField
          id="standard-multiline-static"
          label={EnglishLanguage ? "Text" : "Texto"}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          rows={1}
          defaultValue={currentIncidentReport.rcrScoreDetails }
          variant="standard"
          onBlur={blurHandler }
        />
  );
};