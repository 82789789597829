import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import { useCentroStore } from '../../../../store';

const options = [
'Airport Operator',
'ANSP',
'Tug',
'Tug with Aircraft',
'Contractor',
'Aircraft Not Intended for Flight',
'Other'
];

const optionsSpanish = [
'Operador de Aeropuerto',
'ANSP',
'Tir\xF3n',
'Remolcador con Aviones',
'Contratista',
'Aeronave no Prevista para Volar',
'Otro'
];

export default function VehicleDriverType() {

    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setvehicletype = useCentroStore((state) => state.setvehicletype);


   const handleChange = (event) => {

       setvehicletype(event.target.value);
   };

  return (
    <Box sx={{ minWidth: 120 }}>
<FormControl  fullWidth variant="standard">
        <Select
        className='select-wrap'
        label="Select"
          value={currentIncidentReport.vehicleType}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>;
            }

            return selected;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >


          <MenuItem disabled value="">
            <em>{EnglishLanguage ? ("Select") : ("Seleccionar")}</em>
          </MenuItem>
          {EnglishLanguage ? 
          options.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          )) 
          :
          optionsSpanish.map((age) => (
            <MenuItem
              key={age}
              value={age}
            >
              {age}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
  );
}

