import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCentroStore } from "../../store";
import SessionTimeoutDialog from "./SessionTimeoutDialog";

//const timeout = 300000;
//const promptBeforeIdle = 180000;

const SessionTimeout = () => {
    const setAuth = useCentroStore((state) => state.setAuth);
    const setUserId = useCentroStore((state) => state.setUserId);
    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [remaining, setRemaining] = useState(0);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const handleLogout = async (isTimedOut = false) => {
        try {
            setTimeoutModalOpen(false);
            setAuth(false);
            setUserId(0);
            queryClient.clear();
            sessionStorage.clear();
            navigate("/");
        }
        catch (err) {
            console.log(err);
        }
    };

    const onActive = () => {
        setTimeoutModalOpen(false);
    };

    const onPrompt = () => {
        setTimeoutModalOpen(true);
    }

    const onIdle = () => {
        handleLogout(true);
    };


    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        //timeout: 1000*60*8, // 8 Minutes
        timeout: 1000 * 60 * 5, // 5 Minutes
        //timeout: 1000 * 60 * 1, 
        promptBeforeIdle: 1000 * 60 * 3, // 3 Minutes
        //promptBeforeIdle: 1000 * 30 * 1, 
        crossTab: true,
        throttle: 500,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500);

        return () => {
            clearInterval(interval);
        }
    });

    const handleStillHere = () => {
        activate();
    }


    return (
        <>
            <SessionTimeoutDialog
                countdown={remaining}
                onContinue={handleStillHere}
                onLogout={() => handleLogout(false)}
                open={timeoutModalOpen} />
        </>);
}
export default SessionTimeout;