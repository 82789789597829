
// or for Moment.js
//import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useCentroStore } from '../../../../store';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, es } from "date-fns/locale";

export default function MaterialUIPickers() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const setIncidentDatetime = useCentroStore((state) => state.setincidentdatetime);

  return (//AdapterDayjs
    <LocalizationProvider locale={EnglishLanguage ? enUS : es } dateAdapter={AdapterDateFns}>
 <MobileDateTimePicker
    fullWidth
    label={EnglishLanguage ? "Enter DateTime Info" : "Ingrese la Informaci\xF3n de Fecha y Hora" }
    sx={{minWidth: "500px!important"}}
              placeholder={EnglishLanguage ? "MM/DD/YYYY" : "DD/MM/AAAA"}
              ampm={false }
              value={currentIncidentReport.incidentDatetime}
              
    onChange = {(newValue) => {
        setIncidentDatetime(newValue);
    }}
    
    renderInput={({ inputRef, inputProps, InputProps }) => (
      <Box className="date-picker-wrapper" sx={{ display: 'flex', alignItems: 'center', border: 'none' }}>
            <TextField ref={inputRef} {...inputProps} placeholder={EnglishLanguage ? "MM/DD/YYYY" : "DD/MM/AAAA"} sx={{ width: "100%" }} className="date-padding-fix time-input" />
        {InputProps?.endAdornment}
      </Box>
    )}
  />
    </LocalizationProvider>
  );
}
