import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useCentroStore } from '../../store';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQAccordion() {
  const [expanded, setExpanded] = React.useState('panel1');
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage); 
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{ EnglishLanguage ? "What is Centro?" : "¿Qu\xE9 es Centro?"} <span className="faq-description">{EnglishLanguage ? "An essential safety tool for modern ANSPs." : "Una herramienta de seguridad esencial para los ANSP modernos."}</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography varient="p" className="faq-text" >
          {EnglishLanguage ? "Created by Infina, Centro is a tool for collecting, organizing and visualizing operational safety data. It enables your ANSP to make smarter safety decisions for your airspace and aerodromes. " 
          : "Creado por Infina, Centro es una herramienta para recopilar, organizar y visualizar datos de seguridad operacional. Permite que su ANSP tome decisiones de seguridad operacional m\xE1s inteligentes para su espacio a\xE9reo y aer\xF3dromos."}
          </Typography>
          <Typography  varient="p" className="faq-text"  >
          {EnglishLanguage ? "Based on ICAO standards and international best practices, the web-based application makes it easy for your team to collect crucial safety-related data, then visualize trends and generate ICAO reporting information. " 
          : "Basada en los est\xE1ndares de la OACI y las mejores pr\xE1cticas internacionales, esta aplicaci\xF3n web permite a su equipo recopilar datos cruciales relacionados con la seguridad operacional, visualizar tendencias y generar informaci\xF3n para reportar a la OACI."}
          </Typography>
          <Typography  varient="p" className="faq-text"  >
          {EnglishLanguage ? "Centro is set up to address ICAO reporting requirements and uses ICAO defined terminology and classifications in a guided series of windows to facilitate the data entry process. Using the ICAO terminology allows for simplified report submission." 
          : "Centro est\xE1 configurado para abordar los requisitos de informes de la OACI y utiliza la terminolog\xEDa y clasificaciones definidas por la OACI en una serie de pasos para facilitar el proceso de ingreso de datos. El uso de la terminolog\xEDa de la OACI permite simplificar la presentaci\xF3n de informes."}
          </Typography>
          <Typography  varient="p" className="faq-text"  >
          {EnglishLanguage ? "Centro's analytics tools allow your ANSP to see this data aggregated over time so you can identify safety challenges and concerns. With this information, your ANSP can conduct risk mitigation and build a modern, robust Safety Management System (SMS)." 
          : "Las herramientas de an\xE1lisis de Centro le permiten a su ANSP ver estos datos agregados a lo largo del tiempo para que pueda identificar los desafíos y preocupaciones de seguridad operacional. Con esta informaci\xF3n, su ANSP puede llevar a cabo la mitigaci\xF3n de riesgos y construir un Sistema de Gesti\xF3n de Seguridad Operacional (SMS) moderno y s\xF3lido. "} 
          </Typography>
          <Typography  varient="p" className="faq-text"  >
          {EnglishLanguage ? "In addition, Centro also offers online and instructor-led training through the " : "Además, Centro también ofrece capacitación en línea dirigida por instructores a través del Portal de Aprendizaje para "}
          <a href="https://www.ansplearningportal.com/" class="faq-external-link"  rel="noreferrer" target="_blank">{EnglishLanguage ? "ANSP Learning Portal" : "ANSP (ALP)"}</a>
          {EnglishLanguage ? ", created by Infina in partnership with CANSO. This optional training module can enhance your ANSP's safety program. Both will be available in English and Spanish. " 
           : ", creado por Infina en asociaci\xF3n con CANSO. Este m\xF3dulo de capacitaci\xF3n opcional puede mejorar el programa de seguridad operacional de su ANSP. Ambos estar\xE1n disponibles en Ingl\xE9s y Espa\xF1ol."}
          </Typography>
          <Typography  varient="p" className="faq-text"  >
          <i> 
          {EnglishLanguage ? "Note: When using Centro and ANSP Learning Portal, please ensure to always verify and validate our content with your ANSP's policies, procedures, and laws. You must follow your ANSP's policies at all times." 
           : "Nota: Cuando utilice Centro y el Portal de Aprendizaje para ANSP (ALP), aseg\xFArese de verificar y validar siempre nuestro contenido con las pol\xEDticas, los procedimientos y las leyes de su ANSP. Debe seguir las políticas de su ANSP en todo momento. "}
         </i>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{EnglishLanguage ? "How can Centro help my ANSP?" : "¿Como puede Centro ayudar a mi ANSP?"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography varient="p" className="faq-text" >
          {EnglishLanguage ? "A proactive operational safety culture saves lives. Centro enables your ANSP to identify potential risks, then monitor, manage, and develop mitigations before an incident happens." 
          : "Una cultura de seguridad operacional proactiva salva vidas. Centro le permite a su ANSP identificar riesgos potenciales, luego monitorear, administrar y desarrollar mitigaciones antes de que ocurra un ocurrencia. "}
          </Typography>
          <Typography  varient="p" className="faq-text"  >
          {EnglishLanguage ? "Centro helps your ANSP improve its safety culture, reporting, and performance. Centro's analytics dashboard presents your ANSP's incident data over time. This information makes it easier to identify short and long-term safety risks to the ANSP as a whole and on a facility or regional level." 
          : "Centro ayuda a su ANSP a mejorar su cultura de seguridad, informes y desempe\xF1o. El panel de an\xE1lisis de Centro presenta los datos de ocurrencias de su ANSP a lo largo del tiempo. Esta informaci\xF3n facilita la identificaci\xF3n de riesgos de seguridad operacional a corto y largo plazo para el ANSP en su conjunto y a nivel de instalaci\xF3n o regional. "}
          </Typography>
          <Typography  varient="p" className="faq-text"  >
          {EnglishLanguage ? "Centro is based on ICAO common reporting requirements and is customizable to your ANSP needs. This allows your team to easily generate ICAO compliance-related reports using terminology and classifications that already exists in the system." 
          : "Centro se basa en los requisitos de informes comunes de la OACI y se puede personalizar seg\xFAn las necesidades de su ANSP. Esto le permite a su equipo generar fácilmente informes relacionados con el cumplimiento de la OACI utilizando terminolog\xEDa y clasificaciones que ya existen en el sistema. "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{EnglishLanguage ? "Who uses Centro?" : "¿Qui\xE9n utiliza Centro?"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography varient="p" className="faq-text" >
          {EnglishLanguage ? "Centro supports your ANSP's safety personnel, including ATC and CNS staff, senior managers, frontline controllers, and field technicians. With Centro, your safety operation is our focus." 
          : "Centro apoya al personal de seguridad operacional de su ANSP, incluido el personal de ATC y CNS, gerentes senior, controladores de tr\xE1nsito a\xE9reo de primera l\xEDnea y t\xE9cnicos de campo. Con Centro, su seguridad operacional es nuestro enfoque."}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{EnglishLanguage ? "When is Centro useful?" : "¿Cu\xE1ndo es \xFAtil Centro?"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography varient="p" className="faq-text" >
          {EnglishLanguage ? "Centro is a tool for everyday use. With its user-friendly interface, controllers, other ANSP staff, and managers can: " 
          : "Centro es una herramienta para el uso diario. Con su interfaz f\xE1cil de usar, los controladores de tr\xE1nsito a\xE9reo, otro personal de ANSP y administradores pueden:"}
          <ul>
            <li>{EnglishLanguage ? "Report new incidents," : "Reportar nuevos ocurrencias,"}</li>
            <li>{EnglishLanguage ? "Review past reports," : "Revisar informes anteriores,"} </li>
            <li>{EnglishLanguage ? "Keep the team informed," : "Mantener informado al equipo,"} </li>
            <li>{EnglishLanguage ? "Create corrective action plans," : "Crear planes de acci\xF3n correctiva,"}</li>
            <li>{EnglishLanguage ? "Develop procedures, and" : "Desarrollar procedimientos, y"}</li>
            <li>{EnglishLanguage ? "Identify safety risk." : "Identificar el riesgo de seguridad."}</li>
          </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{EnglishLanguage ? "Where can Centro be used?" : "¿D\xF3nde se puede usar Centro?"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography varient="p" className="faq-text" >
          {EnglishLanguage ? "Centro is a mobile-friendly, online application. It can be used just as easily at your desk or out in the field (connectivity to your ANSPs Internet network required). It works on phones, tablets and laptops and is compatible with most browsers." 
          : "Centro es una aplicaci\xF3n en l\xEDnea compatible con dispositivos m\xF3viles. Se puede usar con la misma facilidad en su escritorio o en el campo (se requiere conectividad a la red de Internet de su ANSP). Funciona en tel\xE9fonos, tabletas y computadoras port\xE1tiles, y es compatible con la mayor\xEDa de los navegadores."}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{EnglishLanguage ? "Why Centro?" : "¿Por qu\xE9 Centro?"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography varient="p" className="faq-text" >
          {EnglishLanguage ? "Regardless of size, all ANSPs face risks in operational safety. Centro gives your ANSP the tools to improve upon your airspace and aerodrome safety procedures. It helps you meet global ICAO standards, modernize your safety efforts, and prepare for the future." 
          : "Independientemente del tama\xF1o, todos los ANSP enfrentan riesgos en la seguridad operacional. Centro le brinda a su ANSP las herramientas para mejorar los procedimientos de seguridad de su espacio a\xE9reo y aer\xF3dromo. Ayuda a cumplir con los est\xE1ndares globales de la OACI, modernizar sus esfuerzos de seguridad y prepararse para el futuro."}
          </Typography>
          <Typography varient="p" className="faq-text" >
          {EnglishLanguage ? "A safe and efficient airspace system is vital for a robust economy and a modern way of life. Being proactive about operational safety improves your airspace and builds trust. Centro is a crucial tool in building your trusted airspace." 
          : "Un sistema de espacio a\xE9reo seguro y eficiente es vital para una econom\xEDa s\xF3lida y una forma de vida moderna. Ser proactivo con respecto a la seguridad operacional mejora su espacio a\xE9reo y genera confianza. Centro es una herramienta crucial en la construcci\xF3n de su espacio a\xE9reo de confianza."}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{EnglishLanguage ? "What is ANSP Learning Portal (ALP)?" : "¿Qu\xE9 es el Portal de Aprendizaje para ANSP (ALP)?"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography varient="p" className="faq-text" >
          {EnglishLanguage ? "ANSP Learning Portal " 
          : "El Portal de Aprendizaje para ANSP (ALP, seg\xFAn sus siglas en ingl\xE9s) "}
          {EnglishLanguage ? "is a comprehensive, safety-focused training platform designed to address the training needs for a modern ANSP. Created by Infina, all of our courses are thoroughly researched and based on ICAO standards and international best practices. We use proven learning strategies in the development of our microlearning modules and videos, webinars, briefings, and multi-day courses. Topics range from ATCO training, including Emergencies and Human Factors, to Safety Management Systems, Safety Resiliency, Surface Weather, and CNS Communications. We use high engagement value techniques, including immersive video, audio, and graphic media, so participants retain more of what they learned. Visit " 
          : "es una plataforma de capacitaci\xF3n integral centrada en la seguridad diseñada para abordar las necesidades de capacitaci\xF3n de un ANSP moderno. Creados por Infina, todos nuestros cursos se investigan minuciosamente y se basan en los est\xE1ndares de la OACI y las mejores pr\xE1cticas internacionales. Usamos estrategias de aprendizaje comprobadas en el desarrollo de nuestros m\xF3dulos y videos de microaprendizaje, seminarios web, sesiones informativas y cursos de varios d\xEDas. Los temas van desde la capacitaci\xF3n de ATCO, incluidas las emergencias y los factores humanos, hasta los sistemas de gesti\xF3n de la seguridad, la resiliencia de la seguridad, el clima en la superficie y las comunicaciones CNS. Usamos t\xE9cnicas de alto valor de participaci\xF3n, que incluyen video, audio y medios gr\xE1ficos inmersivos, para que los participantes retengan m\xE1s de lo que aprendieron. Visite "}
          <a href="https://www.ansplearningportal.com/" class="faq-external-link"  rel="noreferrer" target="_blank">ansplearningportal.com</a>
          {EnglishLanguage ? " for further information." 
          : " para obtener m\xE1s informaci\xF3n."}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{EnglishLanguage ? "How can I get more information?" : "¿C\xF3mo puedo obtener m\xE1s informaci\xF3n?"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography varient="p" className="faq-text" >
          {EnglishLanguage ? "We look forward to hearing from you! Please contact us at " 
          : "¡Esperamos con inter\xE9s escuchar de usted! Cont\xE1ctenos en "}
          <a href="mailto:corporate@infina.net" class="faq-external-link" rel="noreferrer" target="_blank">corporate@infina.net</a> 
          {EnglishLanguage ? " or (703) 556-4944. Visit us at " 
          : " o +01-703-556-4944. Visítenos en "}
          <a href="https://www.infina.net/" class="faq-external-link"  rel="noreferrer" target="_blank">https://www.infina.net/</a>.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}