import * as React from 'react';
import { TextareaAutosize } from '@mui/material';
import { useCentroStore } from '../../../../store';
export default function Narrative() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setIncidentSummary = useCentroStore((state) => state.setincidentsummary);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);

    const blurHandler = (event) => {
        setIncidentSummary(event.target.value);
    }

  return (
<TextareaAutosize
          id="standard-multiline-static"
          placeholder={EnglishLanguage ? "Description of the event" : "Descripci\xF3n del evento"}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          multiline
          rows={1}
          defaultValue={currentIncidentReport.incidentSummary }
          variant="standard"
          onBlur={blurHandler}
        />
  );
};