import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCentroStore } from '../../../../store';
export default function LongitudeText() {
    const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
    const setLongitude = useCentroStore((state) => state.setlongitude);
    const currentIncidentReport = useCentroStore((state) => state.currentincidentreport);
    const blurHandler = (event) => {
        setLongitude(event.target.value);
    }

  return (
<TextField
          id="standard-multiline-number"
          label={EnglishLanguage ? "Number" : "N\xFAmero"}
          sx={{ color: "#848484", fontFamily: "'Montserrat', Ariel!important" }}
          fullWidth
          rows={1}
          defaultValue={currentIncidentReport.longitude === 0 || isNaN(currentIncidentReport.longitude)? "" : currentIncidentReport.longitude }
          variant="standard"
          onBlur={blurHandler}
          onInput={e => setLongitude(e.target.value)}
          value={currentIncidentReport.longitude}
        />
  );
};